/** Override Blockly.prompt() with custom implementation. */
Blockly.prompt = function (message, defaultValue, callback) {
  document.getElementById("new_variable").style.display = "block";
  let varible_name = document.getElementById("varible_name");
  varible_name.focus();
  let create_variable = document.getElementById("create-button");
  create_variable.addEventListener('click', () => {
    if (varible_name.value === "") callback(null);
    callback(varible_name.value);
    varible_name.value = "";
  });
  varible_name.addEventListener('keypress', (e) => {
    if (e.key === 'Enter') {
      if (varible_name.value === "") callback(null);
      callback(varible_name.value);
      varible_name.value = "";
      document.getElementById("new_variable").style.display = "none";
    }
  });
};

const getBlockValue = (block, name) => {
  return Blockly.Python.valueToCode(block, name, Blockly.Python.ORDER_ATOMIC) || 'None';
}


// Constant: Default values for block properties
const BLOCK_CONFIG_DEFAULTS = {
  tooltip: "",          // Default to empty string if not provided
  helpUrl: "https://google.com" // Default to a generic help URL
};

/**
 * @enum {string}
 * @readonly
 * Defines the possible connection types for Blockly blocks.
 * 
 * - `OUTPUT`: Represents blocks that produce a value that can be connected to other blocks.
 * - `SEQUENTIAL`: Represents blocks that can connect both to previous and next blocks.
 * - `NEXT`: Represents blocks that can only connect to the next block.
 * - `PREVIOUS`: Represents blocks that can only connect to the previous block.
 */
const BlockConnectionType = Object.freeze({
  /** 
   * Represents blocks that produce a value that can be connected to other blocks.
   */
  OUTPUT: 'output',

  /** 
   * Represents blocks that can connect both to previous and next blocks.
   */
  SEQUENTIAL: 'sequential',

  /** 
   * Represents blocks that can only connect to the next block.
   */
  NEXT: 'next',

  /** 
   * Represents blocks that can only connect to the previous block.
   */
  PREVIOUS: 'previous'
});

/**
 * @enum {string}
 * @readonly
 * Defines execution order types for Blockly blocks.
 * 
 * - `ORDER_NONE`: Default order, no specific precedence.
 * - `ORDER_ATOMIC`: Highest precedence, executes independently with parenthesis.
 */
const BlockOrderType = Object.freeze({
  NONE: 'ORDER_NONE',
  ATOMIC: 'ORDER_ATOMIC'
});

/**
 * Enum for the block configuration types.
 * Used for specifying which block configuration.
 * 
 * @readonly
 * @enum {string}
 */
const BlockConfigType = Object.freeze({
  INPUT_VALUE: 'inputValueConfig',
  DROPDOWN: 'dropdownConfig',
  FIELD_INPUT: 'fieldInputConfig',
  FIELD_NUMBER: 'fieldNumberConfig',
});

/**
 * Throws a generic error with an optional code and details.
 * 
 * @param {string} message - The error message.
 * @param {string} [errorCode='GENERAL_ERROR'] - Optional error code.
 * @param {Object} [details={}] - Optional additional details.
 * @throws {Error} The custom error with the provided message and code.
 *
 * @example
 * // Simple error message
 * throwError('Something went wrong!');
 *
 * @example
 * // Error with custom code and details
 * throwError('Invalid orderType', 'INVALID_ORDER_TYPE', { orderType: 'SEQUENTIAL', expectedTypes: ['ATOMIC', 'NONE'] });
 */
function throwError(message, errorCode = 'GENERAL_ERROR', details = {}) {
  // Ensure details is always an object
  details = details || {};
  const errorDetails = Object.keys(details).length > 0 ? JSON.stringify(details, null, 2) : '';
  const formattedMessage = `${message} (Code: ${errorCode})${errorDetails ? `\nDetails: ${errorDetails}` : ''}`;
  throw new Error(formattedMessage);
}

/**
 * Validates that a given value matches a specified type.
 * Optionally checks if the value is non-empty (for string and array types).
 *
 * @param {any} value - The value to be checked.
 * @param {'string' | 'array' | 'object' | 'boolean' | 'number' | 'function'} type - The expected type of the value
 * @param {boolean} [nonEmpty=false] - If true, checks if the value is non-empty (for strings and arrays).
 * @param {boolean} [allowWhitespace=false] - For string validation: if true, allows whitespace-only strings as valid.
 * 
 * @returns {boolean} - Returns true if the value matches the specified type and non-empty condition (if applicable), otherwise false.
 * 
 * @example
 * // Check if a value is a non-empty string
 * isValidType("Hello", "string", true);  // Returns true
 * 
 * @example
 * // Check if a value is a non-empty array
 * isValidType([1, 2, 3], "array", true);  // Returns true
 * 
 * @example
 * // Check if a value is a boolean
 * isValidType(true, "boolean");  // Returns true
 * 
 * @example
 * // Check if a value is a number (not NaN)
 * isValidType(42, "number");  // Returns true
 * 
 * @example
 * // Check if a value is a function
 * isValidType(function() {}, "function");  // Returns true
 */
function isValidType(value, type, nonEmpty = false, allowWhitespace = false) {
  const typeChecks = {
    string: () => {
      if (typeof value !== "string") return false;
      if (nonEmpty) {
        // If nonEmpty is true and allowWhitespace is true, only trim leading/trailing spaces
        return allowWhitespace ? value.length > 0 : value.trim() !== "";
      }
      return true;
    },
    array: () => {
      if (!Array.isArray(value)) return false;
      if (nonEmpty) {
        // If nonEmpty is true, the array must have at least one item
        return value.length > 0;
      }
      return true;
    },
    object: () => value && typeof value === "object" && !Array.isArray(value),
    boolean: () => typeof value === "boolean",
    number: () => typeof value === "number" && !isNaN(value),
    function: () => typeof value === "function"
  };

  // Ensure that the type is valid
  if (!typeChecks[type]) {
    throw new Error(`Invalid type: ${type}`);
  }

  return typeChecks[type]() || false;
}

/**
 * Generates a validation function for checking if a value matches a specified type.
 * If the value doesn't match the type (and non-empty condition, if applicable), an error is thrown.
 * 
 * @param {'string' | 'array' | 'object' | 'boolean' | 'number' | 'function'} type - The expected type of the value
 * @param {string} errorMessage - The error message to throw when the validation fails.
 * @param {boolean} [nonEmpty=false] - If true, checks if the value is non-empty (for strings and arrays).
 * @param {boolean} [allowWhitespace=false] - For string validation: if true, allows whitespace-only strings as valid.
 * 
 * @returns {Function} - A validation function that takes a value and an optional field name, and throws an error if the validation fails.
 * 
 * @example
 * const validateString = generateValidator('string', 'must be a valid string');
 * 
 * // This will pass
 * validateString('Hello, world!');
 * 
 * // This will throw an error: 'value must be a valid string'
 * validateString(123);
 * 
 * @example
 * const validateNonEmptyArray = generateValidator('array', 'must be a non-empty array', true);
 * 
 * // This will pass
 * validateNonEmptyArray([1, 2, 3]);
 * 
 * // This will throw an error: 'value must be a non-empty array'
 * validateNonEmptyArray([]);
 * 
 * @example
 * const validateNonEmptyStringAllowWhitespace = generateValidator('string', 'must be a non-empty string (whitespace allowed)', true, true);
 * 
 * // This will pass (string with only whitespace is valid)
 * validateNonEmptyStringAllowWhitespace('   ');
 * 
 * // This will throw an error: 'value must be a non-empty string (whitespace allowed)'
 * validateNonEmptyStringAllowWhitespace('');
 */
function generateValidator(type, errorMessage, nonEmpty = false, allowWhitespace = false) {
  return (value, fieldName = 'value') => {
    // Handle null and undefined values explicitly before validation
    if (value == null) { // Handles both null and undefined
      throwError(`Field "${fieldName}" ${errorMessage}`, "VALIDATION_ERROR", { value, expectedType: type });
    }
    
    // Validate the type with the additional 'allowWhitespace' for strings
    if (!isValidType(value, type, nonEmpty, allowWhitespace)) {
      throwError(`Field "${fieldName}" ${errorMessage}`, "VALIDATION_ERROR", { value, expectedType: type });
    }
  };
}

/**
 * Validator for strings.
 * Validates that the given value is a string.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @param {boolean} [allowWhitespace=false] - Flag to allow whitespace-only strings as valid.
 * @throws {Error} If the value is not a string.
 */
const validateString = generateValidator('string', 'must be a string');

/**
 * Validator for non-empty strings.
 * Validates that the given value is a string and non-empty.
 * Optionally, allow whitespace-only strings as valid.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @param {boolean} [allowWhitespace=false] - Flag to allow whitespace-only strings as valid.
 * @throws {Error} If the value is not a non-empty string.
 */
const validateNonEmptyString = generateValidator('string', 'must be a non-empty string', true, false);

/**
 * Validator for non-empty strings that may allow whitespace.
 * This validator is explicitly for cases where you want to allow strings that are not empty
 * but may contain whitespace characters.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a non-empty string or contains only whitespace.
 */
const validateNonEmptyStringAllowWhitespace = generateValidator('string', 'must be a non-empty string (whitespace allowed)', true, true);

/**
 * Validator for objects.
 * Validates that the given value is an object (and not null).
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a valid object.
 */
const validateObject = generateValidator('object', 'must be a valid object');

/**
 * Validator for functions.
 * Validates that the given value is a function.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a valid function.
 */
const validateFunction = generateValidator('function', 'must be a valid function');

/**
 * Validator for arrays.
 * Validates that the given value is a non-empty array.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a non-empty array.
 */
const validateArray = generateValidator('array', 'must be a non-empty array', true);

/**
 * Validator for booleans.
 * Validates that the given value is a boolean.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a boolean.
 */
const validateBoolean = generateValidator('boolean', 'must be a boolean');

/**
 * Validator for numbers.
 * Validates that the given value is a valid number.
 * Optionally checks if the number is a valid, non-NaN number.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a valid number.
 */
const validateNumber = generateValidator('number', 'must be a valid number');

/**
 * Validator for non-NaN numbers.
 * Validates that the given value is a number and is not NaN.
 * 
 * @param {any} value - The value to validate.
 * @param {string} [fieldName='value'] - The name of the field to be validated.
 * @throws {Error} If the value is not a valid number or is NaN.
 */
const validateNonNaNNumber = generateValidator('number', 'must be a valid number and not NaN', false, false);


/**
 * Validates that a value is part of a valid array of values.
 * Checks both that the validValues is an array and the value is included in it.
 *
 * @param {any} value - The value to validate.
 * @param {Array} validValues - The allowed values to check against.
 * @param {string} fieldName - The name of the field being validated
 * @throws {Error} If validation fails.
 */
function validateValueInArray(value, validValues, fieldName) {
  // Validate that the array is of correct type
  validateArray(validValues, fieldName);

  // Check if validValues is an array and the value is within it
  if (!validValues.includes(value)) {
    throwError(
      `Field "${fieldName}" has an invalid value '${value}'. Expected one of the value: ${validValues.join(', ')}.`,
      "VALIDATION_ERROR"
    );
  }
}

/**
 * Validates that an object only contains specified valid keys and throws an error if it doesn't match.
 * @param {Object} obj - The object to validate.
 * @param {Array<string>} validKeys - Array of valid keys that the object can have.
 * @param {string} [fieldName='objectValue'] - The name of the field
 * @throws {Error} If there are any invalid keys.
 */
function validateObjectKeys(obj, validKeys, fieldName = 'objectValue') {
  // Validate that the object is of correct type
  validateObject(obj, fieldName);

  // Filter out keys that are not in the validKeys array
  const invalidKeys = Object.keys(obj).filter(key => !validKeys.includes(key));

  // If there are invalid keys, throw an error
  if (invalidKeys.length > 0) {
    throwError(
      `Field "${fieldName}" has invalid keys: '${invalidKeys.join(', ')}'. Expected one of the keys: [ ${validKeys.join(', ')} ].`,
      "VALIDATION_ERROR"
    );
  }
}

/**
 * Validates that a given value is one of the valid values in an object.
 * Throws an error if the value is not found in the object's values.
 * 
 * @param {any} value - The value to validate.
 * @param {Object} validObject - The object whose values are used for validation.
 * @param {string} fieldName - The name of the field being validated (used in the error message).
 * 
 * @example
 * const validRoles = { admin: 'admin', user: 'user' };
 * validateValueInObject('admin', validRoles, 'role'); // Passes
 * validateValueInObject('guest', validRoles, 'role'); // Throws error: "role value 'guest' is invalid. Expected one of: admin, user."
 */
function validateValueInObject(value, validObject, fieldName) {
  validateObject(validObject, fieldName);

  // Extract all values from the validObject
  const validValues = Object.values(validObject);

  // If value is not found in validValues, throw an error
  if (!validValues.includes(value)) {
    throwError(
      `Field "${fieldName}" has an invalid value '${value}'. Expected one of the value: ${validValues.join(', ')}.`,
      "VALIDATION_ERROR"
    );
  }
}

/**
 * Validates that an object's keys and values match those in a valid object.
 * Recursively checks nested objects and reports any invalid keys or values.
 * 
 * @param {Object} value - The object to validate.
 * @param {Object} validObject - The object containing the valid keys and values to compare against.
 * @param {string} fieldName - The name of the field being validated (used in the error message).
 * @param {string} [parentKey=''] - The key path for nested validation (used for recursion).
 * 
 * @example
 * const validConfig = { name: 'string', age: 30 };
 * const input = { name: 'John', age: 25 };
 * validateObjectKeysAndValues(input, validConfig, 'userConfig'); // Throws error: "Object Validation failed for userConfig."
 */
function validateObjectKeysAndValues(value, validObject, fieldName, parentKey = '') {
  // Validate that value is an object of the correct type
  validateObject(value, fieldName);

  // Arrays to collect invalid keys and values for reporting
  const invalidKeys = [];
  const invalidValues = [];

  // Iterate over the keys of the value object
  Object.keys(value).forEach(key => {
    // Construct the full key path (supports nested objects)
    const fullKey = parentKey ? `${parentKey}.${key}` : key;
    const validValue = validObject[key];

    // If the key does not exist in validObject, it's an invalid key
    if (!validObject.hasOwnProperty(key)) {
      invalidKeys.push(fullKey);
    } 
    // If both the valid and value objects are nested objects, recurse to validate them
    else if (isValidType(validValue, 'object') && isValidType(value[key], 'object')) {
      validateObjectKeysAndValues(value[key], validValue, fieldName, fullKey);
    } 
    // If the key's value does not match the expected value, it's an invalid value
    else if (validValue !== value[key]) {
      invalidValues.push({
        key: fullKey,
        expected: validValue,
        actual: value[key]
      });
    }
  });

  // If there are any invalid keys or values, throw an error
  if (invalidKeys.length > 0 || invalidValues.length > 0) {
    const errorDetails = {
      fieldName,
      invalidKeys,
      invalidValues,
      message: `Object Validation failed for ${fieldName}.`
    };
    throwError(errorDetails.message, 'OBJECT_VALIDATION_ERROR', errorDetails);
  }
}

/**
 * Filters the entries of an object based on a specified filter type ('key' or 'value').
 * Excludes the specified entry (key or value) from the resulting array.
 * 
 * @param {Object} obj - The object whose entries are to be filtered.
 * @param {string} exclude - The key or value to exclude from the result.
 * @param {string} filterType - The type of filter ('key' to filter by keys, 'value' to filter by values).
 * @returns {Array} - An array of filtered entries (either keys or values).
 * 
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * filterObjectEntries(obj, 'a', 'key'); // Returns ['b', 'c']
 * filterObjectEntries(obj, 2, 'value'); // Returns ['a', 'c']
 */
function filterObjectEntries(obj, exclude, filterType) {
  validateObject(obj);
  validateNonEmptyString(exclude);

  // Validate that filterType is either 'key' or 'value'
  if (![ 'key', 'value' ].some(validType => validType === filterType)) {
    throwError("Invalid filterType provided. Expected 'key' or 'value'.");
  }

  // Filter and return keys or values based on filterType
  return Object[filterType === 'key' ? 'keys' : 'values'](obj).filter(entry => entry !== exclude);
}

/**
 * Filters the keys of an object, excluding a specified key.
 * 
 * @param {Object} obj - The object whose keys are to be filtered.
 * @param {string} excludeKey - The key to exclude from the result.
 * @returns {Array} - An array of filtered keys.
 * 
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * filterObjectKeys(obj, 'b'); // Returns ['a', 'c']
 */
function filterObjectKeys(obj, excludeKey) {
  return filterObjectEntries(obj, excludeKey, 'key');
}

/**
 * Filters the values of an object, excluding a specified value.
 * 
 * @param {Object} obj - The object whose values are to be filtered.
 * @param {any} excludeValue - The value to exclude from the result.
 * @returns {Array} - An array of filtered values.
 * 
 * @example
 * const obj = { a: 1, b: 2, c: 3 };
 * filterObjectValues(obj, 2); // Returns [1, 3]
 */
function filterObjectValues(obj, excludeValue) {
  return filterObjectEntries(obj, excludeValue, 'value');
}

/**
 * Dynamically appends a field (dropdown or input value) to a Blockly block.
 * 
 * This function is useful for adding dynamic input fields (either dropdown or value input) to a Blockly block
 * with flexibility. You can use it for any custom block where you need to add either a dropdown or an input field.
 * 
 * @param {Blockly.Block} block - The Blockly block instance to which the field is appended.
 * @param {Object} options - Object containing the properties for the field.
 * @param {'inputValue' | 'dropdown'} options.fieldType - The type of field to append ('dropdown' or 'inputValue').
 * @param {string} options.label - The label displayed for the field.
 * @param {string} options.fieldName - The unique name of the field. This name identifies the field and is used for referencing it.
 * @param {Array<Array<string>>} [options.dropdownOptions] - Array of [label, value] pairs for dropdown fields. Required if fieldType is 'dropdown'.
 * @param {string} [options.checkType] - Type of data check for input fields (e.g., "String", "Number"). Required if fieldType is 'inputValue'.
 * @param {Function} [options.eventHandler] - Optional event handler function for dropdown fields. This is called when the dropdown value changes.
 * 
 * @throws {Error} Throws an error if required parameters are missing or invalid.
 * 
 * @example
 * 
 * **1. Adding an InputValue for Email**:
 * ```javascript
 * appendDynamicField(this, {
 *   fieldType: 'inputValue',
 *   label: 'Email Address',
 *   fieldName: 'email',
 *   checkType: 'String'
 * });
 * ```
 * This adds an inputValue field with the label "Email Address", and ensures that the input value is a string (email).
 * 
 * **2. Adding a Dropdown with Custom Labels and Values**:
 * ```javascript
 * appendDynamicField(this, {
 *   fieldType: 'dropdown',
 *   label: 'Choose an Option',
 *   fieldName: 'options',
 *   dropdownOptions: [
 *     ['Option 1', 'option1'],
 *     ['Option 2', 'option2'],
 *     ['Option 3', 'option3']
 *   ]
 * });
 * ```
 * This adds a dropdown with the options "Option 1", "Option 2", and "Option 3" to the block.
 * 
 * **3. Using `appendDynamicField` for Configurable Block Setup**:
 * ```javascript
 * Blockly.Blocks['custom_configurable_block'] = {
 *   init: function() {
 *     // Adding a dropdown for selecting a task type
 *     appendDynamicField(this, {
 *       fieldType: 'dropdown',
 *       label: 'Select Task',
 *       fieldName: 'taskType',
 *       dropdownOptions: [
 *         ['Task 1', 'task1'],
 *         ['Task 2', 'task2'],
 *         ['Task 3', 'task3']
 *       ]
 *     });

 *     // Adding an input field for the task duration (in minutes)
 *     appendDynamicField(this, {
 *       fieldType: 'input',
 *       label: 'Task Duration (minutes)',
 *       fieldName: 'duration',
 *       checkType: 'Number'
 *     });
 *   }
 * };
 * ```
 */
function appendDynamicField(block, { fieldType, label, fieldName, dropdownOptions, checkType, eventHandler }) {
  validateObject(block, "block"); // Ensure block is valid
  // Validate that the fieldType, label and fieldName are non-empty strings
  const fields = [
    { value: fieldType, name: 'fieldType' },
    { value: label, name: 'label' },
    { value: fieldName, name: 'fieldName' },
  ];
  
  // Validate fields
  fields.forEach(({ value, name }) => {
    validateNonEmptyString(value, name);
  });

  // Define logic for handling field types
  const fieldTypeHandlers = {
    inputValue: () => {
      validateValueInArray(
        checkType,
        ['Array', 'Boolean', 'Colour', 'Number', 'String', null],
        "checkType"
      );
      if (!block.getInput(fieldName)) {
        block.appendValueInput(fieldName)
          .setCheck(checkType)
          .appendField(label)
          .setAlign(Blockly.ALIGN_RIGHT);
      }
    },
    dropdown: () => {
      validateArray(dropdownOptions, `dropdownOptions`);
      const invalidOptionIndex = dropdownOptions.findIndex(
        optionPair => !Array.isArray(optionPair) || 
                      optionPair.length !== 2 || 
                      !optionPair[0] || 
                      !optionPair[1]
      );

      if (invalidOptionIndex !== -1) {
        throwError(`dropdownOptions[${invalidOptionIndex}] is invalid`);
      }
      block.appendDummyInput()
        .appendField(label)
        .appendField(
          new Blockly.FieldDropdown(dropdownOptions, eventHandler ? eventHandler.bind(block) : null),
          fieldName
        )
        .setAlign(Blockly.ALIGN_RIGHT);
    }
  };

  // Execute the appropriate handler for the given field type
  const handler = fieldTypeHandlers[fieldType];
  if (handler) {
    return handler();
  } else {
    throwError(`Unsupported field type "${fieldType}".`, "UNSUPPORTED_FIELD_TYPE", { fieldType });
  }
}

/**
 * Configures the connections of a block based on its connection type.
 * 
 * This function modifies the `baseJson` object to reflect the appropriate block connection configuration
 * based on the provided `connectionType`. The configuration is done by setting `output`, `previousStatement`,
 * and `nextStatement` to `null` based on the connection type, ensuring that blocks have the correct connection behavior.
 *
 * @param {'output' | 'sequential' | 'next' | 'previous'} connectionType - The connection type of the block (e.g., 'output', 'sequential', etc.).
 * @param {Object} baseJson - The base block configuration object that will be modified.
 * 
 * @throws {Error} Throws an error if the `connectionType` is invalid or not supported.
 * 
 * @example
 * const baseJson = {};
 * configureBlockConnections(BlockConnectionType.OUTPUT, baseJson);
 * console.log(baseJson); // { output: null }
 */
function configureBlockConnections(connectionType, baseJson) {
  // Validate that the connectionType is a valid value from BlockConnectionType
  validateValueInObject(connectionType, BlockConnectionType, "blockConnectionType");

  // Define the configuration for each block connection type
  const connectionConfig = {
    // Output blocks can't have input or next connections
    [BlockConnectionType.OUTPUT]: { output: null },

    // Can connect to previous and next blocks
    [BlockConnectionType.SEQUENTIAL]: { previousStatement: null, nextStatement: null },

    // Can only connect to the next block
    [BlockConnectionType.NEXT]: { nextStatement: null },
    
    // Can only connect to the previous block
    [BlockConnectionType.PREVIOUS]: { previousStatement: null }
  };

  // Retrieve the appropriate configuration for the given connectionType
  const config = connectionConfig[connectionType];

  // Apply the config to baseJson if valid
  if (config) {
    Object.assign(baseJson, config);
  } else {
    // If the connectionType is invalid, throw an error
    throwError(`Invalid blockConnectionType: ${connectionType}`, 'INVALID_BLOCK_CONNECTION_TYPE');
  }
}

/**
 * Generates a common block configuration by applying default values and configuring connections.
 * 
 * This function modifies the `baseJson` object by applying default block properties
 * and setting up the block's connection type
 * 
 * @param {Object} baseJson - The base configuration object for the block to be modified.
 * @param {'output' | 'sequential' | 'next' | 'previous'} connectionType - The connection type for the block
 * @returns {Object} The modified `baseJson` object with applied default values and connection settings.
 * 
 * @example
 * const baseJson = {};
 * const connectionType = BlockConnectionType.SEQUENTIAL;
 * const commonBlock = generateCommonBlock(baseJson, connectionType);
 * console.log(commonBlock);
 * // Output: { inputsInline: true, tooltip: "", helpUrl: "https://google.com", previousStatement: null, nextStatement: null }
 */
function generateCommonBlock(baseJson, connectionType) {
  // Validate the baseJson
  validateObject(baseJson, "baseJson");
  
  // Apply default values (inputsInline, tooltip, helpUrl) to baseJson
  Object.keys(BLOCK_CONFIG_DEFAULTS).forEach(key => {
    if (baseJson[key] == null) {
      baseJson[key] = BLOCK_CONFIG_DEFAULTS[key];
    }
  });

  // Configure block connections based on the specified connectionType (OUTPUT, SEQUENTIAL, etc.)
  configureBlockConnections(connectionType, baseJson);

  // Return the modified baseJson with default values and connection configurations
  return baseJson;
}

/**
 * Validates an object-based block connection configuration.
 * Ensures the object has valid keys ('connectionType' and 'orderType'), and validates the values of those keys.
 *
 * @param {string} blockType - The type of the block (e.g., 'math', 'logic').
 * @param {Object} blockConnectionConfig - The block connection configuration object.
 * @param {Array} invalidBlockConnectionTypes - Array of connection types where 'orderType' should not be specified.
 * @returns {Object} - The validated connection type and order type.
 * @throws {Error} - Throws an error if validation fails for keys or values.
 * 
 * @example
 * validateObjectConnection('math', { connectionType: 'SEQUENTIAL', orderType: 'NONE' });
 * // Returns: { validatedConnectionType: 'SEQUENTIAL', validatedOrderType: 'NONE' }
 * 
 * validateObjectConnection('logic', { connectionType: 'OUTPUT', orderType: 'ATOMIC' });
 * // Returns: { validatedConnectionType: 'OUTPUT', validatedOrderType: undefined }
 */
function validateObjectConnection(blockType, blockConnectionConfig, invalidBlockConnectionTypes) {
  // Validate that the object has only valid keys: 'connectionType' and 'orderType'
  const validConnectionConfigKeys = ['connectionType', 'orderType'];
  validateObjectKeys(blockConnectionConfig, validConnectionConfigKeys);

  let { connectionType, orderType } = blockConnectionConfig;

  // Validate that the connectionType is valid
  validateValueInObject(connectionType, BlockConnectionType, `${blockType}connectionType`);

  // If connectionType is OUTPUT, no need for orderType validation (set it to NONE by default)
  if (connectionType === BlockConnectionType.OUTPUT) {
    orderType ||= BlockOrderType.NONE; // If orderType is not provided, default it to NONE
    validateValueInObject(orderType, BlockOrderType, `${blockType}orderType`); // Validate orderType
  } 
  // If connectionType is invalid with orderType, throw error
  else if (invalidBlockConnectionTypes.includes(connectionType) && orderType) {
    throwError(`${blockType} orderType must not be provided with  ${connectionType} connectionType in blockConnectionConfig.`);
  }

  // Return the validated connectionType and orderType
  return { validatedConnectionType: connectionType, validatedOrderType: orderType };
}

/**
 * Validates the block connection configuration, either as a string or an object.
 * If the configuration is a string, it checks if it is a valid connection type.
 * If it's an object, it validates the keys and values within the object.
 *
 * @param {string} blockType - The type of the block (e.g., 'math', 'logic').
 * @param {string|Object} blockConnectionConfig - The block connection configuration, either a connection type string or an object.
 * @returns {Object} - The validated connection and order type.
 * @throws {Error} - Throws an error if the configuration format is invalid.
 * 
 * @example
 * validateBlockConnectionConfig('math', 'OUTPUT'); 
 * // Returns: { validatedConnectionType: 'OUTPUT', validatedOrderType: undefined }
 * 
 * validateBlockConnectionConfig('logic', { connectionType: 'SEQUENTIAL', orderType: 'NONE' });
 * // Returns: { validatedConnectionType: 'SEQUENTIAL', validatedOrderType: 'NONE' }
 */
function validateBlockConnectionConfig(blockType, blockConnectionConfig) {
  // Filter out the 'OUTPUT' connection type from the BlockConnectionType
  const invalidBlockConnectionTypes = filterObjectValues(BlockConnectionType, BlockConnectionType.OUTPUT);

  // When blockConnectionConfig is a string
  if (isValidType(blockConnectionConfig, 'string')) {
    // Validate the connectionType in the provided blockConnectionConfig
    validateValueInObject(blockConnectionConfig, BlockConnectionType, `${blockType}connectionType`);
    
    // Return validated connectionType and a default orderType (if applicable)
    return {
      validatedConnectionType: blockConnectionConfig, 
      validatedOrderType: invalidBlockConnectionTypes.includes(blockConnectionConfig) ? undefined : BlockOrderType.NONE 
    };
  } 
  // When blockConnectionConfig is an object
  else if (isValidType(blockConnectionConfig, 'object')) {
    return validateObjectConnection(blockType, blockConnectionConfig, invalidBlockConnectionTypes);
  } 
  // Invalid format
  else {
    throwError(
      `Invalid format for ${blockType} blockConnectionConfig. Expected either a string (${Object.values(BlockConnectionType).join(', ')}) or an object containing 'connectionType' and 'orderType'.`
    );
  }
}

/**
 * Validates and sets up configuration for a block, ensuring all required properties are properly defined.
 * 
 * This function performs validation on the block's type, message, color, connection configuration, and associated Python code.
 * If any validation fails, an error will be thrown. Otherwise, the validated block connection configuration is returned.
 * 
 * @param {string} blockType - The type of the block (e.g., 'math', 'logic').
 * @param {string} blockMessage - A description or message associated with the block.
 * @param {string} blockColour - The color assigned to the block.
 * @param {Object} blockConnectionConfig - The connection configuration for the block, containing connectionType and orderType.
 * @param {boolean} [inputsInline=true] - Whether inputs should be arranged inline or in separate rows.
 * @param {string} pythonCode - The Python code string associated with the block.
 * @returns {Object} The validated block connection configuration.
 * 
 * @example
 * const blockType = "math";
 * const blockMessage = "A math block";
 * const blockColour = "#ff0000";
 * const blockConnectionConfig = { connectionType: "OUTPUT", orderType: "ATOMIC" };
 * const pythonCode = "def math_function(): pass";
 * const inputsInline = false;
 * const validatedConfig = validateBlockSetupConfig(blockType, blockMessage, blockColour, blockConnectionConfig, pythonCode);
 * console.log(validatedConfig);
 */
function validateBlockSetupConfig(blockType, blockMessage, blockColour, blockConnectionConfig, inputsInline, pythonCode) {
  // Validate that the blockType, blockMessage, blockColour and pythonCode are non-empty strings
  const fields = [
    { value: blockType, name: 'blockType' },
    { value: blockMessage, name: 'blockMessage' },
    { value: blockColour, name: 'blockColour' },
    { value: pythonCode, name: 'pythonCode' }
  ];
  
  // Validate fields
  fields.forEach(({ value, name }) => {
    validateNonEmptyString(value, name);
  });

  validateBoolean(inputsInline, `inputsInline`);

  // Validate and return the validated connection configuration for the block
  const validatedBlockConfigs = validateBlockConnectionConfig(blockType, blockConnectionConfig);

  // Return the validated block connection configurations
  return validatedBlockConfigs;
}

/**
 * @typedef {'inputValueConfig' | 'dropdownConfig' | 'fieldInputConfig' | 'fieldNumberConfig'} ConfigType
 * Specifies the type of configuration to validate:
 * - `inputValueConfig`: Used for validating input value configurations.
 * - `dropdownConfig`: Used for validating dropdown field configurations.
 * - `fieldInputConfig`: Used for validating text input field configurations.
 * - `fieldNumberConfig`: Used for validating numeric input field configurations.
 */

/**
 * Validates and normalizes configurations for Blockly blocks.
 *
 * This function dynamically determines the `configType` based on the provided configuration
 * object (`blockConfig`). It validates the configuration against the expected structure for 
 * the inferred `configType`, ensuring all required fields are present and correctly formatted. 
 * The validated configurations, along with the connection and order types, are returned.
 *
 * @param {Object} options - The configuration options for the Blockly block.
 * @param {string} options.blockType - The unique identifier for the Blockly block.
 * @param {string} options.blockMessage - The message template displayed on the block.
 * @param {string|number} options.blockColour - The color of the block, specified as a hex string or number.
 * @param {blockConnectionConfig} options.blockConnectionConfig - Configuration for the block's connections.
 * @param {boolean} [options.inputsInline] - Whether inputs should be arranged inline or in separate rows.
 * @param {string} [options.pythonCode] - The Python code template associated with the block.
 * @param {Object} options.blockConfig - The configuration object containing exactly one key corresponding 
 *     to the configuration type (e.g., `{ inputValueConfig: [...] }`).
 * @returns {Object} - An object containing the validated configurations and additional metadata:
 *  - `processedConfigs {Array<Object>}`: The validated and normalized configuration items.
 *  - `validatedConnectionType {string}`: The validated connection type for the block.
 *  - `validatedOrderType {string}`: The validated order type for the block (if applicable).
 *
 * @throws {Error} - Throws an error if multiple or no configuration types are provided, 
 *                   or if the configurations do not meet the expected structure.
 *
/**
 * @example
 * // Example 1: Validating input value configurations with defaults
 * const options = {
 *   blockType: "move_snake",
 *   blockMessage: "Move the snake %1",
 *   blockColour: "#FFA500",
 *   blockConnectionConfig: { connectionType: "OUTPUT" },
 *   blockConfig: {
 *     inputValueConfig: [
 *       { name: "speed", inputExecutionOrder: "ORDER_ATOMIC", align: 'LEFT', check: 'Number' },
 *       { name: "direction" } // Defaults: inputExecutionOrder='ORDER_NONE', align='RIGHT', check=null
 *     ]
 *   },
 *   pythonCode: 'move(##speed##, ##direction##)'
 * };
 * 
 * const result = validateBlockConfigs(options);
 * console.log(result);
 * // Output:
 * // {
 * //   processedConfigs: [
 * //     { name: "speed", inputExecutionOrder: "ORDER_ATOMIC", align: 'LEFT', check: 'Number' },
 * //     { name: "direction", inputExecutionOrder: "ORDER_NONE", align: 'RIGHT', check: null },
 * //   ],
 * //   validatedConnectionType: "OUTPUT",
 * //   validatedOrderType: "ORDER_ATOMIC"
 * // }
 *
 * @example
 * // Example 2: Validating dropdown configurations
 * const options = {
 *   blockType: "action_block",
 *   blockMessage: "Perform %1 action",
 *   blockColour: "#FF0000",
 *   blockConnectionConfig: { connectionType: "SEQUENTIAL" },
 *   blockConfig: {
 *     dropdownConfig: [
 *       {
 *         name: "actionType",
 *         options: [
 *           ['Start', 'start()'],
 *           ['Stop', 'stop()']
 *         ]
 *       }
 *     ]
 *   },
 *   pythonCode: '##actionType##'
 * };
 *
 * const result = validateBlockConfigs(options);
 * console.log(result);
 * // Output:
 * // {
 * //   processedConfigs: [
 * //     {
 * //       name: "actionType",
 * //       options: [
 * //         ['Start', 'start()'],
 * //         ['Stop', 'stop()']
 * //       ]
 * //     }
 * //   ],
 * //   validatedConnectionType: "SEQUENTIAL",
 * //   validatedOrderType: "ORDER_NONE"
 * // }
 *
 * @example
 * // Example 3: Validating field input configurations
 * const options = {
 *   blockType: "input_block",
 *   blockMessage: "Enter value %1",
 *   blockColour: "#3792cb",
 *   blockConnectionConfig: { connectionType: "OUTPUT" },
 *   blockConfig: {
 *     fieldInputConfig: [
 *       { name: "input1", defaultValue: "default text" },
 *     ],
 *   },
 * };
 *
 * const result = validateBlockConfigs(options);
 * console.log(result);
 * // Output:
 * // {
 * //   validatedBlockConfigs: [
 * //     { name: "input1", defaultValue: "default text" }
 * //   ],
 * //   validatedConnectionType: "OUTPUT",
 * //   validatedOrderType: "ORDER_NONE"
 * // }
 *
 * @example
 * // Example 4: Validating field number configurations
 * const options = {
 *   blockType: "pause_block",
 *   blockMessage: "Pause for %1 second(s)",
 *   blockColour: "#935ba5",
 *   blockConnectionConfig: { connectionType: "sequential" },
 *   blockConfig: {
 *     fieldNumberConfig: [
 *       { name: "duration", defaultValue: 1, min: 0, max: 10 },
 *     ],
 *   },
 * };
 *
 * const result = validateBlockConfigs(options);
 * console.log(result);
 * // Output:
 * // {
 * //   validatedBlockConfigs: [
 * //     { name: "duration", defaultValue: 1, min: 0, max: 10 }
 * //   ],
 * //   validatedConnectionType: "sequential",
 * //   validatedOrderType: "ORDER_NONE"
 * // }
 */
function validateBlockConfigs(options) {
  const {
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    pythonCode,
    ...configs
  } = options;

  // Optimize config type validation with early return
  const configEntries = Object.entries(configs);
  if (configEntries.length !== 1) {
    throwError(
      `"${blockType}" block requires exactly one configuration type. Multiple configurations were provided.`,
      "MULTIPLE_BLOCK_CONFIG_TYPES",
      { providedConfigs: configEntries.map(([key]) => key) }
    );    
  }

  const [configType, blockConfig] = configEntries[0];

   // Define validation rules
   const VALIDATION_RULES = {
    [BlockConfigType.INPUT_VALUE]: {
      requiredKeys: ['name', 'inputExecutionOrder', 'align', 'check'],
      defaultValues: {
        inputExecutionOrder: BlockOrderType.NONE,
        align: 'RIGHT',
        check: null,
      },
      validate: (config, itemContext) => {
        // Extract default values for simplicity
        const { inputExecutionOrder: defaultOrder, align: defaultAlign, check: defaultCheck } =
        VALIDATION_RULES[BlockConfigType.INPUT_VALUE].defaultValues;

        // Apply default values
        config.inputExecutionOrder ||= defaultOrder;
        config.align ||= defaultAlign;
        config.check ||= defaultCheck;
        
        validateValueInObject(
          config.inputExecutionOrder,
          BlockOrderType,
          `${itemContext}.inputExecutionOrder`
        );

        validateValueInArray(
          config.align,
          ['LEFT', 'CENTER', 'RIGHT'],
          `${itemContext}.align`
        );

        validateValueInArray(
          config.check,
          ['Array', 'Boolean', 'Colour', 'Number', 'String', null],
          `${itemContext}.check`
        );
      }
    },
    [BlockConfigType.DROPDOWN]: {
      requiredKeys: ['name', 'options'],
      validate: (config, itemContext) => {
        validateArray(config.options, `${itemContext}.options`);

        const invalidOptionIndex = config.options.findIndex(
          optionPair => !Array.isArray(optionPair) || 
                        optionPair.length !== 2 || 
                        !optionPair[0] || 
                        !optionPair[1]
        );

        if (invalidOptionIndex !== -1) {
          throwError(`${itemContext}.options[${invalidOptionIndex}] is invalid`);
        }
      }
    },
    [BlockConfigType.FIELD_INPUT]: {
      requiredKeys: ['name', 'defaultValue'],
      validate: (config, itemContext) => {
        validateNonEmptyString(config.defaultValue, `${itemContext}.defaultValue`);
      }
    },
    [BlockConfigType.FIELD_NUMBER]: {
      requiredKeys: ['name', 'defaultValue', 'min', 'max'],
      validate: (config, itemContext) => {
        ['defaultValue', 'min', 'max'].forEach((key) => {
          validateNumber(config[key], `${itemContext}.${key}`);
        });


        if (config.min >= config.max) {
          throwError(`${itemContext}.min (${config.min}) should be less than max (${config.max})`);
        }
      }
    },
  };

  // validation of config type
  const validationRule = VALIDATION_RULES[configType];
  if (!validationRule) {
    throwError(
      `Unknown configType: "${configType}" for block type: "${blockType}".`,
      "INVALID_CONFIG_TYPE"
    );
  }

  // Validate and process configurations
  validateArray(blockConfig);

  // Configuration validation with reduced iterations
  const processedConfigs = blockConfig.map((config, index) => {
    const itemContext = `${blockType} block: "${config.name}" in ${configType} config at index [${index}]`;

    // Apply default values
    const validatedConfig = { ...validationRule.defaultValues, ...config };

    // Validate required keys
    const missingKeys = validationRule.requiredKeys.filter((key) => !(key in validatedConfig));
    if (missingKeys.length > 0) {
      throwError(
        `Missing keys in ${itemContext}: ${missingKeys.join(', ')}`,
        "MISSING_KEYS"
      );
    }

    // Validate the configuration using the custom validator
    validationRule.validate(validatedConfig, itemContext);

    return validatedConfig;
  });

  
  // Validate block setup configuration with destructuring
  const blockSetupValidation = validateBlockSetupConfig(
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    pythonCode
  );

  return {
    processedConfigs,
    ...blockSetupValidation
  };
}

/**
 * Initializes a Blockly block dynamically using the provided block configuration data.
 *
 * @param {string} blockType - The unique identifier for the Blockly block (e.g., 'custom_block').
 * @param {Object} blockData - The configuration data for the block, typically the output of a function like `generateCommonBlock`.
 *    This includes the block's message, color, inputs, and connection types.
 *
 * @example
 * // Example of how to initialize a custom block with dynamic inputs and configuration
 * const blockType = "math_block";
 * const blockData = {
 *   type: blockType,
 *   message0: "Calculate %1 + %2",
 *   colour: "#ff6600", // The color of the block
 *   args0: [
 *     { type: "input_value", name: "NUM1" }, // First input field for a number
 *     { type: "input_value", name: "NUM2" }  // Second input field for a number
 *   ]
 * };
 * 
 * // Initialize the Blockly block with the specified type and configuration data
 * initializeBlocklyBlock(blockType, blockData);
 */
function initializeBlocklyBlock(blockType, blockData) {
  // Validate block data to ensure it includes essential fields
  if (!blockData || !blockData.type || !blockData.message0 || !blockData.colour) {
    throwError(`The "${blockType}" block has invalid data. The fields 'type', 'message0', and 'colour' are required.`);
  }

  // Initialize the Blockly block with dynamic inputs and configuration
  Blockly.Blocks[blockType] = {
    init: function () {
      this.jsonInit(blockData); // Initialize block with the generated configuration
    },
  };
}

/**
 * Sets the Python code generation function for a specific Blockly block type.
 * 
 * This function assigns a given code generation function to the `Blockly.Python` object, 
 * mapping it to a specific block type. If the assignment fails (e.g., due to an invalid block type),
 * an error is logged to the console.
 * 
 * @param {string} blockType - The type of the Blockly block (e.g., 'math_add', 'logic_and').
 * @param {Function} codeGenerationFunction - The function that generates the Python code for the block.
 * @throws {Error} If the `codeGenerationFunction` is not a valid function.
 * 
 * @example
 * const blockType = "math_add";
 * const codeGenerationFunction = function(block) {
 *   return 'result = a + b'; // Python code for the block
 * };
 * setPythonCodeForBlock(blockType, codeGenerationFunction);
 */
const setPythonCodeForBlock = (() => {
  return (blockType, codeGenerationFunction) => {
    // Validate that codeGenerationFunction is a function
    validateFunction(codeGenerationFunction, 'codeGenerationFunction');
    
    try {
        // Assign the code generation function to the block type in Blockly.Python
        Blockly.Python[blockType] = codeGenerationFunction;
    } catch (error) {
        // Log any error that occurs during the assignment
        console.error(`Failed to set Python code for block type: ${blockType}`, error);
    }
  };
})();

/**
 * Generates the Python code along with its corresponding order for a given connection type and order type.
 * 
 * This function maps the given orderType to Blockly's predefined order constants and returns 
 * the appropriate Python code with the corresponding order, or throws errors if the inputs are invalid.
 * 
 * @param {string} blockType - Unique block identifier (e.g., 'number_block').
 * @param {'output' | 'sequential' | 'next' | 'previous'} connectionType - The type of the block connection (e.g., 'output' or 'sequential' or 'next' or 'previous').
 * @param {'ORDER_ATOMIC' | 'ORDER_NONE'} orderType - The order type for the block (e.g., 'ORDER_ATOMIC', 'ORDER_NONE').
 * @param {string} pythonCode - The Python code to be returned.
 * @returns {Array|string} - Returns an array of Python code and order (for 'output' connection type), 
 *                            or just the pythonCode (for 'sequential', 'next', 'previous' connection types).
 * @throws {Error} - Throws an error if an invalid `connectionType` or `orderType` is provided.
 * 
 * @example
 * const result = getPythonCodeWithOrder("number_block", BlockConnectionType.OUTPUT, BlockOrderType.ATOMIC, 'someCode');
 * console.log(result); // Outputs: [ 'someCode', Blockly.Python.ORDER_ATOMIC ]
 */
function getPythonCodeWithOrder(blockType, connectionType, orderType, pythonCode) {
  // Validate connectionType and handle orderType based on the connection type
  switch (connectionType) {
    case BlockConnectionType.OUTPUT:
      // Map BlockOrderType values to corresponding Blockly Python order constants
      const orderMapping = {
        [BlockOrderType.ATOMIC]: Blockly.Python.ORDER_ATOMIC,
        [BlockOrderType.NONE]: Blockly.Python.ORDER_NONE
      };

      // Get the corresponding Blockly order value for the provided orderType
      const order = orderMapping[orderType];

      // If the orderType is valid, return the pythonCode with the appropriate order
      if (order !== undefined) {
        return [pythonCode, order];
      } else {
        // If the orderType is invalid, validate it
        validateValueInObject(orderType, BlockOrderType, `${blockType}orderType`);
      }
      break;

    case BlockConnectionType.SEQUENTIAL:
    case BlockConnectionType.NEXT:
    case BlockConnectionType.PREVIOUS:
      // For these connection types, orderType should not be provided
      if (orderType !== undefined) {
        throwError(`Invalid  orderType: '${orderType}' provided for ${blockType}'s '${connectionType}' connection type. orderType should not be specified for ${connectionType}.`);
      }
      return pythonCode;

    default:
      // Handle invalid connectionType
      throwError(`Invalid ${blockType}'s connectionType: '${connectionType}'. Expected one of: ${Object.values(BlockConnectionType).join(', ')}.`);
  }
}

/**
 * Dynamically retrieves code or values from a Blockly block based on inputType.
 *
 * @param {Blockly.Block} block - The Blockly block instance.
 * @param {string} inputName - The name of the input or field to retrieve.
 * @param {Object} options - Additional options for retrieving the value.
 * @param {'valueToCode' | 'statementToCode' | 'fieldValue'} options.inputType - The type of input retrieval ('valueToCode' or 'statementToCode', or 'fieldValue').
 * @param {'ORDER_ATOMIC' | 'ORDER_NONE'} [options.executionOrder] - (Optional) Execution order for `valueToCode` inputType (e.g., 'ORDER_ATOMIC', 'ORDER_NONE'). Ignored for other input types.
 * @returns {string} The retrieved code or value as a string.
 * @throws {Error} If the block does not support the specified inputType, required parameters are missing, or an unsupported inputType is provided.
 *
 * @example
 * const code = getDynamicBlocklyCode(block, 'myInput', { inputType: 'valueToCode', executionOrder: 'ORDER_ATOMIC' });
 * // Retrieves the code corresponding to the 'valueToCode' inputType with atomic execution order.
 *
 * @example
 * const statement = getDynamicBlocklyCode(block, 'myStatement', { inputType: 'statementToCode' });
 * // Retrieves the statement code for the given input name.
 *
 * @example
 * const fieldValue = getDynamicBlocklyCode(block, 'myField', { inputType: 'fieldValue' });
 * // Retrieves the field value for the specified input name.
 */
function getDynamicBlocklyCode(block, inputName, options = {}) {
  const { inputType, executionOrder } = options;

  // Helper function to throw errors
  const throwIfMissing = (condition, message, errorCode, additionalData = {}) => {
    if (condition) {
      throwError(message, errorCode, { inputName, options, ...additionalData });
    }
  };

  // Ensure inputType is provided
  throwIfMissing(!inputType, `Missing required "inputType" parameter for blocktype "${block.type}"`, "MISSING_INPUT_TYPE");

  // Validate that executionOrder is only passed for valueToCode
  throwIfMissing(inputType !== "valueToCode" && executionOrder, 
    `executionOrder should only be provided for inputType 'valueToCode' for the "${block.type}" block`,
    "INVALID_EXECUTION_ORDER", 
    { executionOrder }
  );
  
  // Use map to handle inputType cases
  const inputHandlers = {
    valueToCode: () => {
      // Ensure executionOrder is provided for valueToCode
      throwIfMissing(!executionOrder, `Missing required parameter: ${block.type} block's executionOrder for valueToCode`, "MISSING_EXECUTION_ORDER");
      return Blockly.Python.valueToCode(block, inputName, Blockly.Python[executionOrder]) || "None";
    },
    statementToCode: () => Blockly.Python.statementToCode(block, inputName),
    fieldValue: () => block.getFieldValue(inputName),
  };
  
  const handleInputType = inputHandlers[inputType];
  
  if (handleInputType) {
    return handleInputType(); // Execute the appropriate handler function based on inputType
  } else {
    throwError(`Unsupported inputType: ${inputType} for the ${block.type} block`, "UNSUPPORTED_INPUT_TYPE", { inputType, inputName });
  }  
}

/**
 * Validates and replaces placeholders in the provided Python template.
 * 
 * This function checks for missing and unexpected placeholders based on the block inputs,
 * and then replaces valid placeholders in the template with the corresponding values.
 * 
 * @param {string} pythonTemplate - The Python template string containing placeholders in the form of `##placeholder##`.
 * @param {Object} placeholderValues - An object mapping placeholders (as strings) to their respective replacement values.
 * @param {Array<Object>} blockConfigs - The block inputs, each containing an object with a `name` property representing the placeholder names expected in the template.
 * 
 * @returns {string} - The Python template with placeholders replaced by their corresponding values.
 * 
 * @throws {Error} - Throws errors if there are missing or unexpected placeholders in the template.
 * 
 * @example
 * const pythonTemplate = "Sensor Value: ##sensor_value##, Code: ##inner_code##";
 * const placeholderValues = { "##sensor_value##": 42, "##inner_code##": "if x > 0: print('Positive')" };
 * const blockConfigs = [{ name: "sensor_value" }, { name: "inner_code" }];
 * 
 * const result = validateAndReplacePlaceholders(pythonTemplate, placeholderValues, blockConfigs);
 * console.log(result); // "Sensor Value: 42, Code: if x > 0: print('Positive')"
 */
function validateAndReplacePlaceholders(pythonTemplate, placeholderValues, blockConfigs) {
  // Prepare sets for efficient lookups
  const placeholderPattern = /##(.*?)##/g;
  const expectedPlaceholders = new Set(blockConfigs.map(input => input.name));
  const foundPlaceholders = new Set();
  const unexpectedPlaceholders = [];

  // Single pass regex matching with O(m)
  const matches = pythonTemplate.match(placeholderPattern) || [];
  
  for (const match of matches) {
    const placeholder = match.slice(2, -2); // Remove ##
    if (expectedPlaceholders.has(placeholder)) {
      foundPlaceholders.add(placeholder);
    } else {
      unexpectedPlaceholders.push(placeholder);
    }
  }

  // Identify missing placeholders
  const missingPlaceholders = [...expectedPlaceholders].filter(
    placeholder => !foundPlaceholders.has(placeholder)
  );

  // Throw errors for missing or unexpected placeholders
  const errors = [
    { placeholders: missingPlaceholders, message: "Missing placeholders in Python Code", code: "MISSING_PLACEHOLDERS" },
    { placeholders: unexpectedPlaceholders, message: "Unexpected placeholders found in Python Code", code: "UNEXPECTED_PLACEHOLDERS" },
  ];

  errors.forEach(({ placeholders, message, code }) => {
    if (placeholders.length > 0) {
      throwError(message, code, { placeholders });
    }
  });

  // Replace placeholders
  return pythonTemplate.replace(placeholderPattern, (match) => {
    const replacementValue = placeholderValues[match];

    if (replacementValue === undefined) {
      throwError(`Missing value for placeholder: ${match}`);
    }

    return replacementValue;
  });
}

/**
 * Processes placeholders in the Python template dynamically.
 *
 * @param {Blockly.Block} block - The Blockly block instance.
 * @param {Array<Object>} blockConfigs - The block inputs with dynamic values.
 * @param {string} pythonTemplate - The Python template with placeholders.
 * @param {'valueToCode' | 'statementToCode' | 'fieldValue'} inputType - Type of input retrieval ('valueToCode', 'statementToCode', 'fieldValue').
 * @returns {string} The final Python code with placeholders replaced.
 * @throws {Error} If placeholders are unmatched or undefined.
 *
 * @example
 * // Example usage where `block` is the Blockly block, `blockConfigs` contains dynamic input values, and `pythonTemplate` is a template string.
 * const pythonCode = processPythonPlaceholders(block, blockConfigs, "print(##input1##, ##input2##)", "valueToCode");
 * // Processes the template by replacing `##input1##` and `##input2##` with dynamic values retrieved from the block's inputs.
 *
 * @example
 * const pythonCode = processPythonPlaceholders(block, blockConfigs, "if ##input1##: pass", "statementToCode");
 * // Replaces `##input1##` with the generated code corresponding to the `input1` from the block's inputs.
 *
 * @example
 * const pythonCode = processPythonPlaceholders(block, blockConfigs, "return ##input1##", "fieldValue");
 * // Replaces `##input1##` with the field value from the block.
 */
function processPythonPlaceholders(block, blockConfigs, pythonTemplate, inputType) {
  // Build a mapping of placeholders to their corresponding replacement values
  const placeholderValues = {};

  blockConfigs.forEach((blockConfig, index) => {
    const params = { inputType };
    if (inputType === 'valueToCode') {
      params.executionOrder = blockConfig.inputExecutionOrder || BlockOrderType.NONE;
    }

    // Ensure the blockConfig has a name before proceeding
    if (!blockConfig.name) {
      throwError(`"${block.type}" block configuration at index ${index} is missing a "name" field.`);
      return; // Skip processing this blockConfig if the name is missing
    }

    // Retrieve the value or code dynamically
    const value = getDynamicBlocklyCode(block, `block_arg_${blockConfig.name}_${index}`, params);

    // Add the placeholder and its value to the mapping
    placeholderValues[`##${blockConfig.name}##`] = value;
  });

  return validateAndReplacePlaceholders(pythonTemplate, placeholderValues, blockConfigs);
}

/**
 * Initializes a Blockly label block with associated Python code generation based on connection settings.
 *
 * @param {string} blockType - Unique block identifier (e.g., 'label_block').
 * @param {string} blockMessage - Display text for the block (e.g., 'Display %1').
 * @param {string | number} blockColour - Block color, specified as a hex string or a number (e.g., '#ff6600' or 230).
 * @param {blockConnectionConfig | 'output' | 'sequential' | 'next' | 'previous'} blockConnectionConfig - 
 *    Configuration object or string specifying:
 *    - `connectionType`: Type ('output', 'sequential', 'next', 'previous').
 *    - `orderType`: Type ('ORDER_NONE', 'ORDER_ATOMIC').
 * @param {string} pythonCode - Python code associated with the block.
 * @param {boolean} [options.inputsInline=true] - Whether inputs should be arranged inline or in separate rows.
 *
 * @example
 * // Example of initializing a block with 'output' connection and 'ORDER_ATOMIC'
 * initializeLabelBlockWithPython(
 *   'display_block', 
 *   'Display %1', 
 *   '#ffcc00', 
 *   { connectionType: 'output', orderType: 'ORDER_ATOMIC' }, 
 *   'print(%1)'
 * );
 * 
 * // Example of initializing a block with 'sequential' connection
 * initializeLabelBlockWithPython(
 *   'action_block', 
 *   'Perform action %1', 
 *   '#66ccff', 
 *   'sequential', 
 *   'performAction(%1)'
 * );
 * 
 * // Example of initializing a block with 'next' connection and no order type
 * initializeLabelBlockWithPython(
 *   'next_block', 
 *   'Next step: %1', 
 *   '#ff9900', 
 *   { connectionType: 'next'}, 
 *   'nextStep(%1)'
 * );
 */
function initializeLabelBlockWithPython(blockType, blockMessage, blockColour, blockConnectionConfig, pythonCode, inputsInline=true) {
  // Validate block setup configuration and get validated connection and order types
  const { validatedConnectionType, validatedOrderType } = validateBlockSetupConfig(blockType, blockMessage, blockColour, blockConnectionConfig, inputsInline, pythonCode);

  // Generate block configuration data
  const blockData = generateCommonBlock(
    { 
      type: blockType, 
      message0: blockMessage, 
      colour: blockColour,
      inputsInline
    },
    validatedConnectionType
  );

  // Initialize the block with the specified type and configuration data
  initializeBlocklyBlock(blockType, blockData);

  // Set the Python code generation function for the block
  setPythonCodeForBlock(blockType, (block) => {
    // Get the generated Python code with the block's connection and order configuration applied
    return getPythonCodeWithOrder(blockType, validatedConnectionType, validatedOrderType, pythonCode);
  });
}

/**
 * @typedef {Object} blockConnectionConfig
 * @property {'output' | 'sequential' | 'next' | 'previous'} connectionType - Block connection type:
 *    - 'output': Block produces a value for other blocks to connect to.
 *    - 'sequential': Block performs a sequential action in a series.
 *    - 'next': Block connects only to the next block in the sequence.
 *    - 'previous': Block connects only to the previous block in the sequence.
 * @property {'ORDER_NONE' | 'ORDER_ATOMIC'} orderType - Execution order:
 *    - 'ORDER_NONE': Weak precedence, used for blocks with no operators or to enforce parentheses.
 *    - 'ORDER_ATOMIC': Strong precedence, ensures block is always parenthesized and executed first.
 */

/**
 * Shared helper function to initialize a Blockly block with dynamic configurations and Python code generation.
 *
 * This function handles the common logic for initializing Blockly blocks, including:
 * - Validating configurations.
 * - Generating Blockly block definitions dynamically.
 * - Setting up Python code generation based on block configurations.
 *
 * Specific behavior is determined dynamically using the `FIELD_MAPPERS` and `pythonInputType`.
 *
 * @param {Object} options - Configuration options for the block.
 * @param {string} options.blockType - Unique identifier for the Blockly block (e.g., "input_1").
 * @param {string} options.blockMessage - Block message displayed on the Blockly block, with placeholders for arguments.
 * @param {string|number} options.blockColour - Block color, specified as a hex string (e.g., "#FF6600") or a number.
 * @param {blockConnectionConfig} options.blockConnectionConfig - Configuration for the block's connections.
 * @param {boolean} [options.inputsInline=true] - Whether inputs should be arranged inline or in separate rows.
 * @param {inputValueConfig | DropdownConfig | FieldInputConfig | FieldNumberConfig} options.blockConfig - Configuration object containing one key corresponding to the configuration type.
 *     - Example: `{ inputValueConfig: [...] }`
 * @param {string} options.pythonCode - Python code template with placeholders (e.g., "##input1## + ##input2##").
 * @param {'valueToCode' | 'statementToCode' | 'fieldValue'} options.pythonInputType - Specifies the type of Python input.
 *
 * @throws {Error} If the configuration type is unsupported or validation fails.
 *
 * @returns {void} This function doesn't return a value but dynamically modifies the Blockly workspace.
 *
 * @example
 * // Example: Initialize an input value block
 * initializeDynamicBlock({
 *   blockType: "move_snake",
 *   blockMessage: "Move the snake %1",
 *   blockColour: "#FFA500",
 *   blockConnectionConfig: { connectionType: "output" },
 *   inputsInline: true,
 *   blockConfig: {
 *     inputValueConfig: [
 *       { name: "speed", align: "LEFT", check: "Number" },
 *       { name: "direction", align: "RIGHT", check: "String" }
 *     ]
 *   },
 *   pythonCode: "move(##speed##, ##direction##)",
 *   pythonInputType: "valueToCode"
 * });
 *
 * @example
 * // Example: Initialize a dropdown block
 * initializeDynamicBlock({
 *   blockType: "action_block",
 *   blockMessage: "Perform %1 action",
 *   blockColour: "#FF0000",
 *   blockConnectionConfig: { connectionType: "sequential" },
 *   inputsInline: false,
 *   blockConfig: {
 *     dropdownConfig: [
 *       { name: "action", options: [["Start", "start()"], ["Stop", "stop()"]] }
 *     ]
 *   },
 *   pythonCode: "##action##",
 *   pythonInputType: "fieldValue"
 * });
 */
function initializeDynamicBlock({
  blockType,
  blockMessage,
  blockColour,
  blockConnectionConfig,
  inputsInline=true,
  blockConfig,
  pythonCode,
  pythonInputType,
}) {
  const { processedConfigs, validatedConnectionType, validatedOrderType } = validateBlockConfigs({
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    pythonCode,
    ...blockConfig,
  });

  /**
   * Generates a field configuration for Blockly blocks dynamically.
   * This utility handles common properties while allowing customization.
   * 
   * @param {string} type - The Blockly field type (e.g., 'input_value', 'field_dropdown').
   * @param {Object} config - The configuration for the field.
   * @param {number} index - The index of the field.
   * @param {Object} [overrides={}] - Optional overrides for specific properties.
   * @returns {Object} - The field configuration for Blockly.
   */
  function generateFieldConfig(type, config, index, overrides = {}) {
    return {
      type,
      name: `block_arg_${config.name}_${index}`, // Consistent naming convention
      ...overrides, // Apply any specific overrides (e.g., options for dropdowns)
    };
  }

  /**
   * Maps configuration types to their respective handlers for Blockly block field definitions.
   * Each handler uses the shared `generateFieldConfig` utility.
   * 
   * @type {Object.<string, Function>}
   */
  const FIELD_MAPPERS = {
    [BlockConfigType.INPUT_VALUE]: {
      handler: ({ config: inputConfig, index }) =>
        generateFieldConfig("input_value", inputConfig, index, {
          align: inputConfig.align,
          check: inputConfig.check,
        }),
    },

    [BlockConfigType.DROPDOWN]: {
      handler: ({ config: dropdownConfig, index }) =>
        generateFieldConfig("field_dropdown", dropdownConfig, index, {
          options: dropdownConfig.options,
        }),
    },

    [BlockConfigType.FIELD_INPUT]: {
      handler: ({ config: fieldInputConfig, index }) =>
        generateFieldConfig("field_input", fieldInputConfig, index, {
          text: fieldInputConfig.defaultValue,
        }),
    },

    [BlockConfigType.FIELD_NUMBER]: {
      handler: ({ config: numberConfig, index }) =>
        generateFieldConfig("field_number", numberConfig, index, {
          value: numberConfig.defaultValue,
          min: numberConfig.min,
          max: numberConfig.max,
        }),
    },
  };

  const [configType] = Object.keys(blockConfig); // Extract the configuration type

  // Use the appropriate field mapper based on the config type
  const fieldMapper = FIELD_MAPPERS[configType];
  if (!fieldMapper) {
    throwError(
      `Unsupported block configuration type: "${configType}"`,
      "INVALID_CONFIG_TYPE",
      { blockType, configType }
    );
  }

  // Generate Blockly block configuration
  const blockData = generateCommonBlock(
    {
      type: blockType,
      message0: blockMessage,
      args0: processedConfigs.map((config, index) =>
        fieldMapper.handler({ config, index }) // Delegate to field mapper
      ),
      colour: blockColour,
      inputsInline
    },
    validatedConnectionType
  );

  // Initialize the block in Blockly
  initializeBlocklyBlock(blockType, blockData);

  // Set Python code generation logic dynamically
  setPythonCodeForBlock(blockType, (block) => {
    const pythonCodeTemplate = processPythonPlaceholders(block, processedConfigs, pythonCode, pythonInputType);
    return getPythonCodeWithOrder(blockType, validatedConnectionType, validatedOrderType, pythonCodeTemplate);
  });
}

/**
 * @typedef {Object} inputValueConfig
 * Represents a configuration item for an input value.
 * @property {string} name - The unique name of the input field (e.g., "input1").
 * @property {string} [inputExecutionOrder='ORDER_NONE'] - Execution order type for this input (`ORDER_NONE` or `ORDER_ATOMIC`).
 * @property {'LEFT' | 'CENTER' | 'RIGHT'} [align='RIGHT'] - Alignment of the input field in the block ('LEFT', 'CENTER', 'RIGHT').
 * @property {'Array' | 'Boolean' | 'Colour' | 'Number' | 'String' | null} [check=null] - The expected type for the input field (or `null` for no type checking).
 */

/**
 * Initializes a Blockly block with dynamic inputs and generates associated Python code.
 * This function dynamically configures the block's inputs, validates the block setup,
 * and sets up a Python code generation function that dynamically replaces placeholders 
 * in the provided Python code with block values.
 * 
 * @param {string} blockType - Unique block identifier (e.g., 'input_value_block').
 * @param {string} blockMessage - Block message displayed on Blockly block (e.g., 'set value %1 and %2').
 * @param {string | number} blockColour - Block color, specified as a hex string or a number (e.g., '#ff6600' or 230).
 * @param {blockConnectionConfig | 'output' | 'sequential' | 'next' | 'previous'} blockConnectionConfig - 
 *    Configuration object or string specifying:
 *    - `connectionType`: Type ('output' or 'sequential' or 'next' or 'previous').
 *    - `orderType`: Type ('ORDER_NONE' or 'ORDER_ATOMIC').
 * @param {Array<inputValueConfig>} inputValueConfig - Array of input objects, each containing:
 *    - `name` (string): Unique name identifier used in Python template placeholders (e.g., 'input1').
 *    - `inputExecutionOrder` (optional): Execution order for this input (`ORDER_NONE` or `ORDER_ATOMIC`).
 *    - `align` (optional): Alignment for the input field ('LEFT', 'CENTER', 'RIGHT'). Defaults to 'RIGHT'.
 *    - `check` (optional): The type for the input field (`null`, 'Number', 'String', etc.). Defaults to `null`.
 * @param {string} pythonCode - Python code template, using placeholders in the format `##inputName##` 
 *    where `inputName` corresponds to an input `name` property. Each placeholder will be replaced with the 
 *    dynamic input values from the block.
 * @param {boolean} inputsInline - Whether inputs should be arranged inline or in separate rows.
 * 
 * @example
 * // Example with output connection and order type ORDER_ATOMIC
 * initializeDynamicInputValuePythonBlock('math_block', 'compute %1 + %2', '#ff6600', 
 *   { connectionType: 'output', orderType: 'ORDER_ATOMIC' }, 
 *   [
 *     { name: 'x', align: 'LEFT', check: 'Number' },
 *     { name: 'y', inputExecutionOrder: 'ORDER_ATOMIC', align: 'RIGHT', check: 'String' }
 *   ], 
 *   'result = ##x## + ##y##', false);
 *
 * @example
 * // Example with sequential connection
 * initializeDynamicInputValuePythonBlock('action_block', 'perform action %1', '#ffcc00', 
 *   { connectionType: 'sequential' }, 
 *   [
 *     { name: 'action', align: 'CENTER', check: 'Boolean' }
 *   ], 
 *   'performAction(##action##)', false);
 *
 * @throws {Error} - Throws an error if the block configuration is invalid, or if there are issues with placeholders.
 */
function initializeDynamicInputValuePythonBlock(blockType, blockMessage, blockColour, blockConnectionConfig, inputValueConfig, pythonCode, inputsInline) {
  initializeDynamicBlock({
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    blockConfig: { [BlockConfigType.INPUT_VALUE]: inputValueConfig },
    pythonCode,   
    pythonInputType: "valueToCode",
  });
}

/**
 * @typedef {Object} DropdownConfig
 * Represents a configuration item for a field dropdown.
 * @property {string} name - The unique name of the dropdown field (e.g., "dropdown1").
 * @property {Array<Array<string>>} options - An array of [label, value] pairs for the dropdown options.
 *    - The first item in the pair is the label that will appear in the dropdown.
 *    - The second item is the value used in the Python code generation.
 */

/**
 * Initializes a dynamic Blockly block with field dropdown options and corresponding Python code generation.
 * This function validates and normalizes dropdown configurations, generates the necessary Blockly block
 * configuration, and sets up Python code generation logic based on the block's dropdown selections.
 *
 * @param {string} blockType - Unique identifier for the Blockly block (e.g., 'dropdown_1').
 * @param {string} blockMessage - Block message displayed on Blockly block, with placeholders (e.g., 'do %1 at %2 speed').
 * @param {string | number} blockColour - Block color, specified as a hex string or a number (e.g., '#FFA500' or 230).
 * @param {blockConnectionConfig | 'output' | 'sequential' | 'next' | 'previous'} blockConnectionConfig - Configuration for block connections, with the following properties:
 *    - `connectionType`: Specifies how the block connects to other blocks (e.g., 'output', 'sequential', 'next', 'previous').
 *    - `orderType`: Execution order type ('ORDER_NONE' or 'ORDER_ATOMIC').
 * @param {DropdownConfig} dropdownConfig - Array of dropdown field configurations, each containing:
 *    - `name` (string): Unique name of the dropdown field.
 *    - `options` (Array<[string, string]>): Array of options, where each option is a `[label, value]` pair.
 *      The value is used in the generated Python code for the selected option.
 * @param {string} pythonCode - Python code template with placeholders (e.g., '##dropdown1## ##dropdown2##').
 *      These placeholders will be dynamically replaced with the selected dropdown values.
 * @param {boolean} inputsInline - Whether inputs should be arranged inline or in separate rows.
 * 
 * @returns {void} This function doesn't return a value but dynamically modifies the Blockly workspace.
 * 
 * @throws {Error} - Throws an error if the dropdown configuration is invalid or if any part of the process fails.
 *
 * @example
 * const dropdownConfig = [
 *   {
 *     name: "dropdown1",
 *     options: [
 *       ['Option1', 'Code1'],
 *       ['OptionA', 'CodeA'],
 *     ]
 *   },
 *   {
 *     name: "dropdown2",
 *     options: [
 *       ['Option2', 'Code2'],
 *       ['OptionB', 'CodeB']],
 *     ]
 *   }
 * ];
 *
 * initializeDynamicFieldDropdownBlock(
 *   "dropdown_1",
 *   "do %1 at %2 speed",
 *   "#FF5733",
 *   { connectionType: "output" },
 *   dropdownConfig,
 *   "##dropdown1##\n##dropdown2##",
 *   false
 * );
 */
function initializeDynamicFieldDropdownBlock(blockType, blockMessage, blockColour, blockConnectionConfig, dropdownConfig, pythonCode, inputsInline) {
  initializeDynamicBlock({
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    blockConfig: { [BlockConfigType.DROPDOWN]: dropdownConfig },
    pythonCode,
    pythonInputType: "fieldValue",
  });
}

/**
 * @typedef {Object} FieldInputConfig
 * Represents a configuration item for a field input.
 * @property {string} name - The unique name of the input field (e.g., "field1").
 * @property {string} defaultValue - The default text value displayed in the input field.
 */

/**
 * Initializes a dynamic Blockly block with field inputs and corresponding Python code generation.
 * This function validates and normalizes field input configurations, generates the necessary Blockly block
 * configuration, and sets up Python code generation logic based on the block's input field values.
 *
 * @param {string} blockType - Unique identifier for the Blockly block (e.g., 'input_1').
 * @param {string} blockMessage - Block message displayed on Blockly block, with placeholders (e.g., 'Which Column %1'). 
 *    Each placeholder corresponds to a field input.
 * @param {string | number} blockColour - Block color, specified as a hex string or a number (e.g., '#3792cb' or 230).
 * @param {blockConnectionConfig | 'output' | 'sequential' | 'next' | 'previous'} blockConnectionConfig - Configuration for block connections, with the following properties:
 *    - `connectionType`: Specifies how the block connects to other blocks (e.g., 'output', 'sequential', 'next', 'previous').
 *    - `orderType`: Execution order type ('ORDER_NONE' or 'ORDER_ATOMIC').
 * @param {Array<FieldInputConfig>} fieldInputConfig - Array of input field configurations, each containing:
 *    - `name` (string): Unique name of the input field.
 *    - `defaultValue` (string): Default text value for the input field.
 * @param {string} pythonCode - Python code template with placeholders (e.g., 'column("##column##")').
 *      These placeholders will be dynamically replaced with the values from the input fields.
 * @param {boolean} inputsInline - Whether inputs should be arranged inline or in separate rows.
 * 
 * @returns {void} This function doesn't return a value but dynamically modifies the Blockly workspace.
 *
 * @throws {Error} - Throws an error if the field input configuration is invalid or if any part of the process fails.
 *
 * @example
 * const fieldInputConfig = [
 *   { name: "column", defaultValue: "one" }
 * ];
 *
 * initializeDynamicFieldInputBlock(
 *   "column",
 *   "Which Column %1",
 *   "#3792cb",
 *   { connectionType: "output" },
 *   fieldInputConfig,
 *   'column("##column##")',
 *   false
 * );
 */
function initializeDynamicFieldInputBlock(blockType, blockMessage, blockColour, blockConnectionConfig, fieldInputConfig, pythonCode,     inputsInline) {
  initializeDynamicBlock({
    blockType,
    blockMessage,
    blockColour,
    blockConnectionConfig,
    inputsInline,
    blockConfig: { [BlockConfigType.FIELD_INPUT]: fieldInputConfig },
    pythonCode,
    pythonInputType: "fieldValue",
  });
}

/**
 * @typedef {Object} FieldNumberConfig
 * Represents a configuration item for a field number.
 * @property {string} name - The unique name of the number field (e.g., "duration").
 * @property {number} defaultValue - The default numeric value displayed in the number field.
 * @property {number} min - The minimum allowed numeric value.
 * @property {number} max - The maximum allowed numeric value.
 */

/**
 * Initializes a dynamic Blockly block with field numbers and corresponding Python code generation.
 * This function validates and normalizes field number configurations, generates the necessary Blockly block
 * configuration, and sets up Python code generation logic based on the block's numeric field values.
 *
 * @param {string} blockType - Unique identifier for the Blockly block (e.g., 'field_number_1').
 * @param {string} blockMessage - Block message displayed on Blockly block, with placeholders for numbers (e.g., 'Pause for %1 seconds'). 
 *    Each placeholder corresponds to a field number.
 * @param {string | number} blockColour - Block color, specified as a hex string or a number (e.g., '#935ba5' or 230).
 * @param {blockConnectionConfig | 'output' | 'sequential' | 'next' | 'previous'} blockConnectionConfig - Configuration for block connections, with the following properties:
 *    - `connectionType`: Specifies how the block connects to other blocks (e.g., 'output', 'sequential', 'next', 'previous').
 *    - `orderType`: Execution order type ('ORDER_NONE' or 'ORDER_ATOMIC').
 * @param {Array<FieldNumberConfig>} fieldNumberConfig - Array of field number configurations, each containing:
 *    - `name` (string): Unique name of the number field.
 *    - `defaultValue` (number): Default numeric value for the field.
 *    - `min` (number): Minimum allowed value.
 *    - `max` (number): Maximum allowed value.
 * @param {string} pythonCode - Python code template with placeholders (e.g., 'time.sleep(##duration##)').
 *      These placeholders will be dynamically replaced with the values from the number fields.
 * @param {boolean} inputsInline - Whether inputs should be arranged inline or in separate rows.
 * 
 * @returns {void} This function doesn't return a value but dynamically modifies the Blockly workspace.
 *
 * @throws {Error} - Throws an error if the field number configuration is invalid or if any part of the process fails.
 *
 * @example
 * const fieldNumberConfig = [
 *   { name: "duration", defaultValue: 1, min: 0, max: 10 }
 * ];
 *
 * initializeDynamicFieldNumberBlock(
 *   "pause_block",
 *   "Pause for %1 second(s)",
 *   "#935ba5",
 *   { connectionType: "sequential" },
 *   fieldNumberConfig,
 *   'time.sleep(##duration##)',
 *   false
 * );
 */
function initializeDynamicFieldNumberBlock(blockType, blockMessage, blockColour, blockConnectionConfig, fieldNumberConfig, pythonCode, inputsInline) {
  initializeDynamicBlock({
    blockType,
    blockMessage,
    blockColour,
    inputsInline,
    blockConnectionConfig,
    blockConfig: { [BlockConfigType.FIELD_NUMBER]: fieldNumberConfig },
    pythonCode,
    pythonInputType: "fieldValue",
  });
}

var single_axis_multi_graph_color = "#00A19D";
var buzzer_toggle = {
  type: "buzzer_toggle",
  lastDummyAlign0: "CENTRE",
  message0: "Turn %1 %2 BUZZER at pin %3",
  args0: [{
    type: "field_dropdown",
    name: "BUZZER",
    options: [
      ["on", "True"],
      ["off", "False"],
    ],
  },
  {
    type: "input_dummy",
    align: "CENTRE",
  },
  {
    type: "field_number",
    name: "PIN",
    value: 0,
    min: 0,
  },
  ],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "#197419",
  tooltip: "",
  helpUrl: "https://google.com",
};

var distance_from_time = {
  type: "distance_from_time",
  message0: "Get distance from time %1 %2",
  args0: [{
    type: "input_dummy",
  },
  {
    type: "input_value",
    name: "TIME",
    check: "Number",
  },
  ],
  inputsInline: true,
  output: null,
  colour: "#03C04A",
  tooltip: "",
  helpUrl: "",
};

var get_time = {
  type: "get_time",
  lastDummyAlign0: "CENTRE",
  message0: "Get current time",
  output: null,
  colour: "#03C04A",
  tooltip: "",
  helpUrl: "",
};

var trigger_toggle = {
  type: "trigger_toggle",
  lastDummyAlign0: "CENTRE",
  message0: "Turn %1 %2 TRIGGER at pin %3",
  args0: [{
    type: "field_dropdown",
    name: "TRIGGER",
    options: [
      ["on", "True"],
      ["off", "False"],
    ],
  },
  {
    type: "input_dummy",
    align: "CENTRE",
  },
  {
    type: "field_number",
    name: "PIN",
    value: 0,
    min: 0,
  },
  ],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "#03C04A",
  tooltip: "",
  helpUrl: "https://google.com",
};

var print_distance = {
  type: "print_distance",
  message0: "Print distance",
  previousStatement: null,
  nextStatement: null,
  colour: "#03C04A",
  tooltip: "",
  helpUrl: "",
};

var exit = {
  type: "exit",
  message0: "Exit program",
  previousStatement: null,
  colour: "#935ba5",
  tooltip: "",
  helpUrl: "",
};

var wrong_count = {
  type: "wrong_count",
  message0: "wrong_count",
  output: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};

var password = {
  type: "password",
  message0: "Password",
  output: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};

var word = {
  type: "word",
  message0: "Word",
  output: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};

var add_key_word = {
  type: "add_key_word",
  message0: "Add key to word",
  previousStatement: null,
  nextStatement: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};
var key = {
  type: "key",
  message0: "Key",
  output: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};

var key_pressed = {
  type: "key_pressed",
  message0: "Logic for keypress event: %1 %2",
  args0: [{ type: 'input_dummy' }, {
    type: "input_statement",
    name: "DO",
  },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  colour: "#710193",
  tooltip: "",
  helpUrl: "",
};

var keypad = {
  type: "keypad",
  message0: "Create keypad %1 Row Pins: %2 Col Pins: %3",
  args0: [{
    type: "input_dummy",
  },
  {
    type: "input_value",
    name: "ROW_PINS",
    check: "Array",
    align: "RIGHT",
  },
  {
    type: "input_value",
    name: "COL_PINS",
    check: "Array",
    align: "RIGHT",
  },
  ],
  inputsInline: false,
  previousStatement: null,
  nextStatement: null,
  tooltip: `This block creates a keypad with following structure:\n
      1, 2, 3, A\n
      4, 5, 6, B\n
      7, 8, 9, C\n
      *, 0, #, D`,
  colour: "#710193",
  helpUrl: "",
};

var temperature = {
  type: "temperature",
  lastDummyAlign0: "CENTRE",
  message0: "Temperature from climate sensor",
  output: null,
  colour: "45b6fe",
  tooltip: "",
  helpUrl: "",
};

var humidity = {
  type: "humidity",
  lastDummyAlign0: "CENTRE",
  message0: "Humidity from climate sensor",
  output: null,
  colour: "45b6fe",
  tooltip: "",
  helpUrl: "",
};

// var moisture_sensor = {
//   type: "moisture_sensor",
//   message0: "Create Moisture Sensor as  %1 as %2 %3  at pin number %4",
//   args0: [{
//     type: "field_input",
//     name: "moisture_sensor_name",
//     text: "moisture_sensor",
//   },
//   {
//     type: "field_dropdown",
//     name: "io",
//     options: [
//       ["input", "GPIO.IN"],
//       ["output", "GPIO.OUT"],
//     ],
//   },
//   {
//     type: "input_dummy",
//     align: "CENTRE",
//   },
//   {
//     type: "input_value",
//     name: "pin_num",
//     check: "Number",
//     align: "RIGHT",
//   },
//   ],
//   inputsInline: true,
//   previousStatement: null,
//   nextStatement: null,
//   colour: "45b6fe",
//   tooltip: "Select Moisture Sensor as Input",
//   helpUrl: "",
// };

// 2,3,4,14, 15,17,18,27,22,23,24,10, 9,25,11,8,7,5,6,12,13,19,16,26,20,21
var pin_num = {
  type: "pin_num",
  message0: "%1",
  args0: [{
    type: "field_dropdown",
    name: "pin",
    options: [
      ["2", "2"],
      ["3", "3"],
      ["4", "4"],
      ["5", "5"],
      ["6", "6"],
      ["7", "7"],
      ["8", "8"],
      ["9", "9"],
      ["10", "10"],
      ["11", "11"],
      ["12", "12"],
      ["13", "13"],
      ["14", "14"],
      ["15", "15"],
      ["16", "16"],
      ["17", "17"],
      ["18", "18"],
      ["19", "19"],
      ["20", "20"],
      ["21", "21"],
      ["22", "22"],
      ["23", "23"],
      ["24", "24"],
      ["25", "25"],
      ["26", "26"],
      ["27", "27"],
    ],
  },],
  output: null,
  colour: "#935ba5",
  tooltip: "",
  helpUrl: "",
};

// var motion_sensor = {
//   type: "motion_sensor",
//   message0: "Create Motion sensor with name  %1 as an %2 %3  at pin number %4",
//   args0: [{
//     type: "field_input",
//     name: "motion_sensor_name",
//     text: "motion_sensor",
//   },
//   {
//     type: "field_dropdown",
//     name: "io",
//     options: [
//       ["input", "GPIO.IN"],
//       ["output", "GPIO.OUT"],
//     ],
//   },
//   {
//     type: "input_dummy",
//   },
//   {
//     type: "input_value",
//     name: "pin_num",
//     check: "Number",
//     align: "RIGHT",
//   },
//   ],
//   inputsInline: true,
//   previousStatement: null,
//   nextStatement: null,
//   colour: "45b6fe",
//   tooltip: "Create Motion Sensor as Input",
//   helpUrl: "",
// };

// var button = {
//   type: "button",
//   message0: "Create Button with name %1 as an %2 %3 at pin number %4",
//   args0: [{
//     type: "field_input",
//     name: "button_name",
//     text: "button",
//   },
//   {
//     type: "field_dropdown",
//     name: "io",
//     options: [
//       ["input", "GPIO.IN"],
//       ["output", "GPIO.OUT"],
//     ],
//   },
//   {
//     type: "input_dummy",
//   },
//   {
//     type: "input_value",
//     name: "pin_num",
//     check: "Number",
//     align: "RIGHT",
//   },
//   ],
//   inputsInline: true,
//   previousStatement: null,
//   nextStatement: null,
//   colour: "45b6fe",
//   tooltip: "Select Button as Input",
//   helpUrl: "",
// };

// var relay = {
//   type: "relay",
//   message0: "Create Relay sensor with name %1 as an  %2 %3  at pin number %4",
//   args0: [{
//     type: "field_input",
//     name: "relay_name",
//     text: "Relay",
//   },
//   {
//     type: "field_dropdown",
//     name: "io",
//     options: [
//       ["output", "GPIO.OUT"]
//     ],
//   },
//   {
//     type: "input_dummy",
//   },
//   {
//     type: "input_value",
//     name: "pin_num",
//     check: "Number",
//     align: "RIGHT",
//   },
//   ],
//   inputsInline: true,
//   previousStatement: null,
//   nextStatement: null,
//   colour: "45b6fe",
//   tooltip: "Select Relay as an Output",
//   helpUrl: "",
// };

// var pi_camera = ;

var sleep = {
  type: "sleep",
  lastDummyAlign0: "CENTRE",
  message0: "Pause for %1 second(s)",
  args0: [{
    type: "field_number",
    name: "TIME",
    value: 1,
    min: 0,
  },],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "#935ba5",
  tooltip: "",
  helpUrl: "",
};

var led_toggle = {
  type: "led_toggle",
  lastDummyAlign0: "CENTRE",
  message0: "Turn %1 %2 LED at pin %3",
  args0: [{
    type: "field_dropdown",
    name: "LIGHT",
    options: [
      ["on", "True"],
      ["off", "False"],
    ],
  },
  {
    type: "input_dummy",
    align: "CENTRE",
  },
  {
    type: "field_number",
    name: "PIN",
    value: 0,
    min: 0,
  },
  ],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "#fcd12a",
  tooltip: "",
  helpUrl: "https://google.com",
};

var run = {
  type: "run",
  message0: "Start",
  nextStatement: null,
  colour: "#935ba5",
  tooltip: "",
  helpUrl: "",
};

var input = {
  type: "input",
  message0: "Get input from pin  %1",
  args0: [{
    type: "field_number",
    name: "PIN",
    value: 0,
    min: 0,
  },],
  inputsInline: true,
  output: null,
  colour: "#935ba5",
  tooltip: "",
  helpUrl: "",
};

// var use = ;

var relayToggle = {
  type: "relay toggle",
  message0: "Turn %1 %2 relay at pin %3",
  args0: [{
    type: "field_dropdown",
    name: "IO",
    options: [
      ["on", "GPIO.HIGH"],
      ["off", "GPIO.LOW"],
    ],
  },
  {
    type: "input_dummy",
  },
  {
    type: "field_number",
    name: "PIN",
    value: 0,
    min: 0,
  },
  ],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "#960018",
  tooltip: "",
  helpUrl: "",
};

var picamera_state = {
  type: "pi_camera_state",
  message0: "Turn %1 Pi Camera",
  args0: [{
    type: "field_dropdown",
    name: "state",
    options: [
      ["on", "on"],
      ["off", "off"],
    ],
  },],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: "84898b",
  tooltip: "",
  helpUrl: "",
};

var take_picture = {
  type: "takepicture",
  message0: "Take a picture using Pi Camera and save to %1",
  args0: [{
    type: "field_input",
    name: "pathname",
    text: "/home/pi/Desktop/Grok-Downloads/image.jpg",
  },],
  previousStatement: null,
  nextStatement: null,
  colour: "84898b",
  tooltip: "",
  helpUrl: "",
};

Blockly.Blocks["picamera_state"] = {
  init: function () {
    this.jsonInit(picamera_state);
  },
};

Blockly.Blocks["take_picture"] = {
  init: function () {
    this.jsonInit(take_picture);
  },
};

Blockly.Blocks["relay_toggle"] = {
  init: function () {
    this.jsonInit(relayToggle);
  },
};

Blockly.Blocks["run"] = {
  init: function () {
    this.jsonInit(run);
  },
};

Blockly.Blocks["input"] = {
  init: function () {
    this.jsonInit(input);
  },
};

Blockly.Blocks["led_toggle"] = {
  init: function () {
    this.jsonInit(led_toggle);
  },
};

// Blockly.Blocks["moisture_sensor"] = {
//   init: function () {
//     this.jsonInit(moisture_sensor);
//   },
// };

Blockly.Blocks["pin_num"] = {
  init: function () {
    this.jsonInit(pin_num);
  },
};

// Blockly.Blocks["motion_sensor"] = {
//   init: function () {
//     this.jsonInit(motion_sensor);
//   },
// };

// Blockly.Blocks["button"] = {
//   init: function () {
//     this.jsonInit(button);
//   },
// };

// Blockly.Blocks["relay"] = {
//   init: function () {
//     this.jsonInit(relay);
//   },
// };

Blockly.Blocks["sleep"] = {
  init: function () {
    this.jsonInit(sleep);
  },
};

Blockly.Blocks["humidity"] = {
  init: function () {
    this.jsonInit(humidity);
  },
};

Blockly.Blocks["temperature"] = {
  init: function () {
    this.jsonInit(temperature);
  },
};

Blockly.Blocks["keypad"] = {
  init: function () {
    this.jsonInit(keypad);
  },
};

Blockly.Blocks["key_pressed"] = {
  init: function () {
    this.jsonInit(key_pressed);
  },
};

Blockly.Blocks["key"] = {
  init: function () {
    this.jsonInit(key);
  },
};

Blockly.Blocks["word"] = {
  init: function () {
    this.jsonInit(word);
  },
};

Blockly.Blocks["add_key_word"] = {
  init: function () {
    this.jsonInit(add_key_word);
  },
};

Blockly.Blocks["password"] = {
  init: function () {
    this.jsonInit(password);
  },
};

Blockly.Blocks["wrong_count"] = {
  init: function () {
    this.jsonInit(wrong_count);
  },
};

Blockly.Blocks["exit"] = {
  init: function () {
    this.jsonInit(exit);
  },
};

Blockly.Blocks["print_distance"] = {
  init: function () {
    this.jsonInit(print_distance);
  },
};

Blockly.Blocks["trigger_toggle"] = {
  init: function () {
    this.jsonInit(trigger_toggle);
  },
};

Blockly.Blocks["get_time"] = {
  init: function () {
    this.jsonInit(get_time);
  },
};

Blockly.Blocks["distance_from_time"] = {
  init: function () {
    this.jsonInit(distance_from_time);
  },
};

Blockly.Blocks["buzzer_toggle"] = {
  init: function () {
    this.jsonInit(buzzer_toggle);
  },
};

Blockly.Python["buzzer_toggle"] = function (block) {
  var dropdown_trigger = block.getFieldValue("BUZZER");
  var number_pin = block.getFieldValue("PIN");
  //TODO: Assemble Python into code variable.
  // var code = `GPIO.output(pin["buzzer"],${dropdown_trigger})\ndevice["buzzer"]=${dropdown_trigger}\n`;
  var code = `GPIO.output(${number_pin}, ${dropdown_trigger})\n`;
  return code;
};

Blockly.Blocks["GPIO_output"] = {
  init: function () {
    this.jsonInit({
      type: "GPIO_output",
      lastDummyAlign0: "CENTRE",
      message0: "Change output to %1 at pin %2",
      args0: [{
        type: "field_dropdown",
        name: "GPIO",
        options: [
          ["Low", "GPIO.LOW"],
          ["High", "GPIO.HIGH"],
        ],
      },
      {
        type: "input_value",
        name: "PIN",
        value: 0,
        min: 0,
      },
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "",
      helpUrl: "https://google.com",
    });
  },
};
Blockly.Python["GPIO_output"] = function (block) {
  var dropdown_GPIO = block.getFieldValue("GPIO");
  var number_pin = Blockly.Python.valueToCode(block, 'PIN', Blockly.Python.ORDER_ATOMIC);
  var code = `GPIO.output(${number_pin}, ${dropdown_GPIO})\n`;
  return code;
};

Blockly.Python["distance_from_time"] = function (block) {
  var value_time = Blockly.Python.valueToCode(
    block,
    "TIME",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.

  var code = `${(value_time * 33112) / 2}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

var BLOCKS_GPIO_JSON = {
  "type": "block_type",
  "message0": "Block text %1",
  "args0": [
    {
      "type": "input_value",
      "name": "pin",
      "align": "RIGHT"
    }
  ],
  "inputsInline": true,
  "previousStatement": null,
  "nextStatement": null,
  "tooltip": "",
  "helpUrl": ""
};
var PYTHON_GPIO_INPUT = function (block) {
  var value_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  var code = `GPIO.setup(${value_pin}, GPIO.IN)\n`;
  return code;
};
var PYTHON_GPIO_OUTPUT = function (block) {
  var value_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  var code = `GPIO.setup(${value_pin}, GPIO.OUT)\n`;
  return code;
};

Blockly.Blocks["ir_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "ir_sensor";
    data['message0'] = "Set IR Sensor as input at pin %1";
    data['colour'] = "230";
    this.jsonInit(data);
  },
};
Blockly.Python["ir_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["motion_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "motion_sensor";
    data['message0'] = "Set Motion Sensor as input at pin %1";
    data['colour'] = "ce897b";
    this.jsonInit(data);
  },
};
Blockly.Python["motion_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["moisture_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "moisture_sensor";
    data['message0'] = "Set Moisture Sensor as input at pin %1";
    data['colour'] = "#3792cb";
    this.jsonInit(data);
  },
};
Blockly.Python["moisture_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["button"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "button";
    data['message0'] = "Set Button as input at pin %1";
    data['colour'] = "#1f417e";
    this.jsonInit(data);
  },
};
Blockly.Python["button"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["relay"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "relay";
    data['message0'] = "Set Relay as output at pin %1";
    data['colour'] = "#960018";
    this.jsonInit(data);
  },
};
Blockly.Python["relay"] = function (block) {
  var value_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  var code = `GPIO.setup(${value_pin}, GPIO.OUT)\nGPIO.output(${value_pin}, GPIO.HIGH)\n`;
  return code;
};;

Blockly.Blocks["buzzer"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "buzzer";
    data['message0'] = "Set Buzzer as output at pin %1";
    data['colour'] = "#197419";
    this.jsonInit(data);
  },
};
Blockly.Python["buzzer"] = PYTHON_GPIO_OUTPUT;

Blockly.Blocks["pir_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "pir_sensor";
    data['message0'] = "Set PIR Sensor as input at pin %1";
    data['colour'] = "230";
    this.jsonInit(data);
  },
};
Blockly.Python["pir_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["raindrop"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "raindrop";
    data['message0'] = "Set Raindrop Sensor as input at pin %1";
    data['colour'] = "#3792cb";
    this.jsonInit(data);
  },
};
Blockly.Python["raindrop"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["air_quality_sensor_data"] = {
  init: function () {
    this.jsonInit({
      "type": "air_quality_sensor_data",
      "message0": "Get Air Quality Data from pin %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "setAirQualityValue",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output" : null,
      "colour": "#00d7bb",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['air_quality_sensor_data'] = function (block) {  
  var sensor_pin = block.getFieldValue('setAirQualityValue');
  var code = `read_adc(${sensor_pin})`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["alcohol_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "alcohol_sensor";
    data['message0'] = "Set Alcohol Sensor as input at pin %1";
    data['colour'] = "#ff6600";
    this.jsonInit(data);
  },
};
Blockly.Python["alcohol_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["ldr_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "ldr_sensor";
    data['message0'] = "Set LDR Sensor as input at pin %1";
    data['colour'] = "#191919";
    this.jsonInit(data);
  },
};
Blockly.Python["ldr_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks["sound_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "sound_sensor";
    data['message0'] = "Set Sound Sensor as input at pin %1";
    data['colour'] = "#197419";
    this.jsonInit(data);
  },
};
Blockly.Python["sound_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks['read_accel'] = {
  init: function () {
    this.jsonInit({
      type: "read_accel",
      message0: "Get values from accelerometer into  %1 =X,  %2 =Y, %3 =Z",
      args0: [
        { type: "input_value", name: "x" },
        { type: "input_value", name: "y" },
        { type: "input_value", name: "z" }
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#191919",
      tooltip: "",
      helpUrl: ""
    });
  }
};
Blockly.Python['read_accel'] = function (block) {
  var value_x = Blockly.Python.valueToCode(block, 'x', Blockly.Python.ORDER_ATOMIC);
  var value_y = Blockly.Python.valueToCode(block, 'y', Blockly.Python.ORDER_ATOMIC);
  var value_z = Blockly.Python.valueToCode(block, 'z', Blockly.Python.ORDER_ATOMIC);
  var code = value_x + ', ' + value_y + ', ' + value_z + ' = accel.read()\n';
  return code;
};

Blockly.Blocks['get_compass_angle'] = {
  init: function () {
    this.jsonInit({
      type: "get_compass_angle",
      message0: "Get current angle using Compass sensor",
      output: null,
      colour: 65,
      tooltip: "",
      helpUrl: ""
    });
  }
};
Blockly.Python['get_compass_angle'] = function (block) {
  var code = 'calculate_angle()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["get_time"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "time.time()\n";
  return code;
};

Blockly.Python["trigger_toggle"] = function (block) {
  var dropdown_trigger = block.getFieldValue("TRIGGER");
  var number_pin = block.getFieldValue("PIN");
  //TODO: Assemble Python into code variable.
  var code = `GPIO.output(${number_pin}, ${dropdown_trigger})\n`;
  return code;
};

Blockly.Python["print_distance"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'print("Distance : {0:5.1f}cm".format(distance))\n';
  return code;
};

Blockly.Python["exit"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `exit()\n`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};
Blockly.Python["password"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "password";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["wrong_count"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "wrong_count";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["word"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "word";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["add_key_word"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "word += key\n";
  return code;
};

Blockly.Python["key"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "key";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["key_pressed"] = function (block) {
  var statements_do = Blockly.Python.statementToCode(block, "DO");

  var code = `
def printKey(key):
  global word, password
${statements_do}
`;
  return code;
};

Blockly.Python["keypad"] = function (block) {
  // TODO: Assemble Python into code variable.
  var value_row_pins = Blockly.Python.valueToCode(
    block,
    "ROW_PINS",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_col_pins = Blockly.Python.valueToCode(
    block,
    "COL_PINS",
    Blockly.Python.ORDER_ATOMIC
  );
  var dropdown_name = block.getFieldValue("ACTION");

  var code = `
  \n
KEYPAD = [
  ['1', '2', '3', 'A'],
  ['4', '5', '6', 'B'],
  ['7', '8', '9', 'C'],
  ['*', '0', '#', 'D'],
]\n
factory = rpi_gpio.KeypadFactory()\n
keypad = factory.create_keypad(keypad=KEYPAD, row_pins=${value_row_pins}, col_pins=${value_col_pins})\n`;
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.Blocks['register_key_press_event'] = {
  init: function () {
    this.jsonInit({
      "type": "register_key_press_event",
      "message0": "Register a key press event",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#710193",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['register_key_press_event'] = function (block) {
  var code = 'keypad.registerKeyPressHandler(printKey)\n';
  return code;
};


Blockly.Python["temperature"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `Adafruit_DHT.read_retry(11, 4)[1]`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["humidity"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `Adafruit_DHT.read_retry(11, 4)[0]`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

// Blockly.Python["moisture_sensor"] = function (block) {
//   // var text_moisture_sensor_name = block.getFieldValue("moisture_sensor_name");
//   // var dropdown_io = block.getFieldValue("io");
//   var value_pin_num = Blockly.Python.valueToCode(
//     block,
//     "pin_num",
//     Blockly.Python.ORDER_ATOMIC
//   );
//   // TODO: Assemble Python into code variable.
//   var code = `GPIO.setup(${value_pin_num}, GPIO.IN)\n`;
//   return code;
// };

Blockly.Python["pin_num"] = function (block) {
  var dropdown_pin = block.getFieldValue("pin");
  // TODO: Assemble Python into code variable.
  var code = `${dropdown_pin}`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

// Blockly.Python["motion_sensor"] = function (block) {
//   var text_motion_sensor_name = block.getFieldValue("motion_sensor_name");
//   var dropdown_io = block.getFieldValue("io");
//   var value_pin_num = Blockly.Python.valueToCode(
//     block,
//     "pin_num",
//     Blockly.Python.ORDER_ATOMIC
//   );
//   // TODO: Assemble Python into code variable.
//   var code = `GPIO.setup(${value_pin_num}, ${dropdown_io})\n`;
//   return code;
// };

// Blockly.Python["button"] = function (block) {
//   var text_button_name = block.getFieldValue("button_name");
//   var dropdown_io = block.getFieldValue("io");
//   var value_pin_num = Blockly.Python.valueToCode(
//     block,
//     "pin_num",
//     Blockly.Python.ORDER_ATOMIC
//   );
//   // TODO: Assemble Python into code variable.
//   var code = `GPIO.setup(${value_pin_num}, ${dropdown_io})\n`;
//   return code;
// };


Blockly.Blocks["led"] = {
  init: function () {
    this.jsonInit({
      type: "led",
      message0: "Set LED sensor as output at pin %1",
      args0: [{
        type: "input_value",
        name: "pin_num",
        check: "Number",
        align: "RIGHT",
      },
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#fcd12a",
      tooltip: "",
      helpUrl: "",
    });
  },
};
Blockly.Python["led"] = function (block) {
  var value_pin_num = Blockly.Python.valueToCode(
    block,
    "pin_num",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code = `GPIO.setup(${value_pin_num}, GPIO.OUT)\n`;
  return code;
};

// Blockly.Python["relay"] = function (block) {
//   var text_relay_name = block.getFieldValue("relay_name");
//   var dropdown_io = block.getFieldValue("io");
//   var value_pin_num = Blockly.Python.valueToCode(
//     block,
//     "pin_num",
//     Blockly.Python.ORDER_ATOMIC
//   );
//   // TODO: Assemble Python into code variable.
//   var code = `GPIO.setup(${value_pin_num}, ${dropdown_io})\nGPIO.setup(${value_pin_num}, GPIO.HIGH)\n`;
//   return code;
// };

Blockly.Blocks["pi_camera"] = {
  init: function () {
    this.jsonInit({
      type: "pi_camera",
      message0: "Set a Pi Camera as an output",
      args0: [],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "84898b",
      tooltip: "Select Camera as an Input or Output",
      helpUrl: "",
    });
  },
};
Blockly.Python["pi_camera"] = function (block) {
  // var text_picamera_name = block.getFieldValue("picamera_name");
  // var dropdown_io = block.getFieldValue("io");
  var code = "camera = PiCamera()\n";
  return code;
};

Blockly.Blocks["ultrasonic_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "ultrasonic_sensor",
      "message0": "Set Ultrasonic Sensor as %1 Echo Input at Pin %2 Trigger Output at Pin %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "echo",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "trigger",
          "align": "RIGHT"
        }
      ],
      "inputsInline": false,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};
Blockly.Python["ultrasonic_sensor"] = function (block) {
  var value_echo = Blockly.Python.valueToCode(block, 'echo', Blockly.Python.ORDER_ATOMIC);
  var value_trigger = Blockly.Python.valueToCode(block, 'trigger', Blockly.Python.ORDER_ATOMIC);
  var code = `GPIO.setup(${value_echo}, GPIO.IN)\nGPIO.setup(${value_trigger}, GPIO.OUT)\n`;
  return code;
};

Blockly.Python["sleep"] = function (block) {
  var number_time = block.getFieldValue("TIME");
  //TODO: Assemble Python into code variable.
  var code = `time.sleep(${number_time})\n`;
  return code;
};

Blockly.Python["led_toggle"] = function (block) {
  var dropdown_light = block.getFieldValue("LIGHT");
  var number_pin = block.getFieldValue("PIN");
  //TODO: Assemble Python into code variable.
  // var code = `GPIO.output(pin["led"],${dropdown_light})\ndevice["led"]=${dropdown_light}\n`
  var code = `GPIO.output(${number_pin}, ${dropdown_light})\n`;
  return code;
};

Blockly.Python["run"] = function (block) {
  return "";
};

Blockly.Python["input"] = function (block) {
  var number_pin = block.getFieldValue("PIN");
  // TODO: Assemble Python into code variable.
  var code = `GPIO.input(${number_pin})`;
  // var code = `GPIO.input(pin["ir_sensor"])`;
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Python["relay_toggle"] = function (block) {
  var dropdown_io = block.getFieldValue("IO");
  var number_pin = block.getFieldValue("PIN");
  // TODO: Assemble Python into code variable.
  var code = `GPIO.output(${number_pin}, ${dropdown_io})\n`;
  return code;
};

Blockly.Blocks["use"] = {
  init: function () {
    this.jsonInit({
      type: "use",
      message0: "Use %1",
      args0: [{
        type: "field_dropdown",
        name: "import",
        options: [
          ["Air Quality Sensor", "air_quality_sensor"],
          ["Alcohol Sensor", "alcohol_sensor"],
          ["BloodPressure Sensor", "bloodpressure_sensor"],
          ["Button", "button"],
          ["Buzzer", "buzzer"],
          ["Climate Sensor", "temperature"],
          ["DC Drive", "Dc_drive"],
          ["ECG Sensor","ecg_sensor"], 
          ["Exit", "exit"],
          ["Flame Sensor", "flame_sensor"],
          ["Flow Sensor", "flow_sensor"],
          ["Gas Sensor-Analog", "gas_sensor_analog"],
          ["GPS Sensor", "gps_sensor"],
          ["Gyroscope", "gyroscope"],
          ["GSR Sensor","gsr_sensor"],
          ["Hall Effect Sensor", "hall_effect_sensor"],
          ["IR Sensor", "ir_sensor"],
          ["Json", "json"],
          ["Keypad", "keypad"],
          // ["Graphs", "graphs"],
          // ["Files", "files"],
          // ["Statistics", "statistics"],
          ["LCD Display", "lcd_display"],
          ["LDR Sensor", "ldr_sensor"],
          ["LED", "led"],
          ["Metal Detector Sensor", "metal_detector_sensor"],
          ["Moisture Sensor", "moisture_sensor"],
          ["Motion Sensor", "motion_sensor"],
          ["Particulate Matter Sensor", "particulate_matter_sensor"],
          ["PH Sensor", "ph_sensor"],
          ["Pi Camera", "pi_camera"],
          ["PIR Sensor", "pir_sensor"],
          ["Power Meter", "power_meter"],
          ["Pulse Oximeter", "pulseoximeter"],
          ["Raindrop Sensor", "raindrop"],
          ["Relay", "relay"],
          ["Robotics", "robotics"],
          ["RTC", "rtc"],
          ["Serial", "serial"],
          ["Servo Motor", "servo motor"],
          ["SMBus", "smbus"],
          ["Sound Sensor", "sound_sensor"],
          ["Stepper Motor", "stepper_motor"],
          ["TDS Sensor", "tds_sensor"],
          ["Thermal Sensor", "thermal_sensor"],
          ["Telepot", "telepot"],
          ["Time", "time"],
          ["Ultrasonic Sensor", "ultrasonic_sensor"],
          ["USB Camera", "usb_camera"],
          ["Vibration Sensor", "vibration_sensor"],
          ["Weight Sensor", "weight_sensor"],
        ],
      },],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#935ba5",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["use"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "relay":
    case "ultrasonic_sensor":
    case "moisture_sensor":
    case "buzzer":
    case "ir_sensor":
    case "pir_sensor":
    case "raindrop":
    case "alcohol_sensor":
    case "ldr_sensor":
    case "sound_sensor":
    case "button":
    case "led":
    case "keypad":
    case "motion_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "time":
      code = "import time\n";
      break;
    case "pi_camera":
      code = `from picamera import PiCamera\n`;
      break;
    case "temperature":
    case "humidity":
      code = `import Adafruit_DHT\n`;
      break;
    case "gps_sensor":
      code = "import serial\nimport pynmea2" + "\n";
      break;
    case "robotics":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)" +
        "\n";
      break;
    case "smbus":
      code = "import smbus" + "\n";
      break;
    case "telepot":
      code =
        "import telepot" +
        "\n";
      break;
    case "graphs":
      code = "import matplotlib.pyplot as plt\n"
      break;
    case "files":
      code = "import pandas as pd\n"
      break;
    case "statistics":
      code = "import numpy as np\nnp.set_printoptions(suppress=True) # prevent numpy exponential\n"
      break;
    case "servo motor":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n";
      break;
    case "exit":
      code = `from sys import exit\n`;
      break;
    case "pulseoximeter":
      code = `import sys\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')\nimport max30100\nmx30 = max30100.MAX30100()\nmx30.enable_spo2()\n`;
      break;
    // case "tds_sensor":
    //   code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\ndef tdsValue(analog_channel):\n\tValue = read_adc(analog_channel)\n\tif Value != 0:\n\t\tVoltage = Value *5/1024\n\t\ttds = int((133.42/Voltage*Voltage*Voltage - 255.86*Voltage*Voltage + 857.39*Voltage)*0.5)tds = int((133.42/Voltage*Voltage*Voltage - 255.86*Voltage*Voltage + 857.39*Voltage)*0.5)\n\t\treturn tds\n`;
    //   break;
    case "tds_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "flame_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "flow_sensor":
      code = `import RPi.GPIO as GPIO\nimport time, sys\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "ph_sensor":
      // code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n\n`;
      break;
    case "rtc":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import init_rtc\nfrom grok_i2c_peripherals import rtc_read_time\nfrom grok_i2c_peripherals import rtc_read_date_str\nfrom grok_i2c_peripherals import rtc_read_time_str\nfrom grok_i2c_peripherals import rtc_write_time\nfrom grok_i2c_peripherals import rtc_write_date\n`;
      break;
    case "usb_camera":
      code = `from cv2 import *\ncam_port = 0\n`;
      break;
    case "power_meter":
      // code = 
      // `import time\nimport json\nimport serial\nimport modbus_tk.defines as cst\nfrom modbus_tk import modbus_rtu\n` +  
      // `serial = serial.Serial(port='/dev/ttyS0',baudrate=9600,bytesize=8,parity='N',stopbits=1,xonxoff=0)\nmaster = modbus_rtu.RtuMaster(serial)\nmaster.set_timeout(2.0)\nmaster.set_verbose(True)\ndict_payload = dict()\n` +
      // `def readData():\n\ttry:\n\t\tdata = master.execute(1, cst.READ_INPUT_REGISTERS, 0, 10)\n\t\tdict_payload["voltage"]= data[0] / 10.0\n\t\tdict_payload["current_A"] = (data[1] + (data[2] << 16)) / 1000.0 # [A]\n\t\tdict_payload["power_W"] = (data[3] + (data[4] << 16)) / 10.0 # [W]\n\t\tdict_payload["energy_KWh"] = (data[5] + (data[6] << 16))/1000 # [KWh]\n\t\tdict_payload["frequency_Hz"] = data[7] / 10.0 # [Hz]\n\t\tdict_payload["power_factor"] = data[8] / 100.0\n\t\tstr_payload = json.dumps(dict_payload, indent=2)\n\t\tprint(str_payload)\n\t\tpowerReadings = [dict_payload["voltage"],dict_payload["current_A"],dict_payload["power_W"],dict_payload["energy_KWh"],dict_payload["frequency_Hz"],dict_payload["power_factor"]]\n\texcept:\n\t\tpowerReadings =[0,0,0,0,0,0]\n\treturn powerReadings\n`;
      code =
        `import time\nimport json\nimport serial\nimport modbus_tk.defines as cst\nfrom modbus_tk import modbus_rtu\n` +
        `serial = serial.Serial(port='/dev/ttyS0',baudrate=9600,bytesize=8,parity='N',stopbits=1,xonxoff=0)\nmaster = modbus_rtu.RtuMaster(serial)\nmaster.set_timeout(2.0)\nmaster.set_verbose(True)\ndict_payload = dict()\n`;
      break;
    case "serial":
      code = "import serial\n";
      break;
    case "weight_sensor":
      code = `import sys` + 
        `\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')` +
        `\nEMULATE_HX711=False` +
        `\nreferenceUnit = 261` +
        `\nif not EMULATE_HX711:` +
        `\n\timport RPi.GPIO as GPIO` +
        `\n\tfrom hx711 import HX711` +
        `\nelse:` +
        `\n\tfrom emulated_hx711 import HX711` +
        `\ndef cleanAndExit():` +
        `\n\tprint("Cleaning...")` +
        `\n\tif not EMULATE_HX711:` +
        `\n\t\tGPIO.cleanup()` +
        `\n\tprint("Bye!")` +
        `\n\tsys.exit()\n`;
      break;
    case "Dc_drive":
      code = "PWM1_Fine = None" +
        `\nPWM2_Course = None` +
        `\nimport RPi.GPIO as GPIO` +
        `\nGPIO.setwarnings(False)` +
        `\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "vibration_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gsr_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "ecg_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gas_sensor_analog":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "hall_effect_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "metal_detector_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "lcd_display":
      code = `from rpi_lcd import LCD\nlcd_display_I2C = LCD()\n`;
      break;
    case "json":
      code = `import json\n`;
      break;
    case "thermal_sensor":
      code = `import smbus\nimport time\n\ndef readMLX90614value(registerAddress):\n\terror = None\n\tfor i in range(3):\n\t\ttry:\n\t\t\treturn smbus.SMBus(1).read_word_data(0x5a, registerAddress)\n\t\texcept IOError as e:\n\t\t\terror = e\n\t\t\ttime.sleep(0.1)\n\traise error\n\ndef readObjectTemperature():\n\treturn -273.15 + (readMLX90614value(0x07) * 0.02)\n\ndef readAmbientTemperature():\n\treturn -273.15 + (readMLX90614value(0x06) * 0.02)\n\n`;
      break;
    case "air_quality_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "particulate_matter_sensor":
      code = `import serial\nimport RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\nimport time\n\nser = serial.Serial('/dev/ttyUSB0', 9600)\n\ndef get_pm25():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm25 = (data[3] * 256 + data[2]) / 10.0\n\telse:\n\t\tpm25 = 0\n\treturn pm25\n\ndef get_pm10():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm10 = (data[5] * 256 + data[4]) / 10.0\n\telse:\n\t\tpm10 = 0\n\treturn pm10\n`;
      break;
    case "bloodpressure_sensor":
      code = `import serial\nserial_input_data = serial.Serial('/dev/serial0', 9600)\n\n`;
      break;
    case "gyroscope":
      code = `import smbus\nPWR_MGMT_1 = 0x6B\nSMPLRT_DIV = 0x19\nCONFIG = 0x1A\nGYRO_CONFIG = 0x1B\nINT_ENABLE = 0x38\nACCEL_XOUT_H = 0x3B\nACCEL_YOUT_H = 0x3D\nACCEL_ZOUT_H = 0x3F\nGYRO_XOUT_H = 0x43\nGYRO_YOUT_H = 0x45\nGYRO_ZOUT_H = 0x47\n`+
      `def MPU_Init():\n\tbus.write_byte_data(Device_Address, SMPLRT_DIV, 7)\n\tbus.write_byte_data(Device_Address, PWR_MGMT_1, 1)\n\tbus.write_byte_data(Device_Address, CONFIG, 0)\n\tbus.write_byte_data(Device_Address, GYRO_CONFIG, 24)\n\tbus.write_byte_data(Device_Address, INT_ENABLE, 1)\n`+
      `def read_raw_data(addr):\n\thigh = bus.read_byte_data(Device_Address, addr)\n\tlow = bus.read_byte_data(Device_Address, addr + 1)\n\tvalue = (high << 8) | low\n\tif value > 32768:\n\t\tvalue = value - 65536\n\treturn value\nbus = smbus.SMBus(1)  # or bus=smbus.SMBus(0) for older version boards\nDevice_Address = 0x68 # MPU6050 device address\nMPU_Init()\n\n`;
      break;
    case "stepper_motor":
      code = `import RPi.GPIO as GPIO\nGPIO.setmode(GPIO.BCM)\nimport time\n\ndef calculate_delay(speed_percentage):\n\tmax_delay = 0.02  \n\tmin_delay = 0.0005 \n\tif speed_percentage < 0:\n\t\tspeed_percentage = 0\n\telif speed_percentage > 100:\n\t\tspeed_percentage = 100\n\treturn max_delay - (speed_percentage / 100) * (max_delay - min_delay)\n\ndef run_motor(speed_percentage, direction, STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\ttry:\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\texcept KeyboardInterrupt:\n\t\tpass\n\ndef move_steps(steps, speed_percentage, direction,STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\tfor _ in range(steps):\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\n`
      break;
  }
  return code;
};


Blockly.Blocks["use_data_science"] = {
  init: function () {
    this.jsonInit({
      type: "use_data_science",
      message0: "use %1",
      args0: [{
        type: "field_dropdown",
        name: "import",
        options: [
          ["Adam Optimizer", "adam_optimizer"],
          ["API", "api"],
          ["Binary Class Matrix", "binary_class_matrix"],
          ["Camera", "camera"],
          ["Computer Vision", "computer_vision"],
          ["Dataframe", "pandas"],
          ["Double Ended Queue", "double_ended_queue"],
          ["Files", "files"],
          ["Graph", "graphs"],
          ["Image Generator", "image_generator"],
          ["Image Processing", "image_processing"],
          ["Json", "json"],
          ["Layers", "layers"],
          ["Machine Learning", "sklearn"],
          ["Natural Language Processing", "natural_language_processing"],
          ["OS", "os"],
          ["Pickle", "pickle"],
          ["Prediction Model", "prediction_model"],
          ["Random", "random"],
          ["Random Element", "random_element"],
          ["Re-order Sequence", "reorder_sequence"],
          ["SciPy Statistics", "scipy_statistics"],
          ["Sentiment Analysis", "sentiment_analysis"],
          ["Statistics", "statistics"],
          ["Target Label Encoder", "target_label_encoder"],
          ["Time", "time"],
          ["Tensorflow", "tensorflow"],
          ["TFLearn", "tflearn"],
          ["Train & Test Subsets", "train_test_subsets"],
          ["Warnings", "warnings"],
        ],
      },],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#935ba5",
      tooltip: "",
      helpUrl: "",
    });
  },
};
Blockly.Python["use_data_science"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "graphs":
      code = "import matplotlib.pyplot as plt\nimport sys\nimport io\nimport base64\nimport random\nplt.clf()\n"
      break;
    case "files":
    case "pandas":
      code = "import pandas as pd\n"
      break;
    case "sklearn":
      code = "from sklearn.linear_model import LinearRegression\nfrom sklearn.cluster import KMeans\n"
      break;
    case "statistics":
      code = "import numpy as np\nnp.set_printoptions(suppress=True) # prevent numpy exponential\n"
      break;
    case "computer_vision":
      code = `import cv2\n`;
      break;
    case "prediction_model":
      code = `from tensorflow.keras.models import load_model\n`;
      break;
    case "sentiment_analysis":
      code = `from vaderSentiment.vaderSentiment import SentimentIntensityAnalyzer\n`;
      break;
    case "tflearn":
      code = `import tensorflow as tf\nimport tflearn\n`;
      break;
    case "random":
      code = `import random\n`;
      break;
    case "natural_language_processing":
      code = `import nltk\nfrom nltk.stem.lancaster import LancasterStemmer\n`;
      break;
    case "json":
      code = `import json\n`;
      break;
    case "pickle":
      code = `import pickle\n`;
      break;
    case "warnings":
      code = `import warnings\nwarnings.filterwarnings("ignore")\n`;
      break;
    case "image_processing":
      code = `import imutils\n`;
      break;
    case "double_ended_queue":
      code = `from collections import deque\n`;
      break;
    case "time":
      code = `import time\n`;
      break;  
    case "os":
      code = `import os\n`;
      break;    
    case "image_generator":
      code = `from tensorflow.keras.preprocessing.image import ImageDataGenerator\n`;
      break;
    case "layers":
      code = `from tensorflow.keras.layers import Dense,MaxPool2D,Dropout,Flatten,Conv2D,GlobalAveragePooling2D,Activation\n`;
      break;
    case "adam_optimizer":
      code = `from tensorflow.keras.optimizers import Adam\n`;
      break;
    case "binary_class_matrix":
      code = `from tensorflow.keras.utils import to_categorical\n`;
      break;
    case "train_test_subsets":
      code = `from sklearn.model_selection import train_test_split\n`;
      break;
    case "target_label_encoder":
      code = `from sklearn.preprocessing import LabelEncoder\n`;
      break;
    case "random_element":
      code = `from random import choice\n`;
      break;
    case "reorder_sequence":
      code = `from random import shuffle\n`;
      break;
    case "scipy_statistics":
      code = `from scipy import stats as st\n`;
      break; 
    case "tensorflow":
      code = `import tensorflow as tf\n`;
      break;
    case "api":
      code = `import json, requests\n`;
      break;
    case "camera":
      code = `import cv2\nimport numpy as np\n`;
      break;
  }
  return code;
};

Blockly.Blocks["use_robotics"] = {
  init: function () {
    this.jsonInit({
      type: "use_robotics",
      message0: "use %1",
      args0: [{
        type: "field_dropdown",
        name: "import",
        options: [
          ["Accelerometer", "accelerometer"],
          ["Alcohol Sensor", "alcohol_sensor"],
          ["Button", "button"],
          ["Buzzer", "buzzer"],
          ["Climate Sensor", "temperature"],
          ["Compass Sensor", "compass_sensor"],
          ["DC Drive", "Dc_drive"],
          ["ECG Sensor","ecg_sensor"], 
          ["Exit", "exit"],
          ["Flame Sensor", "flame_sensor"],
          ["Flow Sensor", "flow_sensor"],
          ["Gas Sensor-Analog", "gas_sensor_analog"],
          ["GPS Sensor", "gps_sensor"],
          ["Gyroscope", "gyroscope"],
          ["GSR Sensor","gsr_sensor"],
          ["Hall Effect Sensor", "hall_effect_sensor"],          
          ["IR Sensor", "ir_sensor"],
          ["Keypad", "keypad"],
          ["LDR Sensor", "ldr_sensor"],
          ["LED", "led"],
          ["Metal Detector Sensor", "metal_detector_sensor"],
          ["Moisture Sensor", "moisture_sensor"],
          ["Motion Sensor", "motion_sensor"],
          ["Particulate Matter Sensor", "particulate_matter_sensor"],
          ["PH Sensor", "ph_sensor"],
          ["Pi Camera", "pi_camera"],
          ["PIR Sensor", "pir_sensor"],
          ["Power Meter ", "power_meter"],
          ["Pulse Oximeter", "pulseoximeter"],
          ["Raindrop Sensor", "raindrop"],
          ["Relay", "relay"],
          ["Robotics", "robotics"],
          ["Robotic Arm", "robotic_arm"],
          ["RTC", "rtc"],
          ["Serial", "serial"],
          ["Servo Motor", "servo motor"],
          ["SMBus", "smbus"],
          ["Sound Sensor", "sound_sensor"],
          ["Stepper Motor", "stepper_motor"],
          ["TDS Sensor", "tds_sensor"],
          ["Telepot", "telepot"],
          ["Time", "time"],
          ["Ultrasonic Sensor", "ultrasonic_sensor"],
          ["USB Camera", "usb_camera"],
          ["Vibration Sensor", "vibration_sensor"],
          ["Weight Sensor", "weight_sensor"],
        ],
      },],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#935ba5",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["use_robotics"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "accelerometer":
      code = "import Adafruit_ADXL345\naccel = Adafruit_ADXL345.ADXL345()\n"
      break;
    case "compass_sensor":
      code = "import smbus\nimport math\n\nRegister_A = 0\nRegister_B = 0x01\nRegister_mode = 0x02\n\nX_axis_H = 0x03\nZ_axis_H = 0x05\nY_axis_H = 0x07\ndeclination = -0.00669\npi = 3.14159265359\n\nbus = smbus.SMBus(1)\nDevice_Address = 0x1e\n\ndef Magnetometer_Init():\n\tbus.write_byte_data(Device_Address, Register_A, 0x70)\n\tbus.write_byte_data(Device_Address, Register_B, 0xa0)\n\tbus.write_byte_data(Device_Address, Register_mode, 0)\n\ndef read_raw_data(addr):\n\thigh = bus.read_byte_data(Device_Address, addr)\n\tlow = bus.read_byte_data(Device_Address, addr + 1)\n\tvalue = ((high << 8) | low)\n\tif (value > 32768):\n\t\tvalue = value - 65536\n\treturn value\n\ndef calculate_angle():\n\tx = read_raw_data(X_axis_H)\n\tz = read_raw_data(Z_axis_H)\n\ty = read_raw_data(Y_axis_H)\n\theading = math.atan2(y, x) + declination\n\tif (heading > 2 * pi):\n\t\theading = heading - 2 * pi\n\tif (heading < 0):\n\t\theading = heading + 2 * pi\n\treturn int(heading * 180 / pi)\n\nMagnetometer_Init()\n";
      break;
    case "relay":
    case "robotics":
    case "ultrasonic_sensor":
    case "moisture_sensor":
    case "buzzer":
    case "ir_sensor":
    case "pir_sensor":
    case "raindrop":
    case "alcohol_sensor":
    case "ldr_sensor":
    case "sound_sensor":
    case "button":
    case "led":
    case "keypad":
    case "motion_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "time":
      code = "import time\n";
      break;
    case "pi_camera":
      code = `from picamera import PiCamera\n`;
      break;
    case "temperature":
    case "humidity":
      code = `import Adafruit_DHT\n`;
      break;
    case "gps_sensor":
      code = "import serial\nimport pynmea2" + "\n";
      break;
    case "robotics":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)" +
        "\n";
      break;
    case "smbus":
      code = "import smbus" + "\n";
      break;
    case "telepot":
      code =
        "import telepot" +
        "\n";
      break;
    case "graphs":
      code = "import matplotlib.pyplot as plt\n"
      break;
    case "files":
      code = "import pandas as pd\n"
      break;
    case "statistics":
      code = "import numpy as np\nnp.set_printoptions(suppress=True) # prevent numpy exponential\n"
      break;
    case "servo motor":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n";
      break;
    case "exit":
      code = `from sys import exit\n`;
      break;
    case "pulseoximeter":
      code = `import sys\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')\nimport max30100\nmx30 = max30100.MAX30100()\nmx30.enable_spo2()\n`;
      break;
    case "tds_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "flame_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "flow_sensor":
      code = `import RPi.GPIO as GPIO\nimport time, sys\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "ph_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n\n`;
      break;
    case "rtc":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import init_rtc\nfrom grok_i2c_peripherals import rtc_read_time\nfrom grok_i2c_peripherals import rtc_read_date_str\nfrom grok_i2c_peripherals import rtc_read_time_str\nfrom grok_i2c_peripherals import rtc_write_time\nfrom grok_i2c_peripherals import rtc_write_date\n`;
      break;
    case "usb_camera":
      code = `from cv2 import *\ncam_port = 0\n`;
      break;
    case "power_meter":
      code =
        `import time\nimport json\nimport serial\nimport modbus_tk.defines as cst\nfrom modbus_tk import modbus_rtu\n` +
        `serial = serial.Serial(port='/dev/ttyS0',baudrate=9600,bytesize=8,parity='N',stopbits=1,xonxoff=0)\nmaster = modbus_rtu.RtuMaster(serial)\nmaster.set_timeout(2.0)\nmaster.set_verbose(True)\ndict_payload = dict()\n`;
      break;
    case "serial":
      code = "import serial\n";
      break;
    case "weight_sensor":
      code = `import sys` + 
        `\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')` +
        `\nEMULATE_HX711=False` +
        `\nreferenceUnit = 261` +
        `\nif not EMULATE_HX711:` +
        `\n\timport RPi.GPIO as GPIO` +
        `\n\tfrom hx711 import HX711` +
        `\nelse:` +
        `\n\tfrom emulated_hx711 import HX711` +
        `\ndef cleanAndExit():` +
        `\n\tprint("Cleaning...")` +
        `\n\tif not EMULATE_HX711:` +
        `\n\t\tGPIO.cleanup()` +
        `\n\tprint("Bye!")` +
        `\n\tsys.exit()\n`;
      break;
    case "Dc_drive":
      code = "PWM1_Fine = None" +
        `\nPWM2_Course = None` +
        `\nimport RPi.GPIO as GPIO` +
        `\nGPIO.setwarnings(False)` +
        `\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "vibration_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gsr_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "ecg_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gas_sensor_analog":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "hall_effect_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "metal_detector_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "robotic_arm":
      code = `import subprocess\n`+`try:\n`+`\timport pydobot\n`+`except ImportError:\n`+`\tsubprocess.check_call(['pip', 'install', "pydobot"])\n`+`from serial.tools import list_ports\n`+`from pydobot import Dobot\n`+`available_ports = list_ports.comports()\n`+`print(f'available ports: {[x.device for x in available_ports]}')\n`+`port = available_ports[0].device\n`+`print(port)\n`+`dobot=Dobot(port)\n`;
      break;
    case "particulate_matter_sensor":
      code = `import serial\nimport RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\nimport time\n\nser = serial.Serial('/dev/ttyUSB0', 9600)\n\ndef get_pm25():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm25 = (data[3] * 256 + data[2]) / 10.0\n\telse:\n\t\tpm25 = 0\n\treturn pm25\n\ndef get_pm10():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm10 = (data[5] * 256 + data[4]) / 10.0\n\telse:\n\t\tpm10 = 0\n\treturn pm10\n`;
      break;
    case "gyroscope":
      code = `import smbus\nPWR_MGMT_1 = 0x6B\nSMPLRT_DIV = 0x19\nCONFIG = 0x1A\nGYRO_CONFIG = 0x1B\nINT_ENABLE = 0x38\nACCEL_XOUT_H = 0x3B\nACCEL_YOUT_H = 0x3D\nACCEL_ZOUT_H = 0x3F\nGYRO_XOUT_H = 0x43\nGYRO_YOUT_H = 0x45\nGYRO_ZOUT_H = 0x47\n`+
      `def MPU_Init():\n\tbus.write_byte_data(Device_Address, SMPLRT_DIV, 7)\n\tbus.write_byte_data(Device_Address, PWR_MGMT_1, 1)\n\tbus.write_byte_data(Device_Address, CONFIG, 0)\n\tbus.write_byte_data(Device_Address, GYRO_CONFIG, 24)\n\tbus.write_byte_data(Device_Address, INT_ENABLE, 1)\n`+
      `def read_raw_data(addr):\n\thigh = bus.read_byte_data(Device_Address, addr)\n\tlow = bus.read_byte_data(Device_Address, addr + 1)\n\tvalue = (high << 8) | low\n\tif value > 32768:\n\t\tvalue = value - 65536\n\treturn value\nbus = smbus.SMBus(1)  # or bus=smbus.SMBus(0) for older version boards\nDevice_Address = 0x68 # MPU6050 device address\nMPU_Init()\n\n`;
      break;
    case "stepper_motor":
      code = `import RPi.GPIO as GPIO\nGPIO.setmode(GPIO.BCM)\nimport time\n\ndef calculate_delay(speed_percentage):\n\tmax_delay = 0.02  \n\tmin_delay = 0.0005 \n\tif speed_percentage < 0:\n\t\tspeed_percentage = 0\n\telif speed_percentage > 100:\n\t\tspeed_percentage = 100\n\treturn max_delay - (speed_percentage / 100) * (max_delay - min_delay)\n\ndef run_motor(speed_percentage, direction, STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\ttry:\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\texcept KeyboardInterrupt:\n\t\tpass\n\ndef move_steps(steps, speed_percentage, direction,STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\tfor _ in range(steps):\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\n`
      break;
  }
  return code;
};

Blockly.Python["picamera_state"] = function (block) {
  var dropdown_state = block.getFieldValue("state");
  // TODO: Assemble Python into code variable.

  var code = ``;
  if (dropdown_state === "on") {
    code = `camera.start_preview()\n`;
  } else {
    code = `camera.stop_preview()\n`;
  }
  return code;
};

Blockly.Python["take_picture"] = function (block) {
  var text_pathname = block.getFieldValue("pathname");
  // TODO: Assemble Python into code variable.
  return `camera.capture('${text_pathname}')\n`;
};

Blockly.Blocks['absolute_axes_values'] = {
  init: function () {
    this.jsonInit({
      "type": "absolute_axes_values",
      "message0": "Absolute Value of Axes",
      "output": null,
      "colour": 60,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['absolute_axes_values'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '(abs(x)>5) or (abs(y)>5) or (abs(z)<5)';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

// Blockly.Blocks["change_duty_cycle_for_motor1"] = {
//   init: function () {
//     this.jsonInit({
//       type: "change_duty_cycle_for_motor1",
//       message0: "ChangeDutyCycle for Motor 1 %1",
//       args0: [{
//         type: "field_number",
//         name: "MOTOR1",
//         value: 0,
//         min: 0,
//       },],
//       previousStatement: null,
//       nextStatement: null,
//       colour: 230,
//       tooltip: "change_duty_cycle_for_motor1",
//       helpUrl: "",
//     });
//   },
// };
// Blockly.Python["change_duty_cycle_for_motor1"] = function (block) {
//   var number_name = block.getFieldValue("MOTOR1");
//   // TODO: Assemble Python into code variable.
//   var code = "p1.ChangeDutyCycle(" + parseInt(number_name) + ")" + "\n";
//   return code;
// };
// Blockly.Blocks["change_duty_cycle_for_motor2"] = {
//   init: function () {
//     this.jsonInit({
//       type: "change_duty_cycle_for_motor2",
//       message0: "ChangeDutyCycle for Motor 2 %1",
//       args0: [{
//         type: "field_number",
//         name: "MOTOR2",
//         value: 0,
//         min: 0,
//       },],
//       previousStatement: null,
//       nextStatement: null,
//       colour: 230,
//       tooltip: "change_duty_cycle_for_motor2",
//       helpUrl: "",
//     });
//   },
// };
// Blockly.Python["change_duty_cycle_for_motor2"] = function (block) {
//   var number_name = block.getFieldValue("MOTOR2");
//   // TODO: Assemble Python into code variable.
//   var code = "p2.ChangeDutyCycle(" + parseInt(number_name) + ")" + "\n";
//   return code;
// };

Blockly.Blocks["get_axes"] = {
  init: function () {
    this.jsonInit({
      type: "get_axes",
      message0: "Get Axes",
      previousStatement: null,
      nextStatement: null,
      colour: 150,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_axes"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "bytes = bus.read_i2c_block_data(0x53, 0x32, 6)\nx = bytes[0] | (bytes[1] << 8)\nif(x & (1 << 16 - 1)):\n\tx = x - (1<<16)\ny = bytes[2] | (bytes[3] << 8)\nif(y & (1 << 16 - 1)):\n\ty = y - (1<<16)\nz = bytes[4] | (bytes[5] << 8)\nif(z & (1 << 16 - 1)):\n\tz = z - (1<<16)\nx = x * 0.004\ny = y * 0.004\nz = z * 0.004\nx = x * 9.80665\ny = y * 9.80665\nz = z * 9.80665\nx = int(x)\ny = int(y)\nz = int(z)" +
    "\n";
  return code;
};

Blockly.Blocks['get_location'] = {
  init: function () {
    this.jsonInit({
      "type": "get_location",
      "message0": "Get Location of Robot",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 180,
      "tooltip": "get location of robot",
      "helpUrl": ""
    });
  }
};

Blockly.Python['get_location'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "get_location()" + '\n';
  return code;
};

Blockly.Blocks['initialise_front_distance'] = {
  init: function () {
    this.jsonInit({
      "type": "initialise_front_distance",
      "message0": "FrontDistance",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 150,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['initialise_front_distance'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'FrontDistance = float(calculate_distance(FRONT_ECHO, FRONT_TRIG))' + '\n';
  return code;
};

Blockly.Blocks["message_loop"] = {
  init: function () {
    this.jsonInit({
      type: "message_loop",
      message0: "Bot Message Loop",
      previousStatement: null,
      nextStatement: null,
      colour: 180,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["message_loop"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "def handle(msg):\n\tchat_id = msg['chat']['id']\n\tprint(chat_id)\nbot.message_loop(handle)\n";
  return code;
};

Blockly.Blocks["print_variable"] = {
  init: function () {
    this.jsonInit({
      type: "print_variable",
      message0: "print %1",
      args0: [{
        type: "field_variable",
        name: "variable",
        variable: "item",
      },],
      previousStatement: null,
      nextStatement: null,
      colour: 15,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["print_variable"] = function (block) {
  var variable_variable = Blockly.Python.variableDB_.getName(
    block.getFieldValue("variable"),
    Blockly.Variables.NAME_TYPE
  );
  // TODO: Assemble Python into code variable.
  var code = "print (" + variable_variable + ")" + "\n";
  return code;
};

Blockly.Blocks["robot_sleep"] = {
  init: function () {
    this.jsonInit({
      type: "robot_sleep",
      message0: "Sleep Robot for ( %1 ) secs",
      args0: [{
        type: "field_number",
        name: "sec",
        value: 0,
        min: 0,
        max: 20,
      },],
      previousStatement: null,
      nextStatement: null,
      colour: 230,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["robot_sleep"] = function (block) {
  var number_sec = block.getFieldValue("sec");
  // TODO: Assemble Python into code variable.
  var code = "robot.sleep(" + number_sec + ")" + "\n";
  return code;
};

Blockly.Blocks["send_accident_location_message"] = {
  init: function () {
    this.jsonInit({
      type: "send_accident_location_message",
      message0: "Send Accident Location Message",
      previousStatement: null,
      nextStatement: null,
      colour: 195,
      tooltip: "send accident location message",
      helpUrl: "",
    });
  },
};

Blockly.Python["send_accident_location_message"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "bot.sendMessage(chat_id,'Accident at' + str((last_known_location)))" +
    "\n";
  return code;
};

Blockly.Blocks["send_last_known_location_message"] = {
  init: function () {
    this.jsonInit({
      type: "send_last_known_location_message",
      message0: "Send Last Known Location Message",
      previousStatement: null,
      nextStatement: null,
      colour: 195,
      tooltip: "send last known location message",
      helpUrl: "",
    });
  },
};

Blockly.Python["send_last_known_location_message"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "bot.sendMessage(414553391,last_known_location)" + "\n";
  return code;
};

Blockly.Blocks["add_event_function"] = {
  init: function () {
    this.jsonInit({
      type: "add_event_function",
      message0: "add_event_function %1 pin %2",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "pin",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "add_event_detect and add_event_callback",
      helpUrl: "",
    });
  },
};

Blockly.Python["add_event_function"] = function (block) {
  var value_name = Blockly.Python.valueToCode(
    block,
    "pin",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code =
    "GPIO.add_event_detect(" +
    value_name +
    ",GPIO.BOTH,bouncetime=300)\nGPIO.add_event_callback(" +
    value_name +
    ",callback)" +
    "\n";
  return code;
};

Blockly.Blocks['get_distance'] = {
  init: function () {
    this.jsonInit({
      "type": "get_distance",
      "message0": "Get Distance  with Echo Pin %1 Trigger Pin %2",
      "args0": [{
        "type": "input_value",
        "name": "echo_pin",
        "align": "RIGHT"
      },
      {
        "type": "input_value",
        "name": "trigger_pin",
        "align": "RIGHT"
      }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "get_distance",
      "helpUrl": ""
    });
  }
};

Blockly.Python['get_distance'] = function (block) {
  var value_echo_pin = Blockly.Python.valueToCode(block, 'echo_pin', Blockly.Python.ORDER_ATOMIC);
  var value_trigger_pin = Blockly.Python.valueToCode(block, 'trigger_pin', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'calculate_distance(' + value_echo_pin + ',' + value_trigger_pin + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};
Blockly.Blocks['tuple_create'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items

    this.setColour(65);
    this.appendDummyInput().appendField('create tuple with');

    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');

    this.setInputsInline(false);
    this.setOutput(true, 'Array');
    this.setTooltip('Create a tuple with the given elements');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['tuple_item']));
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(items);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('tuple_create_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('tuple_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },

  // Define the mutator property
  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(items);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('tuple_create_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('tuple_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },
};

Blockly.Python['tuple_create'] = function (block) {
  const itemValues = [];
  for (let i = 1; i <= block.itemCount_; i++) {
    const itemValue = Blockly.Python.valueToCode(block, 'ITEM' + i, Blockly.Python.ORDER_NONE) || 'None';
    itemValues.push(itemValue);
  }
  let code = '(' + itemValues.join(', ');
  if (itemValues.length === 1) {
    code += ',';
  }
  code += ')';
  return [code, Blockly.Python.ORDER_ATOMIC];
};

Blockly.Blocks['tuple_create_container'] = {
  init() {
    this.setColour(65);
    this.appendDummyInput().appendField('tuple items');
    this.appendStatementInput('STACK');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['tuple_item'] = {
  init() {
    this.setColour(65);
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'tuple_item');
    this.setNextStatement(true, 'tuple_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks["main_function"] = {
  init: function () {
    this.jsonInit({
      type: "main_function",
      message0: "main()",
      previousStatement: null,
      colour: '#935ba5',
      tooltip: 'Used to call the main function if __name__ == "__main__"',
      helpUrl: "",
    });
  },
};

Blockly.Python["main_function"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'if __name__ == "__main__":\n\tmain()' + "\n";
  return code;
};

Blockly.Blocks['calculate_beep_freq'] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_beep_freq",
      "message0": "Calculate Beep Frequency %1 %2 %3",
      "args0": [{
        "type": "input_dummy"
      },
      {
        "type": "field_label_serializable",
        "name": "distance",
        "text": "input distance"
      },
      {
        "type": "input_value",
        "name": "distance",
        "align": "RIGHT"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 20,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['calculate_beep_freq'] = function (block) {
  var value_distance = Blockly.Python.valueToCode(block, 'distance', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'def beep_freq():\n\tdist = ' + value_distance + '\n\tdevice["ultrasonic_sensor"] = int(dist)\n\tif dist > 50:\n\t\treturn -1\n\telif dist <= 50 and dist >=30:\n\t\treturn 1\n\telif dist < 30 and dist >= 20:\n\t\treturn 0.5\n\telif dist < 20 and dist >= 10:\n\t\treturn 0.25\n\telse:\n\t\treturn 0' + "\n";

  ""
  return code;
};

Blockly.Blocks["change_duty_cycle"] = {
  init: function () {
    this.jsonInit({
      type: "change_duty_cycle",
      message0: "ChangeDutyCycle %1",
      args0: [{
        type: "field_dropdown",
        name: "CDC",
        options: [
          ["0", "0"],
          ["0.5", "0.5"],
          ["1", "1"],
          ["1.5", "1.5"],
          ["2", "2"],
          ["2.5", "2.5"],
          ["3", "3"],
          ["3.5", "3.5"],
          ["4", "4"],
          ["4.5", "4.5"],
          ["5", "5"],
          ["5.5", "5.5"],
          ["6", "6"],
          ["6.5", "6.5"],
          ["7", "7"],
          ["7.5", "7.5"],
          ["8", "8"],
          ["8.5", "8.5"],
          ["9", "9"],
          ["9.5", "9.5"],
          ["10", "10"],
          ["10.5", "10.5"],
          ["11", "11"],
          ["11.5", "11.5"],
          ["12", "12"],
        ],
      },],
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["change_duty_cycle"] = function (block) {
  var dropdown_cdc = block.getFieldValue("CDC");
  // TODO: Assemble Python into code variable.
  var code = "p.ChangeDutyCycle(" + dropdown_cdc + ")" + "\n";
  return code;
};

Blockly.Blocks["change_new_duty_cycle"] = {
  init: function () {
    this.jsonInit({
      type: "change_new_duty_cycle",
      message0: "ChangeDutyCycle %1 variable name %2",
      args0: [{
        type: "field_dropdown",
        name: "CDC",
        options: [
          ["0", "0"],
          ["0.5", "0.5"],
          ["1", "1"],
          ["1.5", "1.5"],
          ["2", "2"],
          ["2.5", "2.5"],
          ["3", "3"],
          ["3.5", "3.5"],
          ["4", "4"],
          ["4.5", "4.5"],
          ["5", "5"],
          ["5.5", "5.5"],
          ["6", "6"],
          ["6.5", "6.5"],
          ["7", "7"],
          ["7.5", "7.5"],
          ["8", "8"],
          ["8.5", "8.5"],
          ["9", "9"],
          ["9.5", "9.5"],
          ["10", "10"],
          ["10.5", "10.5"],
          ["11", "11"],
          ["11.5", "11.5"],
          ["12", "12"],
        ],
      }, {
        type: "input_value",
        name: "pinVariable",
      }],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "Create New Servo Motor",
      helpUrl: "",
    });
  },
};

Blockly.Python["change_new_duty_cycle"] = function (block) {
  var dropdown_cdc = block.getFieldValue("CDC");
  var pinVariableName = Blockly.Python.valueToCode(block, "pinVariable", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `${pinVariableName}` +
    ".ChangeDutyCycle(" + dropdown_cdc + ")" + "\n";
  return code;
};


Blockly.Blocks["gpio_cleanup"] = {
  init: function () {
    this.jsonInit({
      type: "gpio_cleanup",
      message0: "GPIO Cleanup",
      previousStatement: null,
      nextStatement: null,
      colour: '#935ba5',
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["gpio_cleanup"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "GPIO.cleanup()" + "\n";
  return code;
};

Blockly.Blocks["initialise_function_to_a_variable"] = {
  init: function () {
    this.jsonInit({
      type: "initialise_function_to_a_variable",
      message0: "%1",
      args0: [{
        type: "field_input",
        name: "function_name",
        text: "Function Name",
      },],
      output: null,
      colour: '#935ba5',
      tooltip: "initialise function to a variable",
      helpUrl: "",
    });
  },
};

Blockly.Python["initialise_function_to_a_variable"] = function (block) {
  var text_function_name = block.getFieldValue("function_name");
  // TODO: Assemble Python into code variable.
  var code = text_function_name + "()";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["print_temperature_humidity"] = {
  init: function () {
    this.jsonInit({
      type: "print_temperature_humidity",
      message0: "Print Temperature and Humidity",
      previousStatement: null,
      nextStatement: null,
      colour: 300,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["print_temperature_humidity"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "device[\"mobile_messages\"].append({'type' : 'text','value' : 'Temp={0:0.1f}*C  Humidity={1:0.1f}%'.format(device[\"temperature\"], device[\"humidity\"]),'color' : '#FF6666'})" +
    "\n";
  return code;
};

Blockly.Blocks["print_wrong_password"] = {
  init: function () {
    this.jsonInit({
      type: "print_wrong_password",
      message0: "Print Wrong Password",
      previousStatement: null,
      nextStatement: null,
      colour: "#710193",
      tooltip: "print_wrong_password",
      helpUrl: "",
    });
  },
};

Blockly.Python["print_wrong_password"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "print('Wrong Password!  Please try again!   ('+str(5-int(wrong_count))+'Attempts left!) ')" +
    "\n";
  return code;
};

Blockly.Blocks["try_except_block"] = {
  init: function () {
    this.jsonInit({
      type: "try_except_block",
      message0: "try %1 %2 except %3 %4",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_statement",
        name: "try",
      },
      {
        type: "input_value",
        name: "exception",
      },
      {
        type: "input_statement",
        name: "except_block",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: '#935ba5',
      tooltip: "try except block",
      helpUrl: "",
    });
  },
};

Blockly.Python["try_except_block"] = function (block) {
  var statements_try = Blockly.Python.statementToCode(block, "try");
  var value_exception = Blockly.Python.valueToCode(
    block,
    "exception",
    Blockly.Python.ORDER_ATOMIC
  );
  var statements_except_block = Blockly.Python.statementToCode(
    block,
    "except_block"
  );
  // TODO: Assemble Python into code variable.
  var code =
    "try:\n" +
    statements_try +
    "\nexcept " +
    value_exception +
    ":\n" +
    statements_except_block +
    "\n";
  return code;
};

Blockly.Blocks["typecast_variable"] = {
  init: function () {
    this.jsonInit({
      type: "typecast_variable",
      message0: "Typecast into %1 %2",
      args0: [{
        type: "field_dropdown",
        name: "types",
        options: [
          ["int", "int"],
          ["float", "float"],
          ["str", "str"],
        ],
      },
      {
        type: "input_value",
        name: "NAME",
      },
      ],
      output: null,
      colour: "#935ba5",
      tooltip: "typecast a variable into desired type",
      helpUrl: "",
    });
  },
};

Blockly.Python["typecast_variable"] = function (block) {
  var dropdown_types = block.getFieldValue("types");
  var value_name = Blockly.Python.valueToCode(
    block,
    "NAME",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code = dropdown_types + "(" + value_name + ")";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["wrong_count"] = {
  init: function () {
    this.jsonInit({
      type: "wrong_count",
      message0: "increment wrong count",
      previousStatement: null,
      nextStatement: null,
      colour: '#710193',
      tooltip: "increment wrong count by 1",
      helpUrl: "",
    });
  },
};

Blockly.Python["wrong_count"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "wrong_count += 1" + "\n";
  return code;
};

Blockly.Blocks["exception_keyboard_interrupt"] = {
  init: function () {
    this.jsonInit({
      type: "exception_keyboard_interrupt",
      message0: "KeyboardInterrupt",
      output: null,
      colour: '#935ba5',
      tooltip: "KeyboardInterrupt Exception",
      helpUrl: "",
    });
  },
};

Blockly.Python["exception_keyboard_interrupt"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "KeyboardInterrupt";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['start_motor'] = {
  init: function () {
    this.jsonInit({
      "type": "start_motor",
      "message0": "Start Motor %1 with pin  %2",
      "args0": [{
        "type": "field_dropdown",
        "name": "motor_number",
        "options": [
          [
            "1",
            "1"
          ],
          [
            "2",
            "2"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "pin_number"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "345",
      "tooltip": "start motor",
      "helpUrl": ""
    });
  }
};

Blockly.Python['start_motor'] = function (block) {
  var dropdown_motor_number = block.getFieldValue('motor_number');
  var value_pin_number = Blockly.Python.valueToCode(block, 'pin_number', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "p" + dropdown_motor_number + "=GPIO.PWM(" + value_pin_number + ",1000)\np" + dropdown_motor_number + ".start(25)" + '\n';
  return code;
};


Blockly.Blocks['update_block'] = {
  init: function () {
    this.jsonInit({
      "type": "update_block",
      "message0": "Update %1 password %2 %3 Link %4",
      "args0": [{
        "type": "input_dummy",
      },
      {
        "type": "field_input",
        "name": "password",
        "text": "Enter the password",
      },
      {
        "type": "input_dummy",
      },
      {
        "type": "field_input",
        "name": "link",
        "text": "https://comms.grokstem.com/wp/wp-content/uploads/Grok-Downloads.zip"
      }
      ],
      "colour": "#935ba5",
      "tooltip": "start motor",
      "helpUrl": ""
    });
  }
};

Blockly.Python['update_block'] = function (block) {
  var text_link = block.getFieldValue("link");
  var text_password = block.getFieldValue("password");
  // TODO: Assemble Python into code variable.

  var code = `key = None\n` +
    `password = None\n` +
    `keypad_1 = None\n` +
    `import sys, os\n` +
    `HOME        = os.path.expanduser('~')\n` +
    `RPI_HOME    = HOME + '/RPI/'\n` +
    `GROK_HOME   = HOME + '/Desktop/Grok-Downloads/'\n` +
    `sys.path.insert(1, RPI_HOME)\n` +
    `from file_watcher import FileWatcher, device_sensor\n` +
    `from grok_library import check_with_simulator,check_with_simulator2, device, sim_device, pin, GrokLib\n` +
    `import threading\n` +
    `grokLib = GrokLib()\n` +
    `device['applicationIdentifier'] = str(os.path.splitext(os.path.basename(__file__))[0])\n` +
    `device['mobile_messages'] = list()\n` +
    `def simulate(list_of_sensors):\n` +
    `\tif list_of_sensors is not None:\n` +
    `\t\tglobal sim_device\n` +
    `\t\tsim_device = list_of_sensors\n` +
    `def startListener1():\n` +
    `\tFileWatcher(simulate, 'simulation.json', RPI_HOME, 'config_file')\n` +
    `thread1 = threading.Thread(target=startListener1, args=())\n` +
    `thread1.daemon=True\n` +
    `thread1.start()\n` +
    `import RPi.GPIO as GPIO\n` +
    `GPIO.setwarnings(False)\n` +
    `GPIO.setmode(GPIO.BCM)\n` +
    `import RPi.GPIO as GPIO\n` +
    `GPIO.setwarnings(False)\n` +
    `GPIO.setmode(GPIO.BCM)\n` +
    `import time\n` +
    `import os, sys, subprocess\n` +
    `def install(package):\n`+
    `\tif(type(package)==str):\n`+
    `\t\ttry:\n`+
    `\t\t\t__import__(package)\n`+
    `\t\texcept ImportError as err:\n`+
    `\t\t\ttry:\n`+
    `\t\t\t\tos.system(f'python3 -m pip install {package}')\n`+
    `\t\t\t\t__import__(package)\n`+
    `\t\t\t\tprint(f"\\n{package.upper()} Module has been installed and imported\\n")\n`+
    `\t\t\texcept ModuleNotFoundError as er:\n`+
    `\t\t\t\tprint(er.msg)\n`+
    `\t\telse:\n`+
    `\t\t\tprint("Module has been imported")\n`+
    `\telse:\n`+ 
    `\t\tprint("Please Enter a valid Package Name")\n`+
    `install('wget')\n` +
    `import wget\n` +
    `key = None\n` +
    `password = '${text_password}'\n` +
    `keypad_1 = ''\n` +
    `R1 = (16)\n` +
    `R2 = (17)\n` +
    `R3 = (18)\n` +
    `R4 = (20)\n` +
    `GPIO.setup(R1, GPIO.OUT)\n` +
    `GPIO.setup(R2, GPIO.OUT)\n` +
    `GPIO.setup(R3, GPIO.OUT)\n` +
    `GPIO.setup(R4, GPIO.OUT)\n` +
    `C1 = (21)\n` +
    `C2 = (24)\n` +
    `C3 = (25)\n` +
    `C4 = (27)\n` +
    `GPIO.setup(C1, GPIO.IN)\n` +
    `GPIO.setup(C2, GPIO.IN)\n` +
    `GPIO.setup(C3, GPIO.IN)\n` +
    `GPIO.setup(C4, GPIO.IN)\n` +
    `def readRow(line, characters):\n` +
    `\tglobal key\n` +
    `\tGPIO.output(line, GPIO.LOW)\n` +
    `\ttime.sleep(0.04)\n` +
    `\tif(GPIO.input(C1) == 0):\n` +
    `\t\tkey = characters[0]\n` +
    `\tif(GPIO.input(C2) == 0):\n` +
    `\t\tkey = characters[1]\n` +
    `\tif(GPIO.input(C3) == 0):\n` +
    `\t\tkey = characters[2]\n` +
    `\tif(GPIO.input(C4) == 0):\n` +
    `\t\tkey = characters[3]\n` +
    `\tGPIO.output(line, GPIO.HIGH)\n` +
    `def readKey():\n` +
    `\treadRow(R1, ["1","2","3","A"])\n` +
    `\treadRow(R2, ["4","5","6","B"])\n` +
    `\treadRow(R3, ["7","8","9","C"])\n` +
    `\treadRow(R4, ["*","0","#","D"])\n` +
    `while True:\n` +
    `\twhile key == None:\n` +
    `\t\tif 'keypad_1' in sim_device:\n` +
    `\t\t\tkeypad_1 = sim_device['keypad_1']\n` +
    `\t\t\tkey = ''\n` +
    `\t\t\tdel sim_device['keypad_1']\n` +
    `\t\telse:\n` +
    `\t\t\treadKey()\n` +
    `\tif key == 'C':\n` +
    `\t\tdevice["mobile_messages"].append({'type' : 'text','value' : 'Reset','color' : '#ffffff'})\n` +
    `\t\tdevice_sensor(device)\n` +
    `\t\tdevice["mobile_messages"] = []\n` +
    `\t\tkeypad_1 = ''\n` +
    `\t\ttime.sleep(0.2)\n` +
    `\telse:\n` +
    `\t\tkeypad_1 = str(keypad_1) + str(key)\n` +
    `\t\tprint('Key pressed')\n` +
    `\t\tdevice["mobile_messages"].append({'type' : 'text','value' : 'Key pressed','color' : '#ffffff'})\n` +
    `\t\tif len(keypad_1) >= len(password):\n` +
    `\t\t\tif keypad_1 == password:\n` +
    `\t\t\t\tif os.path.exists("/home/pi/Desktop/Grok-Downloads.zip"):\n` +
    `\t\t\t\t\tos.remove("/home/pi/Desktop/Grok-Downloads.zip")\n` +
    `\t\t\t\tZip_url='${text_link}'\n` +
    `\t\t\t\tZip_path = '/home/pi/Desktop'\n` +
    `\t\t\t\twget.download(Zip_url,out = Zip_path)\n` +
    `\t\t\t\tfrom zipfile import ZipFile\n` +
    `\t\t\t\twith ZipFile("/home/pi/Desktop/Grok-Downloads.zip", 'r') as zObject:\n` +
    `\t\t\t\t\tzObject.extractall(\n` +
    `\t\t\t\t\t\t\tpath="/home/pi/Desktop")\n` +
    `\t\t\t\tif os.path.exists("/home/pi/Desktop/Grok-Downloads.zip"):\n` +
    `\t\t\t\t\tos.remove("/home/pi/Desktop/Grok-Downloads.zip")\n` +
    `\t\t\t\tdevice["mobile_messages"].append({'type' : 'text','value' : 'Programs Updated','color' : '#99ff99'})\n` +
    `\t\t\t\tdevice_sensor(device)\n` +
    `\t\t\t\tdevice["mobile_messages"] = []\n` +
    `\t\t\telse:\n` +
    `\t\t\t\tprint('Wrong password')\n` +
    `\t\t\t\tdevice["mobile_messages"].append({'type' : 'text','value' : 'Wrong password','color' : '#ffcccc'})\n` +
    `\t\t\t\tdevice_sensor(device)\n` +
    `\t\t\t\tdevice["mobile_messages"] = []\n` +
    `\t\t\tkeypad_1 = ''\n` +
    `\t\telse:\n` +
    `\t\t\tdevice["mobile_messages"].append({'type' : 'text','value' : 'Incomplete password','color' : '#ffffff'})\n` +
    `\t\t\tdevice_sensor(device)\n` +
    `\t\t\tdevice["mobile_messages"] = []\n` +
    `\tkey = None\n` +
    `\ttime.sleep(0.4)\n`;
  return code;
};


Blockly.Blocks["change_duty_cycle_for_motor"] = {
  init: function () {
    this.jsonInit({
      type: "change_duty_cycle_for_motor",
      message0: "ChangeDutyCycle for Motor Number  %1 by %2",
      args0: [{
        type: "field_dropdown",
        name: "motor_no",
        options: [
          ["1", "1"],
          ["2", "2"],
          ["3", "3"],
        ],
      },
      {
        type: "field_number",
        name: "duty_cycle",
        value: 0,
        min: 25,
        max: 100,
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "change_duty_cycle_for_motor in between 25-100",
      helpUrl: "",
    });
  },
};

Blockly.Python["change_duty_cycle_for_motor"] = function (block) {
  var dropdown_motor_no = block.getFieldValue("motor_no");
  var number_duty_cycle = block.getFieldValue("duty_cycle");
  // TODO: Assemble Python into code variable.
  var code =
    "p" + dropdown_motor_no + ".ChangeDutyCycle(" + number_duty_cycle + ")\n";
  return code;
};

Blockly.Blocks["create_servo_motor"] = {
  init: function () {
    this.jsonInit({
      type: "create_servo_motor",
      message0: "Set Servo Motor as output at pin %1",
      args0: [{
        type: "input_value",
        name: "pin",
      }],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "Create Servo Motor",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_servo_motor"] = function (block) {
  var value_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    "servo = " + value_pin + " \nGPIO.setup(servo,GPIO.OUT) \np=GPIO.PWM(servo,50) \np.start(2.5)" +
    "\n";
  return code;
};

Blockly.Blocks["create_new_servo_motor"] = {
  init: function () {
    this.jsonInit({
      type: "create_new_servo_motor",
      message0: "Set Servo Motor as output at pin %1 variable name %2",
      args0: [{
        type: "input_value",
        name: "pin",
      },
      {
        type: "input_value",
        name: "pinVariable",
      }],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#5b80a5",
      tooltip: "Create New Servo Motor",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_new_servo_motor"] = function (block) {
  var value_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  var pinVariableName = Blockly.Python.valueToCode(block, "pinVariable", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.

  var code =
    `GPIO.setup(${value_pin},GPIO.OUT)\n` +
    `${pinVariableName}` +
    `=GPIO.PWM(${value_pin},50) \n` +
    `${pinVariableName}.start(2.5)\n`;
  return code;
};

Blockly.Blocks["setup_rtc_module"] = {
  init: function () {
    this.jsonInit({
      type: "setup_rtc_module",
      message0: "Setup RTC module",
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["setup_rtc_module"] = function (block) {
  var code = "\ninit_i2c_bus()\ntime.sleep(1)\ninit_rtc()\ntime.sleep(0.2)\n"
  return code;
};

Blockly.Blocks["calculate_current_time_function"] = {
  init: function () {
    this.jsonInit({
      type: "calculate_current_time_function",
      message0: "Calculate current time - function",
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["calculate_current_time_function"] = function (block) {
  var code = "\ndef calculate_current_time():\n    hour = str(rtc_read_time()[0])\n    minute = str(rtc_read_time()[1])\n    second = str(rtc_read_time()[2])\n    current_time = hour + ':' + minute\n    return current_time\n\n"
  return code;
};

Blockly.Blocks["get_current_time"] = {
  init: function () {
    this.jsonInit({
      type: "get_current_time",
      message0: "Get current time",
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_current_time"] = function (block) {
  var code = "calculate_current_time()\n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_pulseoximeter_data"] = {
  init: function () {
    this.jsonInit({
      type: "read_pulseoximeter_data",
      message0: "Read PulseOximeter Data",
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["read_pulseoximeter_data"] = function (block) {
  var code = "mx30.read_sensor()\nmx30.ir, mx30.red\n"
  return code;
};

Blockly.Blocks["get_heartbeats"] = {
  init: function () {
    this.jsonInit({
      type: "get_heartbeats",
      message0: "Get heartbeats",
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_heartbeats"] = function (block) {
  var code = "int(mx30.ir / 100)\n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_spo2"] = {
  init: function () {
    this.jsonInit({
      type: "get_spo2",
      message0: "Get SpO2",
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_spo2"] = function (block) {
  var code = "int(mx30.red / 100)\n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["check_hb_with_buffer"] = {
  init: function () {
    this.jsonInit({
      type: "check_hb_with_buffer",
      message0: "Check Heartbeats %1 with buffer value",
      "args0": [
        {
          "type": "input_value",
          "name": "Heartbeats"
        }
      ],
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["check_hb_with_buffer"] = function (block) {
  var value_name = Blockly.Python.valueToCode(block, 'Heartbeats', Blockly.Python.ORDER_ATOMIC);
  var code = value_name + " != int(mx30.buffer_ir[0] / 100)"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["check_spo2_with_buffer"] = {
  init: function () {
    this.jsonInit({
      type: "check_spo2_with_buffer",
      message0: "Check SpO2 %1 with buffer value",
      "args0": [
        {
          "type": "input_value",
          "name": "SpO2"
        }
      ],
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["check_spo2_with_buffer"] = function (block) {
  var value_name = Blockly.Python.valueToCode(block, 'SpO2', Blockly.Python.ORDER_ATOMIC);
  var code = value_name + " != int(mx30.buffer_red[0] / 100)"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["create_telepot"] = {
  init: function () {
    this.jsonInit({
      type: "create_telepot",
      message0: "Create Telepot",
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "Create telepot",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_telepot"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "bot = telepot.Bot('876422035:AAFrorXaD5ghOdx6YgXegWWb-11QyKE3ahA')" + "\n";
  return code;
};

Blockly.Blocks["create_smbus"] = {
  init: function () {
    this.jsonInit({
      type: "create_smbus",
      message0: "Create SMBUS",
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "Create smbus",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_smbus"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code =
    "bus = smbus.SMBus(1)\nbus.write_byte_data(0x53, 0x2C, 0x0B)\nvalue = bus.read_byte_data(0x53, 0x31)\nvalue &= ~0x0F;\nvalue |= 0x0B;\nvalue |= 0x08;\nbus.write_byte_data(0x53, 0x31, value)\nbus.write_byte_data(0x53, 0x2D, 0x08)" +
    "\n";
  return code;
};

Blockly.Blocks["create_robot_speed_pin"] = {
  init: function () {
    this.jsonInit({
      type: "create_robot_speed_pin",
      message0: "Speed Control %1 en1 %2 en2 %3",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "en1",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "en2",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "345",
      tooltip: "Initialize Robot pins",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_robot_speed_pin"] = function (block) {
  var value_en1 = Blockly.Python.valueToCode(
    block,
    "en1",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_en2 = Blockly.Python.valueToCode(
    block,
    "en2",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code =
    "en1 = " +
    value_en1 +
    "\nen2 = " +
    value_en2 +
    "\nGPIO.setup(en1,GPIO.OUT)\nGPIO.setup(en2,GPIO.OUT)" +
    "\n";
  return code;
};

Blockly.Blocks["create_robot_without_speed"] = {
  init: function () {
    this.jsonInit({
      type: "create_robot_without_speed",
      message0: "Create Robot with Pins %1 in1 %2 in2 %3 in3 %4 in4 %5",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "in1",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in2",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in3",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in4",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "345",
      tooltip: "Initialize Robot pins",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_robot_without_speed"] = function (block) {
  var value_in1 = Blockly.Python.valueToCode(
    block,
    "in1",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in2 = Blockly.Python.valueToCode(
    block,
    "in2",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in3 = Blockly.Python.valueToCode(
    block,
    "in3",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in4 = Blockly.Python.valueToCode(
    block,
    "in4",
    Blockly.Python.ORDER_ATOMIC
  );
  // TODO: Assemble Python into code variable.
  var code =
    "in1 = " +
    value_in1 +
    "\nin2 = " +
    value_in2 +
    "\nin3 = " +
    value_in3 +
    "\nin4 = " +
    value_in4 +
    "\nGPIO.setup(in1,GPIO.OUT)\nGPIO.setup(in2,GPIO.OUT)\nGPIO.setup(in3,GPIO.OUT)\nGPIO.setup(in4,GPIO.OUT)\nGPIO.output(in1,GPIO.LOW)\nGPIO.output(in2,GPIO.LOW)\nGPIO.output(in3,GPIO.LOW)\nGPIO.output(in4,GPIO.LOW)" +
    "\n";
  return code;
};

Blockly.Blocks['create_robot_functions'] = {
  init: function () {
    this.jsonInit({
      "type": "create_robot_functions",
      "message0": "Create Robot %1 with Power %2",
      "args0": [{
        "type": "field_dropdown",
        "name": "create robot functions",
        "options": [
          ["Forward", "go_forward"],
          ["Backward", "go_backward"],
          ["Point Left", "go_point_left"],
          ["Point Right", "go_point_right"],
          ["Swing Left", "go_swing_left"],
          ["Swing Right", "go_swing_right"],
          ["Gradual Left", "go_gradual_left"],
          ["Gradual Right", "go_gradual_right"]
        ]
      },
      {
        "type": "field_number",
        "name": "power",
        "value": 0,
        "min": 20,
        "max": 100
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "345",
      "tooltip": "create robot functions with power ranging in between 20-100",
      "helpUrl": ""
    });
  }
};

Blockly.Python['create_robot_functions'] = function (block) {
  var dropdown_create_robot_functions = block.getFieldValue('create robot functions');
  var number_power = block.getFieldValue('power');
  // TODO: Assemble Python into code variable.
  switch (dropdown_create_robot_functions) {
    case "go_forward":
      var code = "def go_forward():\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)";
      break;
    case "go_backward":
      var code = "def go_backward():\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)";
      break;
    case "go_point_left":
      var code = "def go_point_left():\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)";
      break;
    case "go_point_right":
      var code = "def go_point_right():\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)";
      break;
    case "go_swing_left":
      var code = "def go_swing_left():\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)";
      break;
    case "go_swing_right":
      var code = "def go_swing_right():\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.LOW)";
      break;
    case "go_gradual_left":
      var code = "def go_gradual_left():\n\tp1.ChangeDutyCycle(" + number_power / 2 + ")\n\tp2.ChangeDutyCycle(" + number_power + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)";
      break;
    case "go_gradual_right":
      var code = "def go_gradual_right():\n\tp1.ChangeDutyCycle(" + number_power + ")\n\tp2.ChangeDutyCycle(" + number_power / 2 + ")\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)";
      break;
  }
  var code = code + '\n';
  return code;
};

Blockly.Blocks['create_robot_functions_without_speed'] = {
  init: function () {
    this.jsonInit({
      "type": "create_robot_functions_without_speed",
      "message0": "Create Robot %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "create robot",
        "options": [
          ["Forward", "go_forward"],
          ["Backward", "go_backward"],
          ["Point Left", "go_point_left"],
          ["Point Right", "go_point_right"],
          ["Stop", "go_stop"]
        ]
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "345",
      "tooltip": "create robot",
      "helpUrl": ""
    });
  }
};

Blockly.Python['create_robot_functions_without_speed'] = function (block) {
  var dropdown_create_robot_functions = block.getFieldValue('create robot');
  // TODO: Assemble Python into code variable.
  switch (dropdown_create_robot_functions) {
    case "go_forward":
      var code = "def go_forward():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n";
      break;
    case "go_backward":
      var code = "def go_backward():\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n";
      break;
    case "go_point_left":
      var code = "def go_point_left():\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n";
      break;
    case "go_point_right":
      var code = "def go_point_right():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n";
      break;
    case "go_stop":
      var code = "def stop():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n";
      break;
  }
  var code = code + '\n';
  return code;
};

Blockly.Blocks["robot_functions_without_speed"] = {
  init: function () {
    this.jsonInit({
      type: "robot_functions_without_speed",
      message0: "Robot %1",
      args0: [{
        type: "field_dropdown",
        name: "robot functions",
        options: [
          ["Move Forward", "go_forward"],
          ["Move Backward", "go_backward"],
          ["Take point left turn", "go_point_left"],
          ["Take point right turn", "go_point_right"],
          ["Stop", "stop"],
        ],
      },],
      previousStatement: null,
      nextStatement: null,
      colour: "345",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["robot_functions_without_speed"] = function (block) {
  var dropdown_robot_functions = block.getFieldValue("robot functions");
  // TODO: Assemble Python into code variable.
  var code = dropdown_robot_functions + "()\n";
  return code;
};

Blockly.Blocks["robot_functions"] = {
  init: function () {
    this.jsonInit({
      type: "robot_functions",
      message0: "Robot %1",
      args0: [{
        type: "field_dropdown",
        name: "robot functions",
        options: [
          ["Move Forward", "go_forward"],
          ["Move Backward", "go_backward"],
          ["Take point left turn", "go_point_left"],
          ["Take point right turn", "go_point_right"],
          ["Take swing left turn", "go_swing_left"],
          ["Take swing right turn", "go_swing_right"],
          ["Take gradual left turn", "go_gradual_left"],
          ["Take gradual right turn", "go_gradual_right"],
          ["Stop", "stop"],
        ],
      },],
      previousStatement: null,
      nextStatement: null,
      colour: "345",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["robot_functions"] = function (block) {
  var dropdown_robot_functions = block.getFieldValue("robot functions");
  // TODO: Assemble Python into code variable.
  var code = dropdown_robot_functions + "()\n";
  return code;
};


Blockly.Blocks["create_mecanum"] = {
  init: function () {
    this.jsonInit({
      type: "create_mecanum",
      message0: "Create Mecanum with Pins %1 in1 %2 in2 %3 in3 %4 in4 %5 in5 %6 in6 %7 in7 %8 in8 %9 en1 %10",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "in1",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in2",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in3",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in4",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in5",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in6",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in7",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in8",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "en1",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "#84898b",
      tooltip: "Initialize Robot pins",
      helpUrl: "",
    });
  },
};

Blockly.Python["create_mecanum"] = function (block) {
  var value_in1 = Blockly.Python.valueToCode(
    block,
    "in1",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in2 = Blockly.Python.valueToCode(
    block,
    "in2",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in3 = Blockly.Python.valueToCode(
    block,
    "in3",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in4 = Blockly.Python.valueToCode(
    block,
    "in4",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in5 = Blockly.Python.valueToCode(
    block,
    "in5",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in6 = Blockly.Python.valueToCode(
    block,
    "in6",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in7 = Blockly.Python.valueToCode(
    block,
    "in7",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in8 = Blockly.Python.valueToCode(
    block,
    "in8",
    Blockly.Python.ORDER_ATOMIC
  );

  var value_en1 = Blockly.Python.valueToCode(
    block,
    "en1",
    Blockly.Python.ORDER_ATOMIC
  );

  // TODO: Assemble Python into code variable.
  var code =
    "in1 = " +
    value_in1 +
    "\nin2 = " +
    value_in2 +
    "\nin3 = " +
    value_in3 +
    "\nin4 = " +
    value_in4 +
    "\nin5 = " +
    value_in5 +
    "\nin6 = " +
    value_in6 +
    "\nin7 = " +
    value_in7 +
    "\nin8 = " +
    value_in8 +
    "\nen1 = " +
    value_en1 +
    "\nGPIO.setup(in1,GPIO.OUT)\nGPIO.setup(in2,GPIO.OUT)\nGPIO.setup(in3,GPIO.OUT)\nGPIO.setup(in4,GPIO.OUT)\nGPIO.setup(in5,GPIO.OUT)\nGPIO.setup(in6,GPIO.OUT)\nGPIO.setup(in7,GPIO.OUT)\nGPIO.setup(in8,GPIO.OUT)\nGPIO.output(in1,GPIO.LOW)\nGPIO.output(in2,GPIO.LOW)\nGPIO.output(in3,GPIO.LOW)\nGPIO.output(in4,GPIO.LOW)\nGPIO.output(in5,GPIO.LOW)\nGPIO.output(in6,GPIO.LOW)\nGPIO.output(in7,GPIO.LOW)\nGPIO.output(in8,GPIO.LOW)\nGPIO.setup(en1,GPIO.OUT)" +
    "\n";
  return code;
};


Blockly.Blocks["single_motor_controller"] = {
  init: function () {
    this.jsonInit({
      type: "single_motor_controller",
      message0: "Single Motor Controller with Pins %1 in1 %2 in2 %3",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "in1",
        check: "Number",
        align: "RIGHT",
      },
      {
        type: "input_value",
        name: "in2",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "345",
      tooltip: "Single Motor Controller",
      helpUrl: "",
    });
  },
};


Blockly.Python["single_motor_controller"] = function (block) {
  var value_in1 = Blockly.Python.valueToCode(
    block,
    "in1",
    Blockly.Python.ORDER_ATOMIC
  );
  var value_in2 = Blockly.Python.valueToCode(
    block,
    "in2",
    Blockly.Python.ORDER_ATOMIC
  );

  // TODO: Assemble Python into code variable.
  var code =
    "in1 = " +
    value_in1 +
    "\nin2 = " +
    value_in2 +
    "\nGPIO.setup(in1,GPIO.OUT)\nGPIO.setup(in2,GPIO.OUT)" +
    "\n";
  return code;
};


Blockly.Blocks["single_motor_pin_controller"] = {
  init: function () {
    this.jsonInit({
      type: "single_motor_pin_controller",
      message0: "Single Motor Pin Controller with Pins %1 en1 %2",
      args0: [{
        type: "input_dummy",
      },
      {
        type: "input_value",
        name: "en1",
        check: "Number",
        align: "RIGHT",
      },
      ],
      previousStatement: null,
      nextStatement: null,
      colour: 345,
      tooltip: "Single Motor Controller Pin",
      helpUrl: "",
    });
  },
};

Blockly.Python["single_motor_pin_controller"] = function (block) {

  var value_en1 = Blockly.Python.valueToCode(
    block,
    "en1",
    Blockly.Python.ORDER_ATOMIC
  );

  // TODO: Assemble Python into code variable.
  var code =
    "en1 = " +
    value_en1 +
    "\nGPIO.setup(en1,GPIO.OUT)" +
    "\n";
  return code;
};

Blockly.Blocks['mecanum_block'] = {
  init: function () {
    this.jsonInit({
      "type": "mecanum_block",
      "message0": "Mecanum Block",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#84898b",
      "tooltip": "mecanum_block",
      "helpUrl": ""
    });
  }
};

Blockly.Python['mecanum_block'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "def M1Forward():\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n" +
    "def M2Forward():\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n" +
    "def M3Forward():\n\tGPIO.output(in5,GPIO.HIGH)\n\tGPIO.output(in6,GPIO.LOW)\n" +
    "def M4Forward():\n\tGPIO.output(in7,GPIO.HIGH)\n\tGPIO.output(in8,GPIO.LOW)\n" +
    "def M1Backward():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n" +
    "def M2Backward():\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)\n" +
    "def M3Backward():\n\tGPIO.output(in5,GPIO.LOW)\n\tGPIO.output(in6,GPIO.HIGH)\n" +
    "def M4Backward():\n\tGPIO.output(in7,GPIO.LOW)\n\tGPIO.output(in8,GPIO.HIGH)\n" +
    "def M1Stop():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n" +
    "def M2Stop():\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.LOW)\n" +
    "def M3Stop():\n\tGPIO.output(in5,GPIO.LOW)\n\tGPIO.output(in6,GPIO.LOW)\n" +
    "def M4Stop():\n\tGPIO.output(in7,GPIO.LOW)\n\tGPIO.output(in8,GPIO.LOW)\n\n" +
    "def FW():\n\tM1Forward()\n\tM2Forward()\n\tM3Forward()\n\tM4Forward()\n" +
    "def BW():\n\tM1Backward()\n\tM2Backward()\n\tM3Backward()\n\tM4Backward()\n" +
    "def LT():\n\tM1Forward()\n\tM2Forward()\n\tM3Backward()\n\tM4Backward()\n" +
    "def RT():\n\tM1Backward()\n\tM2Backward()\n\tM3Forward()\n\tM4Forward()\n" +
    "def LM():\n\tM1Forward()\n\tM2Backward()\n\tM3Backward()\n\tM4Forward()\n" +
    "def RM():\n\tM1Backward()\n\tM2Forward()\n\tM3Forward()\n\tM4Forward()\n" +
    "def FL():\n\tM1Forward()\n\tM2Stop()\n\tM3Stop()\n\tM4Forward()\n" +
    "def FR():\n\tM1Stop()\n\tM2Forward()\n\tM3Forward()\n\tM4Stop()\n" +
    "def BL():\n\tM1Stop()\n\tM2Backward()\n\tM3Backward()\n\tM4Stop()\n" +
    "def BR():\n\tM1Backward()\n\tM2Stop()\n\tM3Stop()\n\tM4Backward()\n" +
    "def SP():\n\tM1Stop()\n\tM2Stop()\n\tM3Stop()\n\tM4Stop()\n";
  return code;
};

Blockly.Blocks["mecanum_direction"] = {
  init: function () {
    this.jsonInit({
      type: "mecanum_direction",
      message0: "Mecanum direction %1",
      args0: [{
        type: "field_dropdown",
        name: "Mecanum direction",
        options: [
          ["Move Forward", "FW"],
          ["Move Backward", "BW"],
          ["Take Left Turn", "LT"],
          ["Take Right Turn", "RT"],
          ["Take Left Movement", "LM"],
          ["Take Right Movement", "RM"],
          ["Take Front Left", "FL"],
          ["Take Front Right", "FR"],
          ["Take Back Left", "BL"],
          ["Take Back Right", "BR"],
          ["Stop", "SP"],
        ],
      },],
      previousStatement: null,
      nextStatement: null,
      colour: "#84898b",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["mecanum_direction"] = function (block) {
  var dropdown_mecanum_diection = block.getFieldValue("Mecanum direction");
  var code = dropdown_mecanum_diection + "()\n";
  return code;
};

Blockly.Blocks["servo_motor"] = {
  init: function () {
    this.jsonInit({
      type: "servo_motor",
      message0: "use %1",
      args0: [{
        type: "field_dropdown",
        name: "servo",
        options: [
          ["servo motor", "serv_motor"]
        ],
      },],
      previousStatement: null,
      nextStatement: null,
      colour: 225,
      tooltip: "use block for servo motor",
      helpUrl: "",
    });
  },
};

Blockly.Python["servo_motor"] = function (block) {
  var dropdown_servo = block.getFieldValue("servo");
  // TODO: Assemble Python into code variable.
  var code =
    "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)";
  return code;
};

Blockly.Blocks['calculate_distance'] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_distance",
      "message0": "Calculate Distance (with simulator)",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "Calculate distance with input from Echo and Trigger Pins",
      "helpUrl": ""
    });
  }
};


Blockly.Python['calculate_distance'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'def calculate_distance(echo_pin,trigger_pin):\n\tGPIO.output(trigger_pin, True)\n\ttime.sleep(0.00001)\n\tGPIO.output(trigger_pin, False)\n\tstart = time.time()\n\tstop = time.time()\n\n\twhile GPIO.input(echo_pin) == 0 and ("ultrasonic_sensor" not in sim_device or sim_device["ultrasonic_sensor"] == False):\n\t\tstart = time.time()\n\twhile GPIO.input(echo_pin) == 1 and ("ultrasonic_sensor" not in sim_device or sim_device["ultrasonic_sensor"] == False):\n\t\tstop = time.time()\n\twhile "ultrasonic_sensor" in sim_device and sim_device["ultrasonic_sensor"] == True:\n\t\treturn 5\n\tdevice["buzzer"]=False\n\tdevice_sensor(device)\n\tmeasuredTime = stop - start\n\tdistanceBothWays = measuredTime * 33112\n\tdistance = distanceBothWays / 2\n\tdevice["mobile_messages"].append({"type" : "text","value" : "Distance : {0:5.1f}cm".format(distance),"color" : "#33FF33"})\n\treturn distance' + "\n";
  return code;
};

Blockly.Blocks['calculate_distance_without_simulator'] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_distance_without_simulator",
      "message0": "Calculate Distance (Logic)",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['calculate_distance_without_simulator'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "def calculate_distance(echo_pin,trigger_pin):\n\tGPIO.output(trigger_pin, True)\n\ttime.sleep(0.00001)\n\tGPIO.output(trigger_pin, False)\n\n\twhile GPIO.input(echo_pin) == 0:\n\t\tstart = time.time()\n\twhile GPIO.input(echo_pin) == 1:\n\t\tstop = time.time()\n\tmeasuredTime = stop - start\n\tdistanceBothWays = measuredTime * 33112\n\tdistance = distanceBothWays / 2\n\tprint(\"Distance : {0:5.1f}cm\".format(distance))\n\treturn distance" + '\n';
  return code;
};

Blockly.Blocks['get_beep_frequency'] = {
  init: function () {
    this.jsonInit({
      "type": "get_beep_frequency",
      "message0": "Get Beep Frequency",
      "output": null,
      "colour": 210,
      "tooltip": "get_beep_frequency",
      "helpUrl": ""
    });
  }
};

Blockly.Python['get_beep_frequency'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'beep_freq()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['create_robot_stop'] = {
  init: function () {
    this.jsonInit({
      "type": "create_robot_stop",
      "message0": "Create Robot Stop",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "345",
      "tooltip": "create_robot_stop",
      "helpUrl": ""
    });
  }
};

Blockly.Python['create_robot_stop'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "def stop():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.LOW)\n";
  return code;
};

Blockly.Blocks['create_location'] = {
  init: function () {
    this.jsonInit({
      "type": "create_location",
      "message0": "Create Location of Robot",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 180,
      "tooltip": "create location of robot",
      "helpUrl": ""
    });
  }
};

Blockly.Python['create_location'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "def get_location():\n\tglobal last_known_location\n\tport='/dev/ttyAMA0'\n\tser=serial.Serial(port, baudrate=9600, timeout=0.5)\n\tdataout = pynmea2.NMEAStreamReader()\n\tnewdata=ser.readline()\n\tif newdata[0:6] == '$GPRMC':\n\t\tnewmsg=pynmea2.parse(newdata)\n\t\tlat=newmsg.latitude\n\t\tlng=newmsg.longitude\n\t\tgps = 'Latitude= + str(lat) + 'and Longitude= + str(lng)\n\t\tlast_known_location = gps\n\t\tprint(last_known_location)" + '\n';
  return code;
};

Blockly.Blocks['add_simulator'] = {
  init: function () {
    this.jsonInit({
      "type": "add_simulator",
      "message0": "Connect to mobile",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['add_simulator'] = function (block) {
  var statements_simulator = Blockly.Python.statementToCode(block, 'simulator');
  // TODO: Assemble Python into code variable.
  var starter_code = "import sys, os\nHOME        = os.path.expanduser('~')\nRPI_HOME    = HOME + '/RPI/'\nGROK_HOME   = HOME + '/Desktop/Grok-Downloads/'\nsys.path.insert(1, RPI_HOME)\nfrom file_watcher import FileWatcher, device_sensor\nfrom grok_library import check_with_simulator,check_with_simulator2, device, sim_device, pin, GrokLib\nimport threading\ngrokLib = GrokLib()\n\ndevice['applicationIdentifier'] = str(os.path.splitext(os.path.basename(__file__))[0])\ndevice['mobile_messages'] = list()\n\ndef simulate(list_of_sensors):\n    if list_of_sensors is not None:\n        global sim_device\n        sim_device = list_of_sensors\ndef startListener1():\n    FileWatcher(simulate, 'simulation.json', RPI_HOME, 'config_file')\nthread1 = threading.Thread(target=startListener1, args=())\nthread1.daemon=True\nthread1.start()\n";
  var code = starter_code + statements_simulator + "\n\n";
  return code;
};

Blockly.Blocks['add_simulator_camera'] = {
  init: function () {
    this.jsonInit({
      "type": "add_simulator_camera",
      "message0": "Use camera output",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "use camera",
      "helpUrl": ""
    });
  }
};

Blockly.Python['add_simulator_camera'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "def blankFunc(status):\n\tif status:\n\t\tprint('Image uploaded', status)\n\ndef startListener2():\n\tFileWatcher(blankFunc, 'image.jpg', GROK_HOME, 'camera_image')\nthread2 = threading.Thread(target=startListener2, args=())\nthread2.daemon=True\nthread2.start()" + '\n';
  return code;
};

Blockly.Blocks['connect_serial'] = {
  init: function () {
    this.jsonInit({
      "type": "connect_serial",
      "message0": "Connect serial %1 serial port: %2 %3 baudrate: %4",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_input",
          "name": "serial_port",
          "text": "/dev/ttyUSB0"
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_number",
          "name": "number",
          "value": 9600,
          "min": 0
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 165,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['connect_serial'] = function (block) {
  var text_serial_port = block.getFieldValue('serial_port');
  var number_number = block.getFieldValue('number');
  // TODO: Assemble JavaScript into code variable.
  var code = "ser = serial.Serial('" + text_serial_port + "', " + number_number + ")\n";
  return code;
};

Blockly.Blocks['read_wireless_sensor'] = {
  init: function () {
    this.jsonInit({
      "type": "read_wireless_sensor",
      "message0": "Read Wireless Sensor Function %1 Number of Values: %2 %3 Else Return: %4",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_number",
          "name": "number_of_values",
          "value": 2,
          "min": 0
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_input",
          "name": "return_value",
          "text": "0 0"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 165,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['read_wireless_sensor'] = function (block) {
  var number_of_values = block.getFieldValue('number_of_values');
  var return_value = block.getFieldValue('return_value');
  // TODO: Assemble JavaScript into code variable.
  var code = 'def readWirelessSensor():\n\ttry:\n\t\treadedText = ser.readline().decode("utf-8").rstrip()\t\t\n\t\ttime.sleep(0.1)\n\t\tdata = readedText.split(" ")\n\t\tser.flush()\n\t\tif len(data) == ' + number_of_values + ' and type(data) != type(None):\n\t\t\treturn data\n\texcept:\n\t\tpass\n\treturn "' + return_value + '"\n';
  return code;
};

Blockly.Blocks["call_read_wireless_sensor"] = {
  init: function () {
    this.jsonInit({
      type: "call_read_wireless_sensor",
      message0: "Read Serial Value",
      output: null,
      colour: 165,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["call_read_wireless_sensor"] = function (block) {
  var code = 'readWirelessSensor()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["check_serial_data"] = {
  init: function () {
    this.jsonInit({
      type: "check_serial_data",
      message0: "Serial Data Available",
      output: null,
      colour: 165,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["check_serial_data"] = function (block) {
  var code = 'ser.in_waiting > 0';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_serial"] = {
  init: function () {
    this.jsonInit({
      type: "read_serial",
      message0: "Read serial Data and save it to %1",
      args0: [
        {
          type: "input_value",
          name: "NAME"
        }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: 165,
      tooltip: "",
      helpUrl: ""
    });
  },
};
Blockly.Python['read_serial'] = function (block) {
  var value_name = Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "if ser.in_waiting > 0:\n\t" + value_name + " = ser.readline().decode('utf-8').rstrip()\n";
  return code;
};

Blockly.Blocks["write_serial"] = {
  init: function () {
    this.jsonInit({
      type: "write_serial",
      message0: "Write serial data with value %1 ",
      previousStatement: null,
      "args0": [
        {
          "type": "input_value",
          "name": "write_serial_value"
        }
      ],
      nextStatement: null,
      colour: 165,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["write_serial"] = function (block) {
  var write_serial_value_temp = Blockly.Python.valueToCode(block, 'write_serial_value', Blockly.Python.ORDER_ATOMIC);
  var serial_value = write_serial_value_temp.replace(/\'/g, "")
  var code = "ser.write(b\"" + serial_value + "\\n" + "\")" + "\n";
  return code;
};

// Blockly.Blocks['create_climate_sensor'] = {
//   init: function () {
//     this.jsonInit({
//       "type": "create_climate_sensor",
//       "message0": "Create Climate Sensor",
//       "previousStatement": null,
//       "nextStatement": null,
//       "colour": 165,
//       "tooltip": "create climate sensor",
//       "helpUrl": ""
//     });
//   }
// };

// Blockly.Python['create_climate_sensor'] = function (block) {
//   // TODO: Assemble Python into code variable.
//   var code = "sensor=Adafruit_DHT.DHT11\ndevice[\"humidity\"], device[\"temperature\"] = Adafruit_DHT.read_retry(sensor,4)" + '\n';
//   return code;
// };

Blockly.Blocks['create_tempandhumi_sensor'] = {
  init: function () {
    this.jsonInit({
      "type": "create_tempandhumi_sensor",
      "message0": "Create Temperature and Humidity Sensor with %1 pin",
      "args0": [
        {
          "type": "input_value",
          "name": "pin"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 165,
      "tooltip": "create climate sensor",
      "helpUrl": ""
    });
  }
};

Blockly.Python['create_tempandhumi_sensor'] = function (block) {
  // TODO: Assemble Python into code variable.
  // var value_pin_number = 4;
  var value_pin_number = Blockly.Python.valueToCode(block, 'pin', Blockly.Python.ORDER_ATOMIC);
  var code = "sensor=Adafruit_DHT.DHT11\n\humidity\,\ temperature\ = Adafruit_DHT.read_retry(sensor," + value_pin_number + ")" + '\n'; var code = "sensor=Adafruit_DHT.DHT11\n\humidity\,\ temperature\ = Adafruit_DHT.read_retry(sensor," + value_pin_number + ")" + '\n';

  return code;
};


Blockly.Blocks["read_climate_data"] = {
  init: function () {
    this.jsonInit({
      type: "read_climate_data",
      message0: "Read Climate Data with %1 pin",
      previousStatement: null,
      "args0": [
        {
          "type": "input_value",
          "name": "pin"
        }
      ],
      nextStatement: null,
      colour: 230,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["read_climate_data"] = function (block) {
  var value_pin_number = Blockly.Python.valueToCode(block, 'pin', Blockly.Python.ORDER_ATOMIC);
  // var code = "sensor=Adafruit_DHT.DHT11\n\humidity\,\ temperature\ = Adafruit_DHT.read_retry(sensor," + value_pin_number + ")" + '\n';
  var code = "humidity\,\ temperature\ = Adafruit_DHT.read_retry(Adafruit_DHT.DHT11," + value_pin_number + ")" + '\n';
  return code;
};


Blockly.Blocks["get_temperature"] = {
  init: function () {
    this.jsonInit({
      type: "get_temperature",
      message0: "Get temperature",
      output: null,
      colour: 230,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_temperature"] = function (block) {
  var code = "\ temperature\ \n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_humidity"] = {
  init: function () {
    this.jsonInit({
      type: "get_humidity",
      message0: "Get humidity",
      output: null,
      colour: 230,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_humidity"] = function (block) {
  var code = "\ humidity\ \n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["temperature_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "temperature_sensor";
    data['message0'] = "Set Climate sensor as input at pin %1";
    data['colour'] = "230";
    this.jsonInit(data);
  },
};
Blockly.Python["temperature_sensor"] = PYTHON_GPIO_INPUT;

Blockly.Blocks['create_simulator'] = {
  init: function () {
    this.jsonInit({
      "type": "create_simulator",
      "message0": "Connect %1 Sensor",
      "args0": [{
        "type": "field_dropdown",
        "name": "simulator",
        "options": [
          [
            "IR",
            "ir_sensor"
          ],
          [
            "Echo",
            "echo_sensor"
          ],
          [
            "Motion",
            "motion_sensor"
          ],
          [
            "Moisture",
            "moisture_sensor"
          ],
          [
            "Temperature Sensor",
            "temperature_sensor"
          ],
          [
            "Temperature",
            "temperature"
          ],
          [
            "Temperature Limit",
            "temperature_limit"
          ],
          [
            "Humidity",
            "humidity"
          ],
          [
            "Flame Sensor",
            "flame_sensor"
          ]
        ]
      }],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "Create Simulator function for each sensors",
      "helpUrl": ""
    });
  }
};

Blockly.Blocks["read_tds"] = {
  init: function () {
    this.jsonInit({
      type: "read_tds",
      message0: "Read TDS value with Analog Input Channel %1",
      args0: [{
        type: "field_dropdown",
        name: "tds_analog_channel",
        options: [
          ["1", "1"],
          ["2", "2"],
          ["3", "3"],
          ["4", "4"]
        ],
      }],
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["read_tds"] = function (block) {
  var value_pin_number = block.getFieldValue("tds_analog_channel");
  var code = "while True:\n\ttds = tdsValue(" + value_pin_number + ")\n\tprint(tds)\n\ttime.sleep(1)\n"
  // var code = "Value = read_adc(" + value_pin_number + ")\nif Value != 0:\n\tVoltage = Value*5/1024\n\tRead_tds = int((133.42/Voltage*Voltage*Voltage - 255.86*Voltage*Voltage + 857.39*Voltage)*0.5)\n"
  return [code, Blockly.Python.ORDER_NONE];
};

// Blockly.Blocks["read_ph"] = {
//   init: function () {
//     this.jsonInit({
//       type: "read_ph",
//       message0: "Read PH Data with Analog Input Channel %1",
//       args0: [{
//         type: "field_dropdown",
//         name: "ph_analog_channel",
//         options: [
//           ["1", "1"],
//           ["2", "2"],
//           ["3", "3"],
//           ["4", "4"]
//         ],
//       }],
//       output: null,
//       colour: 210,
//       tooltip: "",
//       helpUrl: "",
//     });
//   },
// };

// Blockly.Python["read_ph"] = function (block) {
//   var value_pin_number = block.getFieldValue("ph_analog_channel");
//   var code = "while True:\n\tph = phValue(" + value_pin_number + ")\n\tprint(ph)\n\ttime.sleep(1)\n"
//   // var code = "Value = read_adc(" + value_pin_number + ")\nif Value != 0:\n\tVoltage = Value*9/1024\n\tph = int(3.91007- Voltage)/0.18)\n\tprint('ph is', ph)\ntime.sleep(1)\n"
//   return [code, Blockly.Python.ORDER_NONE];
// };

Blockly.Python['create_simulator'] = function (block) {
  var dropdown_simulator = block.getFieldValue('simulator');
  // TODO: Assemble Python into code variable.
  var code = "if list_of_sensors is not None and \'" + dropdown_simulator + "\' in list_of_sensors:\n\tif list_of_sensors[\'" + dropdown_simulator + "\']:\n\t\tsim_device[\'" + dropdown_simulator + "\'] = True\n\telse:\n\t\tsim_device[\'" + dropdown_simulator + "\'] = False" + '\n';
  return code;
};
Blockly.Blocks['create_temperature_limit'] = {
  init: function () {
    this.jsonInit({
      "type": "create_temperature_limit",
      "message0": "Create Temperature Limit:  %1",
      "args0": [{
        "type": "field_number",
        "name": "temp_limit",
        "value": 35,
        "min": 0,
        "max": 100
      }],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 180,
      "tooltip": "Create temperature limit",
      "helpUrl": ""
    });
  }
};


Blockly.Python['create_temperature_limit'] = function (block) {
  var number_temp_limit = block.getFieldValue('temp_limit');
  // TODO: Assemble Python into code variable.
  var code = "device[\"temperature_limit\"] = " + number_temp_limit + "\nif int(device[\"temperature\"]) > device[\"temperature_limit\"]:\n\tdevice[\"temperature_sensor\"] = 1\nelse:\n\tdevice[\"temperature_sensor\"] = 0" + '\n';
  return code;
};

Blockly.Blocks['device_activate'] = {
  init: function () {
    this.jsonInit({
      "type": "send_msgs_mobile",
      "message0": "Send all messages to Mobile",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "Start the device",
      "helpUrl": ""
    });
  }
};

Blockly.Python['device_activate'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `
device_sensor(device)
device["mobile_messages"] = []
`;
  return code;
};

Blockly.Blocks['device_status'] = {
  init: function () {
    this.jsonInit({
      "type": "device_status",
      "message0": "Device Status: %1 %2",
      "args0": [{
        "type": "field_dropdown",
        "name": "device_value",
        "options": [
          [
            "IR",
            "ir_sensor"
          ],
          [
            "Echo",
            "echo_sensor"
          ],
          [
            "Motion",
            "motion_sensor"
          ],
          [
            "Moisture",
            "moisture_sensor"
          ],
          [
            "Temperature Sensor",
            "temperature_sensor"
          ],
          [
            "Temperature",
            "temperature"
          ],
          [
            "Temperature Limit",
            "temperature_limit"
          ],
          [
            "Humidity",
            "humidity"
          ],
          [
            "Camera",
            "usb_camera"
          ],
          [
            "Fan",
            "fan"
          ],
          [
            "LED",
            "led"
          ],
          [
            "Temperature",
            "temperature"
          ],
          [
            "Buzzer",
            "buzzer"
          ],
          [
            "Door",
            "door"
          ],
          [
            "Solenoid Valve",
            "solenoid_valve"
          ],
          [
            "Sleep",
            "sleep"
          ],
          [
            "Flame Sensor",
            "flame_sensor"
          ]
        ]
      },
      {
        "type": "input_value",
        "name": "device_name"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "Change the status of device",
      "helpUrl": ""
    });
  }
};

Blockly.Python['device_status'] = function (block) {
  var dropdown_device_value = block.getFieldValue('device_value');
  var value_device_name = Blockly.Python.valueToCode(block, 'device_name', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "device[\'" + dropdown_device_value + "\']=" + value_device_name + '\n';
  return code;
};

Blockly.Blocks['check_simulator'] = {
  init: function () {
    this.jsonInit({
      "type": "check_simulator",
      "message0": "Simulator check: %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "NAME",
          "options": [
            [
              "IR",
              "ir_sensor"
            ],
            [
              "Echo",
              "echo_sensor"
            ],
            [
              "Motion",
              "motion_sensor"
            ],
            [
              "Moisture",
              "moisture_sensor"
            ],
            [
              "Temperature Sensor",
              "temperature_sensor"
            ],
            [
              "Temperature",
              "temperature"
            ],
            [
              "Temperature Limit",
              "temperature_limit"
            ],
            [
              "Humidity",
              "humidity"
            ],
            [
              "Camera",
              "camera"
            ],
            [
              "Fan",
              "fan"
            ],
            [
              "LED",
              "led"
            ],
            [
              "Buzzer",
              "buzzer"
            ],
            [
              "Door",
              "door"
            ],
            [
              "Solenoid Valve",
              "solenoid_valve"
            ],
            [
              "Sleep",
              "sleep"
            ],
            [
              "Flame Sensor",
              "flame_sensor"
            ]
          ]
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "check_with_simulator function",
      "helpUrl": ""
    });
  }
};

Blockly.Python['check_simulator'] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  // TODO: Assemble Python into code variable.
  var code = "check_with_simulator(\'" + dropdown_name + "\')" + '\n';
  return code;
};

Blockly.Blocks['check_simulator2'] = {
  init: function () {
    this.jsonInit({
      "type": "check_simulator2",
      "message0": "Simulator check: %1 for inverted values",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "NAME",
          "options": [
            [
              "IR",
              "ir_sensor"
            ],
            [
              "Echo",
              "echo_sensor"
            ],
            [
              "Motion",
              "motion_sensor"
            ],
            [
              "Moisture",
              "moisture_sensor"
            ],
            [
              "Temperature Sensor",
              "temperature_sensor"
            ],
            [
              "Temperature",
              "temperature"
            ],
            [
              "Temperature Limit",
              "temperature_limit"
            ],
            [
              "Humidity",
              "humidity"
            ],
            [
              "Camera",
              "camera"
            ],
            [
              "Fan",
              "fan"
            ],
            [
              "LED",
              "led"
            ],
            [
              "Buzzer",
              "buzzer"
            ],
            [
              "Door",
              "door"
            ],
            [
              "Solenoid Valve",
              "solenoid_valve"
            ],
            [
              "Sleep",
              "sleep"
            ],
            [
              "Flame Sensor",
              "flame_sensor"
            ]
          ]
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "check_with_simulator function",
      "helpUrl": ""
    });
  }
};

Blockly.Python['check_simulator2'] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  // TODO: Assemble Python into code variable.
  var code = "check_with_simulator(\'" + dropdown_name + "\', True)" + '\n';
  return code;
};

Blockly.Blocks['device_status_input'] = {
  init: function () {
    this.jsonInit({
      "type": "device_status_input",
      "message0": "Device Sensor Input:  %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "NAME",
          "options": [
            [
              "IR",
              "ir_sensor"
            ],
            [
              "Echo",
              "echo_sensor"
            ],
            [
              "Motion",
              "motion_sensor"
            ],
            [
              "Moisture",
              "moisture_sensor"
            ],
            [
              "Temperature Sensor",
              "temperature_sensor"
            ],
            [
              "Temperature",
              "temperature"
            ],
            [
              "Temperature Limit",
              "temperature_limit"
            ],
            [
              "Humidity",
              "humidity"
            ],
            [
              "Camera",
              "camera"
            ],
            [
              "Fan",
              "fan"
            ],
            [
              "LED",
              "led"
            ],
            [
              "Buzzer",
              "buzzer"
            ],
            [
              "Door",
              "door"
            ],
            [
              "Solenoid Valve",
              "solenoid_valve"
            ],
            [
              "Sleep",
              "sleep"
            ],
            [
              "Flame Sensor",
              "flame_sensor"
            ]
          ]
        }
      ],
      "output": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['device_status_input'] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  // TODO: Assemble Python into code variable.
  var code = "device[\'" + dropdown_name + "\']";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['mobile_message'] = {
  init: function () {
    this.jsonInit({
      "type": "mobile_message",
      "message0": "print text to mobile: %1 with color %2",
      "args0": [
        {
          "type": "field_input",
          "name": "mobile_msg",
          "text": "abc"
        },
        {
          "type": "field_colour",
          "name": "colour",
          "colour": "#ff0000"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['mobile_message'] = function (block) {
  var text_mobile_msg = block.getFieldValue('mobile_msg');
  var colour_colour = block.getFieldValue('colour');
  // TODO: Assemble Python into code variable.
  var code = "device[\"mobile_messages\"].append({'type' : 'text','value' : '" + text_mobile_msg + "','color' : '" + colour_colour + "'})" + '\n';
  return code;
};

Blockly.Blocks['mobile_message_variable'] = {
  init: function () {
    this.jsonInit({
      type: "mobile_message_variable",
      message0: "print text to mobile: %1 with color %2",
      args0: [
        { type: "input_value", name: "mobile_msg", text: "abc" },
        { type: "field_colour", name: "colour", colour: "#ff0000" }
      ],
      previousStatement: null,
      nextStatement: null,
      colour: "#FF4848",
      tooltip: "",
      helpUrl: ""
    });
  }
};
Blockly.Python['mobile_message_variable'] = function (block) {
  var text_mobile_msg = Blockly.Python.valueToCode(block, 'mobile_msg', Blockly.Python.ORDER_ATOMIC);
  var colour_colour = block.getFieldValue('colour');
  // TODO: Assemble Python into code variable.
  var code = "device[\"mobile_messages\"].append({'type' : 'text', 'value' : " + text_mobile_msg + ", 'color' : '" + colour_colour + "'})" + '\n';
  return code;
};


Blockly.Blocks['update_robot_location'] = {
  init: function () {
    this.jsonInit({
      type: "update_robot_location",
      message0: "Update robot location using X: %1 Y: %2 Z: %3 Degree: %4",
      args0: [
        { type: "input_value", align: "RIGHT", name: "x" },
        { type: "input_value", align: "RIGHT", name: "y" },
        { type: "input_value", align: "RIGHT", name: "z" },
        { type: "input_value", align: "RIGHT", name: "degree" }
      ],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#FF4848",
      tooltip: "",
      helpUrl: ""
    });
  }
};
Blockly.Python['update_robot_location'] = function (block) {
  var value_x = Blockly.Python.valueToCode(block, 'x', Blockly.Python.ORDER_ATOMIC);
  var value_y = Blockly.Python.valueToCode(block, 'y', Blockly.Python.ORDER_ATOMIC);
  var value_z = Blockly.Python.valueToCode(block, 'z', Blockly.Python.ORDER_ATOMIC);
  var value_degree = Blockly.Python.valueToCode(block, 'degree', Blockly.Python.ORDER_ATOMIC);
  var code = 'device[\"robot_location\"] = { "x": ' + value_x + ', "y": ' + value_y + ', "z": ' + value_z + ', "degree": ' + value_degree + '}\n';
  return code;
};


Blockly.Blocks['mobile_image_message'] = {
  init: function () {
    this.jsonInit({
      "type": "mobile_message",
      "message0": "print image to mobile at path: %1",
      "args0": [
        {
          "type": "field_input",
          "name": "file_path",
          "text": "/home/pi/Desktop/Grok-Downloads/image.jpg"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['mobile_image_message'] = function (block) {
  var file_path = block.getFieldValue('file_path');
  // TODO: Assemble Python into code variable.
  var code = "image_url = grokLib.upload_image('" + file_path + "')\ndevice['mobile_messages'].append({'type' : 'image','source' : image_url,'state' : True})\n";
  return code;
};

Blockly.Blocks['time_delay'] = {
  init: function () {
    this.jsonInit({
      "type": "time_delay",
      "message0": "Variable pause: %1",
      "args0": [
        {
          "type": "input_value",
          "name": "NAME"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['time_delay'] = function (block) {
  var value_name = Blockly.Python.valueToCode(block, 'NAME', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "time.sleep(" + value_name + ")" + '\n';
  return code;
};

Blockly.Blocks['import_libraries'] = {
  init: function () {
    this.jsonInit({
      "type": "import_libraries",
      "message0": "Import Libraries",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['import_libraries'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "import sys, os\nHOME        = os.path.expanduser(\"~\")\nRPI_HOME    = HOME + \"/RPI/\"\nGROK_HOME   = HOME + \"/Desktop/Grok-Downloads/\"\nsys.path.insert(1, RPI_HOME)\nfrom file_watcher import FileWatcher, device_sensor\nfrom grok_library import check_with_simulator, device, sim_device, pin\nimport threading\n\ndevice[\"applicationIdentifier\"] = str(os.path.splitext(os.path.basename(__file__))[0])\n" + '\n';
  return code;
};

Blockly.Blocks['get_rows'] = {
  init: function () {
    this.jsonInit({
      "type": "get_rows",
      "message0": "Get first %1 rows of data table: %2",
      "args0": [
        {
          "type": "field_number",
          "name": "head",
          "value": 6,
          "min": 1
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_rows'] = function (block) {
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var number_head = block.getFieldValue('head');
  // TODO: Assemble Python into code variable.
  var code = value_df + '.head(' + number_head + ')';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['total_rows'] = {
  init: function () {
    this.jsonInit({
      "type": "total_rows",
      "message0": "Get total number of rows from data table: %1",
      "args0": [
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['total_rows'] = function (block) {
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = 'len(' + value_df + '.index)';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['datatable_get_columns'] = {
  init: function () {
    this.jsonInit({
      "type": "datatable_get_columns",
      "message0": "Get list of columns for data table: %1",
      "args0": [
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['datatable_get_columns'] = function (block) {
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = 'list(' + value_df + '.columns)';
  return [code, Blockly.Python.ORDER_NONE];
};

// Need to remove this block
Blockly.Blocks['list_total_rows'] = {
  init: function () {
    this.jsonInit({
      "type": "list_total_rows",
      "message0": "Get list of total number of rows from data table",
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['list_total_rows'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'list(range(1,len(data.index)+1))';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['list_column_data_last_row'] = {
  init: function () {
    this.jsonInit({
      "type": "list_column_data_last_row",
      "message0": "Get list of all data from column:  %1 within last:  %2 rows",
      "args0": [
        {
          "type": "field_number",
          "name": "column_number",
          "value": 0,
          "min": 1,
          "max": 20
        },
        {
          "type": "field_number",
          "name": "row_number",
          "value": 0,
          "min": 1,
          "max": 20
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['list_column_data_last_row'] = function (block) {
  var number_column_number = block.getFieldValue('column_number');
  var number_row_number = block.getFieldValue('row_number');
  // TODO: Assemble Python into code variable.
  var code = 'list(pd.DataFrame(data.tail(' + number_row_number + ')).iloc[:, ' + number_column_number + '])';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};



Blockly.Blocks['list_range'] = {
  init: function () {
    this.jsonInit({
      "type": "list_range",
      "message0": "Get list of values ranging from %1 to %2 with step: %3",
      "args0": [
        {
          "type": "input_value",
          "name": "start",
          "check": "Number"
        },
        {
          "type": "input_value",
          "name": "stop",
          "check": "Number"
        },
        {
          "type": "field_number",
          "name": "step",
          "value": 1
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "Get list of values ranging from start to stop with step. Provided the step must not be 0.",
      "helpUrl": ""
    });
  }
};
Blockly.Python['list_range'] = function (block) {
  var value_start = Blockly.Python.valueToCode(block, 'start', Blockly.Python.ORDER_ATOMIC);
  var value_stop = Blockly.Python.valueToCode(block, 'stop', Blockly.Python.ORDER_ATOMIC);
  var number_step = block.getFieldValue('step');
  // TODO: Assemble Python into code variable.
  var code = 'list(range(' + value_start + ',' + value_stop + '+1, ' + number_step + '))';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['coefficient_of_x_y'] = {
  init: function () {
    this.jsonInit({
      "type": "coefficient_of_x_y",
      "message0": "Calculate Coefficient of  X %1 Y %2",
      "args0": [
        {
          "type": "input_value",
          "name": "x"
        },
        {
          "type": "input_value",
          "name": "y",
          "align": "RIGHT"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 165,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['coefficient_of_x_y'] = function (block) {
  var value_x = Blockly.Python.valueToCode(block, 'x', Blockly.Python.ORDER_ATOMIC);
  var value_y = Blockly.Python.valueToCode(block, 'y', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "coefficients = np.polyfit(" + value_x + ", " + value_y + ", 1)\npolynomial = np.poly1d(coefficients)\nx = np.linspace(x[0],x[-1])\ny = polynomial(x)" + '\n';
  return code;
};

Blockly.Blocks['graph_marker'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_marker",
      "message0": "Graph Marker: %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "marker",
          "options": [
            [
              "Solid line",
              "–"
            ],
            [
              "Dashed line",
              "—"
            ],
            [
              "dash-dot line",
              "-."
            ],
            [
              "Dotted line",
              ":"
            ],
            [
              "Point marker",
              "."
            ],
            [
              "Circle marker",
              "o"
            ],
            [
              "Pixel marker",
              ","
            ],
            [
              "triangle_down marker",
              "v"
            ],
            [
              "triangle_up marker",
              "^"
            ],
            [
              "triangle_left marker",
              "<"
            ],
            [
              "triangle_right marker",
              ">"
            ],
            [
              "tri_down marker",
              "1"
            ],
            [
              "tri_up marker",
              "2"
            ],
            [
              "tri_left marker",
              "3"
            ],
            [
              "tri_right marker",
              "4"
            ],
            [
              "square marker",
              "s"
            ],
            [
              "pentagon marker",
              "p"
            ],
            [
              "star marker",
              "*"
            ],
            [
              "hexagon1 marker",
              "h"
            ],
            [
              "hexagon2 marker",
              "H"
            ],
            [
              "Plus marker",
              "+"
            ],
            [
              "X marker",
              "x"
            ],
            [
              "Diamond marker",
              "D"
            ],
            [
              "thin_diamond marker",
              "d"
            ],
            [
              "vline marker",
              "|"
            ],
            [
              "hline marker",
              "_"
            ]
          ]
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 345,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_marker'] = function (block) {
  var dropdown_marker = block.getFieldValue('marker');
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(lines, marker='" + dropdown_marker + "')" + '\n';
  return code;
};

Blockly.Blocks['graph_marker_edge_color'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_marker_edge_color",
      "message0": "Graph Marker Edge Color: %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "markeredgecolor",
          "options": [
            [
              "Red",
              "red"
            ],
            [
              "Blue",
              "blue"
            ],
            [
              "Magenta",
              "magenta"
            ],
            [
              "Green",
              "green"
            ],
            [
              "Yellow",
              "yellow"
            ],
            [
              "Black",
              "black"
            ],
            [
              "Cyan",
              "cyan"
            ]
          ]
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 345,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_marker_edge_color'] = function (block) {
  var dropdown_markeredgecolor = block.getFieldValue('markeredgecolor');
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(lines, markeredgecolor='" + dropdown_markeredgecolor + "')" + '\n';
  return code;
};

Blockly.Blocks['graph_marker_edge_width'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_marker_edge_width",
      "message0": "Graph Marker Edge Width: %1",
      "args0": [
        {
          "type": "field_number",
          "name": "markeredgewidth",
          "value": 2,
          "min": 1,
          "max": 50
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 345,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_marker_edge_width'] = function (block) {
  var number_markeredgewidth = block.getFieldValue('markeredgewidth');
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(lines, markeredgewidth=float(" + number_markeredgewidth + "))" + '\n';
  return code;
};

Blockly.Blocks['graph_export_to_file'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Export graph to:")
      .appendField(new Blockly.FieldTextInput("filename"), "filename")
      .appendField(".")
      .appendField(new Blockly.FieldDropdown([["pdf", "pdf"], ["svg", "svg"], ["png", "png"]]), "filetype");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.Python['graph_export_to_file'] = function (block) {
  var filename = block.getFieldValue('filename');
  var extention = block.getFieldValue('filetype');
  var code = "plt.savefig('" + filename + "." + extention + "')\n";
  return code;
};

Blockly.Blocks['multigraph_export_to_file'] = {
  init: function () {
    this.appendDummyInput()
      .appendField("Export multigraph to:")
      .appendField(new Blockly.FieldTextInput("filename"), "filename")
      .appendField(".")
      .appendField(new Blockly.FieldDropdown([["pdf", "pdf"], ["svg", "svg"], ["png", "png"]]), "filetype");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour("#00A19D");
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.Python['multigraph_export_to_file'] = function (block) {
  var filename = block.getFieldValue('filename');
  var extention = block.getFieldValue('filetype');
  var code = "fig.savefig('" + filename + "." + extention + "')\n";
  return code;
};

Blockly.Blocks['write_csv_file'] = {
  init: function () {
    this.jsonInit({
      "type": "write_csv_file",
      "message0": "Write CSV file from: %1 to %2",
      "args0": [
        {
          "type": "field_input",
          "name": "df",
          "text": "dataframe"
        },
        {
          "type": "field_input",
          "name": "path",
          "text": "/home/pi/data.csv"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FD6F96",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['write_csv_file'] = function (block) {
  var text_df = block.getFieldValue('df');
  var text_path = block.getFieldValue('path');
  // TODO: Assemble Python into code variable.
  var code = text_df + ".to_csv('" + text_path + "', encoding = 'utf-8')" + '\n';
  return code;
};

Blockly.Blocks['replace_text'] = {
  init: function () {
    this.jsonInit({
      "type": "replace_text",
      "message0": "Replace  %1 text with %2 text from %3",
      "args0": [
        {
          "type": "field_input",
          "name": "first",
          "text": "old"
        },
        {
          "type": "field_input",
          "name": "last",
          "text": "new"
        },
        {
          "type": "input_value",
          "name": "variable"
        }
      ],
      "output": null,
      "colour": "#FFBF00",
      "tooltip": "Replace text from a variable",
      "helpUrl": ""
    });
  }
};
Blockly.Python['replace_text'] = function (block) {
  var text_first = block.getFieldValue('first');
  var text_last = block.getFieldValue('last');
  var value_variable = Blockly.Python.valueToCode(block, 'variable', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_variable + ".replace('" + text_first + "', '" + text_last + "')";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['column_to_list'] = {
  init: function () {
    this.jsonInit({
      "type": "column_to_list",
      "message0": "Convert %1 of data frame %2 to list",
      "args0": [
        {
          "type": "field_input",
          "name": "column",
          "text": "column"
        },
        {
          "type": "field_input",
          "name": "df",
          "text": "dataframe"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "column1 of data frame df to list",
      "helpUrl": ""
    });
  }
};
Blockly.Python['column_to_list'] = function (block) {
  var text_column = block.getFieldValue('column');
  var text_df = block.getFieldValue('df');
  // TODO: Assemble Python into code variable.
  var code = text_df + "['" + text_column + "'].tolist()";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['list_column_data'] = {
  init: function () {
    this.jsonInit({
      "type": "list_column_data",
      "message0": "Get data of column number %1 from data table %2 as list",
      "args0": [
        {
          "type": "field_number",
          "name": "column_number",
          "value": 0,
          "min": 0,
          "max": 30
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['list_column_data'] = function (block) {
  var number_column_number = block.getFieldValue('column_number');
  var text_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);

  // TODO: Assemble Python into code variable.
  var code = "list(" + text_df + ".iloc[:, " + number_column_number + "])";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['read_csv_file'] = {
  init: function () {
    this.jsonInit({
      "type": "read_csv_file",
      "message0": "Read file %1 with separator %2",
      "args0": [
        {
          "type": "field_input",
          "name": "path",
          "text": "/home/pi/data.csv"
        },
        {
          "type": "field_dropdown",
          "name": "separator",
          "options": [
            [
              "Comma",
              ","
            ],
            [
              "Tab",
              "\\t"
            ],
            [
              "Space",
              " "
            ],
            [
              "Pipe",
              "|"
            ]
          ]
        }
      ],
      "output": null,
      "colour": "#FD6F96",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['read_csv_file'] = function (block) {
  var text_path = block.getFieldValue('path');
  var dropdown_graph_type = block.getFieldValue('separator');
  var code = "pd.DataFrame(pd.read_csv('" + text_path + "',sep='" + dropdown_graph_type + "'))";
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['random_int_range'] = {
  init: function () {
    this.jsonInit({
      "type": "random_int_range",
      "message0": "Generate  %1 random numbers ranging between %2 to %3",
      "args0": [
        {
          "type": "field_number",
          "name": "pick",
          "value": 50,
          "min": 1
        },
        {
          "type": "field_number",
          "name": "start",
          "value": 100
        },
        {
          "type": "field_number",
          "name": "end",
          "value": 999
        }
      ],
      "output": null,
      "colour": "#9966FF",
      "tooltip": "Pick specific random numbers ranging between specified numbers",
      "helpUrl": ""
    });
  }
};
Blockly.Python['random_int_range'] = function (block) {
  var number_pick = block.getFieldValue('pick');
  var number_start = block.getFieldValue('start');
  var number_end = block.getFieldValue('end');
  // TODO: Assemble Python into code variable.
  var code = "random.sample(range(" + number_start + ", " + number_end + "), " + number_pick + ")";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['get_value_from_dict'] = {
  init: function () {
    this.jsonInit({
      "type": "get_value_from_dict",
      "message0": "Get  %1 from %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        },
        {
          "type": "field_input",
          "name": "dict",
          "text": "dict"
        }
      ],
      "output": null,
      "colour": "#345B63",
      "tooltip": "Used to get values by passing key name",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_value_from_dict'] = function (block) {
  var value_value = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  var text_dict = block.getFieldValue('dict');
  // TODO: Assemble Python into code variable.
  var code = text_dict + "[" + value_value + "]";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['set_value_into_dict'] = {
  init: function () {
    this.jsonInit({
      "type": "set_value_into_dict",
      "message0": "Set value: %1 into dict: %2 with key: %3",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        },
        {
          "type": "input_value",
          "name": "dict"
        },
        {
          "type": "input_value",
          "name": "key"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345B63",
      "tooltip": "Set value into dict using key and values initialised",
      "helpUrl": ""
    });
  }
};
Blockly.Python['set_value_into_dict'] = function (block) {
  var value_value = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  var value_dict = Blockly.Python.valueToCode(block, 'dict', Blockly.Python.ORDER_ATOMIC);
  var value_key = Blockly.Python.valueToCode(block, 'key', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_dict + "[" + value_key + "]=" + value_value + '\n';
  return code;
};

Blockly.Blocks['round_off'] = {
  init: function () {
    this.jsonInit({
      "type": "round_off",
      "message0": "Round off number: %1 till number of digits %2",
      "args0": [
        {
          "type": "input_value",
          "name": "number",
          "check": "Number"
        },
        {
          "type": "field_number",
          "name": "till_no",
          "value": 0,
          "min": 0,
          "max": 9
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#59C059",
      "tooltip": "rounds off to the given number of digits and returns the floating-point number, if no number of digits is provided for round off, it rounds off the number to the nearest integer",
      "helpUrl": ""
    });
  }
};
Blockly.Python['round_off'] = function (block) {
  var value_number = Blockly.Python.valueToCode(block, 'number', Blockly.Python.ORDER_ATOMIC);
  var number_till_no = block.getFieldValue('till_no');
  // TODO: Assemble Python into code variable.
  var code = "round(float(" + value_number + "), " + number_till_no + ")";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['append_to_list'] = {
  init: function () {
    this.jsonInit({
      "type": "append_to_list",
      "message0": "Append value: %1 into List: %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        },
        {
          "type": "field_input",
          "name": "list",
          "text": "list"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#9966FF",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['append_to_list'] = function (block) {
  var value_value = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  var text_list = block.getFieldValue('list');
  // TODO: Assemble Python into code variable.
  var code = text_list + ".append(" + value_value + ")" + '\n';
  return code;
};

Blockly.Blocks['time_delay_block'] = {
  init: function () {
    this.jsonInit({
      "type": "time_delay_block",
      "message0": "Pause for %1 Second(s)",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['time_delay_block'] = function (block) {
  var value_name = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = "time.sleep(" + value_name + ")" + '\n';
  return code;
};

Blockly.Blocks['get_temp_mean'] = {
  init: function () {
    this.jsonInit({
      "type": "get_temp_mean",
      "message0": "Get mean of temperature for each month in a year",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 0,
      "tooltip": "Get mean of temperature for each month in a year",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_temp_mean'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "m = df.loc[df['Month'] == i, 'Temperature'].mean()" + '\n';
  return code;
};

Blockly.Blocks['get_temp_standard_deviation'] = {
  init: function () {
    this.jsonInit({
      "type": "get_temp_standard_deviation",
      "message0": "Get standard deviation of Temp for each month",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 0,
      "tooltip": "Get standard deviation of temperature for each month",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_temp_standard_deviation'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "sd = df.loc[df['Month'] == i, 'Temperature'].std()" + '\n';
  return code;
};

Blockly.Blocks['df_mean'] = {
  init: function () {
    this.jsonInit({
      "type": "df_mean",
      "message0": "Calculate mean of dataframe:  %1 with column name: %2",
      "args0": [
        {
          "type": "field_input",
          "name": "df",
          "text": "df"
        },
        {
          "type": "field_input",
          "name": "column",
          "text": "column"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "Pass ",
      "helpUrl": ""
    });
  }
};
Blockly.Python['df_mean'] = function (block) {
  var text_df = block.getFieldValue('df');
  var text_column = block.getFieldValue('column');
  // TODO: Assemble Python into code variable.
  var code = text_df + "['" + text_column + "'].mean()" + '';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['df_std'] = {
  init: function () {
    this.jsonInit({
      "type": "df_std",
      "message0": "Calculate standard deviation of dataframe:  %1 with column name: %2",
      "args0": [
        {
          "type": "field_input",
          "name": "df",
          "text": "df"
        },
        {
          "type": "field_input",
          "name": "column",
          "text": "column"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "Pass ",
      "helpUrl": ""
    });
  }
};
Blockly.Python['df_std'] = function (block) {
  var text_df = block.getFieldValue('df');
  var text_column = block.getFieldValue('column');
  // TODO: Assemble Python into code variable.
  var code = text_df + "['" + text_column + "'].std()" + '';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['get_sensor_value2'] = {
  init: function () {
    this.jsonInit({
      "type": "get_sensor_value2",
      "message0": "Get input from GPIO and Mobile %1 Set variable: %2 GPIO: %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "variable_name",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "pin",
          "align": "RIGHT"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_sensor_value2'] = function (block) {
  var value_variable_name = Blockly.Python.valueToCode(block, 'variable_name', Blockly.Python.ORDER_ATOMIC);
  var value_pin = Blockly.Python.valueToCode(block, 'pin', Blockly.Python.ORDER_ATOMIC);
  var code =
    value_variable_name + " = " + value_pin + "\n" +
    value_variable_name + " = check_with_simulator2(" + value_variable_name + ",'" + value_variable_name + "', sim_device)\n";
  return code;
};

Blockly.Blocks['create_new_graph'] = {
  init: function () {
    this.jsonInit(
      {
        "type": "create_new_graph",
        "message0": "Create New Graph %1 %2",
        "args0": [
          {
            "type": "input_dummy"
          },
          {
            "type": "input_statement",
            "name": "inner_code"
          }
        ],
        previousStatement: null,
        nextStatement: null,
        "colour": 230,
        "tooltip": "",
        "helpUrl": ""
      });
  }
};
Blockly.Python['create_new_graph'] = function (block) {
  var inner_code = Blockly.Python.statementToCode(block, 'inner_code');
  // TODO: Assemble Python into code variable.
  var code = 'def create_new_graph():\n  global plt\n' + inner_code + '\n  plt.show()\ncreate_new_graph()\n\n';
  return code;
};

Blockly.Blocks['graph_labels'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_labels",
      "lastDummyAlign0": "RIGHT",
      "message0": "Change label of  %1 %2 to %3",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "label_type",
          "options": [
            [
              "X axis",
              "xlabel"
            ],
            [
              "Y axis",
              "ylabel"
            ],
            [
              "Title",
              "title"
            ]
          ]
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_input",
          "name": "x_label",
          "text": "Name"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_labels'] = function (block) {
  var dropdown_label_type = block.getFieldValue('label_type');
  var text_x_label = block.getFieldValue('x_label');
  // TODO: Assemble Python into code variable.
  var code = "plt." + dropdown_label_type + "('" + text_x_label + "')" + '\n';
  return code;
};

Blockly.Blocks['plot_graph'] = {
  init: function () {
    this.jsonInit(
      {
        "type": "plot_graph",
        "message0": "Create %1 %2 chart with:   X data %3 Y data %4",
        "args0": [
          {
            "type": "input_dummy"
          },
          {
            "type": "field_dropdown",
            "name": "graph_type",
            "options": [
              [
                "Line",
                "plot"
              ],
              [
                "Bar",
                "bar"
              ],
              [
                "Scatter",
                "scatter"
              ],
              [
                "Area",
                "area"
              ]
            ]
          },
          {
            "type": "field_variable",
            "name": "x_data",
            "variable": "x_data"
          },
          {
            "type": "field_variable",
            "name": "y_data",
            "variable": "y_data"
          }
        ],
        "inputsInline": true,
        "output": null,
        "colour": 230,
        "tooltip": "",
        "helpUrl": ""
      });
  }
};
Blockly.Python['plot_graph'] = function (block) {
  var dropdown_graph_type = block.getFieldValue('graph_type');
  var value_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var value_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt." + dropdown_graph_type + "(" + value_x_data + ", " + value_y_data + ")\n";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['plot_single_axis_graph'] = {
  init: function () {
    this.jsonInit(
      {
        "type": "plot_single_axis_graph",
        "message0": "Create %1 chart with:   X data %2",
        "args0": [
          {
            "type": "field_dropdown",
            "name": "graph_type",
            "options": [
              [
                "Line",
                "plot"
              ],
              [
                "Scatter",
                "scatter"
              ],
            ]
          },
          {
            "type": "field_variable",
            "name": "x_data",
            "variable": "x_data"
          }
        ],
        "inputsInline": true,
        "output": null,
        "colour": 230,
        "tooltip": "",
        "helpUrl": ""
      });
  }
};
Blockly.Python['plot_single_axis_graph'] = function (block) {
  var dropdown_graph_type = block.getFieldValue('graph_type');
  var value_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt." + dropdown_graph_type + "(" + value_x_data + ", [0] * len(" + value_x_data + "))\n";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['graph_line_style'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_line_style",
      "message0": "Change line style %1 %2 %3 Line: %4",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_dropdown",
          "name": "line_style",
          "options": [
            [
              "________",
              "-"
            ],
            [
              "----------",
              "--"
            ],
            [
              "-.-.-.-.-.",
              "-."
            ],
            [
              "...........",
              ":"
            ]
          ]
        },
        {
          "type": "input_dummy",
          "align": "RIGHT"
        },
        {
          "type": "field_variable",
          "name": "line_name",
          "variable": "line1"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_line_style'] = function (block) {
  var dropdown_line_style = block.getFieldValue('line_style');
  var variable_line_name = Blockly.Python.variableDB_.getName(block.getFieldValue('line_name'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(" + variable_line_name + ", linestyle='" + dropdown_line_style + "')" + '\n';
  return code;
};

Blockly.Blocks['graph_line_color'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_line_color",
      "message0": "Change color to %1 %2 %3 for %4",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_dropdown",
          "name": "line_color",
          "options": [
            [
              "Red",
              "red"
            ],
            [
              "Blue",
              "blue"
            ],
            [
              "Magenta",
              "magenta"
            ],
            [
              "Green",
              "green"
            ],
            [
              "Yellow",
              "yellow"
            ],
            [
              "Black",
              "black"
            ],
            [
              "Cyan",
              "cyan"
            ]
          ]
        },
        {
          "type": "input_dummy",
          "align": "RIGHT"
        },
        {
          "type": "field_variable",
          "name": "line_name",
          "variable": "line1"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "Can be used for Line or Scatter",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_line_color'] = function (block) {
  var dropdown_line_style = block.getFieldValue('line_color');
  var variable_line_name = Blockly.Python.variableDB_.getName(block.getFieldValue('line_name'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(" + variable_line_name + ", color='" + dropdown_line_style + "')" + '\n';
  return code;
};

Blockly.Blocks['graph_line_width'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_line_width",
      "lastDummyAlign0": "RIGHT",
      "message0": "Change line width to:  %1 %2 for %3",
      "args0": [
        {
          "type": "field_number",
          "name": "line_width",
          "value": 1,
          "min": 1,
          "max": 100
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_variable",
          "name": "line_name",
          "variable": "line1"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_line_width'] = function (block) {
  var number_line_width = block.getFieldValue('line_width');
  var variable_line_name = Blockly.Python.variableDB_.getName(block.getFieldValue('line_name'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(" + variable_line_name + ", linewidth=float(" + number_line_width + "))" + '\n';
  return code;
};

Blockly.Blocks['graph_marker_style'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_marker_style",
      "message0": "Change marker to %1 %2 for %3",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "line_style",
          "options": [
            [
              "Solid line",
              "–"
            ],
            [
              "Dashed line",
              "—"
            ],
            [
              "dash-dot line",
              "-."
            ],
            [
              "Dotted line",
              ":"
            ],
            [
              "Point marker",
              "."
            ],
            [
              "Circle marker",
              "o"
            ],
            [
              "Pixel marker",
              ","
            ],
            [
              "triangle_down marker",
              "v"
            ],
            [
              "triangle_up marker",
              "^"
            ],
            [
              "triangle_left marker",
              "<"
            ],
            [
              "triangle_right marker",
              ">"
            ],
            [
              "tri_down marker",
              "1"
            ],
            [
              "tri_up marker",
              "2"
            ],
            [
              "tri_left marker",
              "3"
            ],
            [
              "tri_right marker",
              "4"
            ],
            [
              "square marker",
              "s"
            ],
            [
              "pentagon marker",
              "p"
            ],
            [
              "star marker",
              "*"
            ],
            [
              "hexagon1 marker",
              "h"
            ],
            [
              "hexagon2 marker",
              "H"
            ],
            [
              "Plus marker",
              "+"
            ],
            [
              "X marker",
              "x"
            ],
            [
              "Diamond marker",
              "D"
            ],
            [
              "thin_diamond marker",
              "d"
            ],
            [
              "vline marker",
              "|"
            ],
            [
              "hline marker",
              "_"
            ]
          ]
        },
        {
          "type": "input_dummy",
          "align": "RIGHT"
        },
        {
          "type": "field_variable",
          "name": "line_name",
          "variable": "line1"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_marker_style'] = function (block) {
  var dropdown_line_style = block.getFieldValue('line_style');
  var variable_line_name = Blockly.Python.variableDB_.getName(block.getFieldValue('line_name'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(" + variable_line_name + ", marker='" + dropdown_line_style + "')" + '\n';
  return code;
};

Blockly.Blocks['graph_markersize'] = {
  init: function () {
    this.jsonInit({
      "type": "graph_markersize",
      "lastDummyAlign0": "RIGHT",
      "message0": "Change marker size to %1 %2 for %3",
      "args0": [
        {
          "type": "field_number",
          "name": "marker_size",
          "value": 1,
          "min": 1,
          "max": 100
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_variable",
          "name": "line_name",
          "variable": "line1"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['graph_markersize'] = function (block) {
  var number_marker_size = block.getFieldValue('marker_size');
  var variable_line_name = Blockly.Python.variableDB_.getName(block.getFieldValue('line_name'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "plt.setp(" + variable_line_name + ", markersize=float(" + number_marker_size + "))" + '\n';
  return code;
};

Blockly.Blocks['create_list_between_numbers'] = {
  init: function () {
    this.jsonInit({
      "type": "create_list_between_numbers",
      "message0": "Create a list of numbers between  %1 and %2",
      "args0": [
        {
          "type": "input_value",
          "name": "start",
          "check": "Number"
        },
        {
          "type": "input_value",
          "name": "stop",
          "check": "Number"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#9966FF",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_list_between_numbers'] = function (block) {
  var value_start = Blockly.Python.valueToCode(block, 'start', Blockly.Python.ORDER_ATOMIC);
  var value_stop = Blockly.Python.valueToCode(block, 'stop', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'list(range(' + value_start + ',' + value_stop + '+1))\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['create_number_list_from_text'] = {
  init: function () {
    this.jsonInit({
      "type": "create_number_list_from_text",
      "message0": "Make a list of numbers %1 from text %2 with delimiter %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "numbers",
          "check": "String",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "delimiter",
          "check": "String",
          "align": "RIGHT"
        }
      ],
      "inputsInline": false,
      "output": null,
      "colour": "#9966FF",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_number_list_from_text'] = function (block) {
  var value_numbers = Blockly.Python.valueToCode(block, 'numbers', Blockly.Python.ORDER_ATOMIC);
  var value_delimiter = Blockly.Python.valueToCode(block, 'delimiter', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'list(map(lambda x: int(x), ' + value_numbers + '.split(' + value_delimiter + ')))\n';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['create_decimals_list_from_text'] = {
  init: function () {
    this.jsonInit({
      "type": "create_decimals_list_from_text",
      "message0": "Make a list of decimal %1 from text %2 with delimiter %3",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "numbers",
          "check": "String",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "delimiter",
          "check": "String",
          "align": "RIGHT"
        }
      ],
      "inputsInline": false,
      "output": null,
      "colour": "#9966FF",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_decimals_list_from_text'] = function (block) {
  var value_numbers = Blockly.Python.valueToCode(block, 'numbers', Blockly.Python.ORDER_ATOMIC);
  var value_delimiter = Blockly.Python.valueToCode(block, 'delimiter', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'list(map(lambda x: float(x), ' + value_numbers + '.split(' + value_delimiter + ')))\n';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['change_axis_limit'] = {
  init: function () {
    this.appendValueInput("limit")
      .appendField("Change")
      .appendField(new Blockly.FieldDropdown([["Start", "min"], ["Stop", "max"]]), "type")
      .appendField("limit of")
      .appendField(new Blockly.FieldDropdown([["X", "x"], ["Y", "y"]]), "axis")
      .appendField("axis to");
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setInputsInline(true);
    this.setColour(230);
    this.setTooltip("");
    this.setHelpUrl("");
  }
};
Blockly.Python['change_axis_limit'] = function (block) {
  var dropdown_type = block.getFieldValue('type');
  var dropdown_axis = block.getFieldValue('axis');
  var number_limit = Blockly.Python.valueToCode(block, 'limit', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = 'plt.' + dropdown_axis + 'lim(' + dropdown_axis + dropdown_type + '=' + number_limit + ')\n';
  return code;
};

Blockly.Blocks['create_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_sub_plot",
      "message0": "Create sub-plot %1 at row %2 at column %3 %4 using X %5 and Y %6",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "graph_type",
          "options": [
            [
              "Line",
              "plot"
            ],
            [
              "Bar",
              "bar"
            ],
            [
              "Scatter",
              "scatter"
            ]
          ]
        },
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_sub_plot'] = function (block) {
  var dropdown_graph_type = block.getFieldValue('graph_type');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "axs[" + number_col + ", " + number_row + "]." + dropdown_graph_type + "(" + variable_x_data + ", " + variable_y_data + ")\n";
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.Blocks['create_line_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_line_sub_plot",
      "message0": "Line: Create sub-plot at row %1 at column %2 %3 using X %4 and Y %5 %6 with color %7 line width %8 line style %9 marker %10 marker size %11",
      "args0": [
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        },
        { "type": "input_dummy" },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 1
        },
        {
          "type": "field_dropdown",
          "name": "line_style",
          "options": [
            ["_______", "-"],
            ["------------", "--"],
            [".............", ":"],
            ["-.-.-.-.-.-.", "-."],
          ]
        },
        {
          "type": "field_dropdown",
          "name": "marker_type",
          "value": "o",
          "options": [
            ["Circle", "o"],
            ["Star", "*"]
          ]
        },
        {
          "type": "field_number",
          "name": "marker_size",
          "value": 1
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_line_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var number_line_style = block.getFieldValue('line_style');
  var marker_type = block.getFieldValue('marker_type');
  var marker_size = block.getFieldValue('marker_size');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_col + ", " + number_row + "].plot(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), linestyle='" + number_line_style + "', marker='" + marker_type + "', markersize=float(" + marker_size + "))\n";
  return code;
};

Blockly.Blocks['create_single_axis_line_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_single_axis_line_sub_plot",
      "message0": "Line: Create sub-plot at number %1 using X %2 and Y %3 %4 with color %5 line width %6 line style %7 marker %8 marker size %9",
      "args0": [
        {
          "type": "field_number",
          "name": "number",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 1
        },
        {
          "type": "field_dropdown",
          "name": "line_style",
          "options": [
            ["_______", "-"],
            ["------------", "--"],
            [".............", ":"],
            ["-.-.-.-.-.-.", "-."],
          ]
        },
        {
          "type": "field_dropdown",
          "name": "marker_type",
          "value": "o",
          "options": [
            ["Circle", "o"],
            ["Star", "*"]
          ]
        },
        {
          "type": "field_number",
          "name": "marker_size",
          "value": 1
        },
      ],
      "inputInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_single_axis_line_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var number_line_style = block.getFieldValue('line_style');
  var marker_type = block.getFieldValue('marker_type');
  var marker_size = block.getFieldValue('marker_size');
  var number_number = block.getFieldValue('number');
  // var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_number + "].plot(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), linestyle='" + number_line_style + "', marker='" + marker_type + "', markersize=float(" + marker_size + "))\n";
  return code;
};

Blockly.Blocks['create_scatter_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_scatter_sub_plot",
      "message0": "Scatter: Create sub-plot at row %1 at column %2 %3 using X %4 and Y %5 %6 with color %7 line width %8 edge color %9 marker %10 marker size %11",
      "args0": [
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        },
        { "type": "input_dummy" },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 1
        },
        {
          "type": "field_colour",
          "name": "edge_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_dropdown",
          "name": "marker_type",
          "value": "o",
          "options": [
            ["Circle", "o"],
            ["Star", "*"]
          ]
        },
        {
          "type": "field_number",
          "name": "marker_size",
          "value": 10
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_scatter_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var marker_type = block.getFieldValue('marker_type');
  var marker_size = block.getFieldValue('marker_size');
  var number_edge_color = block.getFieldValue('edge_color');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_col + ", " + number_row + "].scatter(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), edgecolor='" + number_edge_color + "', marker='" + marker_type + "', s=float(" + marker_size + "))\n";
  return code;
};

Blockly.Blocks['create_single_axis_scatter_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_single_axis_scatter_sub_plot",
      "message0": "Scatter: Create sub-plot at number %1 using X %2 and Y %3 %4 with color %5 line width %6 edge color %7 marker %8 marker size %9",
      "args0": [
        {
          "type": "field_number",
          "name": "number",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 1
        },
        {
          "type": "field_colour",
          "name": "edge_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_dropdown",
          "name": "marker_type",
          "value": "o",
          "options": [
            ["Circle", "o"],
            ["Star", "*"]
          ]
        },
        {
          "type": "field_number",
          "name": "marker_size",
          "value": 10
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_single_axis_scatter_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var marker_type = block.getFieldValue('marker_type');
  var marker_size = block.getFieldValue('marker_size');
  var number_edge_color = block.getFieldValue('edge_color');
  var number_number = block.getFieldValue('number');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_number + "].scatter(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), edgecolor='" + number_edge_color + "', marker='" + marker_type + "', s=float(" + marker_size + "))\n";
  return code;
};

Blockly.Blocks['create_bar_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_bar_sub_plot",
      "message0": "Bar: Create sub-plot at row %1 at column %2 %3 using X %4 and Y %5 %6 with color %7 line width %8 edge color %9",
      "args0": [
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        },
        { "type": "input_dummy" },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 0.5,
          "precision": 0.1
        },
        {
          "type": "field_colour",
          "name": "edge_color",
          "colour": "#0000ff"
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_bar_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var number_edge_color = block.getFieldValue('edge_color');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_col + ", " + number_row + "].bar(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), edgecolor='" + number_edge_color + "')\n";
  return code;
};

Blockly.Blocks['create_single_axis_bar_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_single_axis_bar_sub_plot",
      "message0": "Bar: Create sub-plot at number %1 using X %2 and Y %3 %4 with color %5 line width %6 edge color %7",
      "args0": [
        {
          "type": "field_number",
          "name": "number",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "plot_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_number",
          "name": "line_width",
          "value": 0.5,
          "precision": 0.1
        },
        {
          "type": "field_colour",
          "name": "edge_color",
          "colour": "#0000ff"
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_single_axis_bar_sub_plot'] = function (block) {
  var plot_color = block.getFieldValue('plot_color');
  var number_line_width = block.getFieldValue('line_width');
  var number_edge_color = block.getFieldValue('edge_color');
  var number_number = block.getFieldValue('number');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_number + "].bar(" + variable_x_data + ", " + variable_y_data + ", color='" + plot_color + "', linewidth=float(" + number_line_width + "), edgecolor='" + number_edge_color + "')\n";
  return code;
};

Blockly.Blocks['create_sub_plot_hist'] = {
  init: function () {
    this.jsonInit({
      "type": "create_sub_plot_hist",
      "message0": "Histogram: Create sub-plot at row %1 at column %2 %3 using X %4 %5 with fill color %6 edge color %7",
      "args0": [
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        },
        { "type": "input_dummy" },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        { "type": "input_dummy" },
        {
          "type": "field_colour",
          "name": "bar_color",
          "colour": "#0000ff"
        },
        {
          "type": "field_colour",
          "name": "fill_color",
          "colour": "#000000"
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_sub_plot_hist'] = function (block) {
  var bar_color = block.getFieldValue('bar_color');
  var fill_color = block.getFieldValue('fill_color');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_col + ", " + number_row + "].hist(" + variable_x_data + ", density=True, color='" + bar_color + "', edgecolor='" + fill_color + "')\n";
  return code;
};

Blockly.Blocks['create_single_axis_sub_plot'] = {
  init: function () {
    this.jsonInit({
      "type": "create_sub_plot",
      "message0": "Create sub-plot %1 at number %2 using X %3 and Y %4",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "graph_type",
          "options": [
            [
              "Line",
              "plot"
            ],
            [
              "Bar",
              "bar"
            ],
            [
              "Scatter",
              "scatter"
            ]
          ]
        },
        {
          "type": "field_number",
          "name": "number",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_variable",
          "name": "x_data",
          "variable": "x_data"
        },
        {
          "type": "field_variable",
          "name": "y_data",
          "variable": "y_data"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_single_axis_sub_plot'] = function (block) {
  var dropdown_graph_type = block.getFieldValue('graph_type');
  var graph_number = block.getFieldValue('number');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var variable_y_data = Blockly.Python.variableDB_.getName(block.getFieldValue('y_data'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = "axs[" + graph_number + "]." + dropdown_graph_type + "(" + variable_x_data + ", " + variable_y_data + ")\n";
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.Blocks['create_single_axis_sub_plot_hist'] = {
  init: function () {
    this.jsonInit({
      "type": "create_single_axis_sub_plot_hist",
      "message0": "Histogram: Create sub-plot number %1 using X %2 %3 with color %4 edge color %5",
      "args0": [{
        "type": "field_number",
        "name": "graph_num",
        "value": 0,
        "min": 0
      },
      {
        "type": "field_variable",
        "name": "x_data",
        "variable": "x_data"
      },
      { "type": "input_dummy" },
      {
        "type": "field_colour",
        "name": "fill_color",
        "colour": "#0000ff"
      },
      {
        "type": "field_colour",
        "name": "edge_color",
        "colour": "#000000"
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_single_axis_sub_plot_hist'] = function (block) {
  var fill_color = block.getFieldValue('fill_color');
  var number_graph_num = block.getFieldValue('graph_num');
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  var code = "axs[" + number_graph_num + "].hist(" + variable_x_data + ", density=True, color='" + fill_color + "', edgecolor='black')\n";
  return code;
};

Blockly.Blocks['add_single_axis_sub_plot_title'] = {
  init: function () {
    this.jsonInit({
      "type": "add_single_axis_sub_plot_title",
      "message0": "Change label of %1 to %2 for graph number %3",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "title_type",
          "options": [
            ["X axis", "xlabel"],
            ["Y axis", "ylabel"],
            ["Title", "title"]
          ]
        },
        {
          "type": "field_input",
          "name": "title",
          "text": "Chart name"
        },
        {
          "type": "field_number",
          "name": "graph_num",
          "value": 0,
          "min": 0
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": single_axis_multi_graph_color,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['add_single_axis_sub_plot_title'] = function (block) {
  var dropdown_title_type = block.getFieldValue('title_type');
  var text_title = block.getFieldValue('title');
  var number_graph_num = block.getFieldValue('graph_num');
  // TODO: Assemble Python into code variable.
  var code = 'axs[' + number_graph_num + '].set_' + dropdown_title_type + '("' + text_title + '")\n';
  return code;
};

Blockly.Blocks['create_multiplot_graph'] = {
  init: function () {
    this.jsonInit({
      "type": "create_multiplot_graph",
      "message0": "Create New Graph with multiple plots %1 Rows %3 Columns %2 %4 %5",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 1,
          "min": 1
        },
        {
          "type": "field_number",
          "name": "col",
          "value": 1,
          "min": 1
        },
        {
          "type": "input_dummy",
          "align": "RIGHT"
        },
        {
          "type": "input_statement",
          "name": "inner_code"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['create_multiplot_graph'] = function (block) {
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  var inner_code = Blockly.Python.statementToCode(block, 'inner_code');
  // TODO: Assemble Python into code variable.
  var code = 'def create_multiplot_graph():\n  global plt\n  fig, axs = plt.subplots(' + number_col + ', ' + number_row + ')\n' + inner_code + '\n  fig.tight_layout()\ncreate_multiplot_graph()\n\n';
  return code;
};

Blockly.Blocks['add_sub_plot_title'] = {
  init: function () {
    this.jsonInit({
      "type": "add_sub_plot_title",
      "message0": "Change label of %1 to %2 %3 for row %4 at column %5",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "title_type",
          "options": [
            ["X axis", "xlabel"],
            ["Y axis", "ylabel"],
            ["Title", "title"]
          ]
        },
        {
          "type": "field_input",
          "name": "title",
          "text": "Chart name"
        },
        { "type": "input_dummy" },
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "field_number",
          "name": "row",
          "value": 0,
          "min": 0
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00A19D",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['add_sub_plot_title'] = function (block) {
  var dropdown_title_type = block.getFieldValue('title_type');
  var text_title = block.getFieldValue('title');
  var number_col = block.getFieldValue('col');
  var number_row = block.getFieldValue('row');
  // TODO: Assemble Python into code variable.
  var code = 'axs[' + number_col + ', ' + number_row + '].set_' + dropdown_title_type + '("' + text_title + '")\n';
  return code;
};

Blockly.Blocks['freq_table_by_col_index'] = {
  init: function () {
    this.jsonInit({
      "type": "freq_table_by_col_index",
      "message0": "Get frequency table for column number %1 of  %2",
      "args0": [
        {
          "type": "field_number",
          "name": "col",
          "value": 0,
          "min": 0
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['freq_table_by_col_index'] = function (block) {
  var number_col = block.getFieldValue('col');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_df + '[' + value_df + '.columns[' + number_col + ']].value_counts()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['freq_table_by_col_name'] = {
  init: function () {
    this.jsonInit({
      "type": "freq_table_by_col_name",
      "message0": "Get frequency table for column name %1 of  %2",
      "args0": [
        {
          "type": "field_input",
          "name": "col_name",
          "text": "name"
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['freq_table_by_col_name'] = function (block) {
  var text_col_name = block.getFieldValue('col_name');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_df + '["' + text_col_name + '"].value_counts()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['filter_table_for_int'] = {
  init: function () {
    this.jsonInit({
      "type": "filter_table_for_int",
      "message0": "Filter data for column %1 with value %2 of table %3",
      "args0": [
        {
          "type": "field_input",
          "name": "col_name",
          "text": "name"
        },
        {
          "type": "field_number",
          "name": "value",
          "value": 0
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['filter_table_for_int'] = function (block) {
  var text_col_name = block.getFieldValue('col_name');
  var number_value = block.getFieldValue('value');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_df + "[" + value_df + "['" + text_col_name + "'] == " + number_value + "]";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

// Keypad blocks
Blockly.Blocks['setup_keypad_rows'] = {
  init: function () {
    this.jsonInit({
      "type": "setup_keypad_rows",
      "message0": "Set keypad as Output for rows: %1 R1 %2 R2 %3 R3 %4 R4 %5",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "R1",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "R2",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "R3",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "R4",
          "align": "RIGHT"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#710193",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['setup_keypad_rows'] = function (block) {
  var value_r1 = Blockly.Python.valueToCode(block, 'R1', Blockly.Python.ORDER_ATOMIC);
  var value_r2 = Blockly.Python.valueToCode(block, 'R2', Blockly.Python.ORDER_ATOMIC);
  var value_r3 = Blockly.Python.valueToCode(block, 'R3', Blockly.Python.ORDER_ATOMIC);
  var value_r4 = Blockly.Python.valueToCode(block, 'R4', Blockly.Python.ORDER_ATOMIC);

  var code = "\nR1 = " + value_r1 +
    "\nR2 = " + value_r2 +
    "\nR3 = " + value_r3 +
    "\nR4 = " + value_r4 +
    "\n" +
    "\nGPIO.setup(R1, GPIO.OUT)" +
    "\nGPIO.setup(R2, GPIO.OUT)" +
    "\nGPIO.setup(R3, GPIO.OUT)" +
    "\nGPIO.setup(R4, GPIO.OUT)" +
    "\n";
  return code;
};

Blockly.Blocks['setup_keypad_cols'] = {
  init: function () {
    this.jsonInit({
      "type": "setup_keypad_cols",
      "message0": "Set keypad as Input for columns: %1 C1 %2 C2 %3 C3 %4 C4 %5",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "C1",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "C2",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "C3",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "C4",
          "align": "RIGHT"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#710193",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['setup_keypad_cols'] = function (block) {
  var value_c1 = Blockly.Python.valueToCode(block, 'C1', Blockly.Python.ORDER_ATOMIC);
  var value_c2 = Blockly.Python.valueToCode(block, 'C2', Blockly.Python.ORDER_ATOMIC);
  var value_c3 = Blockly.Python.valueToCode(block, 'C3', Blockly.Python.ORDER_ATOMIC);
  var value_c4 = Blockly.Python.valueToCode(block, 'C4', Blockly.Python.ORDER_ATOMIC);

  var code = "\nC1 = " + value_c1 +
    "\nC2 = " + value_c2 +
    "\nC3 = " + value_c3 +
    "\nC4 = " + value_c4 +
    "\n" +
    "\nGPIO.setup(C1, GPIO.IN)" +
    "\nGPIO.setup(C2, GPIO.IN)" +
    "\nGPIO.setup(C3, GPIO.IN)" +
    "\nGPIO.setup(C4, GPIO.IN)" +
    "\n";
  return code;
};

Blockly.Blocks['keypad_logic'] = {
  init: function () {
    this.jsonInit({
      "type": "keypad_logic",
      "message0": "Keypad functions (readRow, readKey)",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#710193",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['keypad_logic'] = function (block) {
  var code = '\ndef readRow(line, characters):\n    global key\n    GPIO.output(line, GPIO.LOW)\n    time.sleep(0.04)\n    if(GPIO.input(C1) == 0):\n        key = characters[0]\n    if(GPIO.input(C2) == 0):\n        key = characters[1]\n    if(GPIO.input(C3) == 0):\n        key = characters[2]\n    if(GPIO.input(C4) == 0):\n        key = characters[3]\n    GPIO.output(line, GPIO.HIGH)\n\ndef readKey():\n    readRow(R1, ["1","2","3","A"])\n    readRow(R2, ["4","5","6","B"])\n    readRow(R3, ["7","8","9","C"])\n    readRow(R4, ["*","0","#","D"])\n';
  return code;
};

Blockly.Blocks['read_pressed_key_from_keypad'] = {
  init: function () {
    this.jsonInit({
      "type": "read_pressed_key_from_keypad",
      "message0": "Read pressed key from keypad",
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#710193",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['read_pressed_key_from_keypad'] = function (block) {
  var text_key = block.getFieldValue('key');
  var code = "readKey()\n";
  return code;
};


// Mobile Blocks
Blockly.Blocks['is_data_received_from_mobile'] = {
  init: function () {
    this.jsonInit({
      "type": "is_data_received_from_mobile",
      "message0": "is data received from mobile for:  %1",
      "args0": [
        {
          "type": "field_input",
          "name": "key",
          "text": "key"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['is_data_received_from_mobile'] = function (block) {
  var text_key = block.getFieldValue('key');
  var code = "'" + text_key + "' in sim_device";
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['read_data_from_mobile'] = {
  init: function () {
    this.jsonInit({
      "type": "read_data_from_mobile",
      "message0": "read data from mobile for:  %1",
      "args0": [
        {
          "type": "field_input",
          "name": "key",
          "text": "key"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['read_data_from_mobile'] = function (block) {
  var text_key = block.getFieldValue('key');
  var code = "sim_device['" + text_key + "']";
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['erase_mobile_data'] = {
  init: function () {
    this.jsonInit({
      "type": "erase_mobile_data",
      "message0": "Erase mobile data at:  %1",
      "args0": [
        {
          "type": "field_input",
          "name": "key",
          "text": "key"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['erase_mobile_data'] = function (block) {
  var text_key = block.getFieldValue('key');
  var code = "del sim_device['" + text_key + "']\n";
  return code;
};

// Dataframe Blocks
Blockly.Blocks['blank_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "blank_dataframe",
      "message0": "Create new Dataframe",
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['blank_dataframe'] = function (block) {
  var code = 'pd.DataFrame()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['add_column_to_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "add_column_to_dataframe",
      "message0": "Add column %1 %2 with list of values: %3 to Dataframe %4",
      "args0": [
        {
          "type": "field_input",
          "name": "col",
          "text": "column_name"
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "list"
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['add_column_to_dataframe'] = function (block) {
  var text_col = block.getFieldValue('col');
  var value_list = Blockly.Python.valueToCode(block, 'list', Blockly.Python.ORDER_ATOMIC);
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble JavaScript into code variable.
  var code = value_df + "['" + text_col + "'] = " + value_list + "\n";
  return code;
};

Blockly.Blocks['get_rows'] = {
  init: function () {
    this.jsonInit({
      "type": "get_rows",
      "message0": "Head - Get first %1 rows of data table: %2",
      "args0": [
        {
          "type": "field_number",
          "name": "head",
          "value": 6,
          "min": 1
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_rows'] = function (block) {
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var number_head = block.getFieldValue('head');
  var code = value_df + '.head(' + number_head + ')';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['get_rows_reverse'] = {
  init: function () {
    this.jsonInit({
      "type": "get_rows_reverse",
      "message0": "Tail - Get last %1 rows of data table: %2",
      "args0": [
        {
          "type": "field_number",
          "name": "tail",
          "value": 6,
          "min": 1
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_rows_reverse'] = function (block) {
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var number_tail = block.getFieldValue('tail');
  var code = value_df + '.tail(' + number_tail + ')';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['get_row_by_index_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "get_row_by_index_dataframe",
      "message0": "Get row %1 from Dataframe %2",
      "args0": [
        {
          "type": "field_number",
          "name": "index",
          "value": 1
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_row_by_index_dataframe'] = function (block) {
  var number_index = block.getFieldValue('index');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = value_df + '.loc[' + number_index + ']';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['change_column_name_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "change_column_name_dataframe",
      "message0": "Change column name from %1 to %2 in Dataframe %3",
      "args0": [
        {
          "type": "field_input",
          "name": "from",
          "text": "old_name"
        },
        {
          "type": "field_input",
          "name": "to",
          "text": "new_name"
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['change_column_name_dataframe'] = function (block) {
  var text_from = block.getFieldValue('from');
  var text_to = block.getFieldValue('to');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = value_df + ".rename(columns = {'" + text_from + "':'" + text_to + "'}, inplace = True)\n";
  return code;
};

Blockly.Blocks['aggregate_column_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "aggregate_column_dataframe",
      "message0": "Aggregate %1 by %2 using %3 for dataframe: %4",
      "args0": [
        {
          "type": "field_input",
          "name": "grp",
          "text": "column1"
        },
        {
          "type": "field_input",
          "name": "agg",
          "text": "column2"
        },
        {
          "type": "field_dropdown",
          "name": "method",
          "options": [
            ["Mean", "mean"],
            ["Min", "min"],
            ["Max", "max"],
            ["Count", "count"],
            ["Sum", "sum"],
            ["Median", "median"],
            ["Standard deviation", "std"],
            ["Mode", "mode"]
          ]
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['aggregate_column_dataframe'] = function (block) {
  var text_grp = block.getFieldValue('grp');
  var text_agg = block.getFieldValue('agg');
  var method = block.getFieldValue('method');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = value_df + ".groupby('" + text_grp + "').agg({'" + text_agg + "': ['" + method + "']})['" + text_agg + "'].reset_index()";
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['round_column_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "round_column_dataframe",
      "message0": "Round %1 by %2 digits from dataframe: %3",
      "args0": [
        {
          "type": "field_input",
          "name": "column",
          "text": "column"
        },
        {
          "type": "field_number",
          "name": "digit",
          "value": 0
        },
        {
          "type": "input_value",
          "name": "df"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#59C059",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['round_column_dataframe'] = function (block) {
  var column = block.getFieldValue('column');
  var number_digit = block.getFieldValue('digit');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = value_df + '.round({"' + column + '":' + number_digit + '})';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['round_off_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "round_off_dataframe",
      "message0": "Round off %1 by %2 digits",
      "args0": [
        {
          "type": "input_value",
          "name": "df"
        },
        {
          "type": "field_number",
          "name": "digit",
          "value": 0
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#59C059",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['round_off_dataframe'] = function (block) {
  var number_digit = block.getFieldValue('digit');
  var value_df = Blockly.Python.valueToCode(block, 'df', Blockly.Python.ORDER_ATOMIC);
  var code = value_df + '.round(' + number_digit + ')';
  return [code, Blockly.Python.ORDER_NONE];
};

// Machine Learning
Blockly.Blocks['new_linear_regression_model'] = {
  init: function () {
    this.jsonInit({
      "type": "new_linear_regression_model",
      "message0": "New linear regression model",
      "output": null,
      "colour": 30,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['new_linear_regression_model'] = function (block) {
  var code = 'LinearRegression()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['generate_kmeans_clustering'] = {
  init: function () {
    this.jsonInit({
      "type": "generate_kmeans_clustering",
      "message0": "New Kmeans model from %2 with %1 clusters",
      "args0": [
        {
          "type": "input_value",
          "name": "n_clusters"
        },
        {
          "type": "input_value",
          "name": "dataframe"
        },
      ],
      "output": null,
      "colour": 30,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['generate_kmeans_clustering'] = function (block) {
  var n_clusters = Blockly.Python.valueToCode(block, 'n_clusters', Blockly.Python.ORDER_ATOMIC);
  var dataframe = Blockly.Python.valueToCode(block, 'dataframe', Blockly.Python.ORDER_ATOMIC);
  var code = 'KMeans(n_clusters=' + n_clusters + ').fit(' + dataframe + ')';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['fit_model'] = {
  init: function () {
    this.jsonInit({
      "type": "fit_model",
      "message0": "fitting a model using list x: %1 list y: %2 in model: %3",
      "args0": [
        {
          "type": "input_value",
          "name": "x_data"
        },
        {
          "type": "input_value",
          "name": "y_data"
        },
        {
          "type": "input_value",
          "name": "model"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 30,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['fit_model'] = function (block) {
  var value_x_data = Blockly.Python.valueToCode(block, 'x_data', Blockly.Python.ORDER_ATOMIC);
  var value_y_data = Blockly.Python.valueToCode(block, 'y_data', Blockly.Python.ORDER_ATOMIC);
  var value_model = Blockly.Python.valueToCode(block, 'model', Blockly.Python.ORDER_ATOMIC);
  var code = value_model + '.fit(np.array(' + value_x_data + ').reshape((-1, 1)), np.array(' + value_y_data + '))';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['read_from_model'] = {
  init: function () {
    this.jsonInit({
      "type": "read_from_model",
      "message0": "Get %1 from model %2",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "option",
          "options": [
            ["Slope", "coef_"],
            ["Intercept", "intercept_"],
            ["Cluster means", "cluster_centers_"],
            ["Clustering vector", "labels_"],
          ]
        },
        {
          "type": "input_value",
          "name": "model"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": 30,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['read_from_model'] = function (block) {
  var dropdown_option = block.getFieldValue('option');
  var value_model = Blockly.Python.valueToCode(block, 'model', Blockly.Python.ORDER_ATOMIC);
  var code = value_model + '.' + dropdown_option;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['correlation_coefficient_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "correlation_coefficient_dataframe",
      "message0": "Correlation coefficient of %1 and %2",
      "args0": [
        {
          "type": "input_value",
          "name": "column1"
        },
        {
          "type": "input_value",
          "name": "column2"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#59C059",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['correlation_coefficient_dataframe'] = function (block) {
  var value_column1 = Blockly.Python.valueToCode(block, 'column1', Blockly.Python.ORDER_ATOMIC);
  var value_column2 = Blockly.Python.valueToCode(block, 'column2', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_column1 + ".corr(" + value_column2 + ")";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};



Blockly.Blocks['get_column_from_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "get_column_from_dataframe",
      "message0": "Get column %1 of data frame %2",
      "args0": [
        {
          "type": "field_input",
          "name": "columnName",
          "text": "name"
        },
        {
          "type": "field_variable",
          "name": "dataFrame",
          "variable": "df"
        }
      ],
      "output": null,
      "colour": 230,
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['get_column_from_dataframe'] = function (block) {
  var text_columnname = block.getFieldValue('columnName');
  var variable_dataframe = Blockly.Python.variableDB_.getName(block.getFieldValue('dataFrame'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble Python into code variable.
  var code = variable_dataframe + "['" + text_columnname + "']";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['math_formula_dataframe'] = {
  init: function () {
    this.jsonInit({
      "type": "math_formula_dataframe",
      "message0": "%1 of %2",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "methods",
          "options": [
            ["Mean", "mean"],
            ["Min", "min"],
            ["Max", "max"],
            ["Count", "count"],
            ["Sum", "sum"],
            ["Median", "median"],
            ["Standard deviation", "std"],
            ["Mode", "mode"],
            ["Correlation", "corr"],
          ]
        },
        {
          "type": "input_value",
          "name": "column1"
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#59C059",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['math_formula_dataframe'] = function (block) {
  var dropdown_methods = block.getFieldValue('methods');
  var value_column1 = Blockly.Python.valueToCode(block, 'column1', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = value_column1 + "." + dropdown_methods + "()";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

//commented due to Grok-33
/* 
Blockly.Blocks['timestream_csv'] = {
  init: function () {
    this.jsonInit({
      "type": "timestream_csv",
      "message0": "Get input for Time Stream to CSV %1 Bucket Name: %2 Region Name: %3  TimeStream DB Name: %4 TimeStream Table Name: %5",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "bucket_name",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "region_name",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "dbname",
          "align": "RIGHT"
        },
        {
          "type": "input_value",
          "name": "tablename",
          "align": "RIGHT"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};
Blockly.Python['timestream_csv'] = function (block) {
  var bucket_name = Blockly.Python.valueToCode(block, 'bucket_name', Blockly.Python.ORDER_ATOMIC);
  var region_name = Blockly.Python.valueToCode(block, 'region_name', Blockly.Python.ORDER_ATOMIC);
  var dbname = Blockly.Python.valueToCode(block, 'dbname', Blockly.Python.ORDER_ATOMIC);
  var tablename = Blockly.Python.valueToCode(block, 'tablename', Blockly.Python.ORDER_ATOMIC);

  dbname = dbname.replace(/['"]+/g, '');
  tablename = tablename.replace(/['"]+/g, '');
  var code =
    `import boto3\nimport csv\nimport uuid\nimport json\nimport os\nfrom botocore.config import Config\nfrom datetime import datetime\n` +
    `def lambda_handler(event,context):\n\tlimit = ""\n\tdate = ""\n\tjsonData = ""\n` +
    `\tif event.get('body',None) != None:\n\t\tif (isinstance(event['body'], str)):\n\t\t\tjsonData = json.loads(event['body'])\n\t\telse:\n\t\t\tjsonData = event['body']\n\t\tif jsonData.get('limit',None) != None:\n\t\t\tlimit = jsonData.get('limit')\n\t\tif jsonData.get('date',None) != None:\n\t\t\tdate = jsonData.get('date')\n` +
    `\tif event.get('queryStringParameters',None) != None:\n\t\teventQueryStringParameters = event['queryStringParameters']\n\t\tif eventQueryStringParameters.get('limit',None) != None:\n\t\t\tlimit = int(eventQueryStringParameters.get('limit'))\n\t\tif eventQueryStringParameters.get('date',None) != None:\n\t\t\tdate = eventQueryStringParameters.get('date')\n` +
    `\tif event.get('limit',None) != None:\n\t\tlimit = event['limit']\n\tif event.get('date',None) != None:\n\t\tdate = event['date']\n` +
    `\ts3 = boto3.resource('s3')\n\tid = str(uuid.uuid4())\n\tbucket = s3.Bucket(${bucket_name})\n\tkey = 'csv/' + id + ".csv"\n` +
    `\tsession = boto3.Session()\n\tquery_client = session.client('timestream-query',config=Config(region_name=${region_name}))\n\tquery = "SELECT * from ${dbname}.${tablename}"\n` +
    `\tres = True\n\ttry:\n\t\tres = bool(datetime.strptime(date, '%Y-%m-%d'))\n\texcept ValueError:\n\t\tres = False\n\tif res:\n\t\tquery = f"{query} where time between '{date} 00:00:00' and '{date} 23:59:59'"\n\tif (isinstance(limit, int)):\n\t\tquery = f"{query} limit {limit}"\n` +
    `\tqueryResult = query_client.query(QueryString=query)\n\tColumnInfoData = queryResult['ColumnInfo']\n\trowsData = queryResult['Rows']\n\theaderList = []\n\tdataList = []\n\ttempdataList = []\n` +
    `\tfor x in ColumnInfoData:\n\t\theaderList.append(x['Name'])\n\n\tfor idx, x in enumerate(rowsData):\n\t\tval = x.values()\n\t\tfor y in val:\n\t\t\ttempdataList = []\n\t\t\tfor z in y:\n\t\t\t\tif 'ScalarValue' in z:\n\t\t\t\t\ttempdataList.append(z['ScalarValue'])\n\t\t\t\telse:\n\t\t\t\t\ttempdataList.append("")\n\t\t\tdataList.insert(idx,tempdataList)\n` +
    `\twith open('/tmp/yourfilename.csv', 'w', encoding='UTF8', newline='') as f:\n\twriter = csv.writer(f)\n\twriter.writerow(headerList)\n\twriter.writerows(dataList)\n` +
    `\tbucket.upload_file('/tmp/yourfilename.csv', key)\n\treturn {\n\t\t'message': 'success!!',\n\t\t'id' : id + ".csv"\n\t}\n`;

  return code;
};
*/


Blockly.Blocks['take_picture_usb_camera'] = {
  init: function () {
    this.jsonInit({
      "type": "take_picture_usb_camera",
      "message0": "Take Picture",
      "args0": [
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FD6F96",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['take_picture_usb_camera'] = function (block) {
  var code = `cam = VideoCapture(cam_port)\nresult, image = cam.read()\nif result:\n\timwrite("/home/pi/Desktop/Grok-Downloads/image.jpg", image)\ncam.release()\n`;
  return code;
};

Blockly.Blocks["print_weight"] = {
  init: function () {
    this.jsonInit({
      type: "print_weight",
      message0: "Print Weight %1",
      args0: [{
        type: "input_value",
        name: "weight",
      }],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: 210,
      tooltip: "Print Weight",
      helpUrl: "",
    });
  },
};

Blockly.Python['print_weight'] = function (block) {
  var weightName = Blockly.Python.valueToCode(block, "weight", Blockly.Python.ORDER_ATOMIC);
  var code = `print("val :",int(${weightName}))\n`;
  return code;
};

Blockly.Blocks["weight_sensor"] = {
  init: function () {
    this.jsonInit({
      type: "weight_sensor",
      message0: "Set Weight Sensor as input at pins %1 %2",
      previousStatement: null,
      "args0": [
        {
          "type": "input_value",
          "name": "pin1"
        },
        {
          "type": "input_value",
          "name": "pin2"
        }
      ],
      nextStatement: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["weight_sensor"] = function (block) {
  var value_pin_number1 = Blockly.Python.valueToCode(block, 'pin1', Blockly.Python.ORDER_ATOMIC);
  var value_pin_number2 = Blockly.Python.valueToCode(block, 'pin2', Blockly.Python.ORDER_ATOMIC);
  var code = `hx = HX711(${value_pin_number1},${value_pin_number2})` +
    `\nhx.set_reading_format("MSB", "MSB")` +
    `\nhx.set_reference_unit(referenceUnit)` +
    `\nhx.reset()` +
    `\nhx.tare()` +
    `\nprint("Tare done! Add weight now...")\n`;
  return code;
};

Blockly.Blocks["get_weight"] = {
  init: function () {
    this.jsonInit({
      type: "get_weight",
      message0: "Get Weight",
      output: null,
      colour: 210,
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["get_weight"] = function (block) {
  var code = "hx.get_weight(5)\n"
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['read_powermeter_data'] = {
  init: function () {
    this.jsonInit({
      "type": "read_powermeter_data",
      "message0": "Read Power Meter Data",
      "args0": [
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#FF4848",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['read_powermeter_data'] = function (block) {
  var code = `while True:\n\tdata = readData() #<-- Read Power Meter Value Block\n\tvoltage = data[0] #<-- Read Voltage Block\n\tcurrent = data[1] #<-- Read Current Block\n\tpower = data[2]  #<-- Read Power Block\n\tenergy = data[3]  #<-- Read Energy Block\n\tfrequency = data[4]  #<-- Read Frequency Block\n\tpowerFactor = data[5]  #<-- Read Power Factor Block\n\tprint("voltage: ",voltage)\n\tprint("Current :",current)\n\tprint("Power : ",power)\n\tprint("Energy : ",energy)\n\tprint("Frequency : ",frequency)\n\tprint("Power Factor : ",powerFactor)\n\ttime.sleep(1)\n`;
  return code;
};

Blockly.Blocks["flame_sensor"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "flame_sensor";
    data['message0'] = "Set Flame Sensor as input at pin %1";
    data['colour'] = "#FFBF00";
    this.jsonInit(data);
  },
};
Blockly.Python["flame_sensor"] = PYTHON_GPIO_INPUT;


//calculate PH value (PH Sesnor)
// Blockly.Blocks['calculate_ph_value_function'] = {
//   init: function () {
//     this.jsonInit({
//       "type": "calculate_ph_value_function",
//       "message0": "Calculate PH value-function",
//       "previousStatement": null,
//       "nextStatement": null,
//       "colour": 230,
//       "tooltip": "",
//       "helpUrl": ""
//     });
//   }
// };
// Blockly.Python["calculate_ph_value_function"] = function (block) {
//   var code = "\ndef phValue(analog_channel):\n    Value = read_adc(analog_channel)\n    if Value !=0:\n\t   Voltage=Value * 6/1024\n\t   ph=((3.91007-Voltage)/0.18)\n\t   return ph\n\n";
//   return code;
// };

//get ph Value (PH Sesnor)
Blockly.Blocks["get_ph_value"] = {
  init: function () {
    this.jsonInit({
      type: "get_ph_value",
      "message0": "Read PH Data with Analog Input Channel %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "NAME",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_ph_value"] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  // TODO: Assemble JavaScript into code variable.
  var code = "phValue(" + dropdown_name + ")\n"
  return [code, Blockly.Python.ORDER_NONE];
};


//Calculate tds value (TDS Sensor)
Blockly.Blocks["calculate_tds_value_function"] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_tds_value_function",
      "message0": "Calculate TDS value-function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#a5805b",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['calculate_tds_value_function'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef tdsValue(analog_channel):\n\tValue = read_adc(analog_channel)\n\tif Value != 0:\n\t\tVoltage = Value *5/1024\n\t\ttds = int((133.42/Voltage*Voltage*Voltage - 255.86*Voltage*Voltage + 857.39*Voltage)*0.5)\n\t\treturn tds\n';
  return code;
};

//get TDS Value (TDS Sensor)
Blockly.Blocks["get_tds_value"] = {
  init: function () {
    this.jsonInit({
      "type": "get_tds_value",
      "message0": "Read TDS Data with Analog Input Channel %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "NAME",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output": null,
      "colour": "#a5805b",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['get_tds_value'] = function (block) {
  var dropdown_name = block.getFieldValue('NAME');
  // TODO: Assemble Python into code variable.
  var code = "tdsValue(" + dropdown_name + ")\n";
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

//calculate or read power meter data
Blockly.Blocks["calculate_power_meter_data_function"] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_power_meter_data_function",
      "message0": "Read Power Meter Data-function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['calculate_power_meter_data_function'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'def calculate_power_meter_data():\n\ttry:\n\t\tdata = master.execute(1, cst.READ_INPUT_REGISTERS, 0, 10)\n\t\tdict_payload["voltage"]= data[0] / 10.0\n\t\tdict_payload["current_A"] = (data[1] + (data[2] << 16)) / 1000.0 # [A]\n\t\tdict_payload["power_W"] = (data[3] + (data[4] << 16)) / 10.0 # [W]\n\t\tdict_payload["energy_KWh"] = (data[5] + (data[6] << 16))/1000 # [KWh]\n\t\tdict_payload["frequency_Hz"] = data[7] / 10.0 # [Hz]\n\t\tdict_payload["power_factor"] = data[8] / 100.0\n\t\tstr_payload = json.dumps(dict_payload, indent=2)\n\t\tprint(str_payload)\n\t\tpowerReadings = [dict_payload["voltage"],dict_payload["current_A"],dict_payload["power_W"],dict_payload["energy_KWh"],dict_payload["frequency_Hz"],dict_payload["power_factor"]]\n\texcept:\n\t\tpowerReadings =[0,0,0,0,0,0]\n\t   \n\treturn powerReadings\n\ndef read_data():\n\tpower_meter_read_data = {}\n\tdata = calculate_power_meter_data()\n\tpower_meter_read_data["voltage"] = data[0]\n\tpower_meter_read_data["current"] = data[1]\n\tpower_meter_read_data["power"] = data[2]\n\tpower_meter_read_data["energy"] = data[3]\n\tpower_meter_read_data["frequency"] = data[4]\n\tpower_meter_read_data["powerFactor"] = data[5]\n\t \n\treturn power_meter_read_data\n\n';
  return code;
};

Blockly.Blocks["get_power_meter_data"] = {
  init: function () {
    this.jsonInit({
      "type": "get_power_meter_data",
      "message0": "Get Power Meter Data",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['get_power_meter_data'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data = read_data()\n';
  // TODO: Change ORDER_NONE to the correct strength.
  return code;
};

Blockly.Blocks["read_voltage"] = {
  init: function () {
    this.jsonInit({
      "type": "read_voltage",
      "message0": "Get Voltage",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};
Blockly.Python['read_voltage'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["voltage"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_current"] = {
  init: function () {
    this.jsonInit({
      "type": "read_current",
      "message0": "Get Current",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};
Blockly.Python['read_current'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["current"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_power"] = {
  init: function () {
    this.jsonInit({
      "type": "read_power",
      "message0": "Get Power",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};
Blockly.Python['read_power'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["power"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
}

Blockly.Blocks["read_energy"] = {
  init: function () {
    this.jsonInit({
      "type": "read_energy",
      "message0": "Get Energy",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['read_energy'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["energy"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};
Blockly.Blocks["read_frequency"] = {
  init: function () {
    this.jsonInit({
      "type": "read_frequency_from_power_meter",
      "message0": "Get Frequency",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};
Blockly.Python['read_frequency'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["frequency"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_power_factor"] = {
  init: function () {
    this.jsonInit({
      "type": "read_power_factor",
      "message0": "Get Power Factor",
      "output": null,
      "colour": 330,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['read_power_factor'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'power_meter_read_data["powerFactor"]';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, Blockly.Python.ORDER_NONE];
};

// plot graph 
Blockly.Blocks["graph_plot"] = {
  init: function () {
    this.jsonInit(
      {
        "type": "graph_plot",
        "message0": "Plot graph",
        "previousStatement": null,
        "nextStatement": null,
        "colour": 230,
        "tooltip": "",
        "helpUrl": ""
      });
  },
};

Blockly.Python['graph_plot'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `buf = io.BytesIO()\nplt.savefig(buf, format = 'png')\nbuf.seek(0)\ndata = buf.read()\nbuf.close()\nprint('data:image/png;base64,' + base64.b64encode(data).decode())\n`;
  return code;
}

Blockly.Blocks["set_as_global_variable"] = {
  init: function () {
    this.jsonInit(
      {
        "type": "set_as_global_variable",
        "message0": "%1 %2",
        "args0": [
          {
            "type": "field_label_serializable",
            "name": "NAME",
            "text": "set as Global"
          },
          {
            "type": "field_variable",
            "name": "x_data",
            "variable": "x_data"
          },
        ],
        "previousStatement": null,
        "nextStatement": null,
        "colour": "#00A19D",
        "tooltip": "",
        "helpUrl": ""
      });
  },
};

Blockly.Python['set_as_global_variable'] = function (block) {
  var variable_x_data = Blockly.Python.variableDB_.getName(block.getFieldValue('x_data'), Blockly.Variables.NAME_TYPE);
  // TODO: Assemble JavaScript into code variable.
  var code = `global ${variable_x_data}\n`;
  return code;
};


//calculate or read Ph value function
Blockly.Blocks["calculate_pH_value_function"] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_pH_value_function",
      "message0": "Calculate pH value function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['calculate_pH_value_function'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef phValue(analog_channel):\n\tValue = read_adc(analog_channel)\n\tif Value != 0:\n\t\tVoltage = Value *6/1024\n\t\tph = ((3.91007-Voltage)/0.18)\n\t\treturn ph\n';
  return code;
};

Blockly.Blocks["Dc_Set_Mixer_Pins"] = {
  init: function () {
    this.jsonInit({
      "message0": "Set Mixer pins %1 pin1 %2 pin2 %3 %4",
      "args0": [{
        "type": "input_dummy",
      },
      {
        "type": "input_value",
        "name": "pin1",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "pin2",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_dummy",
      },
      ],
      "message1": "Set Frequency %1",
      "args1": [
        {
          "type": "field_number",
          "name": "Set_Frequency",
          "value": 0,
          "min": 50,
          "max": 50000,
          "check": "Number",
          "align": "RIGHT",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 350,
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

Blockly.Python["Dc_Set_Mixer_Pins"] = function (block) {
  var pin_number1 = Blockly.Python.valueToCode(block, 'pin1', Blockly.Python.ORDER_ATOMIC);
  var pin_number2 = Blockly.Python.valueToCode(block, 'pin2', Blockly.Python.ORDER_ATOMIC);
  var frequency = block.getFieldValue("Set_Frequency");
  var code =
  `\nMixer_pwm_pin1 = ${pin_number1}` +
  `\nMixer_pwm_pin2 = ${pin_number2}` +
  `\nGPIO.setup(${pin_number1},GPIO.OUT)` +
  `\nPWM1_Fine = GPIO.PWM(${pin_number1},${frequency})` +
  `\nPWM1_Fine.start(2.5)` +
  `\nGPIO.setup(${pin_number2},GPIO.OUT)` +
  `\nPWM2_Course = GPIO.PWM(${pin_number2},${frequency})` +
  `\nPWM2_Course.start(2.5)` +
  `\nPWM2_Course.ChangeDutyCycle(1)\n`;
  return code;
};

Blockly.Blocks["Dc_Mixer_Speed"] = {
  init: function () {
    this.jsonInit({
      "type": "Dc_Mixer_Speed",
      "message0": "Change Mixer Speed to %1 Percent",
      "args0": [
        {
          "type": "input_value",
          "name": "speed",
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "350",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};


Blockly.Python['Dc_Mixer_Speed'] = function (block) {  
  var speedName = Blockly.Python.valueToCode(block, "speed", Blockly.Python.ORDER_ATOMIC);
  var code = `PWM1_Fine.ChangeDutyCycle(${speedName})\n`;
  return code;
};

Blockly.Blocks["vibration_meter"] = {
  init: function () {
    this.jsonInit({
      "type": "vibration_sensor",
      "message0": "set Vibration meter value function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 389,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['vibration_meter'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef Vibration_Val(analog_channel):\n\tValue = read_adc(analog_channel)\n\tif Value != 0:\n\t\tValue = 1000000/Value-1135\n\treturn Value\n\n';
  return code;
};

Blockly.Blocks["vibration_data"] = {
  init: function () {
    this.jsonInit({
      "type": "vibration_data",
      "message0": "Get Vibration Data from pin %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "setvalue",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output" : null,
      "colour": 389,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['vibration_data'] = function (block) {  
  var sensor_pin = block.getFieldValue('setvalue');
  var code = `Vibration_Val(${sensor_pin})\n`;
  // var code = `\tSensor_pin = ${sensor_pin}\n\tVib_intensity = Vibration_Val(${sensor_pin})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["gsr_value"] = {
  init: function () {
    this.jsonInit({
      "type": "gsr_value",
      "message0": "Read Stress Value function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#ff6969",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['gsr_value'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef read_stress_Values(analog_channel):\n\tValue = abs((read_adc(analog_channel)-32)*2)\n\treturn Value\n\n';
  return code;
};

Blockly.Blocks["gsr_data"] = {
  init: function () {
    this.jsonInit({
      "type": "gsr_data",
      "message0": "Get Stress Data from pin %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "setvalue",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output" : null,
      "colour": "#ff6969",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['gsr_data'] = function (block) {  
  var sensor_pin = block.getFieldValue('setvalue');
  var code = `read_stress_Values(${sensor_pin})`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["ecg_value"] = {
  init: function () {
    this.jsonInit({
      "type": "ecg_value",
      "message0": "Read ECG Value function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#00d7bb",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['ecg_value'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef read_ECG_Values(analog_channel):\n\tValue = (read_adc(analog_channel))\n\treturn Value\n\n';
  return code;
};

Blockly.Blocks["ecg_data"] = {
  init: function () {
    this.jsonInit({
      "type": "ecg_data",
      "message0": "Get ECG Data from pin %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "setvalue",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output" : null,
      "colour": "#00d7bb",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['ecg_data'] = function (block) {  
  var sensor_pin = block.getFieldValue('setvalue');
  var code = `read_ECG_Values(${sensor_pin})`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["calculate_gas_value"] = {
  init: function () {
    this.jsonInit({
      "type": "calculate_gas_value",
      "message0": "Calculate Gas Value Function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#143f85",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['calculate_gas_value'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ndef gasValue(analog_channel):\n\tValue = read_adc(analog_channel)\n\tif Value != 0:\n\t\tVoltage = Value *6/1024\n\t\tgas = ((3.91007-Voltage)/0.18)\n\t\treturn gas\n';
  return code;
};

Blockly.Blocks["read_gas_value"] = {
  init: function () {
    this.jsonInit({
      "type": "read_gas_value",
      "message0": "Read Gas Value with Analog Input Channel %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "setvalue",
          "options": [
            [
              "1",
              "1"
            ],
            [
              "2",
              "2"
            ],
            [
              "3",
              "3"
            ],
            [
              "4",
              "4"
            ]
          ]
        }
      ],
      "output" : null,
      "colour": "#143f85",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['read_gas_value'] = function (block) {  
  var sensor_pin = block.getFieldValue('setvalue');
  var code = `gasValue(${sensor_pin})`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["set_flow_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "set_flow_sensor",
      "message0": "Set Flow Sensor %1",
      "args0": [
        {
          "type": "input_value",
          "name": "pin",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#025054",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["set_flow_sensor"] = function (block) {
  var flow_sensor_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    "FLOW_SENSOR_GPIO = " + flow_sensor_pin + "\nGPIO.setup(FLOW_SENSOR_GPIO, GPIO.IN, pull_up_down = GPIO.PUD_UP)\n\n";
  return code;
};

Blockly.Blocks["define_count_flow_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "define_count_flow_sensor",
      "message0": "Count Pulse",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#025054",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['define_count_flow_sensor'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '\ncount = 0\ndef countPulse(channel): \n\tglobal count\n\tif start_counter == 1:\n\t\tcount = count + 1\nGPIO.add_event_detect(FLOW_SENSOR_GPIO, GPIO.FALLING, callback=countPulse)\n\n';
  return code;
};

Blockly.Blocks["flow_flow_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "flow_flow_sensor",
      "message0": "Flow",
      "output": null,
      "colour": "#025054",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['flow_flow_sensor'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '(count / 7.5)';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["print_flow_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "print_flow_sensor",
      "message0": "Print Flow",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#025054",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['print_flow_sensor'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'print("The flow is: %.3f Liter/min" % (flow))\ncount = 0\n';
  return code;
};

Blockly.Blocks["dual_motor_setup"] = {
  init: function () {
    this.jsonInit({
      "message0": "Setup Robotic Pins %1 in1 %2 in2 %3 in3 %4 in4 %5 en1 %6 en2 %7",
      "args0": [{
        "type": "input_dummy",
      },
      {
        "type": "input_value",
        "name": "in1",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "in2",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "in3",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "in4",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "en1",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "en2",
        "check": "Number",
        "align": "RIGHT",
      },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

Blockly.Python["dual_motor_setup"] = function (block) {
  var value_in1 = Blockly.Python.valueToCode(block, 'in1', Blockly.Python.ORDER_ATOMIC);
  var value_in2 = Blockly.Python.valueToCode(block, 'in2', Blockly.Python.ORDER_ATOMIC);
  var value_in3 = Blockly.Python.valueToCode(block, 'in3', Blockly.Python.ORDER_ATOMIC);
  var value_in4 = Blockly.Python.valueToCode(block, 'in4', Blockly.Python.ORDER_ATOMIC);
  var value_en1 = Blockly.Python.valueToCode(block, 'en1', Blockly.Python.ORDER_ATOMIC);
  var value_en2 = Blockly.Python.valueToCode(block, 'en2', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    `\nin1 = ${value_in1}\nin2 = ${value_in2}\nin3 = ${value_in3}\nin4 = ${value_in4}\nen1 = ${value_en1}\nen2 = ${value_en2}` +
    `\nGPIO.setup(in1,GPIO.OUT)\nGPIO.setup(in2,GPIO.OUT)\nGPIO.setup(en1,GPIO.OUT)\nGPIO.setup(in3,GPIO.OUT)\nGPIO.setup(in4,GPIO.OUT)\nGPIO.setup(en2,GPIO.OUT)\nGPIO.output(in1,GPIO.LOW)\nGPIO.output(in2,GPIO.LOW)\nGPIO.output(in3,GPIO.LOW)\nGPIO.output(in4,GPIO.LOW)\n` + 
    `\np1 = GPIO.PWM(en1,1000)\np1.start(40)\np1.ChangeDutyCycle(0)` + 
    `\np2 = GPIO.PWM(en2,1000)\np2.start(40)\np2.ChangeDutyCycle(0)\n`;
  return code;
};

Blockly.Blocks["dual_motor_robotic_functions"] = {
  init: function () {
    this.jsonInit({
      "type": "dual_motor_robotic_functions",
      "message0": "Function for Robotic Movement %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "define robot function",
        "options": [
          ["Forward", "go_forward"],
          ["Backward", "go_backward"],
          ["Left", "go_point_left"],
          ["Right", "go_point_right"],
          ["Left-Swing", "go_swing_left"],
          ["Right-Swing", "go_swing_right"],
          ["Gradual Left", "go_gradual_left"],
          ["Gradual Right", "go_gradual_right"],
          ["Stop", "stop"],
        ]
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python['dual_motor_robotic_functions'] = function (block) {
  var dropdown_robotic_movement_functions = block.getFieldValue('define robot function');
  // TODO: Assemble Python into code variable.
  var code = ''
  switch (dropdown_robotic_movement_functions) {
    case "go_forward":
      code = "\ndef go_forward(speed1, speed2):\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
    case "go_backward":
      code = "\ndef go_backward(speed1, speed2):\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
    case "go_point_left":
      code = "\ndef go_point_left(speed1, speed2):\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.HIGH)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
      case "go_point_right":
        code = "\ndef go_point_right(speed1, speed2):\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.HIGH)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
      case "go_swing_left":
        code = "\ndef go_swing_left(speed1, speed2):\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
      case "go_swing_right":
      code = "\ndef go_swing_right(speed1, speed2):\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
      break;
      case "go_gradual_left":
        code = "\ndef go_gradual_left(speed1, speed2):\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
        break;
      case "go_gradual_right":
        code = "\ndef go_gradual_right(speed1, speed2):\n\tGPIO.output(in1,GPIO.HIGH)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.HIGH)\n\tGPIO.output(in4,GPIO.LOW)\n\tp1.ChangeDutyCycle(speed1)\n\tp2.ChangeDutyCycle(speed2)\n";
        break;
      case "stop":
        code = "\ndef stop():\n\tGPIO.output(in1,GPIO.LOW)\n\tGPIO.output(in2,GPIO.LOW)\n\tGPIO.output(in3,GPIO.LOW)\n\tGPIO.output(in4,GPIO.LOW)\n";
      break;
  }
  code += '\n';
  return code;
};

Blockly.Blocks["dual_motor_invoking_robotic_functions"] = {
  init: function () {
    this.jsonInit({
      "type": "dual_motor_robotic_functions",
      "message0": "Move %1 %2 Speed 1 %3 Speed 2 %4",
      "args0": [
        {
        "type": "field_dropdown",
        "name": "invoking robotic functions",
        "options": [
          ["Forward", "go_forward"],
          ["Backward", "go_backward"],
          ["Left", "go_point_left"],
          ["Right", "go_point_right"],
          ["Left-Swing", "go_swing_left"],
          ["Right-Swing", "go_swing_right"],
          ["Gradual Left", "go_gradual_left"],
          ["Gradual Right", "go_gradual_right"],
        ]
      },
      {
        "type": "input_dummy",
      },
      {
        "type": "input_value",
        "name": "speed1_Variable",
      },
      {
        "type": "input_value",
        "name": "speed2_Variable",
      },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python["dual_motor_invoking_robotic_functions"] = function (block) {
  var speed1 = Blockly.Python.valueToCode(block, 'speed1_Variable', Blockly.Python.ORDER_ATOMIC);
  var speed2 = Blockly.Python.valueToCode(block, 'speed2_Variable', Blockly.Python.ORDER_ATOMIC);
  var dropdown_robotic_functions = block.getFieldValue("invoking robotic functions");
  // TODO: Assemble Python into code variable.
  var code = dropdown_robotic_functions + `(${speed1}, ${speed2})\n`;
  return code;
};

Blockly.Blocks["dual_motor_robotic_stop"] = {
  init: function () {
    this.jsonInit({
      "type": "dual_motor_robotic_stop",
      "message0": "Robotic Stop",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['dual_motor_robotic_stop'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = "stop()\n";
  return code;
};

Blockly.Blocks["single_motor_setup"] = {
  init: function () {
    this.jsonInit({
      "message0": "Setup Robotic Pins %1 in1 %2 in2 %3 en1 %4",
      "args0": [{
        "type": "input_dummy",
      },
      {
        "type": "input_value",
        "name": "in1",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "in2",
        "check": "Number",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "en1",
        "check": "Number",
        "align": "RIGHT",
      },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

Blockly.Python["single_motor_setup"] = function (block) {
  var value_in1 = Blockly.Python.valueToCode(block, 'in1', Blockly.Python.ORDER_ATOMIC);
  var value_in2 = Blockly.Python.valueToCode(block, 'in2', Blockly.Python.ORDER_ATOMIC);
  var value_en1 = Blockly.Python.valueToCode(block, 'en1', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    `\nin1 = ${value_in1}\nin2 = ${value_in2}\nen1 = ${value_en1}` +
    `\nGPIO.setup(in1,GPIO.OUT)\nGPIO.setup(in2,GPIO.OUT)\nGPIO.setup(en1,GPIO.OUT)\nGPIO.output(in1,GPIO.LOW)\nGPIO.output(in2,GPIO.LOW)\n` + 
    `\np1 = GPIO.PWM(en1,1000)\np1.start(40)\np1.ChangeDutyCycle(0)\n\n`;
  return code;
};

Blockly.Blocks["single_motor_input_pin1"] = {
  init: function () {
    this.jsonInit({
      "type": "single_motor_input_pin1",
      "message0": "in1 %1 %2",
      "args0": [{
        "type": "input_dummy",
        },
        {
          "type": "input_statement",
          "name": "pin",
        },
      ],
      // "inputsInline": false,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["single_motor_input_pin1"] = function (block) {
  var statements_do = Blockly.Python.statementToCode(block, "pin");
  // TODO: Assemble Python into code variable.
  var code = `GPIO.output(in1,${statements_do.trim()})\n`;
  return code;
};
Blockly.Blocks["single_motor_input_pin2"] = {
  init: function () {
    this.jsonInit({
      "type": "single_motor_input_pin2",
      "message0": "in2 %1 %2",
      "args0": [{
        "type": "input_dummy",
        },
        {
          "type": "input_statement",
          "name": "pin",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["single_motor_input_pin2"] = function (block) {
  var statements_do = Blockly.Python.statementToCode(block, "pin");
  // TODO: Assemble Python into code variable.
  var code = `\nGPIO.output(in2,${statements_do.trim()})\n`;
  return code;
};

Blockly.Blocks["single_motor_setup_gpio_high_or_low"] = {
  init: function () {
    this.jsonInit({
      "type": "single_motor_setup_gpio_high_or_low",
      "message0": "%1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "set_pin",
          "options": [
            ["High", "GPIO.HIGH"],
            ["Low", "GPIO.LOW"],
          ],
        }
      ],
      "previousStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python['single_motor_setup_gpio_high_or_low'] = function (block) {  
  var set_pin = block.getFieldValue('set_pin');
  var code = `${set_pin}`;
  return code;
};

Blockly.Blocks["single_motor_enable_pin"] = {
  init: function () {
    this.jsonInit({
      "type": "single_motor_enable_pin",
      "message0": "en %1",
      "args0": [
        {
          "type": "input_value",
          "name": "pin",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["single_motor_enable_pin"] = function (block) {
  var enable_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `\np1.ChangeDutyCycle(${enable_pin})\n`;
  return code;
};

Blockly.Blocks["single_motor_gpio_pin_high_low"] = {
  init: function () {
    this.jsonInit({
      "type": "single_motor_gpio_pin_high_low",
      "message0": "%1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "gpio",
          "options": [
              ["High", "High = GPIO.HIGH"],
              ["Low", "LOW = GPIO.LOW"],
          ]
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["single_motor_gpio_pin_high_low"] = function (block) {
  var gpio_pin = block.getFieldValue('gpio');
  // TODO: Assemble Python into code variable.
  var code = `p1.ChangeDutyCycle(${gpio_pin})\n`;
  return code;
};

Blockly.Blocks["set_hall_effect_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "set_hall_effect_sensor",
      "message0": "Set Hall Effect Sensor as input at pin %1",
      "args0": [
        {
          "type": "input_value",
          "name": "pin",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#753867",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["set_hall_effect_sensor"] = function (block) {
  var hall_effect_sensor_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    `\nGPIO.setup(${hall_effect_sensor_pin}, GPIO.IN)\n\n`;
  return code;
};

Blockly.Blocks["set_metal_detector_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "set_metal_detector_sensor",
      "message0": "Set Metal Detector Sensor as input at pin %1",
      "args0": [
        {
          "type": "input_value",
          "name": "pin",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#a8633b",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["set_metal_detector_sensor"] = function (block) {
  var metal_detector_sensor_pin = Blockly.Python.valueToCode(block, "pin", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code =
    `\nGPIO.setup(${metal_detector_sensor_pin}, GPIO.IN)\n\n`;
  return code;
};

Blockly.Blocks['load_model'] = {
  init: function () {
    this.jsonInit({
      "type": "load_model",
      "message0": "Load Model %1",
      "args0": [
        {
          "type": "field_input",
          "name": "load_model",
          "text": "../model.h5"
        }
      ],
      "output": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['load_model'] = function (block) {
  var path_load_model = block.getFieldValue('load_model');
  // TODO: Assemble JavaScript into code variable.
  var code = `load_model("${path_load_model}")\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['start_camera'] = {
  init: function () {
    this.jsonInit({
      "type": "start_camera",
      "message0": "Start Camera",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['start_camera'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ncap = cv2.VideoCapture(0)\n`;
  return code;
};

Blockly.Blocks['get_live_image'] = {
  init: function () {
    this.jsonInit({
      "type": "get_live_image",
      "message0": "Get Live Image",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['get_live_image'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nret, frame = cap.read()\n`;
  return code;
};

Blockly.Blocks['process_image'] = {
  init: function () {
    this.jsonInit({
      "type": "process_image",
      "message0": "Process Image",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['process_image'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nimage = cv2.resize(frame, (224, 224))\nimage = np.expand_dims(image, axis=0)\nimage = image / 255.0\n`;
  return code;
};

Blockly.Blocks['predict_species'] = {
  init: function () {
    this.jsonInit({
      "type": "predict_species",
      "message0": "Predict Species",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['predict_species'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nprediction = model.predict(image)[0]\nclass_idx = np.argmax(prediction)\nclass_label = categories[class_idx]\nconfidence = prediction[class_idx] * 100\n`;
  return code;
};

Blockly.Blocks['show_prediction'] = {
  init: function () {
    this.jsonInit({
      "type": "show_prediction",
      "message0": "Show Prediction",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['show_prediction'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ncv2.putText(frame, f"{class_label} {confidence:.2f}%", (10, 30), cv2.FONT_HERSHEY_SIMPLEX, 0.8, (0, 255, 0), 2)\n`;
  return code;
};

Blockly.Blocks['show_live_image'] = {
  init: function () {
    this.jsonInit({
      "type": "show_live_image",
      "message0": "Show Live Image",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['show_live_image'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ncv2.imshow("Live Camera", frame)\n`;
  return code;
};

Blockly.Blocks['stop_prediction'] = {
  init: function () {
    this.jsonInit({
      "type": "stop_prediction",
      "message0": "Stop Prediction",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['stop_prediction'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nif cv2.waitKey(1) & 0xFF == ord('q'):\n\t\tbreak\n`;
  return code;
};

Blockly.Blocks['stop_camera'] = {
  init: function () {
    this.jsonInit({
      "type": "stop_camera",
      "message0": "Stop Camera",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['stop_camera'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ncap.release()\ncv2.destroyAllWindows()\n`;
  return code;
};

Blockly.Blocks['define_sentiment'] = {
  init: function () {
    this.jsonInit({
      "type": "define_sentiment",
      "message0": "Function – Print Sentiment",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['define_sentiment'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ndef sentiment_scores(sentence):\n\n`+ 
  `\t# Create a SentimentIntensityAnalyzer object.\n`+`\tsid_obj = SentimentIntensityAnalyzer()\n\n`+
  `\t# polarity_scores method of SentimentIntensityAnalyzer\n`+
  `\t# object gives a sentiment dictionary.\n`+
  `\t# which contains pos, neg, neu, and compound scores.\n`+
  `\tsentiment_dict = sid_obj.polarity_scores(sentence)\n`+
  `\tprint(sentence)\n`+
  `\t# print("Overall sentiment dictionary is : ", sentiment_dict)\n`+
  `\tprint("sentence was rated as ", sentiment_dict['neg']*100, "% Negative")\n`+
  `\tprint("sentence was rated as ", sentiment_dict['neu']*100, "% Neutral")\n`+
  `\tprint("sentence was rated as ", sentiment_dict['pos']*100, "% Positive")\n\n`+
  `\tprint("Sentence Overall Rated As", end = " ")\n\n`+
  `\t# decide sentiment as positive, negative and neutral\n`+
  `\tif sentiment_dict['compound'] >= 0.05 :\n`+
  `\t\tprint("Positive")\n\n`+
  `\telif sentiment_dict['compound'] <= - 0.05 :\n`+
  `\t\tprint("Negative")\n\n`+
  `\telse :\n`+
  `\t\tprint("Neutral")\n\n`;
  return code;
};

Blockly.Blocks['display_sentiment'] = {
  init: function () {
    this.jsonInit({
      "type": "display_sentiment",
      "message0": "Display Sentiment",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['display_sentiment'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `sentiment_scores(sentence)\n`;
  return code;
};

Blockly.Blocks['define_class_hand_data'] = {
  init: function () {
    this.jsonInit({
      "type": "define_class_hand_data",
      "message0": "Define Class Hand Data",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['define_class_hand_data'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nclass HandData:\n` +
  `\ttop = (0,0)\n` +
  `\tbottom = (0,0)\n` +
  `\tleft = (0,0)\n` +
  `\tright = (0,0)\n` +
  `\tcenterX = 0\n` +
  `\tprevCenterX = 0\n` +
  `\tisInFrame = False\n` +
  `\tisWaving = False\n` +
  `\tfingers = None\n` +
  `\tgestureList = []\n\n` +
  `\tdef __init__(self, top, bottom, left, right, centerX):\n` +
  `\t\tself.top = top\n` +
  `\t\tself.bottom = bottom\n` +
  `\t\tself.left = left\n` +
  `\t\tself.right = right\n` +
  `\t\tself.centerX = centerX\n` +
  `\t\tself.prevCenterX = 0\n` +
  `\t\tisInFrame = False\n` +
  `\t\tisWaving = False\n\n` +
  `\tdef update(self, top, bottom, left, right):\n` +
  `\t\tself.top = top\n` +
  `\t\tself.bottom = bottom\n` +
  `\t\tself.left = left\n` +
  `\t\tself.right = right\n\n` +
  `\tdef check_for_waving(self, centerX):\n` +
  `\t\tself.prevCenterX = self.centerX\n` +
  `\t\tself.centerX = centerX\n\n` +
  `\t\tif abs(self.centerX - self.prevCenterX > 3):\n` +
  `\t\t\tself.isWaving = True\n` +
  `\t\telse:\n` +
  `\t\t\tself.isWaving = False\n`;
  return code;
};

Blockly.Blocks['define_function_recognize_gesture'] = {
  init: function () {
    this.jsonInit({
      "type": "define_function_recognize_gesture",
      "message0": "Define Function-Recognize Gesture",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['define_function_recognize_gesture'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\ndef write_on_image(frame):\n` +
  `\ttext = "Searching..."\n\n` +
  `\tif frames_elapsed < CALIBRATION_TIME:\n` +
  `\t\ttext = "Calibrating..."\n` +
  `\telif hand == None or hand.isInFrame == False:\n` +
  `\t\ttext = "No hand detected"\n` +
  `\telse:\n` +
  `\t\tif hand.isWaving:\n` +
  `\t\t\ttext = "Waving"\n` +
  `\t\telif hand.fingers == 0:\n` +
  `\t\t\ttext = "Rock"\n` +
  `\t\telif hand.fingers == 1:\n` +
  `\t\t\ttext = "Pointing"\n` +
  `\t\telif hand.fingers == 2:\n` +
  `\t\t\ttext = "Scissors"\n\n` +
  `\tcv2.putText(frame, text, (10,20), cv2.FONT_HERSHEY_COMPLEX, 0.4,( 0 , 0 , 0 ),2,cv2.LINE_AA)\n` +
  `\tcv2.putText(frame, text, (10,20), cv2.FONT_HERSHEY_COMPLEX, 0.4,(255,255,255),1,cv2.LINE_AA)\n\n` +
  `\tcv2.rectangle(frame, (region_left, region_top), (region_right, region_bottom), (255,255,255), 2)\n\n` +
  `def get_region(frame):\n` +
  `\tregion = frame[region_top:region_bottom, region_left:region_right]\n` +
  `\tregion = cv2.cvtColor(region, cv2.COLOR_BGR2GRAY)\n` +
  `\tregion = cv2.GaussianBlur(region, (5,5), 0)\n` +
  `\treturn region\n\n` +
  `def get_average(region):\n` +
  `\tglobal background\n` +
  `\tif background is None:\n` +
  `\t\tbackground = region.copy().astype("float")\n` +
  `\t\treturn\n\n` +
  `\tcv2.accumulateWeighted(region, background, BG_WEIGHT)\n\n` +
  `def segment(region):\n` +
  `\tglobal hand\n` +
  `\tdiff = cv2.absdiff(background.astype(np.uint8), region)\n\n` +
  `\tthresholded_region = cv2.threshold(diff, OBJ_THRESHOLD, 255, cv2.THRESH_BINARY)[1]\n` +
  `\tcontours, _ = cv2.findContours(thresholded_region.copy(), cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)\n\n` +
  `\tif len(contours) == 0:\n` +
  `\t\tif hand is not None:\n` +
  `\t\t\thand.isInFrame = False\n` +
  `\t\treturn\n` +
  `\telse:\n` +
  `\t\tif hand is not None:\n` +
  `\t\t\thand.isInFrame = True\n` +
  `\t\tsegmented_region = max(contours, key = cv2.contourArea)\n` +
  `\t\treturn (thresholded_region, segmented_region)\n\n` +
  `def get_hand_data(thresholded_image, segmented_image):\n` +
  `\tglobal hand\n\n` +
  `\tconvexHull = cv2.convexHull(segmented_image)\n\n` +
  `\ttop    = tuple(convexHull[convexHull[:, :, 1].argmin()][0])\n` +
  `\tbottom = tuple(convexHull[convexHull[:, :, 1].argmax()][0])\n` +
  `\tleft   = tuple(convexHull[convexHull[:, :, 0].argmin()][0])\n` +
  `\tright  = tuple(convexHull[convexHull[:, :, 0].argmax()][0])\n\n` +
  `\tcenterX = int((left[0] + right[0]) / 2)\n\n` +
  `\tif hand == None:\n` +
  `\t\thand = HandData(top, bottom, left, right, centerX)\n` +
  `\telse:\n` +
  `\t\thand.update(top, bottom, left, right)\n\n` +
  `\tif frames_elapsed % 6 == 0:\n` +
  `\t\thand.check_for_waving(centerX)\n\n` +
  `\thand.gestureList.append(count_fingers(thresholded_image))\n` +
  `\tif frames_elapsed % 12 == 0:\n` +
  `\t\thand.fingers = most_frequent(hand.gestureList)\n` +
  `\t\thand.gestureList.clear()\n\n` + 
  `def count_fingers(thresholded_image):\n\n` +
  `\tline_height = int(hand.top[1] + (0.2 * (hand.bottom[1] - hand.top[1])))\n` +
  `\tline = np.zeros(thresholded_image.shape[:2], dtype=int)\n\n` +
  `\tcv2.line(line, (thresholded_image.shape[1], line_height), (0, line_height), 255, 1)\n` +
  `\tline = cv2.bitwise_and(thresholded_image, thresholded_image, mask = line.astype(np.uint8))\n\n` +
  `\tcontours, hierarchy = cv2.findContours(line.copy(), cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_NONE)\n\n` +
  `\tfingers = 0\n\n` +
  `\tfor curr in contours:\n` +
  `\t\twidth = len(curr)\n` +
  `\t\tif width < 3 * abs(hand.right[0] - hand.left[0]) / 4 and width > 5:\n` +
  `\t\t\tfingers += 1\n` +
  `\treturn fingers\n\n` +
  `def most_frequent(input_list):\n` +
  `\tdict = {}\n` +
  `\tcount = 0\n` +
  `\tmost_freq = 0\n\n` +
  `\tfor item in reversed(input_list):\n` +
  `\t\tdict[item] = dict.get(item, 0) + 1\n` +
  `\t\tif dict[item] >= count :\n` +
  `\t\t\tcount, most_freq = dict[item], item\n\n` +
  `\treturn most_freq\n\n`
  // `\t\t\t\n`
  return code;
};

Blockly.Blocks['resize_and_flip_image'] = {
  init: function () {
    this.jsonInit({
      "type": "resize_and_flip_image",
      "message0": "Resize and Flip Image",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['resize_and_flip_image'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nframe = cv2.resize(frame, (FRAME_WIDTH, FRAME_HEIGHT))\n` + 
  `frame = cv2.flip(frame, 1)\n`;
  return code;
};

Blockly.Blocks["get_hand_region"] = {
  init: function () {
    this.jsonInit({
      "type": "get_hand_region",
      "message0": "Get Hand Region",
      "output": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['get_hand_region'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'get_region(frame)\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['average_region'] = {
  init: function () {
    this.jsonInit({
      "type": "average_region",
      "message0": "Average Region",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['average_region'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nget_average(region)\n`;
  return code;
};

Blockly.Blocks["region_segments"] = {
  init: function () {
    this.jsonInit({
      "type": "region_segments",
      "message0": "Region Segments",
      "output": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['region_segments'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'segment(region)\n';
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['get_hand_data'] = {
  init: function () {
    this.jsonInit({
      "type": "get_hand_data",
      "message0": "Get Hand Data",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['get_hand_data'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\n(thresholded_region, segmented_region) = region_pair\n` +
  `cv2.drawContours(region, [segmented_region], -1, (255, 255, 255))\n` + 
  `cv2.imshow("Segmented Image", region)\n` +
  `get_hand_data(thresholded_region, segmented_region)\n`;
  return code;
};

Blockly.Blocks['show_gesture'] = {
  init: function () {
    this.jsonInit({
      "type": "show_gesture",
      "message0": "Show Gesture",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#3792cb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['show_gesture'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `\nwrite_on_image(frame)\n` +
  `cv2.imshow("Camera Input", frame)\n` +
  `frames_elapsed += 1\n`;
  return code;
};

Blockly.Blocks["use_stemming"] = {
  init: function () {
    this.jsonInit({
      "type": "use_stemming",
      "message0": "Use Stemming",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["use_stemming"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'LancasterStemmer()\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["with_open"] = {
  init: function () {
    this.jsonInit({
      "type": "with_open",
      "message0": "With Open %1 as %2",
      "args0": [
        {
          "type": "field_input",
          "name": "path",
          "text": "intents.json"
        },
        {
          "type": "input_value",
          "name": "value"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python["with_open"] = function (block) {
  var path = block.getFieldValue('path');
  var value = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `with open('${path}') as ${value}:\n`;
  return code;
};

Blockly.Blocks["load_json"] = {
  init: function () {
    this.jsonInit({
      "type": "load_json",
      "message0": "Load Json %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["load_json"] = function (block) {
  var load_json = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `json.load(${load_json})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_intent_and_pattern"] = {
  init: function () {
    this.jsonInit({
      "type": "get_intent_and_pattern",
      "message0": "Get Intent and Pattern",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_intent_and_pattern"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `for intent in intents['intents']:\n`+
  `\tfor pattern in intent['patterns']:\n`+
  `\t\t# tokenize each word in the sentence\n`+
  `\t\tw = nltk.word_tokenize(pattern)\n`+
  `\t\t# add to our words list\n`+
  `\t\twords.extend(w)\n`+
  `\t\t# add to documents in our corpus\n`+
  `\t\tdocuments.append((w, intent['tag']))\n`+
  `\t\t# add to our classes list\n`+
  `\t\tif intent['tag'] not in classes:\n`+
  `\t\t\tclasses.append(intent['tag'])\n\n`;
  return code;
};

Blockly.Blocks["data_list"] = {
  init: function () {
    this.jsonInit({
      "type": "data_list",
      "message0": "Data %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "data_item",
          "options": [
            ["words", "words"],
            ["classes", "classes"],
            ["train_x", "train_x"],
            ["train_y", "train_y"],
          ],
        },
      ],
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["data_list"] = function (block) {
  var dropdown_list = block.getFieldValue("data_item");
  //TODO: Assemble Python into code variable.
  var code = `data['${dropdown_list}']\n\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["load_model_tflearn"] = {
  init: function () {
    this.jsonInit({
      "type": "load_model_tflearn",
      "message0": "Load Model %1",
      "args0": [
        {
          "type": "field_input",
          "name": "load_model_tflearn",
          "text": "./model.tflearn"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python["load_model_tflearn"] = function (block) {
  var path_load_model_tflearn = block.getFieldValue('load_model_tflearn');
  // TODO: Assemble JavaScript into code variable.
  var code = `model.load('${path_load_model_tflearn}')\n\n`;
  return code;
};

Blockly.Blocks["ignore_words"] = {
  init: function () {
    this.jsonInit({
      "type": "ignore_words",
      "message0": "Ignore Words",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["ignore_words"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '[stemmer.stem(w.lower()) for w in words if w not in ignore_words]\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["set_words_list"] = {
  init: function () {
    this.jsonInit({
      "type": "set_words_list",
      "message0": "Set Words List",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["set_words_list"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'sorted(list(set(words)))\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["set_classes_list"] = {
  init: function () {
    this.jsonInit({
      "type": "set_classes_list",
      "message0": "Set Classes List",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["set_classes_list"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'sorted(list(set(classes)))\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["two_blank_input"] = {
  init: function () {
    this.jsonInit({
      "type": "two_blank_input",
      "message0": "%1 %2 %3",
      "args0": [
        {
          "type": "input_value",
          "name": "firstinput",
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "second_input",
        },
      ],
      "output": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};


Blockly.Python["two_blank_input"] = function (block) {  
  var firstinput = Blockly.Python.valueToCode(block, "firstinput", Blockly.Python.ORDER_ATOMIC);
  var second_input = Blockly.Python.valueToCode(block, "second_input", Blockly.Python.ORDER_ATOMIC);
  var code = `${firstinput}, ${second_input}`;
  return  [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["three_blank_input"] = {
  init: function () {
    this.jsonInit({
      "type": "three_blank_input",
      "message0": "%1 %2 %3 %4 %5",
      "args0": [
        {
          "type": "input_value",
          "name": "firstinput",
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "second_input",
        },
        {
          "type": "input_dummy"
        },
        {
          "type": "input_value",
          "name": "third_input",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};


Blockly.Python["three_blank_input"] = function (block) {  
  var firstinput = Blockly.Python.valueToCode(block, "firstinput", Blockly.Python.ORDER_ATOMIC);
  var second_input = Blockly.Python.valueToCode(block, "second_input", Blockly.Python.ORDER_ATOMIC);
  var third_input = Blockly.Python.valueToCode(block, "third_input", Blockly.Python.ORDER_ATOMIC);
  var code = `${firstinput}, ${second_input}, ${third_input}`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["create_empty_output_list"] = {
  init: function () {
    this.jsonInit({
      "type": "create_empty_output_list",
      "message0": "Create Empty Output List",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["create_empty_output_list"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = '[0] * len(classes)\n';
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["create_training_set"] = {
  init: function () {
    this.jsonInit({
      "type": "create_training_set",
      "message0": "Create Training Set",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["create_training_set"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `for doc in documents:\n`+
  `\t# initialize our bag of words\n`+
  `\tbag = []\n`+
  `\t# list of tokenized words for the pattern\n`+
  `\tpattern_words = doc[0]\n`+
  `\t# stem each word\n`+
  `\tpattern_words = [stemmer.stem(word.lower()) for word in pattern_words]\n`+
  `\t# create our bag of words array\n`+
  `\tfor w in words:\n`+
  `\t\tbag.append(1) if w in pattern_words else bag.append(0)\n\n`+
  `\t# output is a '0' for each tag and '1' for current tag\n`+
  `\toutput_row = list(output_empty)\n`+
  `\toutput_row[classes.index(doc[1])] = 1\n\n`+
  `\ttraining.append([bag, output_row])\n\n`;
  return code;
};

Blockly.Blocks["shuffle_training_set"] = {
  init: function () {
    this.jsonInit({
      "type": "shuffle_training_set",
      "message0": "Shuffle Training Set",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["shuffle_training_set"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `random.shuffle(training)\n`;
  return code;
};

Blockly.Blocks["create_array_for_training"] = {
  init: function () {
    this.jsonInit({
      "type": "create_array_for_training",
      "message0": "Create Array for Training",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["create_array_for_training"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `np.array(training)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["training_x_list"] = {
  init: function () {
    this.jsonInit({
      "type": "training_x_list",
      "message0": "Training X List",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["training_x_list"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'list(training[:,0])\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["training_y_list"] = {
  init: function () {
    this.jsonInit({
      "type": "training_y_list",
      "message0": "Training Y List",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["training_y_list"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'list(training[:,1])\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["reset_default_graph"] = {
  init: function () {
    this.jsonInit({
      "type": "reset_default_graph",
      "message0": "Reset Default Graph",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["reset_default_graph"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'tf.compat.v1.reset_default_graph()\n\n';
  return code;
};

Blockly.Blocks["neural_network_input_layer"] = {
  init: function () {
    this.jsonInit({
      "type": "neural_network_input_layer",
      "message0": "Neural Network - Input Layer",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["neural_network_input_layer"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'tflearn.input_data(shape=[None, len(train_x[0])])\n';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["neural_network_hidden_layer"] = {
  init: function () {
    this.jsonInit({
      "type": "neural_network_hidden_layer",
      "message0": "Neural Network - Hidden Layer",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["neural_network_hidden_layer"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `tflearn.fully_connected(net, 8)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["activate_neural_network"] = {
  init: function () {
    this.jsonInit({
      "type": "activate_neural_network",
      "message0": "Activate Neural Network",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["activate_neural_network"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `tflearn.fully_connected(net, len(train_y[0]), activation='softmax')\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["neural_network_output"] = {
  init: function () {
    this.jsonInit({
      "type": "neural_network_output",
      "message0": "Neural Network Output",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["neural_network_output"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `tflearn.regression(net)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["improve_neural_network_accuracy"] = {
  init: function () {
    this.jsonInit({
      "type": "improve_neural_network_accuracy",
      "message0": "Improve Neural Network Accuracy",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["improve_neural_network_accuracy"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `tflearn.DNN(net, tensorboard_dir='tflearn_logs')\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["train_tensorflow_model"] = {
  init: function () {
    this.jsonInit({
      "message0": "Train Tensorflow Model  %1 item 1 %2 item 2 %3 item 3 %4 item 4 %5 item 5 %6",
      "args0": [
      {
        "type": "input_dummy"
      },
      {
        "type": "input_value",
        "name": "item1",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "item2",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "item3",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "item4",
        "align": "RIGHT",
      },
      {
        "type": "input_value",
        "name": "item5",
        "align": "RIGHT",
      },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

Blockly.Python["train_tensorflow_model"] = function (block) {
  var item1 = Blockly.Python.valueToCode(block, 'item1', Blockly.Python.ORDER_ATOMIC);
  var item2 = Blockly.Python.valueToCode(block, 'item2', Blockly.Python.ORDER_ATOMIC);
  var item3 = Blockly.Python.valueToCode(block, 'item3', Blockly.Python.ORDER_ATOMIC);
  var item4 = Blockly.Python.valueToCode(block, 'item4', Blockly.Python.ORDER_ATOMIC);
  var item5 = Blockly.Python.valueToCode(block, 'item5', Blockly.Python.ORDER_ATOMIC);
  var code = `model.fit(${item1}, ${item2}, n_epoch=${item3}, batch_size=${item4}, show_metric=${item5})\n`;
  return code;
};


Blockly.Blocks["save_tensorflow_model"] = {
  init: function () {
    this.jsonInit({
      "type": "save_tensorflow_model",
      "message0": "Save Tensorflow Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["save_tensorflow_model"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `model.save('model.tflearn')\n`;
  return code;
};

Blockly.Blocks["save_pickle_file"] = {
  init: function () {
    this.jsonInit({
      "type": "save_pickle_file",
      "message0": "Save Pickle File",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["save_pickle_file"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `pickle.dump( {'words':words, 'classes':classes, 'train_x':train_x, 'train_y':train_y}, open( "training_data", "wb" ) )\n`;
  return code;
};

Blockly.Blocks["load_pickle_file"] = {
  init: function () {
    this.jsonInit({
      "type": "load_pickle_file",
      "message0": "Load Pickle File",
      "output": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["load_pickle_file"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `pickle.load( open( "training_data", "rb" ) )\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["chatbot_functions"] = {
  init: function () {
    this.jsonInit({
      "type": "chatbot_functions",
      "message0": "Chatbot Functions",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["chatbot_functions"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def clean_up_sentence(sentence):\n`+
  `\t# It Tokenize or Break it into the constituents parts of Sentense.\n`+
  `\tsentence_words = nltk.word_tokenize(sentence)\n`+
  `\t# Stemming means to find the root of the word.\n`+
  `\tsentence_words = [stemmer.stem(word.lower()) for word in sentence_words]\n`+
  `\treturn sentence_words\n\n`+
  `# Return the Array of Bag of Words: True or False and 0 or 1 for each word of bag that exists in the Sentence\n`+
  `def bow(sentence, words, show_details=False):\n`+
  `\tsentence_words = clean_up_sentence(sentence)\n`+
  `\tbag = [0]*len(words)\n`+
  `\tfor s in sentence_words:\n`+
  `\t\tfor i,w in enumerate(words):\n`+
  `\t\t\tif w == s:\n`+
  `\t\t\t\tbag[i] = 1\n`+
  `\t\t\t\tif show_details:\n`+
  `\t\t\t\t\tprint ("found in bag: %s" % w)\n`+
  `\treturn(np.array(bag))\n\n\n`+
  `def classify(sentence):\n`+
  `\t# Prediction or To Get the Posibility or Probability from the Model\n`+
  `\tresults = model.predict([bow(sentence, words)])[0]\n`+
  `\t# Exclude those results which are Below Threshold\n`+
  `\tresults = [[i,r] for i,r in enumerate(results) if r>ERROR_THRESHOLD]\n`+
  `\t# Sorting is Done because heigher Confidence Answer comes first.\n`+
  `\tresults.sort(key=lambda x: x[1], reverse=True)\n`+
  `\treturn_list = []\n`+
  `\tfor r in results:\n`+
  `\t\treturn_list.append((classes[r[0]], r[1])) #Tuppl -> Intent and Probability\n`+
  `\treturn return_list\n\n`+
  `def response(sentence, userID='123', show_details=False):\n`+
  `\tresults = classify(sentence)\n`+
  `\t# That Means if Classification is Done then Find the Matching Tag.\n`+
  `\tif results:\n`+
  `\t\t# Long Loop to get the Result.\n`+
  `\t\twhile results:\n`+
  `\t\t\tfor i in intents['intents']:\n`+
  `\t\t\t\t# Tag Finding\n`+
  `\t\t\t\tif i['tag'] == results[0][0]:\n`+
  `\t\t\t\t\t# Random Response from High Order Probabilities\n`+
  `\t\t\t\t\treturn print(random.choice(i['responses']))\n\n`+
  `\t\t\tresults.pop(0)\n\n`;
  return code;
};

Blockly.Blocks["speech_recognition"] = {
  init: function () {
    this.jsonInit({
      "type": "speech_recognition",
      "message0": "Speech Recognition",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["speech_recognition"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `import speech_recognition as sr\n\n`+
  `with sr.Microphone() as source:\n`+
  `\tprint('Initialize Recognizer')\n`+
  `\trecognizer = sr.Recognizer()\n`+
  `\t# print("clearing background noise")\n`+
  `\trecognizer.adjust_for_ambient_noise(source)\n`+
  `\tprint("Recording....please speak something")\n`+
  `\trecordaudio = recognizer.listen(source, timeout=10)\n`+
  `\tprint("Recording Done")\n`+
  `\tprint("Convert to text")\n`+
  `\t# Using google to recognize audio\n`+
  `\tMyText = recognizer.recognize_google(recordaudio)\n`+
  `\tMyText = MyText.lower()\n`+
  `\tprint("Did you say ",MyText) # you can you MyText variable for text\n`+
  `\tinput_data = MyText\n`+
  `\tanswer = response(input_data)\n`+
  `\tanswer\n`;
  return code;
};

Blockly.Blocks["get_intents"] = {
  init: function () {
    this.jsonInit({
      "type": "get_intents",
      "message0": "Get intents",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#345874",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_intents"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `with open('intents.json') as json_data:\n`+
  `\tintents = json.load(json_data)\n`;
  return code;
};

Blockly.Blocks["function_get_distance"] = {
  init: function () {
    this.jsonInit({
      "type": "function_get_distance",
      "message0": "Function - Get Distance",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["function_get_distance"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def dist(pt1,pt2):\n` +
  `\treturn np.sqrt((pt1[0]-pt2[0])**2 + (pt1[1]-pt2[1])**2)\n`;
  return code;
};

Blockly.Blocks["frame_copy"] = {
  init: function () {
    this.jsonInit({
      "type": "frame_copy",
      "message0": "Frame Copy",
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["frame_copy"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `frame.copy()\n`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['resize_image_with'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items
    this.heightCount_ = 0; // Initial number of height items
    this.widthCount_ = 0; // Initial number of height items

    this.setColour('#487f8a');
    this.appendDummyInput().appendField('Resize Image with');
    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');

    this.setInputsInline(false);
    this.setOutput(true, 'Array');
    this.setTooltip('Resize Image with');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['resize_image_with_item', 'resize_image_with_width','resize_image_with_height']));
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    container.setAttribute('widthItems', this.widthCount_);
    container.setAttribute('heightItems', this.heightCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    const widthItems = parseInt(xmlElement.getAttribute('widthItems'), 10);
    const heightItems = parseInt(xmlElement.getAttribute('heightItems'), 10);
    this.updateShape_(items, widthItems, heightItems);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('resize_image_with_container');
    containerBlock.initSvg();
    function createBlocks(workspace, connection, blockType, count) {
      for (let i = 1; i <= count; i++) {
        const block = workspace.newBlock(blockType);
        block.initSvg();
        connection.connect(block.previousConnection);
        connection = block.nextConnection;
      }
    }
  
    let connection = containerBlock.getInput('ITEM_STACK').connection;
    createBlocks(workspace, connection, 'resize_image_with_item', this.itemCount_);
  
    connection = containerBlock.getInput('WIDTH_STACK').connection;
    createBlocks(workspace, connection, 'resize_image_with_width', this.widthCount_);
  
    connection = containerBlock.getInput('HEIGHT_STACK').connection;
    createBlocks(workspace, connection, 'resize_image_with_height', this.heightCount_);
  
    return containerBlock;
  },

  compose(containerBlock) {
    const connections = {
      item: [],
      width: [],
      height: []
    };
  
    ['ITEM', 'WIDTH', 'HEIGHT'].forEach((prefix) => {
      let block = containerBlock.getInputTargetBlock(`${prefix}_STACK`);
      while (block) {
        connections[prefix.toLowerCase()].push(block.valueConnection_); // connections[prefix.toLowerCase()].push(block.valueConnection_) == itemConnections.push(itemBlock.valueConnection_);
        block = block.nextConnection && block.nextConnection.targetBlock();
      }
    });
  
    this.updateShape_(connections.item.length, connections.width.length, connections.height.length);
  
    ['ITEM', 'WIDTH', 'HEIGHT'].forEach((prefix) => {
      for (let i = 1; i <= this[`${prefix.toLowerCase()}Count_`]; i++) { //${prefix.toLowerCase()}Count_ == (this.itemCount_ || this.heightCount_ || this.widthCount_)
        Blockly.Mutator.reconnect(connections[prefix.toLowerCase()][i - 1], this, `${prefix}${i}`); // connections[prefix.toLowerCase()] == itemConnections[i - 1]  && `${prefix}${i}` == ('ITEM' + i || 'HEIGHT' + i || 'WIDTH' + i)
      }
    });
  },
  
  updateShape_(itemCount, widthCount, heightCount) {
    ['ITEM', 'WIDTH', 'HEIGHT'].forEach((prefix) => {
      for (let i = 1; i <= this[`${prefix.toLowerCase()}Count_`]; i++) { // ${prefix.toLowerCase()}Count_ == (this.itemCount_ || this.heightCount_ || this.widthCount_)
        this.removeInput(`${prefix}${i}`); // {prefix}${i}` == ('ITEM' + i || 'HEIGHT' + i || 'WIDTH' + i)
      }
    });
  
    this.itemCount_ = itemCount;
    this.widthCount_ = widthCount;
    this.heightCount_ = heightCount;
  
    ['ITEM', 'WIDTH', 'HEIGHT'].forEach((prefix) => {
      for (let i = 1; i <= this[`${prefix.toLowerCase()}Count_`]; i++) {
        this.appendValueInput(`${prefix}${i}`).setCheck(null).appendField(`${prefix.toLowerCase()} ${i}`); // {prefix}${i}` == ('ITEM' + i || 'HEIGHT' + i || 'WIDTH' + i)  &&  `${prefix.toLowerCase()} ${i}` == ('item ' + i || 'width ' + i || 'height ' + i)
      }
    });
  }
};

Blockly.Python['resize_image_with'] = function (block) {
  const itemValues = getValuesFromInputs('ITEM', block.itemCount_);
  const widthValues = getValuesFromInputs('WIDTH', block.widthCount_);
  const heightValues = getValuesFromInputs('HEIGHT', block.heightCount_);
  function getValuesFromInputs(prefix, count) {
    const values = [];
    for (let i = 1; i <= count; i++) {
      const value = Blockly.Python.valueToCode(block, prefix + i, Blockly.Python.ORDER_NONE) || 'None';
      values.push(prefix === "ITEM" ? value : prefix.toLowerCase() + ' = ' + value);
    }
    return values;
  }
  
  let code = `imutils.resize(`;
  code += itemValues.length === 0 ? `${itemValues}` : `${itemValues.join(', ')}`;
  code += widthValues.length >= 1 ? ', ' + widthValues.join(', ') : '';
  code += heightValues.length >= 1 ? ', ' + heightValues.join(', ') : '';
  code += ')';

  return [code, Blockly.Python.ORDER_ATOMIC];
};

Blockly.Blocks['resize_image_with_container'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('Resize Image with');
    this.appendStatementInput('ITEM_STACK').setCheck(['resize_image_with_item', 'resize_image_with_width', 'resize_image_with_height']);
    this.appendStatementInput('WIDTH_STACK').appendField('width').setCheck('resize_image_with_width');
    this.appendStatementInput('HEIGHT_STACK').appendField('height').setCheck('resize_image_with_height');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['resize_image_with_item'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'resize_image_with_item');
    this.setNextStatement(true, 'resize_image_with_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};


Blockly.Blocks['resize_image_with_width'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('width');
    this.setPreviousStatement(true, 'resize_image_with_width');
    this.setNextStatement(true, 'resize_image_with_width');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['resize_image_with_height'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('height');
    this.setPreviousStatement(true, 'resize_image_with_height');
    this.setNextStatement(true, 'resize_image_with_height');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['blur_image_with'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items
    this.text_ = 'image'; // Initial text value

    this.setColour('#487f8a');
    this.appendDummyInput().appendField('Blur Image ').appendField(new Blockly.FieldTextInput(this.text_, this.handleTextChange.bind(this)), 'IMAGE_TEXT').appendField('with');

    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');

    this.setInputsInline(false);
    this.setOutput(true, 'Array');
    this.setTooltip('Blur a Image with the given elements');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['blur_image_item']));
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    container.setAttribute('text', this.text_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    const text = xmlElement.getAttribute('text');
    this.updateShape_(items, text);
  },
  handleTextChange(newText) {
    this.text_ = newText;
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('blur_image_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('blur_image_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount, text) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },

};

Blockly.Python['blur_image_with'] = function (block) {
  const textValue = block.getFieldValue('IMAGE_TEXT');
  const itemValues = [];
  for (let i = 1; i <= block.itemCount_; i++) {
    const itemValue = Blockly.Python.valueToCode(block, 'ITEM' + i, Blockly.Python.ORDER_NONE) || 'None';
    itemValues.push(itemValue);
  }
  let code = `cv2.GaussianBlur(`;
  code += itemValues.length === 0 ? `${textValue}` : `${textValue}, ` + itemValues.join(', ');
  code += ')';
  return [code, Blockly.Python.ORDER_ATOMIC];
};

Blockly.Blocks['blur_image_container'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('blur image');
    this.appendStatementInput('STACK');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['blur_image_item'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'blur_image_item');
    this.setNextStatement(true, 'blur_image_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};


Blockly.Blocks["change_image_color_scheme"] = {
  init: function () {
    this.jsonInit({
      "type": "change_image_color_scheme",
      "message0": "Change Image Color Scheme %1 %2",
      "args0": [
        {
          "type": "field_input",
          "name": "image",
          "text": "img"
        },
        {
          "type": "field_dropdown",
          "name": "data_item",
          "options": [
            ["HSV", "HSV"],
            ["GRAY", "GRAY"],
          ],
        },
      ],
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["change_image_color_scheme"] = function (block) {
  var img_path = block.getFieldValue('image');
  var dropdown_list = block.getFieldValue("data_item");
  //TODO: Assemble Python into code variable.
  var code = `cv2.cvtColor(${img_path}, cv2.COLOR_BGR2${dropdown_list})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};



Blockly.Blocks["assign_coordinates"] = {
  init: function () {
    this.jsonInit({
      "type": "assign_coordinates",
      "message0": "Assign Coordinates %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["assign_coordinates"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `np.random.randint${value}\n`;
  return [code, Blockly.Python.ORDER_ATOMIC];
};

Blockly.Blocks["get_coordinate"] = {
  init: function () {
    this.jsonInit({
      "type": "get_coordinate",
      "message0": "Get Coordinate",
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_coordinate"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `frame.shape[0]-30\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["specify_colour_range_for_with"] = {
  init: function () {
    this.jsonInit({
      "type": "specify_colour_range_for_with",
      "message0": "Specify Colour Range for %1 with %2 %3",
      "args0": [
        {
          "type": "input_value",
          "name": "range1",
        },
        {
          "type": "input_value",
          "name": "range2",
        },
        {
          "type": "input_value",
          "name": "range3",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["specify_colour_range_for_with"] = function (block) {
  var range1 = Blockly.Python.valueToCode(block, "range1", Blockly.Python.ORDER_ATOMIC);
  var range2 = Blockly.Python.valueToCode(block, "range2", Blockly.Python.ORDER_ATOMIC);
  var range3 = Blockly.Python.valueToCode(block, "range3", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `cv2.inRange(${range1},${range2},${range3})\n`;
  return [code, Blockly.Python.ORDER_ATOMIC];
};


Blockly.Blocks["erode_image_with"] = {
  init: function () {
    this.jsonInit({
      "type": "erode_image_with",
      "message0": "Erode Image %1 with %2 %3",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
        {
          "type": "input_value",
          "name": "value3",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["erode_image_with"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  var value3 = Blockly.Python.valueToCode(block, "value3", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `cv2.erode(${value1},${value2}, iterations = ${value3})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["dilate_image_with"] = {
  init: function () {
    this.jsonInit({
      "type": "dilate_image_with",
      "message0": "Dilate Image %1 with %2 %3",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
        {
          "type": "input_value",
          "name": "value3",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["dilate_image_with"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  var value3 = Blockly.Python.valueToCode(block, "value3", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `cv2.dilate(${value1},${value2}, iterations = ${value3})\n`;
  return [code, Blockly.Python.ORDER_ATOMIC];
};


Blockly.Blocks["find_contours_for"] = {
  init: function () {
    this.jsonInit({
      "type": "find_contours_for",
      "message0": "Find Contours for %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["find_contours_for"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `cv2.findContours(${value}, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)\n`;
  return [code, Blockly.Python.ORDER_ATOMIC];
};

Blockly.Blocks["get_contours_from"] = {
  init: function () {
    this.jsonInit({
      "type": "get_contours_from",
      "message0": "Get Contours from %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["get_contours_from"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `imutils.grab_contours(${value})\n`;
  return [code, Blockly.Python.ORDER_ATOMIC];
};


Blockly.Blocks["find_circle_on_contour"] = {
  init: function () {
    this.jsonInit({
      "type": "find_circle_on_contour",
      "message0": "Find Circle on Contour",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["find_circle_on_contour"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `ball_cont = max(cnts,key=cv2.contourArea)\n` +
  `(x,y),radius = cv2.minEnclosingCircle(ball_cont)\n`;
  return code;
};

Blockly.Blocks["find_moments"] = {
  init: function () {
    this.jsonInit({
      "type": "find_moments",
      "message0": "Find Moments",
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["find_moments"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `cv2.moments(ball_cont)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["find_centre_of_contours"] = {
  init: function () {
    this.jsonInit({
      "type": "find_centre_of_contours",
      "message0": "Find Centre of Contours",
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["find_centre_of_contours"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `(int(M['m10']/M['m00']),int(M['m01']/M['m00']))\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks['draw_circle_on_frame'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items

    this.setColour('#487f8a');
    this.setPreviousStatement(true); // Allow previous connection
    this.setNextStatement(true); // Allow next connection
    this.appendDummyInput().appendField('Draw Circle on Frame');

    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');

    this.setTooltip('Draw Circle on Frame');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['draw_circle_on_frame_item']));
    
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(items);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('draw_circle_on_frame_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('draw_circle_on_frame_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },

};

Blockly.Python['draw_circle_on_frame'] = function (block) {
  const itemValues = [];
  for (let i = 1; i <= block.itemCount_; i++) {
    const itemValue = Blockly.Python.valueToCode(block, 'ITEM' + i, Blockly.Python.ORDER_NONE) || 'None';
    itemValues.push(itemValue);
  }
  let code = 'cv2.circle(' + itemValues.join(', ');
  if (itemValues.length === 1) {
    code += ',';
  }
  code += ')\n';
  return code;
};

Blockly.Blocks['draw_circle_on_frame_container'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('Draw Circle on Frame');
    this.appendStatementInput('STACK');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['draw_circle_on_frame_item'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'draw_circle_on_frame_item');
    this.setNextStatement(true, 'draw_circle_on_frame_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks["find_distance"] = {
  init: function () {
    this.jsonInit({
      "type": "find_distance",
      "message0": "Find Distance %1 %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["find_distance"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `dist(${value1}, ${value2})`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["show_snakes_tail"] = {
  init: function () {
    this.jsonInit({
      "type": "show_snakes_tail",
      "message0": "Show Snake’s Tail",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["show_snakes_tail"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `for i in range(1,len(l)):\n` +
  `  if l[i-1] is None or l[i] is None:\n` +
  `    continue\n` +
  `  r,g,b = np.random.randint(0,255,3)\n` +
  `  cv2.line(frame,l[i],l[i-1],(int(r),int(g),int(b)), thickness = int(len(l)/max_lc+2)+2)\n`;
  return code;
};

Blockly.Blocks['write_on_frame_with'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items

    this.setColour('#487f8a');
    this.setPreviousStatement(true); // Allow previous connection
    this.setNextStatement(true); // Allow next connection
    this.appendDummyInput()
        .appendField('Write on Frame with')
        .appendField(new Blockly.FieldDropdown([
          ['simplex', 'SIMPLEX'],
          ['complex', 'COMPLEX'],
        ]), 'dropdown');
    this.appendDummyInput();
    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');
    this.appendValueInput('PARAM1').setCheck(null).appendField('Param 1');
    this.appendValueInput('PARAM2').setCheck(null).appendField('Param 2');
    this.appendValueInput('PARAM3').setCheck(null).appendField('Param 3');
    this.setTooltip('Write on Frame with');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['write_on_frame_with_item']));
    
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(items);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('write_on_frame_with_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('write_on_frame_with_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },

};

Blockly.Python['write_on_frame_with'] = function (block) {
  const dropdownValue = block.getFieldValue('dropdown');
  const Param1 = Blockly.Python.valueToCode(block, 'PARAM1', Blockly.Python.ORDER_NONE) || 'None';
  const Param2 = Blockly.Python.valueToCode(block, 'PARAM2', Blockly.Python.ORDER_NONE) || 'None';
  const Param3 = Blockly.Python.valueToCode(block, 'PARAM3', Blockly.Python.ORDER_NONE) || 'None';

  const itemValues = [];
  for (let i = 1; i <= block.itemCount_; i++) {
    const itemValue = Blockly.Python.valueToCode(block, 'ITEM' + i, Blockly.Python.ORDER_NONE) || 'None';
    itemValues.push(itemValue);
  }
  let code = `cv2.putText(${Param1}, ${Param2}, ${Param3}, cv2.FONT_HERSHEY_${dropdownValue}`;
  code += (itemValues.length == 0) ? `` : `, `;
  code += itemValues.join(', ') + ')\n';
  return code;
};

Blockly.Blocks['write_on_frame_with_container'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('Write on Frame with');
    this.appendStatementInput('STACK');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['write_on_frame_with_item'] = {
  init() {
    this.setColour('#487f8a');
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'write_on_frame_with_item');
    this.setNextStatement(true, 'write_on_frame_with_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks["live_feed"] = {
  init: function () {
    this.jsonInit({
      "type": "live_feed",
      "message0": "Live Feed",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["live_feed"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `cv2.imshow('live feed',frame)\n`;
  return code;
};

Blockly.Blocks["keystroke"] = {
  init: function () {
    this.jsonInit({
      "type": "keystroke",
      "message0": "Keystroke",
      "output": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["keystroke"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `cv2.waitKey(0)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["function_find_winner"] = {
  init: function () {
    this.jsonInit({
      "type": "function_find_winner",
      "message0": "Function – Find Winner",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["function_find_winner"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def findout_winner(user_move, Computer_move):\n` +
  `\tif user_move == Computer_move:\n` +
  `\t\treturn "Tie"\n` +
  `\telif user_move == "rock" and Computer_move == "scissor":\n` +
  `\t\treturn "User"\n` +
  `\telif user_move == "rock" and Computer_move == "paper":\n` +
  `\t\treturn "Computer"\n` +
  `\telif user_move == "scissor" and Computer_move == "rock":\n` +
  `\t\treturn "Computer"\n` +
  `\telif user_move == "scissor" and Computer_move == "paper":\n` +
  `\t\treturn "User"\n` +
  `\telif user_move == "paper" and Computer_move == "rock":\n` +
  `\t\treturn "User"\n` +
  `\telif user_move == "paper" and Computer_move == "scissor":\n` +
  `\t\treturn "Computer"\n`;
  return code;
};

Blockly.Blocks["function_show_winner"] = {
  init: function () {
    this.jsonInit({
      "type": "function_show_winner",
      "message0": "Function – Show Winner",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["function_show_winner"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def show_winner(user_score, computer_score):\n` +
  `\tif user_score > computer_score:\n` +
  `\t\timg = cv2.imread("images/youwin.jpg")\n` +
  `\telif user_score < computer_score:\n` +
  `\t\timg = cv2.imread("images/comwins.jpg")\n` +
  `\telse:\n` +
  `\t\timg = cv2.imread("images/draw.jpg")\n\n` +
  `\tcv2.putText(img, "Press 'ENTER' to play again, else exit",\n` +
  `\t\t\t\t(150, 530), cv2.FONT_HERSHEY_COMPLEX, 1, (0, 0, 255), 3, cv2.LINE_AA)\n\n` +
  `\tcv2.imshow("Rock Paper Scissors", img)\n\n` +
  `\t# If enter is pressed.\n` +
  `\tk = cv2.waitKey(0)\n\n` +
  `\t# If the user presses 'ENTER' key then return TRUE, otherwise FALSE\n` +
  `\tif k == 13:\n` +
  `\t\t return True\n` +
  `\telse:\n` +
  `\t\treturn False\n`;
  return code;
};

Blockly.Blocks["function_show_computer_move"] = {
  init: function () {
    this.jsonInit({
      "type": "function_show_computer_move",
      "message0": "Function – Show Computer Move",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["function_show_computer_move"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def display_computer_move(computer_move_name, frame):\n` +
  `\ticon = cv2.imread( "images/{}.png".format(computer_move_name), 1)\n` +
  `\ticon = cv2.resize(icon, (224,224))\n\n` +
  `\t# This is the portion which we are going to replace with the icon image\n` +
  `\troi = frame[0:224, 0:224]\n\n` +
  `\t# Get binary mask from the transparent image, 4th channel is the alpha channel \n` +
  `\tmask = icon[:,:,-1]\n\n` +
  `\t# Making the mask completely binary (black & white)\n` +
  `\tmask = cv2.threshold(mask, 1, 255, cv2.THRESH_BINARY)[1]\n\n` +
  `\t# Store the normal bgr image\n` +
  `\ticon_bgr = icon[:,:,:3]\n\n` +
  `\t# Now combine the foreground of the icon with background of ROI\n` +
  `\timg1_bg = cv2.bitwise_and(roi, roi, mask = cv2.bitwise_not(mask))\n` +
  `\timg2_fg = cv2.bitwise_and(icon_bgr, icon_bgr, mask = mask)\n` +
  `\tcombined = cv2.add(img1_bg, img2_fg)\n` +
  `\tframe[0:224, 0:224] = combined\n` +
  `\treturn frame\n`;
  return code;
};

Blockly.Blocks["get_box_width"] = {
  init: function () {
    this.jsonInit({
      "type": "get_box_width",
      "message0": "Get Box Width",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_box_width"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `int(cap.get(3))\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["create_double_ended_queue"] = {
  init: function () {
    this.jsonInit({
      "type": "create_double_ended_queue",
      "message0": "Create Double Ended Queue",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["create_double_ended_queue"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `deque(['nothing'] * 5, maxlen=smooth_factor)\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["flip_image"] = {
  init: function () {
    this.jsonInit({
      "type": "flip_image",
      "message0": "Flip Image %1 %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["flip_image"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC) || 'None';
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC) || 'None';
  console.log([code, Blockly.Python.ORDER_NONE])
  // TODO: Assemble Python into code variable.
  var code = `cv2.flip(${value1}, ${value2})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["create_named_window"] = {
  init: function () {
    this.jsonInit({
      "type": "create_named_window",
      "message0": "Create Named Window %1 %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
        {
          "type": "field_dropdown",
          "name": "create named window",
          "options": [
            ["Normal", "NORMAL"],
            ["Autosize", "AUTOSIZE"],
            ["Full Screen", "FULLSCREEN"],
          ]
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": '#034f80',
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python['create_named_window'] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC) || 'None';
  var option_value = block.getFieldValue('create named window');
  // TODO: Assemble Python into code variable.
  var code = `cv2.namedWindow(${value}, cv2.WINDOW_${option_value})\n`;
  return code;
};

Blockly.Blocks["extract_region"] = {
  init: function () {
    this.jsonInit({
      "type": "extract_region",
      "message0": "Extract Region",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["extract_region"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `frame[5: box_size-5 , width-box_size + 5: width -5]\n`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["convert_array"] = {
  init: function () {
    this.jsonInit({
      "type": "convert_array",
      "message0": "Convert Array",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["convert_array"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `np.array([roi]).astype('float64') / 255.0\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["make_prediction"] = {
  init: function () {
    this.jsonInit({
      "type": "make_prediction",
      "message0": "Make Prediction %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["make_prediction"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC) || 'None';
  // TODO: Assemble Python into code variable.
  var code = `model.predict(${value})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_class_index"] = {
  init: function () {
    this.jsonInit({
      "type": "get_class_index",
      "message0": "Get Class Index %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["get_class_index"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC) || 'None[None]';
  // TODO: Assemble Python into code variable.
  var code = `np.argmax(${value})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["find_class_confidence"] = {
  init: function () {
    this.jsonInit({
      "type": "find_class_confidence",
      "message0": "Find Class Confidence",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["find_class_confidence"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `np.max(pred[0])\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["queue_operation"] = {
  init: function () {
    this.jsonInit({
      "type": "queue_operation",
      "message0": "Queue Operation %1 %2",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "queue operation",
          "options": [
            ["Add to Queue", "append"],
            ["Add to Queue from left", "appendleft"],
            ["Delete from Queue", "pop"],
            ["Delete from Queue from left", "popleft"],
            ["Get Count in Queue", "count"],
            ["Remove from Queue", "remove"],
          ]
        },
        {
          "type": "input_value",
          "name": "value",
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": '#034f80',
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python['queue_operation'] = function (block) {
  var option_value = block.getFieldValue('queue operation');
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC) || 'None';
  // TODO: Assemble Python into code variable.
  var code = `de.${option_value}(${value})\n`;
  return code;
};

Blockly.Blocks["get_most_frequent"] = {
  init: function () {
    this.jsonInit({
      "type": "get_most_frequent",
      "message0": "Get Most Frequent",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_most_frequent"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `st.mode(de)[0][0]\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_computer_move"] = {
  init: function () {
    this.jsonInit({
      "type": "get_computer_move",
      "message0": "Get Computer Move",
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_computer_move"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `choice(['rock', 'paper', 'scissor'])\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["find_winner"] = {
  init: function () {
    this.jsonInit({
      "type": "find_winner",
      "message0": "Find Winner %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["find_winner"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  let code = (value == null) ? `findout_winner\n` : `findout_winner${value}\n`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["show_computer_move"] = {
  init: function () {
    this.jsonInit({
      "type": "show_computer_move",
      "message0": "Show Computer Move %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["show_computer_move"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  let code = (value == null) ? `display_computer_move\n` : `display_computer_move${value}\n`;
  return code;
};

Blockly.Blocks["show_winner"] = {
  init: function () {
    this.jsonInit({
      "type": "show_winner",
      "message0": "Show Winner %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["show_winner"] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  let code = (value == null) ? `show_winner\n` : `show_winner${value}\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["line_type"] = {
  init: function () {
    this.jsonInit({
      "type": "line_type",
      "message0": "Line Type %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "option",
          "options": [
            ["Line 4", "4"],
            ["Line AA", "AA"],
            [" Line 8", "8"],
          ],
        },
      ],
      "output": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["line_type"] = function (block) {
  var option_value = block.getFieldValue("option");
  //TODO: Assemble Python into code variable.
  var code = `cv2.LINE_${option_value}`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks['draw_rectangle_on_frame_with'] = {
  init() {
    this.itemCount_ = 1; // Initial number of items

    this.setColour('#034f80');
    this.setPreviousStatement(true); // Allow previous connection
    this.setNextStatement(true); // Allow next connection
    this.appendDummyInput().appendField('Draw Rectangle on Frame with');

    this.appendValueInput('ITEM1').setCheck(null).appendField('item 1');

    this.setTooltip('Draw Rectangle on Frame with');
    this.setHelpUrl('');

    // Mutator configuration
    this.setMutator(new Blockly.Mutator(['draw_rectangle_on_frame_with_item']));
    
  },

  mutationToDom() {
    const container = Blockly.utils.xml.createElement('mutation');
    container.setAttribute('items', this.itemCount_);
    return container;
  },

  domToMutation(xmlElement) {
    const items = parseInt(xmlElement.getAttribute('items'), 10);
    this.updateShape_(items);
  },

  decompose(workspace) {
    const containerBlock = workspace.newBlock('draw_rectangle_on_frame_with_container');
    containerBlock.initSvg();
    let connection = containerBlock.getInput('STACK').connection;

    for (let i = 1; i <= this.itemCount_; i++) {
      const itemBlock = workspace.newBlock('draw_rectangle_on_frame_with_item');
      itemBlock.initSvg();
      connection.connect(itemBlock.previousConnection);
      connection = itemBlock.nextConnection;
    }

    return containerBlock;
  },

  compose(containerBlock) {
    let itemBlock = containerBlock.getInputTargetBlock('STACK');
    const connections = [];

    while (itemBlock) {
      connections.push(itemBlock.valueConnection_);
      itemBlock = itemBlock.nextConnection && itemBlock.nextConnection.targetBlock();
    }

    this.updateShape_(connections.length);

    for (let i = 1; i <= this.itemCount_; i++) {
      Blockly.Mutator.reconnect(connections[i - 1], this, 'ITEM' + i);
    }
  },

  updateShape_(itemCount) {
    // Add or remove inputs based on the item count
    for (let i = 1; i <= this.itemCount_; i++) {
      this.removeInput('ITEM' + i);
    }

    this.itemCount_ = itemCount;

    for (let i = 1; i <= this.itemCount_; i++) {
      this.appendValueInput('ITEM' + i).setCheck(null).appendField('item ' + i);
    }
  },

};

Blockly.Python['draw_rectangle_on_frame_with'] = function (block) {
  const itemValues = [];
  for (let i = 1; i <= block.itemCount_; i++) {
    const itemValue = Blockly.Python.valueToCode(block, 'ITEM' + i, Blockly.Python.ORDER_NONE) || 'None';
    itemValues.push(itemValue);
  }
  let code = 'cv2.rectangle(' + itemValues.join(', ');
  if (itemValues.length === 1) {
    code += ',';
  }
  code += ')\n';
  return code;
};

Blockly.Blocks['draw_rectangle_on_frame_with_container'] = {
  init() {
    this.setColour('#034f80');
    this.appendDummyInput().appendField('Draw Rectangle on Frame');
    this.appendStatementInput('STACK');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks['draw_rectangle_on_frame_with_item'] = {
  init() {
    this.setColour('#034f80');
    this.appendDummyInput().appendField('item');
    this.setPreviousStatement(true, 'draw_rectangle_on_frame_with_item');
    this.setNextStatement(true, 'draw_rectangle_on_frame_with_item');
    this.setTooltip('');
    this.contextMenu = false;
  },
};

Blockly.Blocks["live_image"] = {
  init: function () {
    this.jsonInit({
      "type": "live_image",
      "message0": "Live Image %1 %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#034f80",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["live_image"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC) || 'None';
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC) || 'None';
  // TODO: Assemble Python into code variable.
  var code = `cv2.imshow(${value1}, ${value2})\n`;
  return code;
};

Blockly.Blocks["new_function_get_distance"] = {
  init: function () {
    this.jsonInit({
      "type": "new_function_get_distance",
      "message0": "New Function - Get Distance",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#487f8a",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["new_function_get_distance"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `def dist(pt1,pt2):\n` +
  `\tif pt1 is None or pt2 is None:\n` +
  `\t\treturn 0\n` +
  `\treturn np.sqrt((pt1[0]-pt2[0])**2 + (pt1[1]-pt2[1])**2)\n`
  return code;
};

Blockly.Blocks["setup_camera"] = {
  init: function () {
    var options = [...Array(4)].map((_, i) => [String(i), String(i)]);
    this.jsonInit({
      "type": "setup_camera",
      "message0": "Setup Camera At Port %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "port_value",
          "options": options
        }
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#C13C71",
      "tooltip": "",
      "helpUrl": "",
    });
  },
};
  
Blockly.Python['setup_camera'] = function (block) {
  var option_value = block.getFieldValue('port_value');
  // TODO: Assemble Python into code variable.
  var code = `frame_cap = cv2.VideoCapture(${option_value})\n`;
  return code;
};

Blockly.Blocks['camera_color_identification_function'] = {
  init: function () {
    this.jsonInit({
      "type": "camera_color_identification_function",
      "message0": "Camera Color Identification Function",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#C13C71",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['camera_color_identification_function'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `def detect_color(lower, upper, min_area_threshold):\n`+`\thsv_frame = cv2.cvtColor(captured_frame, cv2.COLOR_BGR2HSV)\n`+`\tred_mask = cv2.inRange(hsv_frame, lower, upper)\n`+`\tred_contours, _ = cv2.findContours(red_mask, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)\n`+`\tfor contour in red_contours:\n`+`\t\tarea = cv2.contourArea(contour)\n`+`\t\tif area > min_area_threshold:\n`+`\t\t\treturn True\n`+`\treturn False\n`;
  return code;
};

Blockly.Blocks['read_camera_frames'] = {
  init: function () {
    this.jsonInit({
      "type": "read_camera_frames",
      "message0": "Read Camera Frames",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#C13C71",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['read_camera_frames'] = function (block) {
  // TODO: Assemble JavaScript into code variable.
  var code = `frame_ret, captured_frame = frame_cap.read()\n`+`if not frame_ret:\n`+`    break\n`;
  return code;
};

Blockly.Blocks['check_camera_for_color'] = {
  init: function () {
    this.appendDummyInput()
        .appendField('Check Camera for Color')
    var fields = [
      { label: 'Lower Value', start: 0, end: 2 },
      { label: 'Upper Value', start: 3, end: 5 }
    ];
    
    for (let field of fields) {
      var input = this.appendDummyInput().appendField('    ' + field.label);
      for (let i = field.start; i <= field.end; i++) {
        input.appendField(new Blockly.FieldNumber(0), 'value' + i);
      }
    }
        
    this.appendDummyInput().appendField('    Detection Area').appendField(new Blockly.FieldNumber(0), 'value6');
    
    this.setOutput(true);
    this.setColour('#C13C71');
    this.setTooltip('');
    this.setHelpUrl('');
  }
};


Blockly.Python['check_camera_for_color'] = function (block) {
  const values = Array.from({ length: 7 }, (_, i) => block.getFieldValue("value" + i));
  const formatColor = (start, end) => values.slice(start, end).map((value, index, self) => `${value}${index < self.length - 1 ? ',' : ''}`).join(' ');
  const colors = [formatColor(0, 3), formatColor(3, 6)].map(color => `np.array([${color}])`);
  // TODO: Assemble Python into code variable.
  var code = `detect_color(${colors[0]}, ${colors[1]}, ${values[6]})`;
  return [code, Blockly.Python.ORDER_NONE];
}

Blockly.Blocks["check_camera_for_color_dropdown"] = {
  init: function () {
    this.jsonInit({
      "type": "check_camera_for_color_dropdown",
      "message0": "Check Camera for Color %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "color_value",
          "options": [
            ["Red", "red"],
            ["Green", "green"],
            ["Yellow", "yellow"],
          ]
        }
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#C13C71",
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python['check_camera_for_color_dropdown'] = function (block) {
  var colorValues = {
    "red": "([0, 100, 100]), np.array([10, 255, 255])",
    "green": "([33, 74, 170]), np.array([84, 118, 255])",
    "yellow": "([20, 100, 100]), np.array([40, 255, 255])"
  };
  var option_value = block.getFieldValue('color_value');
  // TODO: Assemble Python into code variable.
  var code = `detect_color(np.array${colorValues[option_value]}, 1000)`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["release_camera"] = {
  init: function () {
    this.jsonInit({
      "type": "release_camera",
      "message0": "Release Camera",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#C13C71",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["release_camera"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `frame_cap.release()\n`;
  return code;
};

/**
 * Blockly block for using a trained model in the AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] module  i.e., aiot.xml .
 * 
 * This block allows users to select from various trained models to use in their AIOT [Artificial Intelligence (AI) + Internet of Things  (IoT)] applications. 
 * 
 * The corresponding Python code generation will handle specific logic for each model type, 
 * enabling the integration of machine learning predictions in the AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] system.
 */
Blockly.Blocks["use_trained_model"] = {
  init: function () {
    this.jsonInit({
      "type": "use_trained_model",
      "message0": "Use Trained Model %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "import",
        "options": [
          ["Drowsiness Detection", "drowsiness_detection"],
          ["Face Identification", "face_identification"],
          ["Plant Health", "plant_health"],
          ["Mask-Only Access", "mask_only_access"],
          ["Waste Management", "waste_management"],
          ["Weather Prediction", "weather_prediction"],
        ]
      }],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

/**
 * Generates Python code for various AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] models based on selected options.
 * The code is generated as a string with escape characters for indentation.
 * 
 * **NOTE:** When replacing tab characters or using escape sequences for indentation or line continuation,
 * ensure that the escape characters [for e.g., *backslashes* (`\\`), *tab* (`\t`), *new line* (`\n`)] are correctly placed. 
 * Misplacement of escape characters can result in literal tabs or misinterpreted backslashes in the generated code, 
 * leading to errors during execution. Always verify the generated code to ensure correct formatting and indentation.
 *
 * @param {Blockly.Block} block - The block that represents the selected AIOT model.
 * @returns {string} The generated Python code as a string.
 */
Blockly.Python["use_trained_model"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "plant_health":
      code = `import numpy as np\n`+`import base64, cv2\n\n`+`result = None\n`+`link = "https://susya.onrender.com"\n`+`path = "/home/pi/Desktop/Grok-Downloads/image.jpg"\n\n`+`def jpg_to_base64(file_path):\n`+`\twith open(file_path, "rb") as image_file:\n`+`\t\tencoded_image = base64.b64encode(image_file.read())\n`+`\t\tbase64_string = encoded_image.decode("utf-8")\n`+`\t\treturn base64_string\n\n`+`def getPredictionsFromModel():\n`+`\t#Api call for the model that exist in cloud\n`+`\timgstr = jpg_to_base64(path)\n`+`\tresponse = requests.post(link,json = {"image":imgstr})\n`+`\tglobal result\n`+`\tresult = json.loads(response.text.strip())\n\n`+`def getResults():\n`+`\treturn result\n\n`+`def getDiseaseName():\n`+`\treturn result.get("disease")\n\n`+`def getRemedyName():\n`+`\treturn result.get("remedy")\n\n`;
      break;
    case "face_identification":
      code = `import imutils\n`+`import face_recognition\n`+`import numpy as np\n`+`from itertools import compress\n`+`import pickle\n\n`+`result = None\n\n`+`def getPredictionsFromModel():\n`+`\tglobal result\n`+`\tglobal currentname\n`+`\tall_face_encodings = None\n`+`\twith open('/home/pi/Desktop/Grok-Downloads/encodings.pickle', 'rb') as f:\n`+`\t\tall_face_encodings = pickle.load(f)\n`+`\t# Grab the list of names and the list of encodings\n`+`\tface_names = all_face_encodings["names"]\n\n`+`\tface_encodings = np.array(all_face_encodings["encodings"])\n\n`+`\t# Grab a face from the image and generate encoding for the same\n`+`\tunknown_image = face_recognition.load_image_file("/home/pi/Desktop/Grok-Downloads/image.jpg")\n`+`\tunknown_image = imutils.resize(unknown_image,width=500)\n`+`\tboxes = face_recognition.face_locations(unknown_image)\n`+`\tif boxes:\n`+`\t\tunknown_face_encoding = face_recognition.face_encodings(unknown_image, boxes)\n`+`\t\t# Try comparing the unknown image and get a list of known names\n`+`\t\tmatches = face_recognition.compare_faces(face_encodings, unknown_face_encoding)\n`+`\t\tnames = list(compress(face_names, matches))\n`+`\t\tif names == []: names = ['Unknown']\n`+`\t\tresult = names\n`+`\telse:\n`+`\t\tresult = None\n\n`+`def getResults():\n`+`\treturn result\n`;
      break;
    case "weather_prediction":
      code = `import numpy as np\nimport os\nos.environ['TF_CPP_MIN_LOG_LEVEL'] = '3'\nimport tflite_runtime.interpreter as tf\n\n#Variables\nresult = None\nweatherParameter = None\nData = None\nModel = None\n\ndef loadDataForPrediction(data:list):\n    global Data\n    global Model\n    Model = tf.Interpreter(model_path=f'/home/pi/Desktop/Grok-Downloads/{weatherParameter}.tflite')\n    Model.allocate_tensors()\n    input_details = Model.get_input_details()\n    dtype =  input_details[0]['dtype']\n    Shape = input_details[0]['shape']\n    dataNP = (np.array(data,dtype=dtype)).reshape(Shape)\n    if data[0]<100 and weatherParameter== 'temperature':\n        dataNP+=273.15 #convert to Kelvin scale.\n    Data = dataNP\n    print(Data)\n    return None\n\ndef getPredictionsFromModel():\n    global result\n    interpreter = tf.Interpreter(model_path=f'/home/pi/Desktop/Grok-Downloads/{weatherParameter}.tflite')\n    interpreter.allocate_tensors()\n\n    input_details = interpreter.get_input_details()\n    output_details = interpreter.get_output_details()\n\n    interpreter.set_tensor(input_details[0]['index'], Data)\n    interpreter.invoke()\n    tflite_results = interpreter.get_tensor(output_details[0]['index'])\n    if isinstance(tflite_results, (np.ndarray, np.generic) ):\n        result = tflite_results.flatten().tolist()\n    else:\n        result = tflite_results\n\ndef getResults():\n  return result\n\n`;
      break;
    case "waste_management":
      code = `import cv2\nimport numpy as np\nimport tflite_runtime.interpreter as tflite\n\n`+
        `result = None\n\n`+
        `def getPredictionsFromModel():\n    model_path = "/home/pi/Desktop/Grok-Downloads/wastemanagement.tflite"\n    interpreter = tflite.Interpreter(model_path=model_path)\n    interpreter.allocate_tensors()\n\n    categories = ["Biodegradable", "NonBiodegradable", "No Object Found"]\n    input_details = interpreter.get_input_details()\n    \n    \n    dtype =  input_details[0]['dtype']\n    Shape = input_details[0]['shape']\n    frame = cv2.imread("/home/pi/Desktop/Grok-Downloads/image.jpg") \n    image = cv2.resize(frame, (224, 224))\n    image = image.reshape(Shape)\n    image = image.astype(dtype)\n    image = image / 255.0\n\n    input_tensor_index = interpreter.get_input_details()[0]['index']\n    interpreter.set_tensor(input_tensor_index, image)\n    interpreter.invoke()\n\n    output_tensor_index = interpreter.get_output_details()[0]['index']\n    prediction = interpreter.get_tensor(output_tensor_index)[0]\n\n    class_idx = np.argmax(prediction)\n    class_label = categories[class_idx]\n    confidence = prediction[class_idx] * 100\n\n    return class_label, confidence\n\n`+
        `def getResults():\n    class_label, confidence = getPredictionsFromModel()\n    print(f"Class: {class_label}, Confidence: {confidence:.2f}%")\n    return class_label, confidence\n\n`;
      break;
    case "drowsiness_detection":
      code = `import cv2\nimport numpy as np\nimport dlib\nfrom imutils import face_utils\nimport time\nimport os\n\n`+
      `face_image = None\ndef compute(ptA,ptB):\n    dist = np.linalg.norm(ptA - ptB)\n    return dist\n\n`+
      `def blinked(a,b,c,d,e,f):\n    up = compute(b,d) + compute(c,e)\n    down = compute(a,f)\n    ratio = up/(2.0*down)\n    if(ratio > 0.25):\n        return 2\n    elif(ratio > 0.21 and ratio <= 0.25):\n        return 1\n    else:\n        return 0\n\n`+
      `def getPredictionsFromModel():\n    global left_blink\n    global right_blink\n    detector = dlib.get_frontal_face_detector()\n    predictor = dlib.shape_predictor("/home/pi/Desktop/Grok-Downloads/shape_predictor_68_face_landmarks.dat")\n    gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)\n    faces = detector(gray)\n    for face in faces:\n        x1 = face.left()\n        y1 = face.top()\n        x2 = face.right()\n        y2 = face.bottom()\n\n        face_image = image.copy()\n\n        landmarks = predictor(gray, face)\n        landmarks = face_utils.shape_to_np(landmarks)\n\n        left_blink = blinked(landmarks[36],landmarks[37], landmarks[38], landmarks[41], landmarks[40], landmarks[39])\n        right_blink = blinked(landmarks[42],landmarks[43], landmarks[44], landmarks[47], landmarks[46], landmarks[45])\n\n`+
      `def getResults():\n    return left_blink, right_blink\n\n`;
      break;
    case "mask_only_access":
      code = `import tflite_runtime.interpreter as tflite\nimport cv2\nimport numpy as np\n\n`+
      `path = '/home/pi/Desktop/Grok-Downloads/mask_detector.tflite'\nface_cascade_path = '/home/pi/Desktop/Grok-Downloads/haarcascade_frontalface_default.xml'  # Path to Haar Cascade XML file\ncam_port = 0\n\n`+
      `type_list = ['got mask', 'no mask']\nWIDTH = 640\nHEIGHT = 480\n\n`+
      `interpreter = tflite.Interpreter(model_path=path)\ninterpreter.allocate_tensors()\n\n`+
      `face_cascade = cv2.CascadeClassifier(face_cascade_path)\n\n`+
      `def imread(img_path, shape):\n    img = cv2.imread(img_path)\n    if img is not None:\n        img_ = cv2.cvtColor(img, cv2.COLOR_BGR2RGB)\n        img_ = cv2.resize(img_, (shape, shape))\n        img_ = (img_ * 2 / 255) - 1\n        img_ = img_[np.newaxis, :, :, :].astype('float32')\n        return img_\n\n`+
      `def getPredictionsFromModel():\n    global result\n    gray = cv2.cvtColor(image, cv2.COLOR_BGR2GRAY)\n    faces = face_cascade.detectMultiScale(gray, scaleFactor=1.3, minNeighbors=5)\n\n    for (x, y, w, h) in faces:\n        cv2.rectangle(image, (x, y), (x+w, y+h), (255, 0, 0), 2)\n        roi_gray = gray[y:y+h, x:x+w]\n        roi_color = image[y:y+h, x:x+w]\n\n        cv2.imwrite("/home/pi/Desktop/Grok-Downloads/image.jpg", roi_color)\n\n    input_details = interpreter.get_input_details()\n    output_details = interpreter.get_output_details()\n\n    image_path = "/home/pi/Desktop/Grok-Downloads/image.jpg"\n    shapes = input_details[0]['shape'][1]\n    nparray = imread(image_path, shapes)\n\n    interpreter.set_tensor(input_details[0]['index'], nparray)\n    interpreter.invoke()\n\n    boxes = interpreter.get_tensor(output_details[0]['index'])\n    lx = boxes.flatten()\n    sumx = lx[0] + lx[1]\n    print('sum of probabilities ', sumx)\n\n    print(lx)\n    if lx[0] > lx[1]:\n        print("I see a mask")\n    else:\n        print("Why no mask?")\n    result = lx.tolist()\n\n`+
      `def getResults():\n    return result\n\n`;
      break;
  }
  return code;
};


/**
 * Blockly block for loading a model for training in the AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] module  i.e., aiot.xml .
 * 
 * This block allows users to select from various models that can be loaded for training in AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] applications. 
 * The selected model will be prepared for the training process, enabling users to train the model with 
 * their custom data or fine-tune it for specific use cases within the AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] system.
 */
Blockly.Blocks["load_model_for_training"] = {
  init: function () {
    this.jsonInit({
      "type": "load_model_for_training",
      "message0": "Load Model for Training %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "import",
        "options": [
          ["Face Identification", "face_identification"],
          ["Mask-Only Access", "mask_only_access"],
          ["Waste Management", "waste_management"],
          ["Weather Prediction", "weather_prediction"],
        ]
      }],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

/**
 * Generates Python code for various AIOT [Artificial Intelligence (AI) + Internet of Things (IoT)] models based on selected options.
 * The code is generated as a string with escape characters for indentation.
 * 
 * **NOTE:** When replacing tab characters or using escape sequences for indentation or line continuation,
 * ensure that the escape characters [for e.g., *backslashes* (`\\`), *tab* (`\t`), *new line* (`\n`)] are correctly placed. 
 * Misplacement of escape characters can result in literal tabs or misinterpreted backslashes in the generated code, 
 * leading to errors during execution. Always verify the generated code to ensure correct formatting and indentation.
 *
 * @param {Blockly.Block} block - The block that represents the selected AIOT model.
 * @returns {string} The generated Python code as a string.
 */
Blockly.Python["load_model_for_training"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "face_identification":
      code = `import cv2, glob\n`+`from imutils import paths\n`+`import face_recognition\n`+`import pickle\n`+`import os\n`+`import time\n\n`+
      `wdir = '/home/pi/Desktop/Grok-Downloads/dataset/face/'\n\n`+`cam_port = None\n\n`+
      `for camera in glob.glob("/dev/video?"):\n`+`\tcam = None\n`+`\ttry:\n`+`\t\tcam = cv2.VideoCapture(camera)\n`+`\texcept:\n`+`\t\tpass\n`+`\tif cam is not None:\n`+`\t\tcam_port = camera\n`+`\t\tprint("echo",camera)\n`+`\t\tcam.release()\n\n`+
      `if cam_port is None:\n`+`\tprint("No Camera Attached")\n\n`+
      `#############################\n`+`# Check if the "dataset" folder exists, create it if not\n`+`def buildDataSetPaths(Names:list):\n`+`\tif os.path.isdir(wdir):\n`+`\t\tos.system("rm -r "+wdir)\n`+`\tos.system("mkdir -p "+wdir)\n`+`\tfor name in Names:\n`+`\t\tdataset_folder = wdir + name\n`+`\t\tos.makedirs(dataset_folder)\n\n`+
      `#take Single Picture:\n`+`def takePicture(name:str,num:int):\n`+`\tcam = None\n`+`\ttry:\n`+`\t\tcam = cv2.VideoCapture(cam_port)\n`+`\texcept Exception as e:\n`+`\t\tprint(e)\n`+`\tresult, image = cam.read()\n`+`\tif result:\n`+`\t\tboxes = face_recognition.face_locations(image)\n`+`\t\tif boxes:\n`+`\t\t\tcv2.imwrite(wdir+name+f'/{name}_{num}.jpg', image)\n`+`\t\telse:\n`+`\t\t\tprint('Failed to capture a face')\n`+`\telse:\n`+`\t\tprint("Camera Error: failed to capture image.")\n`+`\tcam.release()\n`+`\ttime.sleep(DELAY)\n\n`+
      `#Take pictures in Sequence\n`+`def ClickPictures(Names:list):\n`+`\tnum = SAMPLES\n`+`\tfor name in Names:\n`+`\t\tprint("clicking ",num," pictures for ",name,".")\n`+`\t\tfor i in range(0,num):\n`+`\t\t\ttakePicture(name,i)\n`+`\treturn 0\n\n`+
      `def loadDataForTraining(data:list):\n`+`\tbuildDataSetPaths(data)\n`+`\tClickPictures(data)\n\n`+
      `def processDataForTraining():\n`+`\treturn None\n\n`+`######\n`+`knownEncodings = []\n`+`knownNames = []\n\n`+
      `#Train The model\n`+`def trainTheModel():\n`+`\tglobal knownEncodings\n`+`\tglobal knownNames\n\n`+`\tnames = os.listdir(wdir)\n`+`\tfor name in names:\n`+`\t\timagePaths =  list(paths.list_images(os.path.join(wdir,name)))\n`+`\t\tfor image in imagePaths:\n`+`\t\t\timg = cv2.imread(image)\n`+`\t\t\trgb = cv2.cvtColor(img, cv2.COLOR_BGR2RGB)\n\n`+`\t\t\tboxes = face_recognition.face_locations(rgb, model="hog")\n`+`\t\t\tencodings = face_recognition.face_encodings(rgb, boxes)\n`+`\t\t\tfor encoding in encodings:\n`+`\t\t\t\tknownEncodings.append(encoding)\n`+`\t\t\t\tknownNames.append(name)\n\n`+
      `def evaluateModel():\n`+`\treturn None\n\n`+
      `#Store the Model\n`+`def saveModel():\n`+`\tdata = {"encodings": knownEncodings, "names": knownNames}\n`+`\twith open("/home/pi/Desktop/Grok-Downloads/encodings.pickle", "wb") as f:\n`+`\t\tf.write(pickle.dumps(data))\n`+`\t#Cleanup working dir\n`+`\tos.system("rm -r "+wdir)\n\n`;
      break;
    case "weather_prediction":
      code = `import pandas as pd\nimport numpy as np\nimport tensorflow as tf\nimport os\nimport datetime\nimport matplotlib.pyplot as plt\nimport sys\n\n\n`+
      `#Set Variables before we begin training\nTest = None\nTrainX = None\nTrainY = None\nTestX = None\nTestY = None\nData = None\nModel = None\nIN_COLAB = 'google.colab' in sys.modules\n\n`+
      `#Function that gives us the compiled model\ndef build_simple_rnn(num_units=128, embedding=8,num_dense=32,lr=0.001):\n  model = tf.keras.Sequential()\n  model.add(tf.keras.layers.LSTM(units=num_units, input_shape=(1,embedding), activation="relu"))\n  model.add(tf.keras.layers.Dense(num_dense, activation="relu"))\n  model.add(tf.keras.layers.Dense(1))\n  model.compile(loss='mean_squared_error',\\\n                optimizer=tf.keras.optimizers.experimental.RMSprop(learning_rate=lr),\\\n                metrics=['mse'])\n  print(model.summary())\n  return model\n\n`+
      `#1 Function that loads data\ndef loadDataForTraining(link):\n  ####Type CSV\n  global Data\n  Data = pd.read_csv(link)\n  print("Data Loaded")\n\n`+
      `#2 Function that prepares data for training the Model\ndef processDataForTraining():\n  nTainRecords = 7000\n  global Data, TrainX, TestX\n  global TrainY, TestY\n  Data.interpolate(inplace=True)\n  Data.dropna(inplace=True)\n\n  train = np.array(Data[weatherParameter][:nTainRecords])\n  test = np.array(Data[weatherParameter][nTainRecords:])\n\n  train=train.reshape(-1,1)\n  test=test.reshape(-1,1)\n\n  step = 8\n  test = np.append(test,np.repeat(test[-1,],step))\n  train = np.append(train,np.repeat(train[-1,],step))\n\n  trainX,TrainY = convertToMatrix(train,step)\n  testX,TestY = convertToMatrix(test,step)\n\n  TrainX = np.reshape(trainX, (trainX.shape[0], 1, trainX.shape[1]))\n  TestX = np.reshape(testX, (testX.shape[0], 1, testX.shape[1]))\n\n  print("Data has been processed")\n\n`+
      `#3 Function that trains the model\ndef trainTheModel():\n  global Model\n  print("Started Training Model")\n  batch_size=8\n  num_epochs = 2000\n  Model.fit(TrainX,TrainY,\n            epochs=num_epochs,\n            batch_size=batch_size,\n            callbacks=[MyCallback()],verbose=0)\n\n`+
      `#4 Function that Evaluate Model Training\ndef evaluateModel():\n  print("Evalutaing the model...")\n  plt.figure(figsize=(7,5))\n  plt.title("RMSE loss over epochs",fontsize=16)\n  plt.plot(np.sqrt(Model.history.history['loss']),c='k',lw=2)\n  plt.grid(True)\n  plt.xlabel("Epochs",fontsize=14)\n  plt.ylabel("Root-mean-squared error",fontsize=14)\n  plt.xticks(fontsize=14)\n  plt.yticks(fontsize=14)\n  plt.show()\n\n`+
      `#5 Function that saves the trained model, which is then loaded on our kit.\ndef saveModel():\n  converter = tf.lite.TFLiteConverter.from_keras_model(Model)\n  converter.optimizations = [tf.lite.Optimize.DEFAULT]\n  converter.experimental_new_converter=True\n  converter._experimental_lower_tensor_list_ops = False\n  converter.target_spec.supported_ops = [tf.lite.OpsSet.TFLITE_BUILTINS,\\\n                                          tf.lite.OpsSet.SELECT_TF_OPS]\n  tflite_model = converter.convert()\n  # Save the model.\n  with open(f'{weatherParameter}.tflite', 'wb') as f:\n    f.write(tflite_model)\n  print("Saved")\n  if IN_COLAB:\n    from google.colab import files\n    files.download(f'{weatherParameter}.tflite')\n\n`+
      `##############################\n#Helper functions\n#Matrix Transformations\ndef convertToMatrix(data, step):\n  X, Y =[], []\n  for i in range(len(data)-step):\n      d=i+step\n      X.append(data[i:d,])\n      Y.append(data[d,])\n  return np.array(X), np.array(Y)\n###Print Dimensions\ndef printTestTrainDataDimensions():\n  print("Training data shape:", TrainX.shape,', ',TrainY.shape)\n  print("Test data shape:", TestX.shape,', ',TestY.shape)\n\n####Modification to display training at the rate of 50 Intervals\nclass MyCallback(tf.keras.callbacks.Callback):\n  def on_epoch_end(self,epoch,logs=None):\n      if (epoch+1) % 50 == 0 and epoch>0:\n          print("Epoch number {} done".format(epoch+1))\n\n`+
      `Model = build_simple_rnn()\n\n`;
      break;
    case "waste_management":
      code = `import os,sys\nimport numpy as np\nimport tensorflow as tf\nfrom tensorflow.keras.preprocessing.image import ImageDataGenerator\nfrom tensorflow.keras.applications import MobileNetV2\nfrom tensorflow.keras.layers import Dense, GlobalAveragePooling2D\nfrom tensorflow.keras.models import Model\nfrom tensorflow.keras.optimizers import Adam\nfrom tensorflow.keras.utils import get_file\nimport matplotlib.pyplot as plt\nimport gdown\n\n`+
      `CLASSES = ["Biodegradable", "NonBiodegradable", "No Object Found"]\nIMAGE_SIZE = (224, 224)\nBATCH_SIZE = 32\nEPOCHS = 20\nIN_COLAB = 'google.colab' in sys.modules\nDATASET = ""\ntrain_gen = None\nval_gen = None\nmodel=None\n\n`+
      `def loadDataForTraining(dataset):\n  global DATASET\n  print(f"Loading data from {dataset}...")\n  workingdir = os.getcwd()\n  fileName = "waste_dataset.zip"\n  gdown.download(id=dataset, output='waste_dataset.zip', quiet=False)\n  fileLink = 'file://'+os.path.join(workingdir,fileName)\n  file_path = get_file(fname=fileName, origin=fileLink, extract=True)\n  keras_datasets_dir = os.path.dirname(file_path)\n  DATASET = os.path.join(keras_datasets_dir, "dataset")\n  print("Data Loaded")\n\n`+
      `def processDataForTraining():\n  global train_gen, val_gen\n  datagen = ImageDataGenerator(\n      rescale=1.0 / 255,\n      rotation_range=20,\n      zoom_range=0.15,\n      width_shift_range=0.2,\n      height_shift_range=0.2,\n      shear_range=0.15,\n      horizontal_flip=True,\n      fill_mode="nearest",\n      validation_split=0.2,\n  )\n  train_gen = datagen.flow_from_directory(\n      DATASET,\n      target_size=IMAGE_SIZE,\n      class_mode="categorical",\n      classes=CLASSES,\n      batch_size=BATCH_SIZE,\n      subset="training",\n  )\n  val_gen = datagen.flow_from_directory(\n      DATASET,\n      target_size=IMAGE_SIZE,\n      class_mode="categorical",\n      classes=CLASSES,\n      batch_size=BATCH_SIZE,\n      subset="validation",\n  )\n`+
      `def trainTheModel():\n  global model\n  base_model = MobileNetV2(weights="imagenet", include_top=False, input_shape=(224, 224, 3))\n  x = base_model.output\n  x = GlobalAveragePooling2D()(x)\n  predictions = Dense(len(CLASSES), activation="softmax")(x)\n  model = Model(inputs=base_model.input, outputs=predictions)\n  for layer in base_model.layers:\n      layer.trainable = False\n  model.compile(optimizer="Adam", loss="categorical_crossentropy", metrics=["accuracy"])\n  model.fit(train_gen, epochs=EPOCHS, validation_data=val_gen)\n`+
      `def evaluateModel():\n  print("Evalutaing the model...")\n  plt.figure(figsize=(7,5))\n  plt.title("RMSE loss over epochs",fontsize=16)\n  plt.plot(np.sqrt(model.history.history['loss']),c='k',lw=2)\n  plt.grid(True)\n  plt.xlabel("Epochs",fontsize=14)\n  plt.ylabel("Root-mean-squared error",fontsize=14)\n  plt.xticks(fontsize=14)\n  plt.yticks(fontsize=14)\n  plt.show()\n`+
      `def saveModel():\n  converter = tf.lite.TFLiteConverter.from_keras_model(model)\n  converter.optimizations = [tf.lite.Optimize.DEFAULT]\n  converter.experimental_new_converter=True\n  converter._experimental_lower_tensor_list_ops = False\n  converter.target_spec.supported_ops = [tf.lite.OpsSet.TFLITE_BUILTINS,\\\n                                          tf.lite.OpsSet.SELECT_TF_OPS]\n  tflite_model = converter.convert()\n  # Save the model.\n  with open('wastemanagement.tflite', 'wb') as f:\n    f.write(tflite_model)\n  print("Saved")\n  if IN_COLAB:\n    from google.colab import files\n    files.download('wastemanagement.tflite')\n\n`;
      break;
    case "mask_only_access":
      code = `#Import Libraries\n`+
      `from tensorflow.keras.preprocessing.image import ImageDataGenerator\nfrom tensorflow.keras.applications import MobileNetV2\nfrom tensorflow.keras.layers import AveragePooling2D\nfrom tensorflow.keras.layers import Dropout\nfrom tensorflow.keras.layers import Flatten\nfrom tensorflow.keras.layers import Dense\nfrom tensorflow.keras.layers import Input\nfrom tensorflow.keras.models import Model\nfrom tensorflow.keras.optimizers import Adam\nfrom tensorflow.keras.applications.mobilenet_v2 import preprocess_input\nfrom tensorflow.keras.preprocessing.image import img_to_array\nfrom tensorflow.keras.preprocessing.image import load_img\nfrom tensorflow.keras.utils import to_categorical\nfrom sklearn.preprocessing import LabelBinarizer\nfrom sklearn.model_selection import train_test_split\nfrom sklearn.metrics import classification_report\nfrom imutils import paths\nimport matplotlib.pyplot as plt\nimport numpy as np\nimport os\nimport tensorflow as tf\nimport sys\n\n`+
      `# initialize the initial learning rate, number of epochs to train for,\n# and batch size\nINIT_LR = 1e-4\nEPOCHS = 20\nBS = 32\n\n`+
      `IN_COLAB = 'google.colab' in sys.modules\nDIRECTORY = ""\nCATEGORIES = ["with_mask", "without_mask"]\n\n\nMODEL = None\ntrainX = None\ntestX= None\ntrainY= None\ntestY= None\nlabels = None\nLabelClasses  = None\nTrainingHistroy = None\n\n\n`+
      `def buildCNNModel():\n  #Convolution Neural Network\n  # load the MobileNetV2 network, ensuring the head FC layer sets are\n  # left off\n  baseModel = MobileNetV2(weights="imagenet", include_top=False, input_tensor=Input(shape=(224, 224, 3)))\n  # construct the head of the model that will be placed on top of the\n  # the base model\n  headModel = baseModel.output\n  headModel = AveragePooling2D(pool_size=(7, 7))(headModel)\n  headModel = Flatten(name="flatten")(headModel)\n  headModel = Dense(128, activation="relu")(headModel)\n  headModel = Dropout(0.5)(headModel)\n  headModel = Dense(2, activation="softmax")(headModel)  # Changed from 1 to 2\n\n`+
      `  # place the head FC model on top of the base model (this will become\n  # the actual model we will train)\n  model = Model(inputs=baseModel.input, outputs=headModel)\n  # loop over all layers in the base model and freeze them so they will\n  # *not* be updated during the first training process\n  for layer in baseModel.layers:\n    layer.trainable = False\n\n  opt = Adam(learning_rate=INIT_LR)  # Changed from lr to learning_rate\n  model.compile(loss="binary_crossentropy", optimizer=opt,metrics=["accuracy"])\n  print(model.summary())\n  return model\n\n`+
      `#1\ndef loadDataForTraining(workingDir:str):\n  global DIRECTORY\n  DIRECTORY = f'{workingDir}/dataset/'\n\n`+
      `#2\ndef processDataForTraining():\n  global trainX\n  global testX\n  global trainY\n  global testY\n  global labels\n  CATEGORIES = ["with_mask", "without_mask"]\n  data = []\n  labels = []\n\n  for category in CATEGORIES:\n      path = os.path.join(DIRECTORY, category)\n      for img in os.listdir(path):\n          img_path = os.path.join(path, img)\n          image = load_img(img_path, target_size=(224, 224))\n          image = img_to_array(image)\n          image = preprocess_input(image)\n          data.append(image)\n          labels.append(category)\n  # perform one-hot encoding on the labels\n  lb = LabelBinarizer()\n  labels = lb.fit_transform(labels)\n  global LabelClasses\n  LabelClasses = lb.classes_\n  labels = to_categorical(labels)\n  data = np.array(data, dtype="float32")\n  labels = np.array(labels)\n\n  trainX, testX, trainY, testY = train_test_split(data, labels,\n    test_size=0.20, stratify=labels, random_state=42)\n  print("Data has been processed")\n\n`+
      `#3\ndef trainTheModel():\n  print("Started Training Model")\n  global MODEL\n  # construct the training image generator for data augmentation\n  aug = ImageDataGenerator(\n    rotation_range=20,\n    zoom_range=0.15,\n    width_shift_range=0.2,\n    height_shift_range=0.2,\n    shear_range=0.15,\n    horizontal_flip=True,\n    fill_mode="nearest")\n  # train the head of the network\n  print("[INFO] training head...")\n  global TrainingHistroy\n  TrainingHistroy = MODEL.fit(\n    aug.flow(trainX, trainY, batch_size=BS),\n    steps_per_epoch=len(trainX) // BS,\n    validation_data=(testX, testY),\n    validation_steps=len(testX) // BS,\n    epochs=EPOCHS)\n\n`+
      `#4\ndef evaluateModel():\n  # make predictions on the testing set\n  print("[INFO] evaluating network...")\n  predIdxs = MODEL.predict(testX, batch_size=BS)\n  # for each image in the testing set we need to find the index of the\n  # label with corresponding largest predicted probability\n  predIdxs = np.argmax(predIdxs, axis=1)\n  # show a nicely formatted classification report\n  print(classification_report(testY.argmax(axis=1), predIdxs,\n    target_names=LabelClasses))\n  N = EPOCHS\n  plt.style.use("ggplot")\n  plt.figure()\n  plt.plot(np.arange(0, N), TrainingHistroy.history["loss"], label="train_loss")\n  plt.plot(np.arange(0, N), TrainingHistroy.history["val_loss"], label="val_loss")\n  plt.plot(np.arange(0, N), TrainingHistroy.history["accuracy"], label="train_acc")\n  plt.plot(np.arange(0, N), TrainingHistroy.history["val_accuracy"], label="val_acc")\n  plt.title("Training Loss and Accuracy")\n  plt.xlabel("Epoch #")\n  plt.ylabel("Loss/Accuracy")\n  plt.legend(loc="lower left")\n  plt.savefig("plot.png")\n\n`+
      `#5\ndef saveModel():\n  converter = tf.lite.TFLiteConverter.from_keras_model(MODEL) # path to the SavedModel directory\n  converter.optimizations = [tf.lite.Optimize.DEFAULT]\n  converter.experimental_new_converter=True\n  converter._experimental_lower_tensor_list_ops = False\n  converter.target_spec.supported_ops = [tf.lite.OpsSet.TFLITE_BUILTINS,\n                                          tf.lite.OpsSet.SELECT_TF_OPS]\n  tflite_model = converter.convert()\n  # Save the model.\n  with open('tflite_maskDetector.tflite', 'wb') as f:\n    f.write(tflite_model)\n  print("Saved")\n  if IN_COLAB:\n    from google.colab import files\n    files.download('tflite_maskDetector.tflite')\n\nMODEL = buildCNNModel()\n\n`;
      break;
  }
  return code;
};


Blockly.Blocks["get_predictions_from_model"] = {
  init: function () {
    this.jsonInit({
      "type": "get_predictions_from_model",
      "message0": "Get Predictions from Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_predictions_from_model"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `getPredictionsFromModel()\n`;
  return code;
};

Blockly.Blocks["load_data_for_training"] = {
  init: function () {
    this.jsonInit({
      "type": "load_data_for_training",
      "message0": "Load Data for Training %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["load_data_for_training"] = function (block) {
  var value = getBlockValue(block, "value");
  // TODO: Assemble Python into code variable.
  let code = `loadDataForTraining(${value})\n`;
  return code;
};

Blockly.Blocks["load_data_for_prediction"] = {
  init: function () {
    this.jsonInit({
      "type": "load_data_for_prediction",
      "message0": "Load Data for Prediction %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["load_data_for_prediction"] = function (block) {
  var value = getBlockValue(block, "value");
  // TODO: Assemble Python into code variable.
  let code = `loadDataForPrediction(${value})\n`;
  return code;
};

Blockly.Blocks["process_data_for_training"] = {
  init: function () {
    this.jsonInit({
      "type": "process_data_for_training",
      "message0": "Process Data for Training the Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["process_data_for_training"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `processDataForTraining()\n`;
  return code;
};

Blockly.Blocks["evaluate_model"] = {
  init: function () {
    this.jsonInit({
      "type": "evaluate_model",
      "message0": "Evaluate the Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["evaluate_model"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `evaluateModel()\n`;
  return code;
};

Blockly.Blocks["train_the_model"] = {
  init: function () {
    this.jsonInit({
      "type": "train_the_model",
      "message0": "Train the Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["train_the_model"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `trainTheModel()\n`;
  return code;
};

Blockly.Blocks["save_the_model"] = {
  init: function () {
    this.jsonInit({
      "type": "save_the_model",
      "message0": "Save the Model",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["save_the_model"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `saveModel()\n`;
  return code;
};

Blockly.Blocks["get_results"] = {
  init: function () {
    this.jsonInit({
      "type": "get_results",
      "message0": "Get Results",
      "output": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python["get_results"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `getResults()`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_plant_disease_name"] = {
  init: function () {
    this.jsonInit({
      "type": "get_plant_disease_name",
      "message0": "Get Plant Disease Name",
      "output": null,
      "colour": "#D33447",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_plant_disease_name"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `getDiseaseName()`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_plant_disease_remedy"] = {
  init: function () {
    this.jsonInit({
      "type": "get_plant_disease_remedy",
      "message0": "Get Plant Disease Remedy",
      "output": null,
      "colour": "#D33447",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["get_plant_disease_remedy"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `getRemedyName()`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["select_weather_parameter"] = {
  init: function () {
    this.jsonInit({
      "type": "select_weather_parameter",
      "message0": "Select Weather parameter %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "value",
        "options": [
          ["Temperature", "temperature"],
          ["Pressure", "pressure"],
          ["Humidity", "humidity"],
        ]
      }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#ab0944",
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python['select_weather_parameter'] = function (block) {
  var value = block.getFieldValue("value");
  // TODO: Assemble Python into code variable.
  var code = `weatherParameter = '${value}'\n`;
  return code;
};

/**
 * Blockly block for using a trained model in the AI (Artificial Intelligence) module i.e., artificial_intelligence.xml.
 * 
 * This block allows users to select from various trained models to use in their AI (Artificial Intelligence) applications. 
 * 
 * The corresponding Python code generation will handle specific logic for each model type, 
 * enabling the integration of machine learning predictions in the AI (Artificial Intelligence) system.
 */

Blockly.Blocks["use_trained_model_ai"] = {
  init: function () {
    this.jsonInit({
      "type": "use_trained_model_ai",
      "message0": "Use Trained Model %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "import",
        "options": [
          ["Chatbot", "chatbot"],
          ["Facial Expression Analysis", "facial_expression_analysis"],
          ["Sentiment Analysis", "sentiment_analysis"],
          ["Species Classifier", "species_classifier"],
        ]
      }],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    });
  }
};

/**
 * Blockly block for using a trained model in the AI (Artificial Intelligence) module i.e., artificial_intelligence.xml.
 * 
 * This block allows users to select from various trained models to use in their AI (Artificial Intelligence) applications. 
 * 
 * The corresponding Python code generation will handle specific logic for each model type, 
 * enabling the integration of machine learning predictions in the AI (Artificial Intelligence) system.
 */
Blockly.Python["use_trained_model_ai"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "facial_expression_analysis":
      code = `import os\nimport matplotlib.pyplot as plt\nimport numpy as np\nimport cv2\n\nfrom tensorflow.keras.models import load_model\n\n`+
      `#Variables\nresult = None\nData = None\nframe = None\nModel = load_model("EmojiScavenger.keras")\n# Find haar cascade to draw bounding box around face\nfacecasc = cv2.CascadeClassifier(cv2.data.haarcascades+"haarcascade_frontalface_default.xml")#Replace the Path\n\n`+
      `# prevents openCL usage and unnecessary logging messages\ncv2.ocl.setUseOpenCL(False)\n# dictionary mapping class labels with corresponding emotions\nemotion_dict = {0: "Angry", 1: "Disgusted", 2: "Fearful", 3: "Happy", 4: "Neutral", 5: "Sad", 6: "Surprised"}\n\n`+
      `def getPredictionsFromModel():\n  global result\n  global frame\n  gray = cv2.cvtColor(frame, cv2.COLOR_BGR2GRAY)\n  faces = facecasc.detectMultiScale(gray,scaleFactor=1.3, minNeighbors=5)\n\n  for (x, y, w, h) in faces:\n    cv2.rectangle(frame, (x, y-50), (x+w, y+h+10), (255, 0, 0), 2)\n    roi_gray = gray[y:y + h, x:x + w]\n    cropped_img = np.expand_dims(np.expand_dims(cv2.resize(roi_gray, (48, 48)), -1), 0)\n    prediction = Model.predict(cropped_img)\n    result = emotion_dict.get(int(np.argmax(prediction)))\n    cv2.putText(frame, result, (x, y), cv2.FONT_HERSHEY_SIMPLEX, 1, (255, 255, 255), 2, cv2.LINE_AA)\n    cv2.imshow('Video', cv2.resize(frame, (800, 600)))\n\n`+
      `def getResults():\n  return result\n\n`;
      break;
    case "sentiment_analysis":
      code = `import numpy as np\nimport tensorflow as tf\nfrom tensorflow import keras\nfrom tensorflow import constant\nfrom nltk.tokenize import RegexpTokenizer\n\n` +
      `data = None\nresult = None\ntext_model = None\n\ndata = keras.datasets.imdb\n\n` +
      `word_index = data.get_word_index()\nword_index = {k:(v+3) for k, v in word_index.items()}\nword_index['<PAD>'] = 0\nword_index['<START>'] = 1\nword_index['<UNK>'] = 2\nword_index['<UNUSED>'] = 3\n\n` +
      `text_model = keras.models.load_model("SentimentAnalysisModel.keras")\n\n` +
      `def encode(x):\n    global enconded_data\n    enconded_data = [1]\n    for word in x:\n      if word.lower() in word_index:\n        enconded_data.append(word_index[word.lower()])\n      else:\n        enconded_data.append(2)\n\n\n` +
      `# function for preprcessing\n` +
      `def loadDataForPrediction(data):\n    global processed_data\n    tokenizer = RegexpTokenizer(r'\\w+')\n    cleaned_data = tokenizer.tokenize(data)\n    # convert words to integers\n    encode(cleaned_data)\n    # add padding\n    processed_data = keras.preprocessing.sequence.pad_sequences([enconded_data], value=word_index['<PAD>'], padding ='post', maxlen = 250)\n\n\n` +
      `def getPredictionsFromModel():\n    global result\n    predict = text_model.predict(processed_data)\n    print(predict)\n    if predict[0] >= 0.5:\n      result = [1, "good review"]\n    else:\n      result = [0, "bad review"]\n\n\n` +
      `def getResults():\n  return result\n\n`;
      break;
    case "chatbot":
      code = `# import libraries\nimport numpy as np\nimport random\nimport json\n\nimport torch\nimport torch.nn as nn\nfrom nltk.stem.porter import PorterStemmer\nimport nltk\nnltk.download('punkt')\n\n`+
      `#0 Create model\nclass NeuralNet(nn.Module):\n    def __init__(self, input_size, hidden_size, num_classes):\n        super(NeuralNet, self).__init__()\n        self.l1 = nn.Linear(input_size, hidden_size)\n        self.l2 = nn.Linear(hidden_size, hidden_size)\n        self.l3 = nn.Linear(hidden_size, num_classes)\n        self.relu = nn.ReLU()\n\n    def forward(self, x):\n        out = self.l1(x)\n        out = self.relu(out)\n        out = self.l2(out)\n        out = self.relu(out)\n        out = self.l3(out)\n        # no activation and no softmax at the end\n        return out\n\n`+
      `device = torch.device('cuda' if torch.cuda.is_available() else 'cpu')\n\n# requires intents.json and chatbot.pth file in path\nwith open('intents.json', 'r') as json_data:\n    intents = json.load(json_data)\n\nFILE = "ChatBot.pth"\ndata = torch.load(FILE)\n\ninput_size = data["input_size"]\nhidden_size = data["hidden_size"]\noutput_size = data["output_size"]\nall_words = data['all_words']\ntags = data['tags']\nmodel_state = data["model_state"]\n\nmodel = NeuralNet(input_size, hidden_size, output_size).to(device)\nmodel.load_state_dict(model_state)\nmodel.eval()\n\n\n`+
      `# function for preprcessing\ndef loadDataForPrediction(data):\n    global processed_data\n    sentence = tokenize(data)\n    bagOfWordsVec = bag_of_words(sentence, all_words)\n    wordVec = bagOfWordsVec.reshape(1, bagOfWordsVec.shape[0])\n    processed_data = torch.from_numpy(wordVec).to(device)\n\n\n`+
      `def getPredictionsFromModel():\n    global result\n    output = model(processed_data)\n    _, predicted = torch.max(output, dim=1)\n\n    tag = tags[predicted.item()]\n\n    probs = torch.softmax(output, dim=1)\n    prob = probs[0][predicted.item()]\n    if prob.item() > 0.75:\n        for intent in intents['intents']:\n            if tag == intent["tag"]:\n                result = random.choice(intent['responses'])\n    else:\n        result = "I do not understand..."\n\n`+
      `def getResults():\n    return result\n\n`+
      `###Helper functions\nstemmer = PorterStemmer()\ndef tokenize(sentence):\n    """\n    split sentence into array of words/tokens\n    a token can be a word or punctuation character, or number\n    """\n    return nltk.word_tokenize(sentence)\n\n`+
      `def stem(word):\n    """\n    stemming = find the root form of the word\n    examples:\n    words = ["organize", "organizes", "organizing"]\n    words = [stem(w) for w in words]\n    -> ["organ", "organ", "organ"]\n    """\n    return stemmer.stem(word.lower())\n`+
      `def bag_of_words(tokenized_sentence, words):\n    """\n    return bag of words array:\n    1 for each known word that exists in the sentence, 0 otherwise\n    example:\n    sentence = ["hello", "how", "are", "you"]\n    words = ["hi", "hello", "I", "you", "bye", "thank", "cool"]\n    bog   = [  0 ,    1 ,    0 ,   1 ,    0 ,    0 ,      0]\n    """\n    # stem each word\n    sentence_words = [stem(word) for word in tokenized_sentence]\n    # initialize bag with 0 for each word\n    bag = np.zeros(len(words), dtype=np.float32)\n    for idx, w in enumerate(words):\n        if w in sentence_words:\n            bag[idx] = 1\n\n    return bag\n\n`;
      break;
    case "species_classifier":
      code = `import cv2\nimport numpy as np\nfrom tensorflow.keras.models import load_model\n\n`+
      `# Load the trained model\nclass_label = ''\nconfidence = 0\nresult = [None]*2\nmodel = load_model('SpeciesClassifier.keras')\ncategories = ["cats", "dogs","elephants", "human", "Peacock", "pigs"]\n\n`+
      `def getPredictionsFromModel():\n    global class_label, confidence, frame, result\n\n    # Preprocess the image\n    image = cv2.resize(frame, (224, 224))\n    image = np.expand_dims(image, axis=0)\n    image = image / 255.0\n\n    # Predict the class of the image\n    prediction = model.predict(image)[0]\n    class_idx = np.argmax(prediction)\n    class_label = categories[class_idx]\n    confidence = prediction[class_idx] * 100\n    result[0] = class_label\n    result[1] = confidence\n\n\n`+
      `def getResults():\n    global result\n    class_label, confidence = result[0], result[1]\n    print(f"Class: {class_label}, Confidence: {confidence:.2f}%")\n    return result\n\n`;
      break;
  }
  return code;
};

/**
 * Blockly block for loading a model for training in the AI (Artificial Intelligence) module i.e., artificial_intelligence.xml.
 * 
 * This block allows users to select from various models that can be loaded for training in AI (Artificial Intelligence) applications. 
 * The selected model will be prepared for the training process, enabling users to train the model with 
 * their custom data or fine-tune it for specific use cases within the AI (Artificial Intelligence) system.
 */
Blockly.Blocks["load_model_for_training_ai"] = {
  init: function () {
    this.jsonInit({
      "type": "load_model_for_training_ai",
      "message0": "Load Model for Training %1",
      "args0": [{
        "type": "field_dropdown",
        "name": "import",
        "options": [
          ["Chatbot", "chatbot"],
          ["Facial Expression Analysis", "facial_expression_analysis"],
          ["Rock Paper Scissor", "rock_paper_scissor"],
          ["Sentiment Analysis", "sentiment_analysis"],
          ["Species Classifier", "species_classifier"],
        ]
      }],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    });
  }
};


/**
 * Generates Python code for various AI (Artificial Intelligence) models based on selected options.
 * The code is generated as a string with escape characters for indentation.
 * 
 * **NOTE:** When replacing tab characters or using escape sequences for indentation or line continuation,
 * ensure that the escape characters [for e.g., *backslashes* (`\\`), *tab* (`\t`), *new line* (`\n`)] are correctly placed. 
 * Misplacement of escape characters can result in literal tabs or misinterpreted backslashes in the generated code, 
 * leading to errors during execution. Always verify the generated code to ensure correct formatting and indentation.
 *
 * @param {Blockly.Block} block - The block that represents the selected AI model.
 * @returns {string} The generated Python code as a string.
 */
Blockly.Python["load_model_for_training_ai"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "rock_paper_scissor":
      code = `import os\nimport cv2\nimport numpy as np\nimport matplotlib.pyplot as plt\nimport time\n\n`+
      `import tensorflow as tf\nfrom tensorflow.keras.preprocessing.image import ImageDataGenerator\nfrom tensorflow.keras.models import Model, load_model\nfrom tensorflow.keras.layers import Dense,MaxPool2D,Dropout,Flatten,Conv2D,GlobalAveragePooling2D,Activation\nfrom tensorflow.keras.optimizers import Adam\nfrom tensorflow.keras.utils import to_categorical\nfrom sklearn.model_selection import train_test_split\nfrom sklearn.preprocessing import LabelEncoder\nfrom random import choice,shuffle\nfrom scipy import stats as st\nfrom collections import deque\nimport sys\n\n\n`+
      `#Set Variables before we begin trainging\nTest = None\nTrainX = None\nTrainY = None\nTestX = None\nTestY = None\nData = None\nMODEL = None\nHistory = None\nrock = None\npaper = None\nscissor = None\nnothing = None\nno_of_samples = 100\n# Set the figure size\nplt.figure(figsize=[30,20])\n\n\n\n`+
      `def build_cnnModel_for_transferLearning():\n  # This is the input size which our model accepts.\n  image_size = 224\n  # Loading pre-trained NASNETMobile Model without the head by doing include_top = False\n  N_mobile = tf.keras.applications.NASNetMobile( input_shape=(image_size, image_size, 3), include_top=False, weights='imagenet')\n  # Freeze the whole model\n  N_mobile.trainable = False\n  # Adding our own custom head\n  # Start by taking the output feature maps from NASNETMobile\n  layer = N_mobile.output\n  # Convert to a single dimensional vector by Global Average Pooling.\n  # We could also use Flatten()(x) GAP is more effective reduces params and controls overfitting.\n  layer = GlobalAveragePooling2D()(layer)\n  # Adding a dense layer with 512 units\n  layer = Dense(712, activation='relu')(layer)\n  # Dropout 20% of the activations, helps reduces overfitting\n  layer = Dropout(0.40)(layer)\n  # The fianl layer will contain 4 output units (no of units = no of classes) with softmax function.\n  preds = Dense(4,activation='softmax')(layer)\n  # Construct the full model\n  model = Model(inputs=N_mobile.input, outputs=preds)\n  # Check the number of layers in the final Model\n  # Adding transformations that I know would help, you can feel free to add more.\n  # I'm doing horizontal_flip = False, incase you aren't sure which hand you would be using you can make that True.\n  model.compile(optimizer=Adam(learning_rate=0.0001), loss='categorical_crossentropy', metrics=['accuracy'])\n  print ("Number of Layers in Model: {}".format(len(model.layers[:])))\n  return model\n\n`+
      `def loadDataForTraining(num_samples:int):\n    global rock, paper, scissor, nothing\n    # Initialize the camera\n    cap = cv2.VideoCapture(0,  cv2.CAP_DSHOW)\n    # trigger tells us when to start recording\n    trigger = False\n    # Counter keeps count of the number of samples collected\n    counter = 0\n    # This the ROI size, the size of images saved will be box_size -10\n    box_size = 234\n    # Getting the width of the frame from the camera properties\n    width = int(cap.get(3))\n    `+
      `while True:\n        # Read frame by frame\n        ret, frame = cap.read()\n        # Flip the frame laterally\n        frame = cv2.flip(frame, 1)\n        # Break the loop if there is trouble  reading the frame.\n        if not ret:\n            break\n        # If counter is equal to the number samples then reset triger and the counter\n        if counter == num_samples:\n            trigger = not trigger\n            counter = 0\n        # Define ROI for capturing samples\n        cv2.rectangle(frame, (width - box_size, 0), (width, box_size), (0, 250, 150), 2)\n        # Make a resizable window.\n        cv2.namedWindow("Collecting images", cv2.WINDOW_NORMAL)\n        # If trigger is True than start capturing the samples\n        if trigger:\n            # Grab only slected roi\n            roi = frame[5: box_size-5 , width-box_size + 5: width -5]\n            # Append the roi and class name to the list with the selected class_name\n            eval(class_name).append([roi, class_name])\n            # Increment the counter\n            counter += 1\n            # Text for the counter\n            text = "Collected Samples of {}: {}".format(class_name, counter)\n        else:\n            text = "Press 'r' to collect rock samples, 'p' for paper, 's' for scissor and 'n' for nothing"\n        `+
      `# Show the counter on the imaege\n        cv2.putText(frame, text, (3, 350), cv2.FONT_HERSHEY_SIMPLEX, 0.45, (0, 0, 255), 1, cv2.LINE_AA)\n        # Display the window\n        cv2.imshow("Collecting images", frame)\n        # Wait 1 ms\n        k = cv2.waitKey(1)\n        # If user press 'r' than set the path for rock directoryq\n        if k == ord('r'):\n            # Trigger the variable inorder to capture the samples\n            trigger = not trigger\n            class_name = 'rock'\n            rock = []\n        # If user press 'p' then class_name is set to paper and trigger set to True\n        if k == ord('p'):\n            trigger = not trigger\n            class_name = 'paper'\n            paper = []\n        # If user press 's' then class_name is set to scissor and trigger set to True\n        if k == ord('s'):\n            trigger = not trigger\n            class_name = 'scissor'\n            scissor = []\n        # If user press 's' then class_name is set to nothing and trigger set to True\n        if k == ord('n'):\n            trigger = not trigger\n            class_name = 'nothing'\n            nothing = []\n        # Exit if user presses 'q'\n        if k == ord('q'):\n            break\n    #  Release the camera and destroy the window\n    cap.release()\n    cv2.destroyAllWindows()\n\n\n`+
      `def processDataForTraining():\n  # Set the rows and columns\n  global Data, TrainX, TestX\n  global TrainY, TestY\n  rows, cols = 4, 8\n  # Iterate for each class\n  for class_index, each_list in enumerate([rock, paper, scissor,nothing]):\n    # Get 8 random indexes, since we will be showing 8 examples of each class.\n    r = np.random.randint(no_of_samples, size=8);\n    # Plot the examples\n    for i, example_index in enumerate(r,1):\n      plt.subplot(rows,cols,class_index*cols + i );plt.imshow(each_list[example_index][0][:,:,::-1]);plt.axis('off');\n\n  # Combine the labels of all classes together\n  labels = [tupl[1] for tupl in rock] + [tupl[1] for tupl in paper] + [tupl[1] for tupl in scissor] +[tupl[1] for tupl in nothing]\n  # Combine the images of all classes together\n  images = [tupl[0] for tupl in rock] + [tupl[0] for tupl in paper] + [tupl[0] for tupl in scissor] +[tupl[0] for tupl in nothing]\n  # Normalize the images by dividing by 255, now our images are in range 0-1. This will help in training.\n  images = np.array(images, dtype="float") / 255.0\n  # Print out the total number of labels and images.\n  print('Total images: {} , Total Labels: {}'.format(len(labels), len(images)))\n  # Create an encoder Object\n  encoder = LabelEncoder()\n  # Convert Lablels to integers. i.e. nothing = 0, paper = 1, rock = 2, scissor = 3 (mapping is done in alphabatical order)\n  Int_labels = encoder.fit_transform(labels)\n  # Now the convert the integer labels into one hot format. i.e. 0 = [1,0,0,0]  etc.\n  one_hot_labels = to_categorical(Int_labels, 4)\n  # Now we're splitting the data, 75% for training and 25% for testing.\n  TrainX, TestX, TrainY, TestY = train_test_split(images, one_hot_labels, test_size=0.25, random_state=50)\n  # Empty memory from RAM\n  images = []\n  # This can further free up memory from RAM but be careful, if you won't be able to chage split % after this.\n  # rock, paper, scissor = [], [], []\n\n\n\n\n`+
      `#3 Function that trains the model\ndef trainTheModel():\n  global MODEL\n  print("Started Training Model")\n  # Set batchsize according to your system\n  epochs = 15\n  batchsize = 20\n  augment = ImageDataGenerator(\n          rotation_range=30,\n          zoom_range=0.25,\n          width_shift_range=0.10,\n          height_shift_range=0.10,\n          shear_range=0.10,\n          horizontal_flip=False,\n          fill_mode="nearest"\n  )\n  # Start training\n  global Histroy\n  History = MODEL.fit(x=augment.flow(TrainX, TrainY, batch_size=batchsize), validation_data=(TestX, TestY),\n  steps_per_epoch= len(TrainX) // batchsize, epochs=epochs)\n\n`+
      `#4 Function that Evaluate Model Training\ndef evaluateModel():\n  # Plot the accuracy and loss curves\n  acc = MODEL.history.history['accuracy']\n  val_acc = MODEL.history.history['val_accuracy']\n  loss = MODEL.history.history['loss']\n  val_loss = MODEL.history.history['val_loss']\n  epochs = range(len(acc))\n\n  plt.plot(epochs, acc, 'b', label='Training acc')\n  plt.plot(epochs, val_acc, 'r', label='Validation acc')\n  plt.title('Training accuracy')\n  plt.legend()\n  plt.figure()\n  plt.plot(epochs, loss, 'b', label='Training loss')\n  plt.plot(epochs, val_loss, 'r', label='Validation loss')\n  plt.title('Training loss')\n  plt.legend()\n  plt.show()\n\n`+
      `#5 Function that saves the trained model, which is then loaded on our kit.\ndef saveModel():\n  MODEL.save("Rock_Paper_Scissor_model.h5", overwrite=True)\n\nMODEL = build_cnnModel_for_transferLearning()\n\n`;
      break;
    case "facial_expression_analysis":
      code = `\n#Import Libraries\nimport matplotlib.pyplot as plt\nimport numpy as np\nimport cv2\nimport pandas as pd\nfrom tensorflow.keras.callbacks import ModelCheckpoint,EarlyStopping,ReduceLROnPlateau\n\nfrom tensorflow.keras.models import Sequential\nfrom tensorflow.keras.layers import Dense, Dropout, Flatten\nfrom tensorflow.keras.layers import Conv2D\nfrom tensorflow.keras.optimizers import Adam\nfrom tensorflow.keras.layers import MaxPooling2D\nfrom tensorflow.keras.preprocessing.image import ImageDataGenerator\n\n`+
      `#declare a variable workingDir which is of type string\n#It should contain the path to data folder for this application\ntrain_dir = ''\nval_dir = ''\n\nnum_train = 28709\nnum_val = 7178\nbatch_size = 64\nnum_epoch = 70\nMODEL = None\ntrain_generator = None\nvalidation_generator = None\n\n`+
      `def buildCNNModel():\n  #Convolution Neural Network\n  model = Sequential()\n\n  model.add(Conv2D(32, kernel_size=(3, 3), activation='relu', input_shape=(48,48,1)))\n  model.add(Conv2D(64, kernel_size=(3, 3), activation='relu'))\n  model.add(MaxPooling2D(pool_size=(2, 2)))\n  model.add(Dropout(0.25))\n\n  model.add(Conv2D(128, kernel_size=(3, 3), activation='relu'))\n  model.add(MaxPooling2D(pool_size=(2, 2)))\n  model.add(Conv2D(128, kernel_size=(3, 3), activation='relu'))\n  model.add(MaxPooling2D(pool_size=(2, 2)))\n  model.add(Dropout(0.25))\n\n  model.add(Flatten())\n  model.add(Dense(1024, activation='relu'))\n  model.add(Dropout(0.5))\n  model.add(Dense(7, activation='softmax'))\n  model.compile(loss='categorical_crossentropy',\n                optimizer=Adam(learning_rate=0.001),\n                metrics=['accuracy']\n                )\n  print(model.summary())\n  return model\n\n`+
      `#1\ndef loadDataForTraining(workingDir:str):\n  global train_dir, val_dir\n  train_dir = f'{workingDir}/data/train' #Replace the Path\n  val_dir = f'{workingDir}/data/test' #Replace the Path\n\n`+
      `#2\ndef processDataForTraining():\n  global train_generator\n  global validation_generator\n  #We normalize the images to a standard size and create a series of batch\n  datagen = ImageDataGenerator(rescale=1./255)\n  train_generator = datagen.flow_from_directory(\n        train_dir,\n        target_size=(48,48),\n        batch_size=batch_size,\n        color_mode="grayscale",\n        class_mode='categorical')\n  validation_generator = datagen.flow_from_directory(\n          val_dir,\n          target_size=(48,48),\n          batch_size=batch_size,\n          color_mode="grayscale",\n          class_mode='categorical')\n  print("Data has been processed")\n\n`+
      `#3\ndef trainTheModel():\n  print("Started Training Model")\n  global MODEL\n  # model.compile(loss='categorical_crossentropy',optimizer=Adam(lr=0.0001, decay=1e-6),metrics=['accuracy'])\n  #Transfer learning: We use learnings from previously trained model as base in this application.\n  checkpoint = ModelCheckpoint(\n                                'emotion_face_mobilNet.keras',\n                                monitor='val_loss',\n                                mode='min',\n                                save_best_only=True,\n                                verbose=1)\n  earlystop = EarlyStopping(\n                            monitor='val_loss',\n                            min_delta=0,\n                            patience=10,\n                            verbose=1,restore_best_weights=True)\n  learning_rate_reduction = ReduceLROnPlateau(monitor='val_acc',\n                                              patience=5,\n                                              verbose=1,\n                                              factor=0.2,\n                                              min_lr=0.0001)\n  callbackList = [earlystop,checkpoint,learning_rate_reduction]\n\n  MODEL.fit(\n        train_generator,\n        steps_per_epoch=num_train // batch_size,\n        epochs=num_epoch,\n        validation_data=validation_generator,\n        validation_steps=num_val // batch_size)\n\n`+
      `#4\ndef evaluateModel():\n    # summarize history for accuracy\n    pd.DataFrame(MODEL.history.history)[['accuracy','val_accuracy']].plot()\n    plt.title("Accuracy")\n    plt.show()\n\n    # summarize history for loss\n    pd.DataFrame(MODEL.history.history)[['loss','val_loss']].plot()\n    plt.title("Loss")\n    plt.show()\n\n`+
      `#5\ndef saveModel():\n  MODEL.save('EmojiScavenger.keras')\n\nMODEL = buildCNNModel()\n\n`;
      break;
    case "sentiment_analysis":
      code = `from tensorflow.keras.utils import get_file\nfrom tensorflow.keras import Sequential\nfrom tensorflow.keras.layers import Embedding, GlobalAveragePooling1D, Dense\nfrom tensorflow.keras.preprocessing.sequence import pad_sequences\nimport matplotlib.pyplot as plt\nimport numpy as np\nimport json, os\n\n` +
      `# variables\npath = None\ntrain_data = None\ntrain_labels = None\ntest_data = None\ntest_labels = None\ntrain_data_padded = None\ntest_data_padded = None\nx_val = None\nx_train = None\ny_val = None\ny_train = None\nModel = None\nfitModel = None\nresults = None\nwordIndexFile = None\nnumpyArrayFile = None\n\n` +
      `########\n#build model\n` +
      `def buildModel():\n  embedding_dim = 16\n  model = Sequential()\n  model.add(Embedding(88000, embedding_dim))\n  model.add(GlobalAveragePooling1D())\n  model.add(Dense(16, activation="relu"))\n  model.add(Dense(1, activation="sigmoid"))\n  model.compile(optimizer="adam", loss="binary_crossentropy", metrics=["accuracy"])\n  print(model.summary())\n  return model\n\n` +
      `def loadDataForTraining(input):\n  global wordIndexFile, numpyArrayFile\n  fileName = input.rsplit('/', 1)[-1]\n  file_path = get_file(fname =fileName,\n\n` + 
      `                                    origin = input,\n\n` +
      `                                    extract=True)\n  keras_datasets_dir = os.path.dirname(file_path)\n  numpyArrayFile = os.path.join(keras_datasets_dir, \\
                                searchFileInDir('imdb.npz',keras_datasets_dir))\n  wordIndexFile = os.path.join(keras_datasets_dir,\\ \n` +
      `searchFileInDir('imdb_word_index.json',keras_datasets_dir))\n\n` +
      `def processDataForTraining():\n  global train_data_padded, test_data_padded, x_train, x_test, y_train, y_test,x_val, x_training, y_val, y_training\n\n` +
      `\n` +
      `  # variables\n` +
      `  start_char=1\n  index_from=3\n  oov_char=2\n  skip_top=0\n  seed=113\n  num_words=10000\n\n` +
      `  with np.load(numpyArrayFile, allow_pickle=True) as f:\n    x_train, labels_train = f["x_train"], f["y_train"]\n    x_test, labels_test = f["x_test"], f["y_test"]\n\n` +
      `  rng = np.random.RandomState(seed)\n  indices = np.arange(len(x_train))\n  rng.shuffle(indices)\n  x_train = x_train[indices]\n  labels_train = labels_train[indices]\n\n` +
      `  indices = np.arange(len(x_test))\n  rng.shuffle(indices)\n  x_test = x_test[indices]\n  labels_test = labels_test[indices]\n\n` +
      `  if start_char is not None:\n    x_train = [[start_char] + [w + index_from for w in x] for x in x_train]\n    x_test = [[start_char] + [w + index_from for w in x] for x in x_test]\n  elif index_from:\n    x_train = [[w + index_from for w in x] for x in x_train]\n    x_test = [[w + index_from for w in x] for x in x_test]\n  else:\n    x_train = [[w for w in x] for x in x_train]\n    x_test = [[w for w in x] for x in x_test]\n\n` +
      `  xs = x_train + x_test\n  labels = np.concatenate([labels_train, labels_test])\n\n` +
      `  if not num_words:\n    num_words = max(max(x) for x in xs)\n\n` +
      `  if oov_char is not None:\n    xs = [\n` + `      [w if (skip_top <= w < num_words) else oov_char for w in x]\n` +`      for x in xs\n` + `    ]\n  else:\n    xs = [[w for w in x if skip_top <= w < num_words] for x in xs]\n\n` +
      `  idx = len(x_train)\n  x_train, y_train = np.array(xs[:idx], dtype="object"), labels[:idx]\n  x_test, y_test = np.array(xs[idx:], dtype="object"), labels[idx:]\n\n` +
      `  with open(wordIndexFile) as f:\n    word_index = json.load(f)\n    word_index = {k:(v+3) for k, v in word_index.items()}\n    word_index['<PAD>'] = 0\n    word_index['<START>'] = 1\n    word_index['<UNK>'] = 2\n    word_index['<UNUSED>'] = 3\n\n` +
      `  train_data_padded = pad_sequences(x_train, value=word_index['<PAD>'], padding ='post', maxlen = 250)\n  test_data_padded = pad_sequences(x_test, value=word_index['<PAD>'], padding ='post', maxlen = 250)\n\n` +
      `  x_val = train_data_padded[:10000] # make first 10,000 data into validation\n  x_training = train_data_padded[10000:] # make the data from 10,000 to end to be training\n  # labels splitting\n  y_val = y_train[:10000]\n  y_training = y_train[10000:]\n\n` +
      `def trainTheModel():\n  global fitModel\n  fitModel = Model.fit(x_training, y_training, epochs=20, batch_size=512, validation_data=(x_val, y_val), verbose=1)\n\n` +
      `def evaluateModel():\n  global results\n  results = Model.evaluate(test_data_padded, y_test)\n  print("Loss: ", results[0])\n  print("Accuracy: ", results[1])\n  # plot graphs to view loss and accuracy\n  history_dict = fitModel.history\n  history_dict.keys()\n  acc = history_dict['accuracy']\n  val_acc = history_dict['val_accuracy']\n  loss = history_dict['loss']\n  val_loss = history_dict['val_loss']\n  epochs = range(1, len(acc) + 1)\n  plt.plot(epochs, loss, 'bo', label='Training loss')\n  plt.plot(epochs, val_loss, 'b', label='Validation loss')\n  plt.title('Training and validation loss')\n  plt.xlabel('Epochs')\n  plt.ylabel('Loss')\n  plt.legend()\n  plt.show()\n  plt.plot(epochs, acc, 'bo', label='Training acc')\n  plt.plot(epochs, val_acc, 'b', label='Validation acc')\n  plt.title('Training and validation accuracy')\n  plt.xlabel('Epochs')\n  plt.ylabel('Accuracy')\n  plt.legend(loc='lower right')\n  plt.show()\n\n` +
      `def saveModel():\n  Model.save("SentimentAnalysisModel.keras")\n\n` +
      `########helper function\n` +
      `def searchFileInDir(fileName,dir)->str:\n  if fileName == "":\n    return ""\n  extension = fileName.split('.')[-1]\n  files = [[f for f in os.listdir(dir) if f.endswith(type_)] for type_ in [extension]][0]\n  for name in files:\n    if name.find(fileName)>-1 :\n      return name\n\n` +
      `Model = buildModel()\n`;
      break;
    case "chatbot":
      code = `#Import Libraries\nimport numpy as np\nimport random\nimport json\nimport torch\nimport torch.nn as nn\nfrom torch.utils.data import Dataset, DataLoader\nimport numpy as np\nimport nltk\nnltk.download('punkt')\nfrom nltk.stem.porter import PorterStemmer\nimport matplotlib.pyplot as plt\n\n`+
      `#Global variables\nDATA = {}\nMODEL = None\ndevice = torch.device('cuda' if torch.cuda.is_available() else 'cpu')\n\n`+
      `# Hyper-parameters\nnum_epochs = 1000\nbatch_size = 8\nlearning_rate = 0.001\nhidden_size = 8\nX_train = []\ny_train = []\ntrain_loader = None\noutput_size = 0\ninput_size = 0\nLossHistory = []\nAccuracyHistroy = []\n\n`+
      `#0 Create model\nclass NeuralNet(nn.Module):\n    def __init__(self, input_size, hidden_size, num_classes):\n        super(NeuralNet, self).__init__()\n        self.l1 = nn.Linear(input_size, hidden_size)\n        self.l2 = nn.Linear(hidden_size, hidden_size)\n        self.l3 = nn.Linear(hidden_size, num_classes)\n        self.relu = nn.ReLU()\n\n    def forward(self, x):\n        out = self.l1(x)\n        out = self.relu(out)\n        out = self.l2(out)\n        out = self.relu(out)\n        out = self.l3(out)\n        # no activation and no softmax at the end\n        return out\n`+
      ``+
      `#1\ndef loadDataForTraining(fileName:str):\n    global DATA\n    with open('intents.json', 'r') as f:\n        intents = json.load(f)\n    all_words = []\n    tags = []\n    xy = []\n    # loop through each sentence in our intents patterns\n    for intent in intents['intents']:\n        tag = intent['tag']\n        # add to tag list\n        tags.append(tag)\n        for pattern in intent['patterns']:\n            # tokenize each word in the sentence\n            w = tokenize(pattern)\n            # add to our words list\n            all_words.extend(w)\n            # add to xy pair\n            xy.append((w, tag))\n\n    # stem and lower each word\n    ignore_words = ['?', '.', '!']\n    all_words = [stem(w) for w in all_words if w not in ignore_words]\n    # remove duplicates and sort\n    all_words = sorted(set(all_words))\n    tags = sorted(set(tags))\n\n    print(len(xy), "patterns")\n    print(len(tags), "tags:", tags)\n    print(len(all_words), "unique stemmed words:", all_words)\n    DATA['all_words'] = all_words\n    DATA['tags'] = tags\n    DATA['xy'] = xy\n\n\n`+
      `#2\ndef processDataForTraining():\n    global  X_train, y_train, train_loader\n    global output_size, input_size\n    # create training data\n    X_train = []\n    y_train = []\n    all_words = DATA['all_words']\n    tags = DATA['tags']\n    xy = DATA['xy']\n    for (pattern_sentence, tag) in xy:\n        # X: bag of words for each pattern_sentence\n        bag = bag_of_words(pattern_sentence, all_words)\n        X_train.append(bag)\n        # y: PyTorch CrossEntropyLoss needs only class labels, not one-hot\n        label = tags.index(tag)\n        y_train.append(label)\n\n    X_train = np.array(X_train)\n    y_train = np.array(y_train)\n    output_size = len(tags)\n    input_size = len(X_train[0])\n    dataset = ChatDataset()\n    train_loader = DataLoader(dataset=dataset,\n                              batch_size=batch_size,\n                              shuffle=True,\n                              num_workers=0)\n    print("Data has been processed")\n\n`+
      `#3\ndef trainTheModel():\n    print("Started Training Model")\n    global MODEL,AccuracyHistroy,LossHistory\n    MODEL = NeuralNet(input_size, hidden_size, output_size).to(device)\n    # Loss and optimizer\n    criterion = nn.CrossEntropyLoss()\n    optimizer = torch.optim.Adam(MODEL.parameters(), lr=learning_rate)\n    # Train the model\n    for epoch in range(num_epochs):\n        accSum = 0\n        lossSum = 0\n        total_samples = 0\n        for (words, labels) in train_loader:\n            words = words.to(device)\n            labels = labels.to(dtype=torch.long).to(device)\n            # Forward pass\n            outputs = MODEL(words)\n            # if y would be one-hot, we must apply\n            # labels = torch.max(labels, 1)[1]\n            loss = criterion(outputs, labels)\n\n             # Compute Accuracy\n            _, predicted = torch.max(outputs, 1)\n            accSum += (predicted == labels).sum().item()\n            total_samples += labels.size(0)\n            # Backward and optimize\n            optimizer.zero_grad()\n            loss.backward()\n            optimizer.step()\n        #update model training history\n        LossHistory.append(loss.item())\n        AccuracyHistroy.append(accSum/total_samples)\n        if (epoch+1) % 100 == 0:\n            print(f'Epoch [{epoch + 1}/{num_epochs}], Loss: {loss.item():.4f}, Accuracy: {accSum / total_samples:.4f}')\n\n\n    print(f'Final loss: {loss.item():.4f}, accuracy: {accSum / total_samples:.4f}')\n\n`+
      `#4\ndef evaluateModel():\n    # summarize history for accuracy\n    Accuracyxs = [x for x in range(len(AccuracyHistroy))]\n    plt.plot(Accuracyxs, AccuracyHistroy)\n    plt.title("Accuracy")\n    plt.show()\n    # summarize history for loss\n    Lossxs = [x for x in range(len(LossHistory))]\n    plt.plot(Accuracyxs, LossHistory)\n    plt.title("Loss")\n    plt.show()\n\n`+
      `#5\ndef saveModel():\n    Modeldata = {\n    "model_state": MODEL.state_dict(),\n    "input_size": input_size,\n    "hidden_size": hidden_size,\n    "output_size": output_size,\n    "all_words": DATA['all_words'],\n    "tags": DATA['tags']\n    }\n    FileName = "ChatBot.pth"\n    torch.save(Modeldata, FileName)\n    print(f'Training complete. Model saved to {FileName}')\n\n`+
      `##Helper Functions and classes\nstemmer = PorterStemmer()\ndef tokenize(sentence):\n    """\n    split sentence into array of words/tokens\n    a token can be a word or punctuation character, or number\n    """\n    return nltk.word_tokenize(sentence)\n\n`+
      `def stem(word):\n    """\n    stemming = find the root form of the word\n    examples:\n    words = ["organize", "organizes", "organizing"]\n    words = [stem(w) for w in words]\n    -> ["organ", "organ", "organ"]\n    """\n    return stemmer.stem(word.lower())\n\n`+
      `def bag_of_words(tokenized_sentence, words):\n    """\n    return bag of words array:\n    1 for each known word that exists in the sentence, 0 otherwise\n    example:\n    sentence = ["hello", "how", "are", "you"]\n    words = ["hi", "hello", "I", "you", "bye", "thank", "cool"]\n    bog   = [  0 ,    1 ,    0 ,   1 ,    0 ,    0 ,      0]\n    """\n    # stem each word\n    sentence_words = [stem(word) for word in tokenized_sentence]\n    # initialize bag with 0 for each word\n    bag = np.zeros(len(words), dtype=np.float32)\n    for idx, w in enumerate(words):\n        if w in sentence_words:\n            bag[idx] = 1\n\n    return bag\n\n`+
      `class ChatDataset(Dataset):\n    def __init__(self):\n        self.n_samples = len(X_train)\n        self.x_data = X_train\n        self.y_data = y_train\n\n    # support indexing such that dataset[i] can be used to get i-th sample\n    def __getitem__(self, index):\n        return self.x_data[index], self.y_data[index]\n\n    # we can call len(dataset) to return the size\n    def __len__(self):\n        return self.n_samples\n\n`;
      break;
    case "species_classifier":
      code = `import os\nimport numpy as np\nimport tensorflow as tf\nfrom tensorflow.keras.preprocessing.image import ImageDataGenerator\nfrom tensorflow.keras.applications import MobileNetV2\nfrom tensorflow.keras.layers import Dense, GlobalAveragePooling2D\nfrom tensorflow.keras.models import Model\nfrom tensorflow.keras.optimizers import Adam\nimport cv2\nimport pandas as pd\nimport matplotlib.pyplot as plt\n\n`+
      `# Load and preprocess the data\nclasses = ["cats", "dogs", "elephants", "human", "Peacock", "pigs"]  # Replace with your class names\nimage_size = (224, 224)\nbatch_size = 32\ntrain_generator = None\nvalidation_generator = None\ndata_dir = ''\nepochs = 20\nHistory = None\n\n`+
      `def buildModel():\n    #Convolution Neural Network\n    base_model = MobileNetV2(weights="imagenet", include_top=False, input_shape=(224, 224, 3))\n    x = base_model.output\n    x = GlobalAveragePooling2D()(x)\n    predictions = Dense(len(classes), activation="softmax")(x)\n    model = Model(inputs=base_model.input, outputs=predictions)\n\n    for layer in base_model.layers:\n        layer.trainable = False\n\n    model.compile(optimizer="Adam", loss="categorical_crossentropy", metrics=["accuracy"])\n    print(model.summary())\n    return model\n\n`+
      `#1\ndef loadDataForTraining(workingDir:str):\n    global data_dir\n    data_dir = f'{workingDir}/dataset/'\n`+
      `#2\ndef processDataForTraining():\n    global train_generator\n    global validation_generator\n    #We normalize the images to a standard size and create a series of batch\n    datagen = ImageDataGenerator(\n    rescale=1.0 / 255,\n    rotation_range=20,\n    zoom_range=0.15,\n    width_shift_range=0.2,\n    height_shift_range=0.2,\n    shear_range=0.15,\n    horizontal_flip=True,\n    fill_mode="nearest",\n    validation_split=0.2,\n    )\n    train_generator = datagen.flow_from_directory(\n    data_dir,\n    target_size=image_size,\n    class_mode="categorical",\n    classes=classes,\n    batch_size=batch_size,\n    subset="training",\n    )\n    validation_generator = datagen.flow_from_directory(\n        data_dir,\n        target_size=image_size,\n        class_mode="categorical",\n        classes=classes,\n        batch_size=batch_size,\n        subset="validation",\n    )\n    print("Data has been processed")\n\n`+
      `#3\ndef trainTheModel():\n  print("Started Training Model")\n  global MODEL,History\n  History = MODEL.fit(train_generator, epochs=epochs, validation_data=validation_generator)\n\n`+
      `#4\ndef evaluateModel():\n    evaluation = MODEL.evaluate(validation_generator)\n\n    print("Evaluation Loss:", evaluation[0])\n    print("Evaluation Accuracy:", evaluation[1])\n    # summarize history for accuracy\n    pd.DataFrame(History.history)[['accuracy','val_accuracy']].plot()\n    plt.title("Accuracy")\n    plt.show()\n\n    # summarize history for loss\n    pd.DataFrame(History.history)[['loss','val_loss']].plot()\n    plt.title("Loss")\n    plt.show()\n\n`+
      `#5\ndef saveModel():\n  MODEL.save('SpeciesClassifier.keras')\n\n\n`+
      `MODEL = buildModel()\n\n`;
      break;
  }
  return code;
};

Blockly.Blocks["get_current_coordinates"] = {
  init: function () {
    this.jsonInit({
      "type": "get_current_coordinates",
      "message0": "Get Current Coordinates %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "option",
          "options": [
            ["x", "x"],
            ["y", "y"],
            ["z", "z"],
          ],
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["get_current_coordinates"] = function (block) {
  var option_value = block.getFieldValue("option");
  // TODO: Assemble Python into code variable.
  var code = `dobot.get_pose().position.${option_value}`;
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["set_home_positions"] = {
  init: function () {
    this.jsonInit({
      "type": "set_home_positions",
      "message0": "Set Home Positions X %1 Y %2 Z %3 R %4",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
        {
          "type": "input_value",
          "name": "value3",
        },
        {
          "type": "input_value",
          "name": "value4",
        },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["set_home_positions"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  var value3 = Blockly.Python.valueToCode(block, "value3", Blockly.Python.ORDER_ATOMIC);
  var value4 = Blockly.Python.valueToCode(block, "value4", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `dobot.set_home(x=${value1}, y=${value2}, z=${value3}, r=${value4})\n`;
  return code;
};


Blockly.Blocks["move_to"] = {
  init: function () {
    this.jsonInit({
      "type": "move_to",
      "message0": "Move To X %1 Y %2 Z %3 R %4",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
        {
          "type": "input_value",
          "name": "value3",
        },
        {
          "type": "input_value",
          "name": "value4",
        },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["move_to"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  var value3 = Blockly.Python.valueToCode(block, "value3", Blockly.Python.ORDER_ATOMIC);
  var value4 = Blockly.Python.valueToCode(block, "value4", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `dobot.move_to( x=${value1}, y=${value2}, z=${value3}, r=${value4})\n`;
  return code;
};


Blockly.Blocks["set_joint_speed"] = {
  init: function () {
    this.jsonInit({
      "type": "set_joint_speed",
      "message0": "Set Joint Speed    Velocity %1 Acceleration %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["set_joint_speed"] = function (block) {
  var value1 = Blockly.Python.valueToCode(block, "value1", Blockly.Python.ORDER_ATOMIC);
  var value2 = Blockly.Python.valueToCode(block, "value2", Blockly.Python.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `dobot.speed(velocity=${value1}, acceleration=${value2})\n`;
  return code;
};

Blockly.Blocks["suction_cup"] = {
  init: function () {
    this.jsonInit({
      "type": "suction_cup",
      "message0": "Suction Cup %1",
      "args0": [
        {
          "type": "field_dropdown",
          "name": "option",
          "options": [
            ["ON", "True"],
            ["OFF", "False"],
          ],
        },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["suction_cup"] = function (block) {
  var option_value = block.getFieldValue("option");
  //TODO: Assemble Python into code variable.
  var code = `dobot.suck(${option_value})\n`;
  return code;
};


Blockly.Blocks["clear_alarms"] = {
  init: function () {
    this.jsonInit({
      "type": "clear_alarms",
      "message0": "Clear alarms",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["clear_alarms"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `dobot.clear_alarms()\n`;
  return code;
};


Blockly.Blocks["gripper_hold"] = {
  init: function () {
    this.jsonInit({
      "type": "gripper_hold",
      "message0": "Gripper Hold",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["gripper_hold"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `dobot.grip(True)\n`;
  return code;
};


Blockly.Blocks["gripper_release"] = {
  init: function () {
    this.jsonInit({
      "type": "gripper_release",
      "message0": "Gripper Release",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["gripper_release"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `dobot.grip(False)\n`;
  return code;
};


Blockly.Blocks["gripper_disable"] = {
  init: function () {
    this.jsonInit({
      "type": "gripper_disable",
      "message0": "Gripper Disable",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["gripper_disable"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `dobot.suck(False)\n`;
  return code;
};

Blockly.Blocks["move_to_home_position"] = {
  init: function () {
    this.jsonInit({
      "type": "move_to_home_position",
      "message0": "Move to Home Position",
      "previousStatement": null,
      "nextStatement": null,
      "colour": 65,
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python["move_to_home_position"] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = `dobot.home()\n`;
  return code;
};

Blockly.Blocks["connect_gps_serial"] = {
  init: function () {
    this.jsonInit({
      "type": "connect_gps_serial",
      "message0": "Connect to GPS at Port %1 serial port: %2",
      "args0": [
        {
          "type": "input_dummy"
        },
        {
          "type": "field_input",
          "name": "serial_port",
          "text": "/dev/ttyS0"
        }
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#44c9eb",
      "tooltip": "",
      "helpUrl": ""
    });
  }
};

Blockly.Python['connect_gps_serial'] = function (block) {
  var text_serial_port = block.getFieldValue('serial_port');
  // TODO: Assemble JavaScript into code variable.
  var code = `ser = serial.Serial('${text_serial_port}', 9600)\n\ndef read_gps_data():\n`+`\tif ser.in_waiting > 0:\n`+`\t\tnewdata = ser.readline().decode("utf-8").rstrip()\n`+`\t\tif newdata[0:6] == "$GNRMC":\n`+`\t\t\tnewmsg=pynmea2.parse(newdata)\n`+`\t\t\tlat=newmsg.latitude\n`+`\t\t\tlng=newmsg.longitude\n`+`\t\t\treturn [lat,lng]\n`+`\treturn [0,0]\n`;
  return code;
};

Blockly.Blocks["read_gps_sensor"] = {
  init: function () {
    this.jsonInit({
      "type": "read_gps_sensor",
      "message0": "Read GPS Data",
      "output": null,
      "colour": "#44c9eb",
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python["read_gps_sensor"] = function (block) {
  var code = 'read_gps_data()';
  return [code, Blockly.Python.ORDER_NONE];
};


Blockly.Blocks["display_text"] = {
  init: function () {
    var options = [...Array(4)].map((_, i) => [String(i+1), String(i+1)]);
    this.jsonInit({
      "type": "display_text",
      "message0": "Display Text %1 in Row %2",
      "args0": [{
        "type": "input_value",
        "name": "value",
        "value": 0,
        "min": 0,
      },
      {
        "type": "field_dropdown",
        "name": "row_number",
        "options": options
      },
      ],
      "inputsInline": true,
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#40A303",
      "tooltip": "",
      "helpUrl": "",
    });
  },
};

Blockly.Python["display_text"] = function (block) {
  // TODO: Assemble Python into code variable.
  var value = Blockly.Python.valueToCode(block, 'value', Blockly.Python.ORDER_ATOMIC);
  var row_number_dropdown = block.getFieldValue("row_number");
  var code = `lcd_display_I2C.text(${value}, ${row_number_dropdown})\n`;
  return code;
};


Blockly.Blocks["clear_lcd_display"] = {
  init: function () {
    this.jsonInit({
      "type": "clear_lcd_display",
      "message0": "Clear LCD Display",
      "previousStatement": null,
      "nextStatement": null,
      "colour": "#40A303",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['clear_lcd_display'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'lcd_display_I2C.clear()\n';
  return code;
};

Blockly.Blocks["open_as"] = {
  init: function () {
    this.jsonInit({
      "type": "open_as",
      "message0": "Open %1 %2 as %3 %4 %5",
      "args0": [{
          "type": "field_input",
          "name": "filepath",
          "text": "Enter the Filepath"
        },
        {
          "type": "field_dropdown",
          "name": "file_permission",
          "options": [
            ["read", "r"],
            ["write", "w"],
          ]
        },
        {
          "type": "input_value",
          "name": "value"
        },  
        {
          "type": "input_dummy",
        },
        {
          "type": "input_statement",
          "name": "statements",
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": '#935ba5',
      "helpUrl": "",
    });
  },
};

Blockly.Python["open_as"] = function (block) {
  var filepath = block.getFieldValue("filepath");
  var file_permission = block.getFieldValue("file_permission");
  var value = getBlockValue(block, "value");
  var statements = Blockly.Python.statementToCode(block, "statements");
  // TODO: Assemble Python into code variable.
  var code = `with open('${filepath}', '${file_permission}') as ${value}:\n  ${statements.trim()}\n\n`;
  return code;
};

Blockly.Blocks["common_load_json"] = {
  init: function () {
    this.jsonInit({
      "type": "common_load_json",
      "message0": "Load Json %1",
      "args0": [
        {
          "type": "input_value",
          "name": "value",
        },
      ],
      "output": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["common_load_json"] = function (block) {
  var value = getBlockValue(block, "value");
  // TODO: Assemble Python into code variable.
  var code = `json.load(${value})\n`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["get_element_in"] = {
  init: function () {
    this.jsonInit({
      "type": "get_element_in",
      "message0": "Get Element %1 in %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value1",
        },
        {
          "type": "input_value",
          "name": "value2",
        },
      ],
      "inputsInline": true,
      "output": null,
      "colour": "#935ba5",
      "tooltip": "",
      "helpUrl": "",
    })
  }
};

Blockly.Python["get_element_in"] = function (block) {
  var value1 = getBlockValue(block, "value1");
  var value2 = getBlockValue(block, "value2");
  // TODO: Assemble Python into code variable.
  var code = `${value2}[${value1}]`;
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["use_iiot"] = {
  init: function () {
    this.jsonInit({
      type: "use_iiot",
      message0: "Use %1",
      args0: [{
        type: "field_dropdown",
        name: "import",
        options: [
          ["Accelerometer", "accelerometer"],
          ["Alcohol Sensor", "alcohol_sensor"],
          ["BloodPressure Sensor", "bloodpressure_sensor"],
          ["Button", "button"],
          ["Buzzer", "buzzer"],
          ["Climate Sensor", "temperature"],
          ["Compass Sensor", "compass_sensor"],
          ["DC Drive", "Dc_drive"],
          ["ECG Sensor","ecg_sensor"], 
          ["Exit", "exit"],
          ["Flame Sensor", "flame_sensor"],
          ["Flow Sensor", "flow_sensor"],
          ["Gas Sensor-Analog", "gas_sensor_analog"],
          ["GPS Sensor", "gps_sensor"],
          ["GSR Sensor","gsr_sensor"],
          ["Gyroscope", "gyroscope"],
          ["Hall Effect Sensor", "hall_effect_sensor"],          
          ["IR Sensor", "ir_sensor"],
          ["Json", "json"],
          ["Keypad", "keypad"],
          ["LCD Display", "lcd_display"],
          ["LDR Sensor", "ldr_sensor"],
          ["LED", "led"],
          ["Metal Detector Sensor", "metal_detector_sensor"],
          ["Moisture Sensor", "moisture_sensor"],
          ["Motion Sensor", "motion_sensor"],
          ["Particulate Matter Sensor", "particulate_matter_sensor"],
          ["PH Sensor", "ph_sensor"],
          ["Pi Camera", "pi_camera"],
          ["PIR Sensor", "pir_sensor"],
          ["Power Meter ", "power_meter"],
          ["Pulse Oximeter", "pulseoximeter"],
          ["Raindrop Sensor", "raindrop"],
          ["Relay", "relay"],
          ["Robotics", "robotics"],
          ["Robotic Arm", "robotic_arm"],
          ["RTC", "rtc"],
          ["Serial", "serial"],
          ["Servo Motor", "servo motor"],
          ["SMBus", "smbus"],
          ["Sound Sensor", "sound_sensor"],
          ["Stepper Motor", "stepper_motor"],
          ["TDS Sensor", "tds_sensor"],
          ["Telepot", "telepot"],
          ["Time", "time"],
          ["Ultrasonic Sensor", "ultrasonic_sensor"],
          ["USB Camera", "usb_camera"],
          ["Vibration Sensor", "vibration_sensor"],
          ["Weight Sensor", "weight_sensor"],
        ],
      },],
      inputsInline: true,
      previousStatement: null,
      nextStatement: null,
      colour: "#935ba5",
      tooltip: "",
      helpUrl: "",
    });
  },
};

Blockly.Python["use_iiot"] = function (block) {
  var dropdown_import = block.getFieldValue("import");
  // TODO: Assemble Python into code variable.
  var code = "";
  switch (dropdown_import) {
    case "accelerometer":
      code = "import Adafruit_ADXL345\naccel = Adafruit_ADXL345.ADXL345()\n"
      break;
    case "compass_sensor":
      code = "import smbus\nimport math\n\nRegister_A = 0\nRegister_B = 0x01\nRegister_mode = 0x02\n\nX_axis_H = 0x03\nZ_axis_H = 0x05\nY_axis_H = 0x07\ndeclination = -0.00669\npi = 3.14159265359\n\nbus = smbus.SMBus(1)\nDevice_Address = 0x1e\n\ndef Magnetometer_Init():\n\tbus.write_byte_data(Device_Address, Register_A, 0x70)\n\tbus.write_byte_data(Device_Address, Register_B, 0xa0)\n\tbus.write_byte_data(Device_Address, Register_mode, 0)\n\ndef read_raw_data(addr):\n\thigh = bus.read_byte_data(Device_Address, addr)\n\tlow = bus.read_byte_data(Device_Address, addr + 1)\n\tvalue = ((high << 8) | low)\n\tif (value > 32768):\n\t\tvalue = value - 65536\n\treturn value\n\ndef calculate_angle():\n\tx = read_raw_data(X_axis_H)\n\tz = read_raw_data(Z_axis_H)\n\ty = read_raw_data(Y_axis_H)\n\theading = math.atan2(y, x) + declination\n\tif (heading > 2 * pi):\n\t\theading = heading - 2 * pi\n\tif (heading < 0):\n\t\theading = heading + 2 * pi\n\treturn int(heading * 180 / pi)\n\nMagnetometer_Init()\n";
      break;
    case "relay":
    case "robotics":
    case "ultrasonic_sensor":
    case "moisture_sensor":
    case "buzzer":
    case "ir_sensor":
    case "pir_sensor":
    case "raindrop":
    case "alcohol_sensor":
    case "ldr_sensor":
    case "sound_sensor":
    case "button":
    case "led":
    case "keypad":
    case "motion_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "time":
      code = "import time\n";
      break;
    case "pi_camera":
      code = `from picamera import PiCamera\n`;
      break;
    case "temperature":
    case "humidity":
      code = `import Adafruit_DHT\n`;
      break;
    case "gps_sensor":
      code = "import serial\nimport pynmea2" + "\n";
      break;
    case "robotics":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)" +
        "\n";
      break;
    case "smbus":
      code = "import smbus" + "\n";
      break;
    case "telepot":
      code =
        "import telepot" +
        "\n";
      break;
    case "graphs":
      code = "import matplotlib.pyplot as plt\n"
      break;
    case "files":
      code = "import pandas as pd\n"
      break;
    case "statistics":
      code = "import numpy as np\nnp.set_printoptions(suppress=True) # prevent numpy exponential\n"
      break;
    case "servo motor":
      code =
        "import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n";
      break;
    case "exit":
      code = `from sys import exit\n`;
      break;
    case "pulseoximeter":
      code = `import sys\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')\nimport max30100\nmx30 = max30100.MAX30100()\nmx30.enable_spo2()\n`;
      break;
    case "tds_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "flame_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "flow_sensor":
      code = `import RPi.GPIO as GPIO\nimport time, sys\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "ph_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n\n`;
      break;
    case "rtc":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import init_rtc\nfrom grok_i2c_peripherals import rtc_read_time\nfrom grok_i2c_peripherals import rtc_read_date_str\nfrom grok_i2c_peripherals import rtc_read_time_str\nfrom grok_i2c_peripherals import rtc_write_time\nfrom grok_i2c_peripherals import rtc_write_date\n`;
      break;
    case "usb_camera":
      code = `from cv2 import *\ncam_port = 0\n`;
      break;
    case "power_meter":
      code =
        `import time\nimport json\nimport serial\nimport modbus_tk.defines as cst\nfrom modbus_tk import modbus_rtu\n` +
        `serial = serial.Serial(port='/dev/ttyS0',baudrate=9600,bytesize=8,parity='N',stopbits=1,xonxoff=0)\nmaster = modbus_rtu.RtuMaster(serial)\nmaster.set_timeout(2.0)\nmaster.set_verbose(True)\ndict_payload = dict()\n`;
      break;
    case "serial":
      code = "import serial\n";
      break;
    case "weight_sensor":
      code = `import sys` + 
        `\nsys.path.append('/home/pi/Desktop/Grok-Downloads/Custom_lib')` +
        `\nEMULATE_HX711=False` +
        `\nreferenceUnit = 261` +
        `\nif not EMULATE_HX711:` +
        `\n\timport RPi.GPIO as GPIO` +
        `\n\tfrom hx711 import HX711` +
        `\nelse:` +
        `\n\tfrom emulated_hx711 import HX711` +
        `\ndef cleanAndExit():` +
        `\n\tprint("Cleaning...")` +
        `\n\tif not EMULATE_HX711:` +
        `\n\t\tGPIO.cleanup()` +
        `\n\tprint("Bye!")` +
        `\n\tsys.exit()\n`;
      break;
    case "Dc_drive":
      code = "PWM1_Fine = None" +
        `\nPWM2_Course = None` +
        `\nimport RPi.GPIO as GPIO` +
        `\nGPIO.setwarnings(False)` +
        `\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "vibration_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gsr_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "ecg_sensor":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "gas_sensor_analog":
      code = `from grok_i2c_peripherals import init_i2c_bus\nfrom grok_i2c_peripherals import read_adc\nfrom grok_i2c_peripherals import write_analog\ninit_i2c_bus()\n`;
      break;
    case "hall_effect_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "metal_detector_sensor":
      code = `import RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\n`;
      break;
    case "robotic_arm":
      code = `import subprocess\n`+`try:\n`+`\timport pydobot\n`+`except ImportError:\n`+`\tsubprocess.check_call(['pip', 'install', "pydobot"])\n`+`from serial.tools import list_ports\n`+`from pydobot import Dobot\n`+`available_ports = list_ports.comports()\n`+`print(f'available ports: {[x.device for x in available_ports]}')\n`+`port = available_ports[0].device\n`+`print(port)\n`+`dobot=Dobot(port)\n`;
      break;
    case "lcd_display":
      code = `from rpi_lcd import LCD\nlcd_display_I2C = LCD()\n`;
      break;
    case "json":
      code = `import json\n`;
      break;
    case "particulate_matter_sensor":
      code = `import serial\nimport RPi.GPIO as GPIO\nGPIO.setwarnings(False)\nGPIO.setmode(GPIO.BCM)\nimport time\n\nser = serial.Serial('/dev/ttyUSB0', 9600)\n\ndef get_pm25():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm25 = (data[3] * 256 + data[2]) / 10.0\n\telse:\n\t\tpm25 = 0\n\treturn pm25\n\ndef get_pm10():\n\tdata = ser.read(10)\n\tif data[0] == 170 and data[1] == 192:\n\t\tpm10 = (data[5] * 256 + data[4]) / 10.0\n\telse:\n\t\tpm10 = 0\n\treturn pm10\n`;
      break;
    case "bloodpressure_sensor":
      code = `import serial\nserial_input_data = serial.Serial('/dev/serial0', 9600)\n\n`;
      break;
    case "gyroscope":
      code = `import smbus\nPWR_MGMT_1 = 0x6B\nSMPLRT_DIV = 0x19\nCONFIG = 0x1A\nGYRO_CONFIG = 0x1B\nINT_ENABLE = 0x38\nACCEL_XOUT_H = 0x3B\nACCEL_YOUT_H = 0x3D\nACCEL_ZOUT_H = 0x3F\nGYRO_XOUT_H = 0x43\nGYRO_YOUT_H = 0x45\nGYRO_ZOUT_H = 0x47\n`+
      `def MPU_Init():\n\tbus.write_byte_data(Device_Address, SMPLRT_DIV, 7)\n\tbus.write_byte_data(Device_Address, PWR_MGMT_1, 1)\n\tbus.write_byte_data(Device_Address, CONFIG, 0)\n\tbus.write_byte_data(Device_Address, GYRO_CONFIG, 24)\n\tbus.write_byte_data(Device_Address, INT_ENABLE, 1)\n`+
      `def read_raw_data(addr):\n\thigh = bus.read_byte_data(Device_Address, addr)\n\tlow = bus.read_byte_data(Device_Address, addr + 1)\n\tvalue = (high << 8) | low\n\tif value > 32768:\n\t\tvalue = value - 65536\n\treturn value\nbus = smbus.SMBus(1)  # or bus=smbus.SMBus(0) for older version boards\nDevice_Address = 0x68 # MPU6050 device address\nMPU_Init()\n\n`;
      break;
    case "stepper_motor":
      code = `import RPi.GPIO as GPIO\nGPIO.setmode(GPIO.BCM)\nimport time\n\ndef calculate_delay(speed_percentage):\n\tmax_delay = 0.02  \n\tmin_delay = 0.0005 \n\tif speed_percentage < 0:\n\t\tspeed_percentage = 0\n\telif speed_percentage > 100:\n\t\tspeed_percentage = 100\n\treturn max_delay - (speed_percentage / 100) * (max_delay - min_delay)\n\ndef run_motor(speed_percentage, direction, STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\ttry:\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\texcept KeyboardInterrupt:\n\t\tpass\n\ndef move_steps(steps, speed_percentage, direction,STEP_PIN,DIR_PIN):\n\tdelay = calculate_delay(speed_percentage)\n\tGPIO.output(DIR_PIN, GPIO.LOW if direction == "clockwise" else GPIO.HIGH)\n\tfor _ in range(steps):\n\t\tGPIO.output(STEP_PIN, GPIO.HIGH)\n\t\ttime.sleep(delay)\n\t\tGPIO.output(STEP_PIN, GPIO.LOW)\n\t\ttime.sleep(delay)\n\n`
      break;
  }
  return code;
};

Blockly.Blocks["read_object_temperature"] = {
  init: function () {
    this.jsonInit({
      "type": "read_object_temperature",
      "message0": "Read Object Temperature",
      "output": null,
      "colour": "#3ca390",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['read_object_temperature'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'readObjectTemperature()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["read_ambient_temperature"] = {
  init: function () {
    this.jsonInit({
      "type": "read_ambient_temperature",
      "message0": "Read Ambient Temperature",
      "output": null,
      "colour": "#3ca390",
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['read_ambient_temperature'] = function (block) {
  // TODO: Assemble Python into code variable.
  var code = 'readAmbientTemperature()';
  return [code, Blockly.Python.ORDER_NONE];
};

Blockly.Blocks["set_pin_as_output"] = {
  init: function () {
    let data = BLOCKS_GPIO_JSON;
    data['type'] = "set_pin_as_output";
    data['message0'] = "Set Pin %1 as Output";
    data['colour'] = "345";
    this.jsonInit(data);
  },
};

Blockly.Python["set_pin_as_output"] = PYTHON_GPIO_OUTPUT;

Blockly.Blocks["set_output_pin"] = {
  init: function () {
    var options = ["HIGH", "LOW"].map(value => [value, value]);
    this.jsonInit({
      "type": "set_output_pin",
      "message0": "Set Output Pin %1 %2",
      "args0": [
        {
          "type": "input_value",
          "name": "value"
        },
        {
          "type": "field_dropdown",
          "name": "OutputPinOptions",
          "options": options
        },
      ],
      "previousStatement": null,
      "nextStatement": null,
      "colour": '345',
      "tooltip": "",
      "helpUrl": ""
    });
  },
};

Blockly.Python['set_output_pin'] = function (block) {
  var value = Blockly.Python.valueToCode(block, "value", Blockly.Python.ORDER_ATOMIC) || 'None';
  var option_value = block.getFieldValue('OutputPinOptions');
  // TODO: Assemble Python into code variable.
  var code = `GPIO.output(${value}, GPIO.${option_value})\n`;
  return code;
};


// Initialize the "set_up_snake_game" block with its Python code
initializeLabelBlockWithPython(
  "set_up_snake_game",
  "Set up Snake Game",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 1. Load Snake game tools\nimport cv2\nimport imutils\nimport numpy as np\n\n` +
  `# Constants\nMAX_SCORE = 20\nMAX_LENGTH_CAPACITY = 20\nAPPLE_RADIUS = 3\nSNAKE_RADIUS_THRESHOLD = 10\nDISTANCE_THRESHOLD = 3.5\n\n` +
  `# Game state variables\nscore = 0\nlength_capacity = 0\nsnake_body = []\nis_winner = False\napple_position = None\ncenter = None\n\n` +
  `# Distance calculation function\ndef calculate_distance(point1, point2):\n    return np.sqrt((point1[0] - point2[0]) ** 2 + (point1[1] - point2[1]) ** 2)\n\n` +
  `def generate_random_apple_position(frame_shape):\n    x = np.random.randint(30, frame_shape[1] - 30)\n    y = np.random.randint(100, 350)\n    return (x, y)\n\n`
);

// Initialize the "capture_camera_frame_for_snake_game" block with its Python code
initializeLabelBlockWithPython(
  "capture_camera_frame_for_snake_game",
  "Capture camera frame for Snake Game",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 2. Capture and process image to Snake Game frame\nret, frame = cap.read()\nimg = imutils.resize(frame.copy(), width=600)\nimg = cv2.GaussianBlur(img, (11, 11), 0)\nimg_hsv = cv2.cvtColor(img, cv2.COLOR_BGR2HSV)\n\n\n`
);

// Initialize the "draw_an_apple" block with its Python code
initializeLabelBlockWithPython(
  "draw_an_apple",
  "Draw an Apple",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 3. Draw an apple if not present\nif apple_position is None:\n    apple_position = generate_random_apple_position(frame.shape)\n\ncv2.circle(frame, apple_position, APPLE_RADIUS, (0, 0, 255), -1)\n\n\n`
);

const colorBounds = [
  { name: "color_lower_bound", inputExecutionOrder: BlockOrderType.ATOMIC, check: "Array" },
  { name: "color_upper_bound", inputExecutionOrder: BlockOrderType.ATOMIC, check: "Array" }
];

// Initialize the "move_snake_with_object_of_color" block with its Python code
initializeDynamicInputValuePythonBlock(
  'move_snake_with_object_of_color',
  'Move the Snake with object of color: Lower Bound %1 and Upper Bound %2',
  '#266e50',
  { 
    connectionType: BlockConnectionType.SEQUENTIAL
  },
  colorBounds,
  `# 4. Move the snake with an object of color\n# Create mask for color below (snake controller)\ncolor_lower_bound = ##${colorBounds[0].name}##\ncolor_upper_bound = ##${colorBounds[1].name}##\nmask = cv2.inRange(img_hsv, color_lower_bound, color_upper_bound)\nmask = cv2.erode(mask, None, iterations=2)\nmask = cv2.dilate(mask, None, iterations=2)\n\n# Find contours of the masked image\ncontours = cv2.findContours(mask, cv2.RETR_EXTERNAL, cv2.CHAIN_APPROX_SIMPLE)\ncontours = imutils.grab_contours(contours)`
);

// Initialize the "check_if_the_snake_ate_an_apple" block with its Python code
initializeLabelBlockWithPython(
  "check_if_the_snake_ate_an_apple",
  "Check if the Snake ate an Apple",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 5. Check if snake ate the apple\nif contours:\n    largest_contour = max(contours, key=cv2.contourArea)\n    (x, y), radius = cv2.minEnclosingCircle(largest_contour)\n\n    moments = cv2.moments(largest_contour)\n    if moments['m00'] != 0:\n        center = (int(moments['m10'] / moments['m00']), int(moments['m01'] / moments['m00']))\n\n        if radius > SNAKE_RADIUS_THRESHOLD:\n            cv2.circle(frame, center, 2, (0, 0, 255), 3)\n\n            if len(snake_body) > length_capacity:\n                snake_body = snake_body[1:]\n\n            if 'prev_center' in locals() and calculate_distance(prev_center, center) > DISTANCE_THRESHOLD:\n                snake_body.append(center)\n\n            # Check if the snake has eaten the apple\n            if calculate_distance(apple_position, center) < APPLE_RADIUS:\n                score += 1\n                if score == MAX_SCORE:\n                    is_winner = True\n                length_capacity += 1\n                apple_position = None\n\n\n`
);

// Initialize the "update_the_snake_on_game_frame" block with its Python code
initializeLabelBlockWithPython(
  "update_the_snake_on_game_frame",
  "Update the Snake on Game frame",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 6. Update the snake on game frame\nfor i in range(1, len(snake_body)):\n    if snake_body[i - 1] is None or snake_body[i] is None:\n        continue\n    r, g, b = np.random.randint(0, 255, 3)\n    thickness = int(len(snake_body) / MAX_LENGTH_CAPACITY + 2) + 2\n    cv2.line(frame, snake_body[i], snake_body[i - 1], (int(r), int(g), int(b)), thickness)\n\n\n`
);

// Initialize the "update_the_score_on_game_frame" block with its Python code
initializeLabelBlockWithPython(
  "update_the_score_on_game_frame",
  "Update the score on Game frame",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# 7. Update the score on game frame\ncv2.putText(frame,'Score :'+str(score),(450,100),cv2.FONT_HERSHEY_SIMPLEX,1,(255,0,203),2)\n# Show winning message if applicable\nif is_winner:\n    cv2.putText(frame, 'YOU WIN !!', (100, 250), cv2.FONT_HERSHEY_SIMPLEX, 3, (255, 255, 0), 3)\n\n`
);

// Initialize the "draw_the_frame_on_screen" block with its Python code
initializeLabelBlockWithPython(
  "draw_the_frame_on_screen",
  "Draw the frame on the screen",
  "#266e50",
  BlockConnectionType.SEQUENTIAL,
  `# Show the updated game frame\ncv2.imshow('Live Feed', frame)\nprev_center = center\n\n# Exit on 'q' key press\nif cv2.waitKey(10) == ord('q'):\n    break\n\n`
);

const particulateMatterDropdownConfig = [
  {
    name: "particulateMatterFunction",
    options: [
      ["pm10", "10"],
      ["pm2.5", "25"],
    ],
  }
];

// Initialize the "read_particulate_matter_sensor_data" block with its Python code
initializeDynamicFieldDropdownBlock(
  "read_particulate_matter_sensor_data",
  "Read Particulate Matter Sensor Data for %1",
  "#dd4120",
  BlockConnectionType.OUTPUT,
  particulateMatterDropdownConfig,
  `get_pm##${particulateMatterDropdownConfig[0].name}##()`
);

// Initialize the "read_blood_pressure_data" block with its Python code
initializeLabelBlockWithPython(
  "read_blood_pressure_data",
  "Read Blood pressure Data",
  "#5c7212",
  { 
    connectionType: BlockConnectionType.OUTPUT,
    orderType: BlockOrderType.NONE
  },
  `serial_input_data.readline().decode("utf-8").rstrip().split(",")`
);

// Function to generate the gyroscope data reading code
const generateGyroscopeDataCode = (() => {
  const gyroDataAddresses = ["gyro_xout_h", "gyro_yout_h", "gyro_zout_h"];

  return gyroDataAddresses
    .map(addr => `${addr.slice(0, -5)} = read_raw_data(${addr.toUpperCase()})`)
    .join('\n') + '\n';
})();

// Initialize the "read_gyroscope_data" block with its Python code
initializeLabelBlockWithPython(
  "read_gyroscope_data",
  "Read Gyroscope Data",
  "#128076",
  BlockConnectionType.SEQUENTIAL,
  generateGyroscopeDataCode
);

const gyroAxisDropdownConfigs = [
  {
    name: "gyro_axis",
    options: [
      ["x", "x"],
      ["y", "y"],
      ["z", "z"],
    ],
  }
];

// Initialize the "read_gyroscope_value" block with its Python code
initializeDynamicFieldDropdownBlock(
  "read_gyroscope_value",
  "Read value for %1",
  "#128076",
  BlockConnectionType.OUTPUT,
  gyroAxisDropdownConfigs,
  `int(gyro_##${gyroAxisDropdownConfigs[0].name}##/131.0*9.81)`
);

const stepperMotorPins = [
  { name: "step_pin", inputExecutionOrder: BlockOrderType.ATOMIC, check: "Number", align: "RIGHT" },
  { name: "direction_pin", inputExecutionOrder: BlockOrderType.ATOMIC, check: "Number", align: "LEFT" }
];

const setupStepperMotorPinsCode = stepperMotorPins.map(pin => `GPIO.setup(##${pin.name}##, GPIO.OUT)`).join("\n") + '\n';

// Initialize the "setup_stepper_motor_pins" block with its Python code
initializeDynamicInputValuePythonBlock(
  'setup_stepper_motor_pins',
  'Setup Step Pin At %1 Setup Direction Pin At %2',
  '#00556c',
  { 
    connectionType: BlockConnectionType.SEQUENTIAL
  },
  stepperMotorPins,
  setupStepperMotorPinsCode,
  false
);

Blockly.Blocks['move_stepper_motor'] = {
  init: function() {

    // Add a dummy input field for the block label
    this.appendDummyInput()
        .appendField('\t\t\t\t\t\t\tMove Stepper Motor');

    // Define the 'Steps' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "Steps", fieldName: "steps", checkType: "Number" });

    // Define the 'Speed' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "Speed", fieldName: "speed", checkType: "Number" });

    // Direction dropdown
    appendDynamicField(this, {
      fieldType: "dropdown",
      label: "\t\t\t\t\t\t\tDirection",
      fieldName: "direction",
      dropdownOptions: [
        ["Clockwise", "clockwise"],
        ["AntiClockwise", "anticlockwise"],
      ],
    });

    // Define the 'Step Pin' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "Step Pin", fieldName: "stepPin", checkType: "Number" });

    // Define the 'DIR Pin' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "DIR Pin", fieldName: "dirPin", checkType: "Number" });

    // Set properties for the block
    this.setInputsInline(false);            // Set inputsInline to false to stack inputs vertically for better layout
    this.setPreviousStatement(true, null);  // Allows the block to connect before another block
    this.setNextStatement(true, null);      // Allows the block to connect after another block
    this.setColour("#00556c");
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Python["move_stepper_motor"] = function (block) {
  // Define the blockFields in the order they should appear
  const blockFields = ["steps", "speed", "stepPin", "dirPin"];
  const motorParams = blockFields.map(name => getBlockValue(block, name));
  const direction = block.getFieldValue("direction");

  // Insert the direction at the correct index (index 2)
  motorParams.splice(2, 0, `"${direction}"`);

  let code = `move_steps(${motorParams.join(", ")})\n`;
  return code;
};

Blockly.Blocks['run_stepper_motor'] = {
  init: function() {

    // Add a dummy input field for the block label
    this.appendDummyInput()
        .appendField('\t\t\t\t\t\t\tRun Stepper Motor');

    // Define the 'Speed' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "Speed", fieldName: "speed", checkType: "Number" });

    // Direction dropdown
    appendDynamicField(this, {
      fieldType: "dropdown",
      label: "\t\t\t\t\t\t\tDirection",
      fieldName: "direction",
      dropdownOptions: [
        ["Clockwise", "clockwise"],
        ["AntiClockwise", "anticlockwise"],
      ],
    });

    // Define the 'Step Pin' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "Step Pin", fieldName: "stepPin", checkType: "Number" });

    // Define the 'DIR Pin' input field
    appendDynamicField(this, { fieldType: "inputValue", label: "DIR Pin", fieldName: "dirPin", checkType: "Number" });

    // Set properties for the block
    this.setInputsInline(false);            // Set inputsInline to false to stack inputs vertically for better layout
    this.setPreviousStatement(true, null);  // Allows the block to connect before another block
    this.setNextStatement(true, null);      // Allows the block to connect after another block
    this.setColour("#00556c");
    this.setTooltip('');
    this.setHelpUrl('');
  }
};

Blockly.Python["run_stepper_motor"] = function (block) {
  // Define the blockFields in the order they should appear
  const blockFields = ["speed", "stepPin", "dirPin"];
  const motorParams = blockFields.map(name => getBlockValue(block, name));
  const direction = block.getFieldValue("direction");

  // Insert the direction at the correct index (index 1)
  motorParams.splice(1, 0, `"${direction}"`);

  let code = `run_motor(${motorParams.join(", ")})\n`;
  return code;
};
